import React from "react";
import { ITabsListProps } from "./TabsList.types";
import { Tab, TabProps, Tabs, TabsProps, styled } from "@mui/material";
import { a11yProps } from "./utils";

export const TabsList: React.FC<ITabsListProps> = (props) => {
  const { selectedTab, varaint, tabs, onChange } = props;

  return (
    <CustomTabs
      value={selectedTab}
      variant={varaint}
      scrollButtons="auto"
      sx={props.sx}
      onChange={onChange}
      TabIndicatorProps={{ hidden: true }}
    >
      {tabs.map((tab, index) => {
        if (tab.hidden) return <></>;
        return (
          <CustomTab
            icon={tab.icon}
            iconPosition={tab.iconPosition || "start"}
            label={tab.label}
            disabled={tab.disabled}
            hidden={tab.hidden}
            {...a11yProps(tab.index !== undefined ? tab.index : index)}
          />
        );
      })}
    </CustomTabs>
  );
};

export const CustomTabs = styled((props: TabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(73,82,88,0.12)" : "#fff"
    }`,
    boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
    padding: "2px",
    borderRadius: "15px",
    [theme.breakpoints.up("md")]: {
      padding: "2px",
      borderRadius: "10px",
      minHeight: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "4px",
      borderRadius: "15px",
      
    },
    [theme.breakpoints.up("xl")]: {
      padding: "8px",
      borderRadius: "15px",
      minHeight: "40px",
    },
    "& .Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff !important",
      borderRadius: "15px",
      fontWeight: 800,
      [theme.breakpoints.up("md")]: {
        borderRadius: "10px",
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "13px",
        
      },
      [theme.breakpoints.up("xl")]: {
        borderRadius: "15px",
      },
    },
  })
);

export const CustomTab = styled((props: TabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    fontSize: "1rem",
    padding: "15px",
    [theme.breakpoints.up("md")]: {
      padding: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "15px",
    },
  })
);
