import { action } from "typesafe-actions";
import { IBusinessPolicyRestrutant } from "./businessPolicyRestrutant.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IBusinessPolicyRiskLocation } from "../buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";

export const FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_FAILED";

export const fetchBusinessPolicyRestrutantListProgress = () =>
  action(FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_PROGRESS);
export const fetchBusinessPolicyRestrutantListSuccess = (
  data: IBusinessPolicyRestrutant[]
) => action(FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_SUCCESS, { data });
export const fetchBusinessPolicyRestrutantListFailed = () =>
  action(FETCH_BUSINESS_POLICY_RESTRUTANT_LIST_FAILED);

export const fetchBusinessPolicyRestrutantListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyRestrutantListProgress());

      let finalUrl = `/policy/get-policy-business-restaurant?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyRestrutant[] = res.data.data;
      dispatch(fetchBusinessPolicyRestrutantListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyRestrutantListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_RESTRUTANT_PROGRESS =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_PROGRESS";
export const FETCH_BUSINESS_POLICY_RESTRUTANT_SUCCESS =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_SUCCESS";
export const FETCH_BUSINESS_POLICY_RESTRUTANT_FAILED =
  "FETCH_BUSINESS_POLICY_RESTRUTANT_FAILED";

export const fetchBusinessPolicyRestrutantProgress = () =>
  action(FETCH_BUSINESS_POLICY_RESTRUTANT_PROGRESS);
export const fetchBusinessPolicyRestrutantSuccess = (
  data: IBusinessPolicyRestrutant
) => action(FETCH_BUSINESS_POLICY_RESTRUTANT_SUCCESS, { data });
export const fetchBusinessPolicyRestrutantFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_RESTRUTANT_FAILED, { errorMessage });

export const fetchBusinessPolicyRestrutantAsync =
  (
    customerPolicyId: number,
    restrutantNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyRestrutantProgress());
      const res = await api.get(
        `/policy/get-policy-business-restaurant?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${restrutantNumber}`
      );
      const data: IBusinessPolicyRestrutant[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyRestrutantSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyRestrutantFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyRestrutantFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertBusinesPolicyRestrutantAsync =
  (
    data: IBusinessPolicyRestrutant,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-restaurant`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Restrutant saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_RESTRUTANT =
  "CLEAR_BUSINESS_POLICY_RESTRUTANT";
export const CLEAR_BUSINESS_POLICY_RESTRUTANT_STATE =
  "CLEAR_BUSINESS_POLICY_RESTRUTANT_STATE";

export const clearBusinessPolicyRestrutantState = () =>
  action(CLEAR_BUSINESS_POLICY_RESTRUTANT_STATE);

export const clearBusinessPolicyRestrutant = () =>
  action(CLEAR_BUSINESS_POLICY_RESTRUTANT);
