import produce from "immer";
import {
  FETCH_AUTOMOBILE_POLICY_FORMS_LIST_PROGRESS,
  FETCH_AUTOMOBILE_POLICY_FORMS_LIST_FAILED,
  FETCH_AUTOMOBILE_POLICY_FORMS_LIST_SUCCESS,
  FETCH_AUTOMOBILE_AUTHORIZATION_FAILED,
  FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS,
  FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS,
  FETCH_AUTOMOBILE_CONSENT_FORM_FAILED,
  FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS,
  FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS,
  FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED,
  FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS,
  FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS,
  FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED,
  FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS,
  FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS,
  FETCH_AUTOMOBILE_OPCF16_FAILED,
  FETCH_AUTOMOBILE_OPCF16_PROGRESS,
  FETCH_AUTOMOBILE_OPCF16_SUCCESS,
  FETCH_AUTOMOBILE_OPCF17_FAILED,
  FETCH_AUTOMOBILE_OPCF17_PROGRESS,
  FETCH_AUTOMOBILE_OPCF17_SUCCESS,
  FETCH_AUTOMOBILE_OPCF28A_FAILED,
  FETCH_AUTOMOBILE_OPCF28A_PROGRESS,
  FETCH_AUTOMOBILE_OPCF28A_SUCCESS,
  FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED,
  FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS,
  FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS,
  FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED,
  FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS,
  FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS,
  CLEAR_AUTOMOBILE_POLICY_FORMS_STATE,
} from "./automobilePolicyFormsActions";
import { IStoreState } from "../initialStoreState";
import { initialAutomobilePolciyFormsState } from "./automobliePolicyForms.types";
import { AutomobilePolicyFormsActions } from ".";
import { LoadState } from "../../constants/enums";
export const AutomobilePolicyFormsReducer = (
  state: IStoreState["automobile"]["policyForms"] = initialAutomobilePolciyFormsState,
  action: AutomobilePolicyFormsActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_POLICY_FORMS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.InProgress;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_POLICY_FORMS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Loaded;
        draftState.policyFormsList.list = data;
        draftState.policyFormsList.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_POLICY_FORMS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Failed;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.liabilitySlips.loading = LoadState.InProgress;
        draftState.liabilitySlips.data =
          initialAutomobilePolciyFormsState["liabilitySlips"].data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Loaded;
        draftState.liabilitySlips.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Failed;
        draftState.liabilitySlips.data =
          initialAutomobilePolciyFormsState["liabilitySlips"].data;
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.InProgress;
        draftState.liabilityInsurance.data =
          initialAutomobilePolciyFormsState["liabilityInsurance"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Loaded;
        draftState.liabilityInsurance.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Failed;
        draftState.liabilityInsurance.data =
          initialAutomobilePolciyFormsState["liabilityInsurance"]["data"];
        draftState.liabilityInsurance.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_OPCF16_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.InProgress;
        draftState.opcf16.data =
          initialAutomobilePolciyFormsState["opcf16"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF16_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Loaded;
        draftState.opcf16.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF16_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Failed;
        draftState.opcf16.data =
          initialAutomobilePolciyFormsState["opcf16"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_OPCF17_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.InProgress;
        draftState.opcf17.data =
          initialAutomobilePolciyFormsState["opcf17"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF17_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Loaded;
        draftState.opcf17.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF17_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Failed;
        draftState.opcf17.data =
          initialAutomobilePolciyFormsState["opcf17"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_OPCF28A_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.InProgress;
        draftState.opcf28A.data =
          initialAutomobilePolciyFormsState["opcf28A"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF28A_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Loaded;
        draftState.opcf28A.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_OPCF28A_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Failed;
        draftState.opcf28A.data =
          initialAutomobilePolciyFormsState["opcf28A"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.InProgress;
        draftState.paymentAuthorization.data =
          initialAutomobilePolciyFormsState["paymentAuthorization"]["data"];
        draftState.paymentAuthorization.error = null;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Loaded;
        draftState.paymentAuthorization.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Failed;
        draftState.paymentAuthorization.data =
          initialAutomobilePolciyFormsState["paymentAuthorization"]["data"];
        draftState.paymentAuthorization.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.InProgress;
        draftState.privacyConsent.data =
          initialAutomobilePolciyFormsState["privacyConsent"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Loaded;
        draftState.privacyConsent.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Failed;
        draftState.privacyConsent.data =
          initialAutomobilePolciyFormsState["privacyConsent"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.InProgress;
        draftState.retireediscount.data =
          initialAutomobilePolciyFormsState["retireediscount"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.Loaded;
        draftState.retireediscount.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireediscount.loading = LoadState.Failed;
        draftState.retireediscount.data =
          initialAutomobilePolciyFormsState["retireediscount"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.InProgress;
        draftState.rideSharing.data =
          initialAutomobilePolciyFormsState["rideSharing"]["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.Loaded;
        draftState.rideSharing.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.Failed;
        draftState.rideSharing.data =
          initialAutomobilePolciyFormsState["rideSharing"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_POLICY_FORMS_STATE: {
      return initialAutomobilePolciyFormsState;
    }

    default: {
      return state;
    }
  }
};
