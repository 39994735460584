import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IBusinessPolicyContractor } from "./businessPolicyContractor.type";

export const FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_FAILED";

export const fetchBusinessPolicyContractorListProgress = () =>
  action(FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_PROGRESS);
export const fetchBusinessPolicyContractorListSuccess = (
  data: IBusinessPolicyContractor[]
) => action(FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_SUCCESS, { data });
export const fetchBusinessPolicyContractorListFailed = () =>
  action(FETCH_BUSINESS_POLICY_CONTRACTOR_LIST_FAILED);

export const fetchBusinessPolicyContractorListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyContractorListProgress());

      let finalUrl = `/policy/get-policy-business-contractor?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyContractor[] = res.data.data;
      dispatch(fetchBusinessPolicyContractorListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyContractorListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_CONTRACTOR_PROGRESS =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_PROGRESS";
export const FETCH_BUSINESS_POLICY_CONTRACTOR_SUCCESS =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_SUCCESS";
export const FETCH_BUSINESS_POLICY_CONTRACTOR_FAILED =
  "FETCH_BUSINESS_POLICY_CONTRACTOR_FAILED";

export const fetchBusinessPolicyContractorProgress = () =>
  action(FETCH_BUSINESS_POLICY_CONTRACTOR_PROGRESS);
export const fetchBusinessPolicyContractorSuccess = (
  data: IBusinessPolicyContractor
) => action(FETCH_BUSINESS_POLICY_CONTRACTOR_SUCCESS, { data });
export const fetchBusinessPolicyContractorFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_CONTRACTOR_FAILED, { errorMessage });

export const fetchBusinessPolicyContractorAsync =
  (
    customerPolicyId: number,
    CONTRACTORNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyContractorProgress());
      const res = await api.get(
        `/policy/get-policy-business-contractor?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${CONTRACTORNumber}`
      );
      const data: IBusinessPolicyContractor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyContractorSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyContractorFailed(
            "Oops! We couldn't find any records associated with your contractor number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyContractorFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertBusinesPolicyContractorAsync =
  (
    restrutanr: IBusinessPolicyContractor,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-contractor`, restrutanr);
      dispatch(
        showMessage({
          type: "success",
          message: "Contractor saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINESS_POLICY_CONTRACTOR =
  "CLEAR_BUSINESS_POLICY_CONTRACTOR";
export const CLEAR_BUSINESS_POLICY_CONTRACTOR_STATE =
  "CLEAR_BUSINESS_POLICY_CONTRACTOR_STATE";

export const clearBusinessPolicyContractorState = () =>
  action(CLEAR_BUSINESS_POLICY_CONTRACTOR_STATE);

export const clearBusinessPolicyContractor = () =>
  action(CLEAR_BUSINESS_POLICY_CONTRACTOR);
