import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearHabitationalCoverageState,
  fetchHabitationalCoverageListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoveragesActions";
import { IHabitationalCoverages } from "../../../../../../redux/habitationalPolicy/habitationalCoverages/habitationalCoverages.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { CustomSelect } from "../../../../../../components/formsComponents";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const HabitationalCoveragesList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.coverages
  );
  const dispatch = useDispatchWrapper();
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchHabitationalCoverageListAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearHabitationalCoverageState());
    };
  }, [customerPolicyId, status]);

  const coverageTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                {" "}
                {/* <MenuItem value="-1">All Status</MenuItem> */}
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalCoverages) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalCoverages) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "location_number",
        headerName: "Description",
        fieldName: "location_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalCoverages) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/habitational-coverages/${customerPolicyId}/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "create_ts",
        headerName: "Created Date",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE,
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Coverage"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...coverageTableProps} />
    </>
  );
};
