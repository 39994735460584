import axios from "axios";
import { getUserAuth } from "../helpers";

export const API_BASE_URL = process.env["REACT_APP_SERVER_BASE_URL"];

export const OTHER_USER_ACCESS_SUB_DOMAIN = process.env["REACT_APP_OTHER_USER_ACCESS_DOMAIN"];

export const api = axios.create({
  baseURL: process.env["REACT_APP_SERVER_URL"],
});

export const sendGrigApi = axios.create({
  baseURL: process.env["REACT_APP_SEND_GRID"],
});

let isAlert = false;

const logoutUser = () => {
  // Perform any necessary cleanup tasks, e.g., clearing local storage
  localStorage.clear();
  isAlert = true;
  // Redirect to the login page or perform any other required action
  window.alert("Attention: Your session has expired. Please log in again to continue accessing the system. Thank you!")
  window.location.href = "/auth/login"; // Replace with your desired logout destination
};

api.interceptors.request.use((config) => {
  const accessToken = getUserAuth();
  
  if (accessToken && config.headers) {
    config.headers["auth-Token"] = `${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if(!isAlert){
      logoutUser();
      }
      // const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');
      // const currentTime = new Date().getTime() / 1000; // Convert to seconds

      // if (currentTime > accessTokenExpiresAt) {
      //   // Access token has expired, trigger logout
      //   logoutUser();
      // }
    }
    
    return Promise.reject(error);
    
  }
);
