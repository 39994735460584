import { action } from "typesafe-actions";
import { IReconciliationAgencyBill, IReconciliationCustomerPayableBill, IReconciliationDirectBill } from "./reconciliation.types";
import { fecthBusinessTasksListFailed } from "../activity/activityActions";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";


export const FETCH_RECON_AGENCY_BILL_LIST_PROGRESS="FETCH_RECON_AGENCY_BILL_LIST_PROGRESS";
export const FETCH_RECON_AGENCY_BILL_LIST_SUCCESS="FETCH_RECON_AGENCY_BILL_LIST_SUCCESS";
export const FETCH_RECON_AGENCY_BILL_LIST_FAILED="FETCH_RECON_AGENCY_BILL_LIST_FAILED";


export const fetchReconAgencyBillListProgress = () => action(FETCH_RECON_AGENCY_BILL_LIST_PROGRESS);
export const fetchReconAgencyBillListSuccess = (data: IReconciliationAgencyBill[], totalRecords: number) => 
    action(FETCH_RECON_AGENCY_BILL_LIST_SUCCESS, {data, totalRecords});
export const fetchReconAgencyBillListFailed = () => action(FETCH_RECON_AGENCY_BILL_LIST_FAILED);


export const fetchReconAgencyBillListAsync  =
(
  pageNumber: number,
  rowsInPerPage: number,
  limit: number,
  status: string,
  date: IDataTableV2DateState["dates"],
  searchValue: IDatatableV2AdvancedSearchFilter,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(fetchReconAgencyBillListProgress());
    let url = "";
    if (status !== "-1") {
      url = "&status=" + status;
    }

    let finalUrl = `/accounting/get-account-invoice-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

    if (searchValue.length > 0) {
      finalUrl = `/accounting/get-account-invoice-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
    }
    const res = await api.get(finalUrl);
    const data: IReconciliationDirectBill[] = res.data.data;
    const totalRecords = res.data.totalRecords;
    dispatch(fetchReconAgencyBillListSuccess(data, totalRecords));
  } catch (err: any) {
    dispatch(fetchReconAgencyBillListFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
};



export const FETCH_RECON_DIRECT_BILL_LIST_PROGRESS="FETCH_RECON_DIRECT_BILL_LIST_PROGRESS";
export const FETCH_RECON_DIRECT_BILL_LIST_SUCCESS="FETCH_RECON_DIRECT_BILL_LIST_SUCCESS";
export const FETCH_RECON_DIRECT_BILL_LIST_FAILED="FETCH_RECON_DIRECT_BILL_LIST_FAILED";


export const fetchReconDirectBillListProgress = () => action(FETCH_RECON_DIRECT_BILL_LIST_PROGRESS);
export const fetchReconDirectBillListSuccess = (data: IReconciliationDirectBill[], totalRecords: number) => 
    action(FETCH_RECON_DIRECT_BILL_LIST_SUCCESS, {data, totalRecords});
export const fetchReconDirectBillListFailed = () => action(FETCH_RECON_DIRECT_BILL_LIST_FAILED);


export const fetchReconDirectBillListAsync  =
(
  pageNumber: number,
  rowsInPerPage: number,
  limit: number,
  status: string,
  date: IDataTableV2DateState["dates"],
  searchValue: IDatatableV2AdvancedSearchFilter,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(fetchReconDirectBillListProgress());
    let url = "";
    if (status !== "-1") {
      url = "&status=" + status;
    }

    let finalUrl = `/accounting/get-account-invoice-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

    if (searchValue.length > 0) {
      finalUrl = `/accounting/get-account-invoice-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
    }
    const res = await api.get(finalUrl);
    const data: IReconciliationDirectBill[] = res.data.data;
    const totalRecords = res.data.totalRecords;
    dispatch(fetchReconDirectBillListSuccess(data, totalRecords));
  } catch (err: any) {
    dispatch(fetchReconDirectBillListFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
};



export const FETCH_RECON_CUSTOMER_PAYABLE_LIST_PROGRESS="FETCH_RECON_CUSTOMER_PAYABLE_LIST_PROGRESS";
export const FETCH_RECON_CUSTOMER_PAYABLE_LIST_SUCCESS="FETCH_RECON_CUSTOMER_PAYABLE_LIST_SUCCESS";
export const FETCH_RECON_CUSTOMER_PAYABLE_LIST_FAILED="FETCH_RECON_CUSTOMER_PAYABLE_LIST_FAILED";


export const fetchCustomerPayableListProgress = () => action(FETCH_RECON_CUSTOMER_PAYABLE_LIST_PROGRESS);
export const fetchCustomerPayableListSuccess = (data: IReconciliationCustomerPayableBill[], totalRecords: number) => 
    action(FETCH_RECON_CUSTOMER_PAYABLE_LIST_SUCCESS, {data, totalRecords});
export const fetchCustomerPayableListFailed = () => action(FETCH_RECON_CUSTOMER_PAYABLE_LIST_FAILED);


export const fetchCustomerPayableListAsync  =
(
  pageNumber: number,
  rowsInPerPage: number,
  limit: number,
  status: string,
  date: IDataTableV2DateState["dates"],
  searchValue: IDatatableV2AdvancedSearchFilter,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(fetchCustomerPayableListProgress());
    let url = "";
    if (status !== "-1") {
      url = "&status=" + status;
    }

    let finalUrl = `/accounting/get-account-bill-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

    if (searchValue.length > 0) {
      finalUrl = `/accounting/get-account-bill-payment-transaction-items?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
    }
    const res = await api.get(finalUrl);
    const data: IReconciliationCustomerPayableBill[] = res.data.data;
    const totalRecords = res.data.totalRecords;
    dispatch(fetchCustomerPayableListSuccess(data, totalRecords));
  } catch (err: any) {
    dispatch(fetchCustomerPayableListFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
};


export const CLEAR_RECONCILIATION_STATE = "CLEAR_RECONCILIATION_STATE";
export const clearReconciliationState = () => action(CLEAR_RECONCILIATION_STATE);


