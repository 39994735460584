import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ICommAutomobileDriverActivity,
  ICommAutomobileDriverClaims,
  ICommAutomobileDriverConviction,
  ICommAutomobileDriverInformation,
} from "./commAutoDriver.types";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { uploadFile } from "../../../helpers";

export const FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_FAILED";
export const CLEAR_COMM_AUTOMOBILE_DRIVER_INFO =
  "CLEAR_COMM_AUTOMOBILE_DRIVER_INFO";

export const fetchCommAutomobileDriverProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_PROGRESS);
export const fetchCommAutomobileDriverSuccess = (
  data: ICommAutomobileDriverInformation
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriverFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_FAILED, { errorMessage });
export const clearCommAutomobileDriverInfo = () =>
  action(CLEAR_COMM_AUTOMOBILE_DRIVER_INFO);

export const addCommAutomobileDriverAsync =
  (
    data: ICommAutomobileDriverInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-driver", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileDriverAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriverProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-driver?customer_policy_id=${customerPolicyId}&column=driver_number&column=driver_name&value=${incremental_number}`
      );
      const data: ICommAutomobileDriverInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileDriverSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileDriverFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileDriverFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED";

export const fetchCommAutomobileDriversListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_PROGRESS);

export const fetchAutomobileDriverListSuccess = (
  data: ICommAutomobileDriverInformation[]
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriversListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED);

export const fetchCommAutomobileDriverListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriversListProgress());

      let finalUrl = `/policy/get-policy-commauto-driver?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: ICommAutomobileDriverInformation[] = res.data.data;
      dispatch(fetchAutomobileDriverListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileDriversListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_DRIVER_STATE =
  "CLEAR_COMM_AUTOMOBILE_DRIVER_STATE";
export const clearCommAutomobileDriverState = () =>
  action(CLEAR_COMM_AUTOMOBILE_DRIVER_STATE);

export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_FAILED";

export const fetchCommAutomobileDriverClaimProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_PROGRESS);
export const fetchCommAutomobileDriverClaimSuccess = (
  data: ICommAutomobileDriverClaims
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriverClaimFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_FAILED, { errorMessage });

export const addCommAutomobileDriverClaimAsync =
  (
    data: ICommAutomobileDriverClaims,
    customerID: number,
    file: any | null,
    attachment: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "COMM_AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Comm-Auto Attachment",
      };

      const filePath = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );

      await api.post("/policy/edit-policy-commauto-driver-claims", {
        ...data,
        file_upload: filePath,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver claim saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileDriverClaimAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriverClaimProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-driver-claims?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileDriverClaims[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileDriverClaimSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileDriverClaimFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileDriverClaimFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_DRIVER_CLAIM_INFO =
  "CLEAR_COMM_AUTOMOBILE_DRIVER_CLAIM_INFO";
export const clearCommAutomobileClaimInfo = () =>
  action(CLEAR_COMM_AUTOMOBILE_DRIVER_CLAIM_INFO);

export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED";

export const fetchCommAutomobileDriversListClaimProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_PROGRESS);
export const fetchCommAutomobileDriverClaimsListSuccess = (
  data: ICommAutomobileDriverClaims[]
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriversListClaimFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CLAIM_LIST_FAILED);
export const fetchCommAutomobileDriverClaimsListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriversListClaimProgress());
      let finalUrl = `/policy/get-policy-commauto-driver-claims?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: ICommAutomobileDriverClaims[] = res.data.data;
      dispatch(fetchCommAutomobileDriverClaimsListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED";

export const fetchCommAutomobileDriverConvitionsProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_PROGRESS);
export const fetchCommAutomobileDriverConvitionsSuccess = (
  data: ICommAutomobileDriverConviction
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriverConvitionsFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_FAILED, { errorMessage });

export const CLEAR_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_INFO =
  "CLEAR_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_INFO";
export const clearCommAutomobileConvictions = () =>
  action(CLEAR_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_INFO);

export const addCommAutomobileDriverConvitionsAsync =
  (
    data: ICommAutomobileDriverConviction,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-driver-convictions", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver convitions saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileDriverConvitionsAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriverClaimProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-driver-convictions?customer_policy_id=${customerPolicyId}&column=incremental_number&column=driver_number&value=${incremental_number}`
      );
      const data: ICommAutomobileDriverConviction[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileDriverConvitionsSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileDriverConvitionsFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileDriverConvitionsFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED";

export const fetchCommAutomobileDriversConvitionsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_PROGRESS);

export const fetchCommAutomobileDriverConvitionsListSuccess = (
  data: ICommAutomobileDriverConviction[]
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_CONVICTIONS_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriversConvitionsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_LIST_CONVICTIONS_FAILED);

export const fetchCommAutomobileDriverConvitionsListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriversConvitionsListProgress());

      let finalUrl = `/policy/get-policy-commauto-driver-convictions?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: ICommAutomobileDriverConviction[] = res.data.data;
      dispatch(fetchCommAutomobileDriverConvitionsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileDriversConvitionsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_FAILED";

export const fetchCommAutomobileDriverTasksProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_PROGRESS);
export const fetchCommAutomobileDriverTasksSuccess = (
  data: ICommAutomobileDriverActivity
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriverTasksFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_FAILED, { errorMessage });

export const addCommAutomobileDriverTasksAsync =
  (
    data: ICommAutomobileDriverActivity,
    customerID: number | string,
    file: any | null,
    file_two: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "COMM_AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Comm-Auto Attachment",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      const path_two = await uploadFile(
        file_two,
        "POLICY",
        data.file_upload_two || "",
        asPayload
      );
      await api.post("/policy/edit-policy-commauto-insurance-reports", {
        ...data,
        file_upload: path,
        file_upload_two: path_two,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver activity/tasks saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileDriverTasksAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriverTasksProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-insurance-reports?customer_policy_id=${customerPolicyId}&column=incremental_number&column=driver_number&column=driver_name&value=${incremental_number}`
      );
      const data: ICommAutomobileDriverActivity[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileDriverTasksSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileDriverTasksFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileDriverTasksFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_DRIVER_TASKS =
  "CLEAR_COMM_AUTOMOBILE_DRIVER_TASKS";
export const clearCommAutomobileActivityTask = () =>
  action(CLEAR_COMM_AUTOMOBILE_DRIVER_TASKS);

export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED =
  "FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED";

export const fetchCommAutomobileDriversTasksListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_PROGRESS);

export const fetchCommAutomobileDriverTasksListSuccess = (
  data: ICommAutomobileDriverActivity[]
) =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileDriversTasksListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DRIVER_TASKS_LIST_CONVICTIONS_FAILED);

export const fetchCommAutomobileDriverTasksListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDriversTasksListProgress());

      let finalUrl = `/policy/get-policy-commauto-insurance-reports?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: ICommAutomobileDriverActivity[] = res.data.data;
      dispatch(fetchCommAutomobileDriverTasksListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileDriversTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
