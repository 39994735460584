import {
  IRow,
  TABLE_ROW_TYPE,
} from "../../components/Table/interfaces/IDataTableProps";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileScheduleState {
  scheduleA: {
    loading: LoadState;
    data: IAutomobileScheduleA;
    listData: IAutomobileScheduleA[];
    listLoading: LoadState;
  };
}

export interface IAutomobileScheduleA {
  incremental_number: number | null;
  customer_policy_id: number;
  vehicle_number: number | null;
  coverage_number: number | null;
  coverage_name: string | null;
  coverage_details: IAutomobileScheduleCoverage[];
  // endorsements: ICommScheduleAColumns[];
  set_as_default: string | null;
  status: string;
  minimum_retained_premium: number | null;
  total_premium: number | null;
}

export interface IAutomobileScheduleCoverage {
  key: string;
  deductible: string | null;
  description: string | null;
  label: string;
  isTypeOther: boolean;
  limit: string | null;
  premimum: string | null;
}

const coverageData: IAutomobileScheduleCoverage[] = [
  {
    key: getUniqueId(),
    label: "Bodily Injury",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Property Damage",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Accident Benefits (Standard Benefits)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Income Replacement ($600/$800/$1,000)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Medical Rehabilitation & Attendant Care ($130,000/$1,000,000)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label:
      "Optional Catastrophic Impairment(additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care Benefit)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Caregiver, Housekeeping & Home Maintenance",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Death & Funeral",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Dependent Care",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Indexation Benefit (Consumer Price Index)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Uninsured Automobile",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Direct Compensation-Property Damage,",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Specified Perils (excluding Collision or Upset)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Comprehensive (excluding Collision or Upset)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Collision or Upset",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "All Perils",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label:
      "Policy Change Forms (Name & No.)Family Protection Coverage - OPCF 44R",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
];

export const INITIAL_COVERAGE_STATE: IAutomobileScheduleA = {
  incremental_number: null,
  customer_policy_id: 0,
  vehicle_number: null,
  coverage_number: null,
  coverage_name: null,
  coverage_details: coverageData,
  set_as_default: "N",
  minimum_retained_premium: null,
  total_premium: null,

  status: "ACTIVE",
};

export const initialAutomoblieSchedule: IAutomobileScheduleState = {
  scheduleA: {
    data: INITIAL_COVERAGE_STATE,
    loading: LoadState.NotLoaded,
    listData: [],
    listLoading: LoadState.NotLoaded,
  },
};
