/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Link } from "@mui/material";
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
// import { IMarketingBasicDetailsProps } from "./MarketingBasicdetailProps";
// import { MarketingDialog } from "./MarketingDialog";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IBusinessBinder } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearBusinessPolicyMarketingState,
  fetchBusinessPolicyBinderistListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { BinderDialog } from "./BinderDialog";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { IBinderBasicDetailsProps } from "./BinderDialog.types";
import { BinderPreview } from "./BinderPreview";
import { DownLoadFile } from "../../../../../../helpers";
import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { downLoadFile } from "../../../../../../components/FileUpload/utils";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const BinderList = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerpolicyId = data[1];
  const navigate = useNavigate();
  const [openBinder, setOpenBinder] = React.useState(false);

  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.marketing.binderHistory
  );
  const dispatch = useDispatchWrapper();
  const [openPreview, setOpenPreview] = React.useState<number | null>(null);
  const [downloadingLoading, setDownloading] = React.useState(false);

  const handlePreviewOpen = (customerPolicyId: number) => () => {
    setOpenPreview(customerPolicyId);
  };

  const handleDownload = (row: IBusinessBinder) => async () => {
    try {
      setDownloading(true);
      for (let item of row.attachment) {
        await downLoadFile(item);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        })
      );
    } finally {
      setDownloading(false);
    }
  };

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchBusinessPolicyBinderistListAsync(customerpolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPolicyMarketingState());
    };
  }, [customerpolicyId, status]);

  const marketingActivityTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: IBusinessBinder) => {
          return (
            <StandardTableActions
              downloadLoading={downloadingLoading}
              onDownLoadClick={handleDownload(row)}
              onDownloadPreview={handlePreviewOpen(row.customer_policy_id)}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Staus",
        fieldName: "status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "customer_policy_id",
        headerName: "Customer Policy ID",
        fieldName: "customer_policy_id",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "created_by_id",
        headerName: "Created By",
        fieldName: "created_by_id",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 30,
        enableSorting: true,
      },
    ],
    rows: list,
  };

  return (
    <>
      <PageLoader loading={false}>
        <PageTitleBar
          heading="Binder"
          rightHeading={
            <Button
              variant="contained"
              onClick={() => setOpenBinder(true)}
              startIcon={<AddOutlined />}
            >
              Create
            </Button>
          }
        />
        <DataTableV2 {...marketingActivityTableProps} />
      </PageLoader>
      {openBinder && (
        <BinderDialog
          open={openBinder}
          customerPolicyId={customerpolicyId}
          onClose={() => setOpenBinder(false)}
          onComplete={() => {}}
        />
      )}
      {openPreview && (
        <BinderPreview
          customerPolicyId={openPreview}
          open={true}
          onClose={() => setOpenPreview(null)}
          onComplete={() => {}}
        />
      )}
    </>
  );
};
