import moment, { Moment } from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface QuoteSettingsState {
  defaultCoverage: {
    coverageList: QuoteDefaultCoverage[];
    coverage: QuoteDefaultCoverage;
    loading: LoadState;
    error: string | null;
  };
}

export interface QuoteDefaultCoverage {
  default_coverage_code: string | null;
  line_of_business: string | null;
  default_coverage_name: string | null;
  coverage_details:IAutomobileScheduleCoverage[];
  status: "ACTIVE";
}

export interface IAutomobileScheduleCoverage {
  key: string;
  deductible: string | null;
  description: string | null;
  label: string;
  isTypeOther: boolean;
  limit: string | null;
  premimum: string | null;
}

const coverageData: IAutomobileScheduleCoverage[] = [
  {
    key: getUniqueId(),
    label: "Bodily Injury",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Property Damage",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Accident Benefits (Standard Benefits)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Income Replacement ($600/$800/$1,000)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Medical Rehabilitation & Attendant Care ($130,000/$1,000,000)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label:
      "Optional Catastrophic Impairment(additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care Benefit)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Caregiver, Housekeeping & Home Maintenance",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Death & Funeral",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Dependent Care",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Indexation Benefit (Consumer Price Index)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Uninsured Automobile",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Direct Compensation-Property Damage,",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Specified Perils (excluding Collision or Upset)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Comprehensive (excluding Collision or Upset)",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "Collision or Upset",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "All Perils",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label:
      "Policy Change Forms (Name & No.)Family Protection Coverage - OPCF 44R",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: false,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },

  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
  {
    key: getUniqueId(),
    label: "",
    deductible: "",
    description: "",
    limit: "",
    premimum: "",
    isTypeOther: true,
  },
];

export const INITIAL_DEFAULT_COVERAGE_STATE: QuoteDefaultCoverage = {
  default_coverage_code: null,
  line_of_business: null,
  default_coverage_name: null,
  coverage_details: coverageData,
  status: "ACTIVE",
}


export const initialQuoteSettingsState: QuoteSettingsState = {
  defaultCoverage: {
    coverageList: [],
    coverage: INITIAL_DEFAULT_COVERAGE_STATE,
    loading: LoadState.NotLoaded,
    error: null,
  },
};