import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import {
  ICommAutomobileDwelling,
  ICommAutomobileDwellingDeclaration,
  ICommAutomobileGarageForm,
  ICommAutomobileOwnerForm,
} from "./commAutoCertificates.types";

  export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS="FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS";
  export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS="FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS";
  export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED="FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED"

export const fetchCommAutomobileGarageFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS);
export const fetchCommAutomobileGarageFormListSuccess = (
  data: ICommAutomobileGarageForm[]
) => action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileGarageFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_LIST_FAILED);

export const fetchCommAutomobileGarageFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGarageFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileGarageForm[] =res.data.data;
      dispatch(fetchCommAutomobileGarageFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  


export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS =
 "FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_OWNER_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED";

export const fetchCommAutomobileOwnerFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS);
export const fetchCommAutomobileOwnerFormSuccess = (data: ICommAutomobileOwnerForm) =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS, { data });
export const fetchCommAutomobileOwnerFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED, { errorMessage });

export const addCommAutomobileOwnerFormAsync =
  (
    data: ICommAutomobileOwnerForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-owner-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const fetchCommAutomobileOwnerFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOwnerFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOwnerForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOwnerFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOwnerFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OWNER_FORM = "CLEAR_COMM_AUTOMOBILE_OWNER_FORM";
export const clearCommAutomobileOwnerForm = () =>
  action(CLEAR_COMM_AUTOMOBILE_OWNER_FORM);

export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED";


export const fetchCommAutomobileOwnerFormListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS);
export const fetchCommAutomobileOwnerFormListSuccess = (
  data: ICommAutomobileOwnerForm[]
) => action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS, { data });
export const fetchCommAutomobileOwnerFormListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED);

export const fetchCommAutomobileOwnerFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOwnerFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-owner-form?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileOwnerForm[] =res.data.data;
      dispatch(fetchCommAutomobileOwnerFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileOwnerFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOwnerFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-owner-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOwnerFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED =
   "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED";
 
export const fetchCommAutomobileDwellingDeclarationProgress = () =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS);
export const fetchCommAutomobileDwellingDeclarationSuccess = (data: ICommAutomobileDwellingDeclaration) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS, { data });
export const fetchCommAutomobileDwellingDeclarationFailed = (errorMessage: string) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED, { errorMessage });
 
export const addCommAutomobileDwellingDeclarationAsync =
   (
     data: ICommAutomobileDwellingDeclaration,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling-declaration",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchCommAutomobileDwellingDeclarationAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchCommAutomobileDwellingDeclarationProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: ICommAutomobileDwellingDeclaration[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchCommAutomobileDwellingDeclarationSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchCommAutomobileDwellingDeclarationFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchCommAutomobileDwellingDeclarationFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION = "CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION";
export const clearCommAutomobileDwellingDeclaration = () =>
  action(CLEAR_COMM_AUTOMOBILE_DWELLING_DECLARATION);

export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED";


export const fetchCommAutomobileDwellingDeclarationListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS);
export const fetchCommAutomobileDwellingDeclarationListSuccess = (
  data: ICommAutomobileDwellingDeclaration[]
) => action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS, { data });
export const fetchCommAutomobileDwellingDeclarationListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED);

export const fetchCommAutomobileDwellingDeclarationListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDwellingDeclarationListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileDwellingDeclaration[] =res.data.data;
      dispatch(fetchCommAutomobileDwellingDeclarationListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_declaration_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingDeclarationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS =
   "FETCH_AUTOMOBILE_DWELLING_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_FAILED =
   "FETCH_COMM_AUTOMOBILE_DWELLING_FAILED";
 
export const fetchCommAutomobileDwellingProgress = () =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS);
export const fetchCommAutomobileDwellingSuccess = (data: ICommAutomobileDwelling) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS, { data });
export const fetchCommAutomobileDwellingFailed = (errorMessage: string) =>
   action(FETCH_COMM_AUTOMOBILE_DWELLING_FAILED, { errorMessage });
 
export const addCommAutomobileDwellingAsync =
   (
     data: ICommAutomobileDwelling,
     onCallback: (isSuccess: boolean) => void,
     isDuplicate?: boolean
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(saveLoaderProgress());
        await api.post(
           "/policy/edit-policy-form-rental-dwelling",
           {
             ...data,
           }
        );
       onCallback(true);
       dispatch(
         showMessage({
           type: "success",
           message: "Conset Form saved successfully!",
           displayAs: "snackbar",
         })
       );
     } catch (err: any) {
       onCallback(false);
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     } finally {
       dispatch(saveLoaderCompleted());
     }
   };

   export const fetchCommAutomobileDwellingAsync =
   (
     customerPolicyId: number,
     incremental_number: number
   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
   async (dispatch, getState) => {
     try {
       dispatch(fetchCommAutomobileDwellingProgress());
       const res = await api.get(
         `/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
       );
       const data: ICommAutomobileDwelling[] = res.data.data;
       if (data.length > 0) {
         dispatch(
           fetchCommAutomobileDwellingSuccess({
             ...data[0],
           })
         );
       } else {
         dispatch(
           fetchCommAutomobileDwellingFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
         );
       }
     } catch (err: any) {
       dispatch(fetchCommAutomobileDwellingFailed(ERROR_MESSAGES.SERVER_ERROR));
       dispatch(
         showMessage({
           type: "error",
           message: err.response.data.message,
           displayAs: "snackbar",
         })
       );
     }
   };
 
export const CLEAR_COMM_AUTOMOBILE_DWELLING = "CLEAR_COMM_AUTOMOBILE_DWELLING";
export const clearCommAutomobileDwelling = () =>
  action(CLEAR_COMM_AUTOMOBILE_DWELLING);

export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS =
"FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED";


export const fetchCommAutomobileDwellingListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS);
export const fetchCommAutomobileDwellingListSuccess = (
  data: ICommAutomobileDwelling[]
) => action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS, { data });
export const fetchCommAutomobileDwellingListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED);

export const fetchCommAutomobileDwellingListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileDwellingListProgress());
      const res = await api.get(`/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}`);

      const data :ICommAutomobileDwelling[] =res.data.data;
      dispatch(fetchCommAutomobileDwellingListSuccess(data))
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }

  export const downloadCommAutomobileDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileDwellingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE =
  "FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE";

export const clearCommAutoCertificatesState = () =>
  action(FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE);
