import { Chip } from "@mui/material";
import React from "react";
import { ICustomChipProps } from "./interfaces/ICustomChipProps";

export const CustomChip: React.FC<ICustomChipProps> = (props) => {
  const { size, content, color, icon, sx } = props;
  return (
    <Chip
      sx={{
        color: "#fff",
        borderRadius: {
          md: "4px",
          lg: "5px",
          xl: "6px",
        },
        pl: {
          md: "1px",
          lg: "2px",
          xl: "3px"
        },
        pr: {
          md: "1px",
          lg: "2px",
          xl: "3px"
        },
        height: {
          md: "15px",
          lg: "20px",
          xl: "24px",
        },
        ...sx,
      }}
      //@ts-ignore
      icon={icon}
      color={color}
      size={size}
      label={content}
    />
  );
};
