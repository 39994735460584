import { Avatar, Button, MenuItem } from "@mui/material";
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { DataTable } from "../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { useDispatchWrapper } from "../../../../../../hooks";
import { ILegal } from "../../../../../../redux/legals/legals.types";

import { AddOutlined } from "@mui/icons-material";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../components/Table/plugins";
import { ControlledCustomSelect, CustomSelect } from "../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import {
  clearCSIOState,
  fetchFromCSIOListAsync,
} from "../../../../../../redux/csio/csioActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { ICSIORaw } from "../../../../../../redux/csio/csio.types";
import { CSIOMapRightPanel } from "../../../../../csio/CSIOMapRightPanel";
import { fetchCSIOHabitationalListAsync } from "../../../../../../redux/habitationalPolicy/habitationalCsioApplication/habitationalCsioApplicationActions";
import {
  clearCommAutoCSIOApplicationState,
  fetchCSIOCommAutoListAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoCsioApplication/commAutoCsioApplicationActions";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { DataTableV2RowRenderType, IDataTableV2Props } from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const CommAutomobileCsioApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.csioapplication
  );
  const dispatch = useDispatchWrapper();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const navigate = useNavigate();

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    dispatch(fetchCSIOCommAutoListAsync(customerpolicyId, status));
  };

  React.useEffect(() => {
    fetchList();
    return () => {
      dispatch(clearCommAutoCSIOApplicationState());
    };
  }, [status, customerpolicyId]);

  const csioTableProps: IDataTableV2Props = {
    isPagination: true,
    totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
   
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/manage-csio/${row.message_guid}`);
              }}
            />
          );
        },
      },
      {
        key: "csio",
        headerName: "",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        enableSorting: false,

        onRowCellRender: (values, row) => {
          return (
            <Button
              variant="text"
              onClick={() =>
                navigate(
                  `/comm-automobile-policy/${customerId}/application-csio-tab/${customerpolicyId}/application/${row.message_guid}`
                )
              }
            >
              View XML & Map
            </Button>
          );
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/manage-csio/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "MessageType",
        headerName: "Message Type",
        fieldName: "message_type",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },
      {
        key: "FromEmailAddress",
        headerName: "From Email Address",
        fieldName: "from_email_address",
        enableSorting: false,
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 25,
      },
      // {
      //   key: "MessageSize",
      //   headerName: "MessageSize",
      //   fieldName: "count",
      //   renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 20,
      //   enableSorting: false,
      // },
      {
        key: "DateTime",
        headerName: "Date and Time",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 20,
        enableSorting: false,
      },
    ],
    rows: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  return (
    <>
      <PageContainer title="View Csio" description="this is innerpage">
        <DataTableV2 {...csioTableProps} />
      </PageContainer>
    </>
  );
};
