import { ITableTab } from "../components/Table/components/TableTabs/TableTabs.types";
import { IRecordCount } from "../redux/common/common.types";

export const createTabsWithRecordcounts = (
  mainData: { label: string; value: string; variant: string }[],
  recordCounts: IRecordCount[]
) :ITableTab[]=> {
  let totalCount =0;
  const mergedArray = mainData.map((item) => {
    const countObj = recordCounts.find((obj) => (obj.name ? obj.name.toString() :  "").toLowerCase() === item.value.toLowerCase());
    const count =countObj ? countObj.cnt : 0;
    totalCount=totalCount+count;
    return {
      label: item.label,
      value: item.value,
      count: count,
      variant: item.variant
    };
  });
  mergedArray.unshift({label: "All", value: "-1", count: totalCount, variant: "primary"})
  return mergedArray as ITableTab[];
};
