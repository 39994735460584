import { action } from "typesafe-actions";
import { ICustomerFullDetails } from "../customer/customer.types";
import { IAllCustomersDetails } from "./allCustomers.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";

export const FETCH_ALL_CUSTOMERS_LIST_PROGRESS = "FETCH_ALL_CUSTOMERS_LIST_PROGRESS";
export const FETCH_ALL_CUSTOMERS_LIST_SUCCESS = "FETCH_ALL_CUSTOMERS_LIST_SUCCESS";
export const FETCH_ALL_CUSTOMERS_LIST_FAILED = "FETCH_ALL_CUSTOMERS_LIST_FAILED";

export const fetchAllCustomersListProgress = () => action(FETCH_ALL_CUSTOMERS_LIST_PROGRESS);
export const fetchAllCustomersListSuccess = (list: IAllCustomersDetails[]) =>
  action(FETCH_ALL_CUSTOMERS_LIST_SUCCESS, { list });
export const fetchAllCustomersListFailed = () => action(FETCH_ALL_CUSTOMERS_LIST_FAILED);

export const fetchAllCustomersListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState,
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      dispatch(fetchAllCustomersListProgress());
      let finalUrl = `/customer/get-customer-all-detailed-record?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}`;

      if(date.rangeType !== "allTimes"){
        finalUrl = finalUrl+ `&to_date=${date.dates.toDate}&from_date=${date.dates.fromDate}`
      }

      if (searchValue.length > 0) {
        finalUrl = `/customer/get-customer-all-detailed-record?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IAllCustomersDetails[] = res.data.data;
      // const totalRecords = res.data.totalRecords;

      dispatch(fetchAllCustomersListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAllCustomersListFailed());
      dispatch(
        showMessage
        ({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ALL_CUSTOMERS_STATE = "CLEAR_ALL_CUSTOMERS_STATE";


export const clearAllCustomersState = () => action(CLEAR_ALL_CUSTOMERS_STATE);
