import React from "react";
import { Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import {
  clearCommAutomobileSchedule,
  fetchCommAutomobileScheduleDListAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { ICommAutomobileScheduleD } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoSchedule.types";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";
import { AddOutlined } from "@mui/icons-material";
import { ICommAutomobileApplication } from "../../../../../../../redux/commAutoPolicy/commAutoAllApplications/commAutoAllApplications.types";

export const CommAutomobileScheduleDList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { listData: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleD
  );

  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { columnsConfig },
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleD`
    );
  };

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchCommAutomobileScheduleDListAsync(customerpolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearCommAutomobileSchedule());
    };
  }, [customerpolicyId, status]);

  const CommautomobileApplicationTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleD/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileApplication) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "customer_policy_id",
        headerName: "ID",
        fieldName: "customer_policy_id",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileScheduleD) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerpolicyId}/scheduleD/${row.incremental_number}`
                );
              }}
            >
              {row.customer_policy_id}
            </Link>
          );
        },
      },
      {
        key: "created_by_id",
        headerName: "Created by ID",
        fieldName: "created_by_id",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Garage Alberta"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...CommautomobileApplicationTableProps} />
    </>
  );
};
