import produce from "immer";
import { IStoreState } from "../../initialStoreState";

import { LoadState } from "../../../constants/enums";
import { BusinessPolicyFormsActions } from ".";
import { initialBusinessPolicyFormState } from "./businessPolicyForms.types";
import {
  CLEAR_BUSINESS_POLICY_FORMS_STATE,
  FETCH_BUSIENSS_POLICY_FORMS_LIST_FAILED,
  FETCH_BUSIENSS_POLICY_FORMS_LIST_PROGRESS,
  FETCH_BUSIENSS_POLICY_FORMS_LIST_SUCCESS,
  FETCH_BUSINESS_CONSENT_FORM_FAILED,
  FETCH_BUSINESS_CONSENT_FORM_PROGRESS,
  FETCH_BUSINESS_CONSENT_FORM_SUCCESS,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS,
  FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS,
  FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED,
  FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS,
  FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS,
  FETCH_BUSINESS_POLICY_LIABILITY_FAILED,
  FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS,
  FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS,
  FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED,
  FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS,
  FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS,
} from "./businessPolicyFormsActions";

export const businessPolicyFormsReducer = (
  state: IStoreState["business"]["policyForms"] = initialBusinessPolicyFormState,
  action: BusinessPolicyFormsActions
) => {
  switch (action.type) {
    case FETCH_BUSIENSS_POLICY_FORMS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.InProgress;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_BUSIENSS_POLICY_FORMS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Loaded;
        draftState.policyFormsList.list = data;
        draftState.policyFormsList.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_BUSIENSS_POLICY_FORMS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Failed;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.liability.loading = LoadState.InProgress;
        draftState.liability.data =
          initialBusinessPolicyFormState["liability"].data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liability.loading = LoadState.Loaded;
        draftState.liability.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_LIABILITY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liability.loading = LoadState.Failed;
        draftState.liability.data =
          initialBusinessPolicyFormState["liability"].data;
        draftState.liability.error = errorMessage;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.propertyInsurance.loading = LoadState.InProgress;
        draftState.propertyInsurance.data =
          initialBusinessPolicyFormState["propertyInsurance"]["data"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.propertyInsurance.loading = LoadState.Loaded;
        draftState.propertyInsurance.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.propertyInsurance.loading = LoadState.Failed;
        draftState.propertyInsurance.data =
          initialBusinessPolicyFormState["propertyInsurance"]["data"];
        draftState.propertyInsurance.error = errorMessage;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.garageCertificate.loading = LoadState.InProgress;
        draftState.garageCertificate.data =
          initialBusinessPolicyFormState["garageCertificate"]["data"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageCertificate.loading = LoadState.Loaded;
        draftState.garageCertificate.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageCertificate.loading = LoadState.Failed;
        draftState.garageCertificate.data =
          initialBusinessPolicyFormState["garageCertificate"]["data"];
        draftState.garageCertificate.error = errorMessage;
      });
      return newState;
    }

    case FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.InProgress;
        draftState.paymentAuthorization.data =
          initialBusinessPolicyFormState["paymentAuthorization"]["data"];
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Loaded;
        draftState.paymentAuthorization.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Failed;
        draftState.paymentAuthorization.data =
          initialBusinessPolicyFormState["paymentAuthorization"]["data"];
        draftState.paymentAuthorization.error = errorMessage;
      });
      return newState;
    }

    case FETCH_BUSINESS_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.InProgress;
        draftState.privacyConsent.data =
          initialBusinessPolicyFormState["privacyConsent"]["data"];
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Loaded;
        draftState.privacyConsent.data = data;
      });
      return newState;
    }
    case FETCH_BUSINESS_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Failed;
        draftState.privacyConsent.data =
          initialBusinessPolicyFormState["privacyConsent"]["data"];
        draftState.privacyConsent.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BUSINESS_POLICY_FORMS_STATE: {
      return initialBusinessPolicyFormState;
    }

    default: {
      return state;
    }
  }
};
