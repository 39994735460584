import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import {
  clearNotesState,
  fetchNoteListFromPolicyAsync,
} from "../../../../../redux/notes/notesActions";
import { LoadState } from "../../../../../constants/enums";
import { INotices } from "../../../../../redux/notices/notices.types";
import { StatusRenderer } from "../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { IPolicyNote } from "../../../../../redux/notes/notes.types";
import { DataTable } from "../../../../../components/Table/DataTable";
import { clearBusinessPolicyRestrutant } from "../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Add, AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { PageTitleBar } from "../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../components/TableV2/DataTableV2";
import { ControlledCustomSelect } from "../../../../../components/formsComponents";
import { COMMON_STATUS, OPEN_CLOSE_STATUS } from "../../../../../constants/constants";

export const NotesList: React.FC<{
  customerId: number;
  customerPolicyId: number;
  onAddEditClick: (incrementalNumber?: number | null) => void;
}> = (props) => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "Abeyances/Notes",
    },
  ];
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.policyNotes
  );
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last90Days",
      selectedTab: "-1",
    },
  });
  // React.useEffect(() => {
  //   dispatch(fetchNoteListFromPolicyAsync(customerPolicyId));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customerPolicyId]);

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchNoteListFromPolicyAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearNotesState());
    };
  }, [customerPolicyId, status]);

  const notesTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={OPEN_CLOSE_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPolicyNote) => {
          return (
            <StandardTableActions
              commentBoxTypeId={
                row.customer_policy_id + "-" + row.incremental_number
              }
              onEditClick={() => {
                props.onAddEditClick(row.incremental_number);
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        onRowCellRender: (value, row) => {
          return (
            <StatusRenderer
              status={row.status === "ACTIVE" ? "Open" : "Closed"}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: INotices) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => props.onAddEditClick(row.incremental_number)}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "notes_title",
        headerName: "Title",
        fieldName: "notes_title",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "customer",
        headerName: "Customer",
        fieldName: "customer",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 30,
      },

      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        enableSorting: true,
        // exportCellWidth: 20,
      },
      {
        key: "date_created",
        headerName: "Date Modifed",
        fieldName: "date_created",
        renderType: DataTableV2RowRenderType.DATE,
        enableSorting: true,
        // exportCellWidth: 20,
      },
      {
        key: "time_completed",
        headerName: "Date Completed",
        fieldName: "time_completed",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE_TIME,
        // exportCellWidth: 20,
      },
    ],
    rows: list,
  };

  return (
    <>
      <PageTitleBar
        heading="Abeyance/Notes"
        rightHeading={
          <Button
            variant="contained"
            onClick={() => {
              props.onAddEditClick();
            }}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...notesTableProps} />
    </>
  );
};
