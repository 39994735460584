import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IHabitationalClaimState,
  IhabitationalClaims,
} from "./habitationalClaim.types";

export const FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS =
  "FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS";
export const FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS =
  "FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_DRIVER_LIST_INFORMATION_FAILED =
  "FETCH_HABITATIONAL_CLAIM_LIST_FAILED";
export const CLEAR_HABITATIONAL_CLAIM_STATE = "CLEAR_HABITATIONAL_CLAIM_STATE";

export const clearHabitationalClaimState = () =>
  action(CLEAR_HABITATIONAL_CLAIM_STATE);

export const addHanitationalClaimAsync =
  (
    data: IhabitationalClaims,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-habitational-claims", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Claim saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHanitationalClaimListProgress = () =>
  action(FETCH_HABITATIONAL_CLAIM_LIST_PROGRESS);

export const fetchHanitationalClaimListSuccess = (
  data: IhabitationalClaims[]
) =>
  action(FETCH_HABITATIONAL_CLAIM_LIST_SUCCESS, {
    data,
  });

export const fetchHanitationalClaimListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHanitationalClaimListProgress());

      let finalUrl = `/policy/get-habitational-claims?customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IhabitationalClaims[] = res.data.data;
      dispatch(fetchHanitationalClaimListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
