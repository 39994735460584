import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyPremiumFinance } from "./businessPolicyPremiumFinance.types";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { uploadFile } from "../../../helpers";

export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_FAILED";

export const fetchBusinessPolicyPremiumFinanceListProgress = () =>
  action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_PROGRESS);
export const fetchBusinessPolicyPremiumFinanceListSuccess = (
  data: IBusinessPolicyPremiumFinance[]
) => action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_SUCCESS, { data });
export const fetchBusinessPolicyPremiumFinanceListFailed = () =>
  action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_LIST_FAILED);

export const fetchBusinessPolicyPremiumFinanceListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyPremiumFinanceListProgress());
      let finalUrl = `/policy/get-policy-business-premium-finance-agreement?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyPremiumFinance[] = res.data.data;
      dispatch(fetchBusinessPolicyPremiumFinanceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyPremiumFinanceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_PROGRESS =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_PROGRESS";
export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_SUCCESS =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_SUCCESS";
export const FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_FAILED =
  "FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_FAILED";

export const fetchBusinessPolicyPremiumFinanceProgress = () =>
  action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_PROGRESS);
export const fetchBusinessPolicyPremiumFinancerSuccess = (
  data: IBusinessPolicyPremiumFinance
) => action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_SUCCESS, { data });
export const fetchBusinessPolicyPremiumFinanceFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_PREMIUM_FINANCE_FAILED, { errorMessage });

export const fetchBusinessPolicyPremiumFinanceAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyPremiumFinanceProgress());
      const res = await api.get(
        `/policy/get-policy-business-premium-finance-agreement?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementNumber}`
      );
      const data: IBusinessPolicyPremiumFinance[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyPremiumFinancerSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyPremiumFinanceFailed(
            "Oops! We couldn't find any records associated with your fine art number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyPremiumFinanceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyPremiumFinanceAsync =
  (
    data: IBusinessPolicyPremiumFinance,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: data.customer_id,
        policy_type: "BUSINESS",
        customer_policy_id: data.customer_policy_id,
        page_name: "Premium Finance Agreement",
      };
      const path = await uploadFile(
        file,
        "POLICY",
        data.pfa_attachment || "",
        asPayload
      );
      await api.post(`/policy/edit-policy-business-premium-finance-agreement`, {
        ...data,
        pfa_attachment: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "PFA saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINES_POLICY_PREMIUM_FINANCE_STATE =
  "CLEAR_BUSINES_POLICY_PREMIUM_FINANCE_STATE";
export const CLEAR_BUSINES_POLICY_PREMIUM_FINANCE =
  "CLEAR_BUSINES_POLICY_PREMIUM_FINANCE";

export const clearBusinessPremiumFinanceState = () =>
  action(CLEAR_BUSINES_POLICY_PREMIUM_FINANCE_STATE);

export const clearBusinessPremiumFinance = () =>
  action(CLEAR_BUSINES_POLICY_PREMIUM_FINANCE);
