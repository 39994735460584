import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { IBusinessAttachment } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetails.types";
import {
  clearBusinessPolicyAttachment,
  fetchBusinessAttachmentListAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { LeadOppStatus } from "../../../../../../../components/LeadOppStatus/LeadOppStatus";
import { AttachmentBusiness } from "./Attachment";
import { useBreadcrumbContext } from "../../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

const INITIAL_STATE = {
  customer_policy_id: 0,
  serial_number: null,
  document_name: "",
  file_upload: "",
  description: "",
  status: "",
  send_attachment: 0,
};

export const BusinessAttachmentList = () => {
  const params = useOutletContext() as any;
  const customerId = params[0];
  const customerPolicyId = params[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "attachments",
    },
  ];
  const { list: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.attachment
  );
  const dispatch = useDispatchWrapper();

  const [openAttachment, setOpenAttachment] =
    React.useState<IBusinessAttachment | null>(null);

  const handleComplete = () => {
    dispatch(fetchBusinessAttachmentListAsync(customerPolicyId));
    setOpenAttachment(null);
  };

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchBusinessAttachmentListAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPolicyAttachment());
    };
  }, [customerPolicyId, status]);

  const attachmentTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenAttachment(row)}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenAttachment(row)}
            >
              {row.serial_number}
            </Link>
          );
        },
      },

      {
        key: "customer_policy_id",
        headerName: "Customer Policy ID",
        fieldName: "customer_policy_id",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 20,
      },

      {
        key: "document_name",
        headerName: "Document Name",
        fieldName: "document_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return <LeadOppStatus status={row.status} />;
        },
      },
    ],
    rows: data,
  };

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  React.useEffect(() => {
    dispatch(fetchBusinessAttachmentListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <PageTitleBar
        heading="Attachments"
        rightHeading={
          <Button
            variant="contained"
            onClick={() =>
              setOpenAttachment({
                ...INITIAL_STATE,
                customer_policy_id: customerPolicyId,
              })
            }
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...attachmentTableProps} />
      {openAttachment && (
        <AttachmentBusiness
          open={true}
          attachment={openAttachment}
          onClose={() => setOpenAttachment(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
