import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileCertificatesState } from "./commAutoCertificates.types";
import { CommAutomobileCertificatesActions } from ".";
import {
  CLEAR_COMM_AUTOMOBILE_OWNER_FORM,
  FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS,
  FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS,
} from "./commAutoCertificatesActions";

export const CommAutomobileCertificatesReducer = (
  state: IStoreState["commAuto"]["certificates"] = initialCommAutomobileCertificatesState,
  action: CommAutomobileCertificatesActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.InProgress;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_OWNER_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.Loaded;
        draftState.ownerform.owner = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.Failed;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.InProgress;
        draftState.ownerform.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.Loaded;
        draftState.ownerform.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OWNER_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.loading = LoadState.Failed;
        draftState.ownerform.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OWNER_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.ownerform.ownerLoading = LoadState.NotLoaded;
        draftState.ownerform.owner =
          initialCommAutomobileCertificatesState["ownerform"]["owner"];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.InProgress;
        draftState.dwellingdeclaration.dwelling =
          initialCommAutomobileCertificatesState["dwellingdeclaration"]["dwelling"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Loaded;
        draftState.dwellingdeclaration.dwelling = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.dwellingLoading = LoadState.Failed;
        draftState.dwellingdeclaration.dwelling =
          initialCommAutomobileCertificatesState["dwellingdeclaration"]["dwelling"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.InProgress;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Loaded;
        draftState.dwellingdeclaration.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_DECLARATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Failed;
        draftState.dwellingdeclaration.list = [];
      });
      return newState;
    }


    case FETCH_COMM_AUTOMOBILE_DWELLING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.InProgress;
        draftState.dwelling.dwelling =
          initialCommAutomobileCertificatesState["dwelling"]["dwelling"];
      });
      return newState;
    }
     case FETCH_COMM_AUTOMOBILE_DWELLING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Loaded;
        draftState.dwelling.dwelling = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.dwellingLoading = LoadState.Failed;
        draftState.dwelling.dwelling =
          initialCommAutomobileCertificatesState["dwelling"]["dwelling"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.InProgress;
        draftState.dwelling.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Loaded;
        draftState.dwelling.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_DWELLING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Failed;
        draftState.dwelling.list = [];
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE: {
      return initialCommAutomobileCertificatesState;
    }

    default: {
      return state;
    }
  }
};
