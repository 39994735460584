import { action } from "typesafe-actions";
import { IAccountingDashboardState } from "./accountingDashboard.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";

export const FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_PROGRESS =
  "FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_PROGRESS";
export const FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_SUCCESS =
  "FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_SUCCESS";
export const FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_FAILED =
  "FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_FAILED";

export const fetchAcDashboardEarningGraphProgress = () =>
  action(FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_PROGRESS);
export const fetchAcDashboardEarningGraphSuccess = (
  data: IAccountingDashboardState["earningGraph"]
) => action(FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_SUCCESS, { data });
export const fetchAcDashboardEarningGraphFailed = () =>
  action(FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_FAILED);

//week 1, day 7, month -7
export const fetchAcDashboardEarningGraphAysnc =
  (
    duration_type: string,
    duration: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAcDashboardEarningGraphProgress());
      const finalUrl = `/analytics/get-analytics-earning-graph?duration_type=${duration_type}&duration=${duration}`;
      const res = await api.get(finalUrl);
      const data: IAccountingDashboardState["earningGraph"] = res.data.data;

      dispatch(fetchAcDashboardEarningGraphSuccess(data));
    } catch (err: any) {
      dispatch(fetchAcDashboardEarningGraphFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const CLEAR_ACCOUNTING_DASHBOARD_STATE = "CLEAR_ACCOUNTING_DASHBOARD_STATE";
export const clearAccountingDashboardState = () => action(CLEAR_ACCOUNTING_DASHBOARD_STATE);