import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_INFO,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_FAILED,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_FAILED,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_PROGRESS,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_SUCCESS,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_PROGRESS,
  FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_SUCCESS,
} from "./automobileFraudMisRepresentActions";
import { initialAutomobileFraudMisRepresentState } from "./automobileFraudMisRepresent.types";
import { AutomobileFraudMisRepresentAction } from ".";

export const AutomobileFraudMisRepresentReducer = (
  state: IStoreState["automobile"]["fraudMisRepresent"] = initialAutomobileFraudMisRepresentState,
  action: AutomobileFraudMisRepresentAction
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_INFO: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAutomobileFraudMisRepresentState["data"];
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
