import { action } from "typesafe-actions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { IControlLog } from "./controlLog.types";
import { makeApiCall } from "../../helpers/postRequest";
export const FETCH_CONTROL_LOG_LIST_PROGRESS = "FETCH_CONTROL_LOG_LIST_PROGRESS";
export const FETCH_CONTROL_LOG_LIST_SUCCESS = "FETCH_CONTROL_LOG_LIST_SUCCESS";
export const FETCH_CONTROL_LOG_LIST_FAILED = "FETCH_CONTROL_LOG_LIST_FAILED";

export const fetchControlLogListProgress = () => action(FETCH_CONTROL_LOG_LIST_PROGRESS);
export const fetchControlLogListSuccess = (list: IControlLog[], totalRecords: number) =>
  action(FETCH_CONTROL_LOG_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchControlLogListFailed = () => action(FETCH_CONTROL_LOG_LIST_FAILED);

export const fetchControlLogListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    
    try {
      dispatch(fetchControlLogListProgress());

      let finalUrl = `/csio/get-policy-control-logs?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchValue.length > 0) {
        finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IControlLog[] = res.data.data;
      console.log("record", res.data.totalRecords);
      const totalRecords = res.data.totalRecords;

      dispatch(fetchControlLogListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchControlLogListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const FETCH_CONTROL_LOG_PROGRESS = "FETCH_CONTROL_LOG_PROGRESS";
export const FETCH_CONTROL_LOG_SUCCESS = "FETCH_CONTROL_LOG_SUCCESS";
export const FETCH_CONTROL_LOG_FAILED = "FETCH_CONTROL_LOG_FAILED";

export const fetchControlLogProgress = () => action(FETCH_CONTROL_LOG_PROGRESS);
export const fetchControlLogSuccess = (data: IControlLog) =>
  action(FETCH_CONTROL_LOG_SUCCESS, {
    data,
  });
export const fetchControlLogFailed = (errorMessage: string) =>
  action(FETCH_CONTROL_LOG_FAILED, { errorMessage });

export const fetchControlLogAsync =
  (controlId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchControlLogProgress());
      const res = await api.get(
        `/csio/get-policy-control-logs?message_guid=${controlId}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchControlLogSuccess({ ...data[0] }));
      } else {
        dispatch(fetchControlLogFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchControlLogFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const addAccountingReportsAysnc =
  (
    data: IControlLog,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: "/accounting/edit-accn-transaction-report",
          method: "POST",
        },
        {},
        {
          ...data,
        }
      );

      let message = "Log information Saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_CONTROL_LOG_STATE = "CLEAR_CONTROL_LOG_STATE";
export const clearControlLogState = () => action(CLEAR_CONTROL_LOG_STATE);

export const CLEAR_CONTROL_LOG = "CLEAR_CONTROL_LOG";
export const clearControlLog = () => action(CLEAR_CONTROL_LOG);
