import { LoadState } from "../../constants/enums";

export interface IAutomobileInsuranceLapseState {
  list: IAutomobileInsuranceLapse[];
  loading: LoadState;
  data: IAutomobileInsuranceLapse;
  error: string | null;
}

export interface IAutomobileInsuranceLapse {
  customer_policy_id: number;
  incremental_number: number | null;
  date: string | null;
  description: string | null;
  duration: string | null;
  reinstated: string | null;
  status: string;
}

export const INITIAL_INS_LAPSE_STATE: IAutomobileInsuranceLapse = {
  customer_policy_id: 0,
  incremental_number: null,
  date: null,
  description: null,
  duration: null,
  reinstated: null,
  status: "ACTIVE",
};

export const initialAutomobileInsuranceLapseState: IAutomobileInsuranceLapseState = {
  list: [],
  loading: LoadState.NotLoaded,
  error: null,
  data: INITIAL_INS_LAPSE_STATE,
}
