import { LoadState } from "../../constants/enums";

export interface IEnvConfigState {
  list: IEnvConfig[];
  loading: LoadState;
  totalRecords: number;
}

export interface IEnvConfig {
  environment_configuration_code: string | null;
  env_key: string | null;
  env_value: string | null;
  is_encrypted: number;
  status: string;
}

export const initialEnvConfigState: IEnvConfigState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
};


export const INITIAL_ENV_CONFIG: IEnvConfig = {
  environment_configuration_code: null,
  env_key: null,
  env_value: null,
  is_encrypted: 0,
  status: "ACTIVE",
}