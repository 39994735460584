import moment from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";
import { IAutomobileAlbertaApplication } from "../automobileAlbertaApplication/automobileAlbertaApplication.types";

export interface IAutomobileApplicationState {
  applicationsList: {
    list: IAutomobileApplicationList[];
    loading: LoadState;
    totalRecords: number;
  };
  application_on: {
    data: IAutomobileApplication[];
    listLoading: LoadState;
    application: IAutomobileApplication;
    loading: LoadState;
    error: string | null;
  };
  application_ab: {
    data: IAutomobileAlbertaApplication;
    loading: LoadState;
    error: string | null;
  };
}


export interface IAutomobileApplicationList {
  incremental_number: number;
  policy_number: string;
  customer_name: string;
  customer_code: string;
  application_type: string;
  create_ts: string | null;
  created_by_name: number;
  status: string;
}


export interface IAutoDescribedSectionOne {
  key: string;
  autoNo: string | null;
  modelYear: string | null;
  makeTradeName: string | null;
  model: string | null;
  bodyType: string | null;
  noCylinders: string | null;
  grossVehicleWeight: string | null;
}

export interface IAutoDescribedSectionTwo {
  key: string;
  autoNo: string | null;
  vinNumber: string | null;
  owned: string | null;
  leased: string | null;
  purchaseLeasedDate: string | null;
  purchaseLeased: string | null;
  purchasePrice: string | null;
  automobileUsed: string | null;
  automobileUsedOneWay: string | null;
  automobileUsedPrecentage: string | null;
}

export interface IAutoDescribedSectionThree {
  key: string;
  autoNo: string | null;
  annualDrivingDistance: number | null;
  automobileUsedForPooling: string | null;
  automobileUsedForPoolingIfYes: string | null;
  typefFuelUsed: string | null;
  typefFuelUsedIfYes: string | null;
  unrepairedDamage: string | null;
  modifiedCustomized: string | null;
}

export interface IAutoDescribedSectionFour {
  key: string;
  autoNo: string;
  lienholderName: string;
  postalAddress: string;
}

export interface IAutoDriverInformationOne {
  key: string;
  driverNo: string | null;
  nameofDriver: string | null;
  driverLicenseNumber: string | null;
  dateOfBirth: string;
  sex: string;
  maritalStatus: string;
}
export interface IAutoDriverInformationTwo {
  key: string;
  driverNo: string | null;
  driverTrainingCertificateAttached: string | null;
  dateFirstLicencedInCanadaorUSClass: string | null;
  dateFirstLicencedInCanadaorUSYear: string | null;
  otherClassofLicenseIfAnyClass: string | null;
  otherClassofLicenseIfAnyYear: string | null;
  percentageUseByEachDriverAuto1: string | null;
  percentageUseByEachDriverAuto2: string | null;
  percentageUseByEachDriverAuto3: string | null;
  areAnyOtherPersonsInTheHousehold: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
  doAnyDriversQualifyForRetireeDiscount: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
}

export interface IAutoPreviousClaims {
  key: string;
  driverNo: string | null;
  autoNo: string | null;
  date: string | null;
  coverageClaimPaidUnder: {
    bi: string | null;
    pd: string | null;
    Ab: string | null;
    dcpd: string | null;
    ua: string | null;
    col: string | null;
    comp: string | null;
  };
  amountPaidOrEstimate: string | null;
  details: string | null;
}

export interface IAutoHistory {
  key: string;
  driverNo: string | null;
  dateConvicted: string | null;
  details: string;
}

export interface IAutoRatingInformationOne {
  key: string;
  autoNo: string | null;
  class: string | null;
  drivingRecord: string | null;
  bi: string | null;
  pd: string | null;
  ab: string | null;
  dcpd: string | null;
  coll_ap: string | null;
  driverNoPrimary: string | null;
  driverNoSceondary: string | null;
  atFaultClaimSurcharges: string | null;
  atFaultClaimPrecentage: string | null;
  convictionsSurcharges: string | null;
  convictionsPrecentage: string | null;
}
export interface IAutoRatingInformationTwo {
  key: string;
  autoNo: string | null;
  listPriceView: string | null;
  vehicleCode: string | null;
  ratingGroupAb: string | null;
  ratingGroupDcpd: string | null;
  ratingGroupCollAp: string | null;
  ratingGroupcompSp: string | null;
  ratinratingGroupDcpd: string | null;
  location: string | null;
  territory: string | null;
  discountDescription: string | null;
  discountPrecentage: string | null;
}

export interface IAutomobilePremiumPaymentSchedule {
  id: string;
  installment_number: string;
  installment_amount: string;
  installment_date: string;
}

export interface IAutoInsuranceApplied {
  key: string;
  code: string;
  label: string;
  description: string;
  isTypeOther: boolean;
  automobiles: {
    [key: string]: {
      limit: string | null;
      premimum: string | null;
      deductible: string | null;
      isOnlyPremimum: boolean;
    };
  };
}

export interface IAutomobileApplication {
  customer_policy_id: number | null;
  incremental_number: number | null;
  policy_number_assigned: string | null;
  replacing_policy_no: string | null;
  new_policy: string | null;
  billing_method: string | null;
  language_preferred: string | null;
  policy_period_effective_date: string | null;
  policy_period_effective_time: string | null;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string | null;
  insurance: {
    insurance_name: string | null;
    insurance_address: string | null;
    insurance_unit_or_suite: string | null;
    insurance_city: string | null;
    insurance_state: string | null;
    insurance_country: string | null;
    insurance_zip: string | null;
  };
  broker: {
    broker_agent: string | null;
    broker_agent_address: string | null;
    broker_agent_unit_or_suite: string | null;
    broker_agent_city: string | null;
    broker_agent_state: string | null;
    broker_agent_country: string | null;
    broker_agent_zip: string | null;
    broker_code: string | null;
    broker_sub_code: string | null;
  };
  application_details: {
    name: string | null;
    address: string | null;
    address_two: string | null;
    country: string | null;
    city: string | null;
    state: string | null;
    postalcode: string | null;
    phone: string | null;
    mobile: string | null;
  };
  lessor: {
    lessor_name: string | null;
    lessor_full_address: string | null;
    lessor_phone: string | null;
    lessor_email: string | null;
  };
  described_automobiles: {
    described_automobiles_part_one: IAutoDescribedSectionOne[];
    described_automobiles_part_two: IAutoDescribedSectionTwo[];
    described_automobiles_part_three: IAutoDescribedSectionThree[];
    described_automobiles_part_four: IAutoDescribedSectionFour[];
    owner_actual_owner_yesNoCd: string | null;
    total_automobiles_in_business: string | null;
    rented_or_leased_to_other: string | null;
    carry_passengers_for_compensation: string | null;
    haul_a_trailer: string | null;
    carry_explosives: string | null;
  };
  driver_information: {
    section_one: IAutoDriverInformationOne[];
    section_two: IAutoDriverInformationTwo[];
    insurance_company: string | null;
    prior_policy_number: string | null;
    expiry_date: string | null;
    has_any_driver_license: string | null;
    has_any_insurance_company: string | null;
    during_the_last_three_years: string | null;
    has_the_applicant_or_any_listed_driver: string | null;
  };
  previous_accidents_and_insurance_claims: IAutoPreviousClaims[];
  history_of_convictions: IAutoHistory[];
  rating_information: {
    section_one: IAutoRatingInformationOne[];
    section_two: IAutoRatingInformationTwo[];
  };
  payment_reporter_of_broker: {
    type_of_payment_plan: string | null;
    estimated_policy_premium: string | null;
    provincial_sales_tax: string | null;
    interest_amount: string | null;
    total_estimated_cost: string | null;
    amount_paid_with_application: string | null;
    amount_due: string | null;
    no_of_remaining_installments: string | null;
    amount_of_each_installment: string | null;
    installment_due_date: string | null;
    have_you_bound_the_risk: string | null;
    is_this_business_new_to_you: string | null;
    type_of_motor_vehicle_liability_insurance_card: string | null;
    how_long_you_have_known_the_applicant: string | null;
    how_long_you_have_known_the_principal_driver: string | null;
    has_an_inspection_been_done: string | null;
  };
  payment_schedule: IAutomobilePremiumPaymentSchedule[];
  incurance_coverage_applied_for: {
    columns: string[];
    rows: IAutoInsuranceApplied[];
  };
  remarks: string | null;
  status: string;
}

export const initialAutomobileApplicationState: IAutomobileApplicationState = {
  applicationsList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
  },
  application_on: {
    data: [],
    listLoading: LoadState.NotLoaded,
    loading: LoadState.NotLoaded,
    error: null,
    application: {
      customer_policy_id: null,
      incremental_number: null,
      policy_number_assigned: null,
      replacing_policy_no: null,
      new_policy: null,
      billing_method: null,
      language_preferred: null,
      policy_period_effective_date: null,
      policy_period_effective_time: null,
      policy_period_expiry_date: null,
      policy_period_expiry_time: null,
      insurance: {
        insurance_name: null,
        insurance_address: null,
        insurance_unit_or_suite: null,
        insurance_city: null,
        insurance_state: null,
        insurance_country: null,
        insurance_zip: null,
      },
      broker: {
        broker_agent: null,
        broker_agent_address: null,
        broker_agent_unit_or_suite: null,
        broker_agent_city: null,
        broker_agent_state: null,
        broker_agent_country: null,
        broker_agent_zip: null,
        broker_code: null,
        broker_sub_code: null,
      },
      application_details: {
        name: null,
        address: null,
        address_two: null,
        country: null,
        city: null,
        state: null,
        postalcode: null,
        phone: null,
        mobile: null,
      },
      lessor: {
        lessor_name: null,
        lessor_full_address: null,
        lessor_phone: null,
        lessor_email: null,
      },
      described_automobiles: {
        described_automobiles_part_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            modelYear: null,
            makeTradeName: null,
            model: null,
            bodyType: null,
            noCylinders: null,
            grossVehicleWeight: null,
          },
        ],
        described_automobiles_part_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            vinNumber: null,
            owned: null,
            leased: null,
            purchaseLeasedDate: null,
            purchaseLeased: null,
            purchasePrice: null,
            automobileUsed: null,
            automobileUsedOneWay: null,
            automobileUsedPrecentage: null,
          },
        ],
        described_automobiles_part_three: [
          {
            key: getUniqueId(),
            autoNo: null,
            annualDrivingDistance: null,
            automobileUsedForPooling: null,
            automobileUsedForPoolingIfYes: null,
            typefFuelUsed: null,
            typefFuelUsedIfYes: null,
            unrepairedDamage: null,
            modifiedCustomized: null,
          },
        ],
        described_automobiles_part_four: [
          {
            key: getUniqueId(),
            autoNo: "",
            lienholderName: "",
            postalAddress: "",
          },
        ],
        owner_actual_owner_yesNoCd: null,
        total_automobiles_in_business: null,
        rented_or_leased_to_other: null,
        carry_passengers_for_compensation: null,
        haul_a_trailer: null,
        carry_explosives: null,
      },
      driver_information: {
        section_one: [
          {
            key: getUniqueId(),
            driverNo: null,
            nameofDriver: null,
            driverLicenseNumber: null,
            dateOfBirth: "",
            sex: "",
            maritalStatus: "",
          },
        ],
        section_two: [
          {
            key: getUniqueId(),
            driverNo: null,
            driverTrainingCertificateAttached: null,
            dateFirstLicencedInCanadaorUSClass: null,
            dateFirstLicencedInCanadaorUSYear: null,
            otherClassofLicenseIfAnyClass: null,
            otherClassofLicenseIfAnyYear: null,
            percentageUseByEachDriverAuto1: null,
            percentageUseByEachDriverAuto2: null,
            percentageUseByEachDriverAuto3: null,
            areAnyOtherPersonsInTheHousehold: {
              questionCd: null,
              yesNoCd: null,
            },
            doAnyDriversQualifyForRetireeDiscount: {
              questionCd: null,
              yesNoCd: null,
            },
          },
        ],
        insurance_company: null,
        prior_policy_number: null,
        expiry_date: null,
        has_any_driver_license: null,
        has_any_insurance_company: null,
        during_the_last_three_years: null,
        has_the_applicant_or_any_listed_driver: null,
      },
      previous_accidents_and_insurance_claims: [
        {
          key: getUniqueId(),
          driverNo: null,
          autoNo: null,
          date: null,
          coverageClaimPaidUnder: {
            bi: null,
            pd: null,
            Ab: null,
            dcpd: null,
            ua: null,
            col: null,
            comp: null,
          },
          amountPaidOrEstimate: null,
          details: null,
        },
      ],
      history_of_convictions: [
        {
          key: getUniqueId(),
          driverNo: null,
          dateConvicted: null,
          details: "",
        },
      ],
      rating_information: {
        section_one: [
          {
            key: getUniqueId(),
            autoNo: null,
            class: null,
            drivingRecord: null,
            bi: null,
            pd: null,
            ab: null,
            dcpd: null,
            coll_ap: null,
            driverNoPrimary: null,
            driverNoSceondary: null,
            atFaultClaimSurcharges: null,
            atFaultClaimPrecentage: null,
            convictionsSurcharges: null,
            convictionsPrecentage: null,
          },
        ],
        section_two: [
          {
            key: getUniqueId(),
            autoNo: null,
            listPriceView: null,
            vehicleCode: null,
            ratingGroupAb: null,
            ratingGroupDcpd: null,
            ratingGroupCollAp: null,
            ratingGroupcompSp: null,
            ratinratingGroupDcpd: null,
            location: null,
            territory: null,
            discountDescription: null,
            discountPrecentage: null,
          },
        ],
      },
      payment_reporter_of_broker: {
        type_of_payment_plan: null,
        estimated_policy_premium: null,
        provincial_sales_tax: null,
        interest_amount: null,
        total_estimated_cost: null,
        amount_paid_with_application: null,
        amount_due: null,
        no_of_remaining_installments: null,
        amount_of_each_installment: null,
        installment_due_date: null,
        have_you_bound_the_risk: null,
        is_this_business_new_to_you: null,
        type_of_motor_vehicle_liability_insurance_card: null,
        how_long_you_have_known_the_applicant: null,
        how_long_you_have_known_the_principal_driver: null,
        has_an_inspection_been_done: null,
      },
      payment_schedule: [
        {
          id: getUniqueId(),
          installment_number: "",
          installment_amount: "",
          installment_date: "",
        },
      ],
      incurance_coverage_applied_for: {
        columns: [],
        rows: [
          {
            key: getUniqueId(),
            code: "",
            label: "",
            description: "",
            isTypeOther: false,
            automobiles: {
              "": {
                limit: null,
                premimum: null,
                deductible: null,
                isOnlyPremimum: false,
              },
            },
          },
        ],
      },
      remarks: null,
      status: "ACTIVE",
    },
  },
  application_ab: {
    loading: LoadState.NotLoaded,
    error: null,
    data: {
      customer_policy_id: 0,
            incremental_number: null,
            new_policy: false,
            renewal_policy: false,
            replacing_policy_no: null,
            company_bill: false,
            broker_or_agent_bill: false,
            other_specify: null,
            language_preferred: null,
            policy_no_assigned: null,
            insurance_company: null,
            broke_or_agent: null,
            insurance: {
              insurance_name: null,
              insurance_address: null,
              insurance_unit_or_suite: null,
              insurance_city: null,
              insurance_state: null,
              insurance_country: null,
              insurance_zip: null,
            },
            broker: {
              broker_agent: null,
              broker_agent_address: null,
              broker_agent_unit_or_suite: null,
              broker_agent_city: null,
              broker_agent_state: null,
              broker_agent_country: null,
              broker_agent_zip: null,
              broker_code: null,
              broker_sub_code: null,
            },
            application_details: {
              name: null,
              address: null,
              address_two: null,
              country: null,
              city: null,
              state: null,
              postalcode: null,
              phone: null,
              mobile: null,
            },
            lessor: {
              lessor_name: null,
              lessor_full_address: null,
              lessor_phone: null,
              lessor_email: null,
            },
            policy_period_effective_date: null,
            policy_period_effective_time: moment().format(),
            policy_period_expiry_date: null,
            policy_period_expiry_time: moment()
              .startOf("day")
              .add(1, "minutes")
              .format(),
            described_automobiles_part_one: [
              {
                key: getUniqueId(),
                vehNo: 1,
                bodyType: null,
                makeTradeName: null,
                model: null,
                modelYear: null,
                purchase_price_including_accessories_equipment: null,
                vin_serial_No: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                bodyType: null,
                makeTradeName: null,
                model: null,
                modelYear: null,
                purchase_price_including_accessories_equipment: null,
                vin_serial_No: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                bodyType: null,
                makeTradeName: null,
                model: null,
                modelYear: null,
                purchase_price_including_accessories_equipment: null,
                vin_serial_No: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                bodyType: null,
                makeTradeName: null,
                model: null,
                modelYear: null,
                purchase_price_including_accessories_equipment: null,
                vin_serial_No: null,
              },
            ],
            described_automobiles_part_two: [
              {
                key: getUniqueId(),
                VehNo: 1,
                postal_code: null,
                postal_address: null,
                name: null,
                type: null,
              },
              {
                key: getUniqueId(),
                VehNo: 2,
                postal_code: null,
                postal_address: null,
                name: null,
                type: null,
              },
              {
                key: getUniqueId(),
                VehNo: 3,
                postal_code: null,
                postal_address: null,
                name: null,
                type: null,
              },
              {
                key: getUniqueId(),
                VehNo: 4,
                postal_code: null,
                postal_address: null,
                name: null,
                type: null,
              },
            ],
            described_automobiles_part_three: [
              {
                key: getUniqueId(),
                vehNo: 1,
                gvw_kg: null,
                winter_tires: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                gvw_kg: null,
                winter_tires: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                gvw_kg: null,
                winter_tires: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                gvw_kg: null,
                winter_tires: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
            ],
            described_automobiles_part_three_a: [
              {
                key: getUniqueId(),
                occasional_driver_vehicle_no: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
              {
                key: getUniqueId(),
                occasional_driver_vehicle_no: null,
                list_price_new: null,
                vehicle_code: null,
                terr: null,
                loc: null,
                class: null,
                drtpl: null,
                dr_coll_ap: null,
                dcpd: null,
                coll: null,
                comp: null,
              },
            ],
            described_automobiles_part_four: [
              {
                key: getUniqueId(),
                vehNo: 1,
                third_party_liability: null,
                direct_comp_for_property_damage: null,
                accident_benefits: "As stated in Section B of the Policy",
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                veh_no: null,
                endorement_no: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                third_party_liability: null,
                direct_comp_for_property_damage: null,
                accident_benefits: "As stated in Section B of the Policy",
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                veh_no: null,
                endorement_no: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                third_party_liability: null,
                direct_comp_for_property_damage: null,
                accident_benefits: "As stated in Section B of the Policy",
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                veh_no: null,
                endorement_no: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                third_party_liability: null,
                direct_comp_for_property_damage: null,
                accident_benefits: "As stated in Section B of the Policy",
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                veh_no: null,
                endorement_no: null,
              },
            ],
            described_automobiles_part_four_a: [
              {
                key: getUniqueId(),
                vehNo: 1,
                third_party_liability_pd: null,
                third_party_liability_bi: null,
                third_party_liability_grid: null,
                benefits_of_accident: null,
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                sef_premium: null,
                vehicle_premium: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                third_party_liability_pd: null,
                third_party_liability_bi: null,
                third_party_liability_grid: null,
                benefits_of_accident: null,
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                sef_premium: null,
                vehicle_premium: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                third_party_liability_pd: null,
                third_party_liability_bi: null,
                third_party_liability_grid: null,
                benefits_of_accident: null,
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                sef_premium: null,
                vehicle_premium: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                third_party_liability_pd: null,
                third_party_liability_bi: null,
                third_party_liability_grid: null,
                benefits_of_accident: null,
                loss_of_Damage_ap: null,
                loss_of_Damage_coll: null,
                loss_of_Damage_comp: null,
                loss_of_Damage_sp: null,
                sef_premium: null,
                vehicle_premium: null,
              },
            ],
            minimum_retained_premium: null,
            total_estimated_policy_premium: null,
            driver_information_part_one: [
              {
                key: getUniqueId(),
                driver_number: 1,
                name_of_driver: null,
                driver_license_number: null,
                dateOfBirth: "",
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                name_of_driver: null,
                driver_license_number: null,
                dateOfBirth: "",
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                name_of_driver: null,
                driver_license_number: null,
                dateOfBirth: "",
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                name_of_driver: null,
                driver_license_number: null,
                dateOfBirth: "",
              },
            ],
            driver_information_part_two: [
              {
                key: getUniqueId(),
                driver_number: 1,
                date_lic: "",
                lic_class: null,
                grid_step: null,
                driver_veh_one: null,
                driver_veh_two: null,
                driver_veh_three: null,
                driver_veh_four: null,
                driver_occupation: null,
                driver_training_attach_dtc_cert: null,
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                date_lic: "",
                lic_class: null,
                grid_step: null,
                driver_veh_one: null,
                driver_veh_two: null,
                driver_veh_three: null,
                driver_veh_four: null,
                driver_occupation: null,
                driver_training_attach_dtc_cert: null,
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                date_lic: "",
                lic_class: null,
                grid_step: null,
                driver_veh_one: null,
                driver_veh_two: null,
                driver_veh_three: null,
                driver_veh_four: null,
                driver_occupation: null,
                driver_training_attach_dtc_cert: null,
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                date_lic: "",
                lic_class: null,
                grid_step: null,
                driver_veh_one: null,
                driver_veh_two: null,
                driver_veh_three: null,
                driver_veh_four: null,
                driver_occupation: null,
                driver_training_attach_dtc_cert: null,
              },
            ],
            all_convictions: [
              {
                key: getUniqueId(),
                driver_number: "",
                date: "",
                description: null,
              },
              {
                key: getUniqueId(),
                driver_number: "",
                date: "",
                description: null,
              },
              {
                key: getUniqueId(),
                driver_number: "",
                date: "",
                description: null,
              },
              {
                key: getUniqueId(),
                driver_number: "",
                date: "",
                description: null,
              },
            ],
            all_accidents_or_claims: [
              {
                key: getUniqueId(),
                vehNo: "",
                driver_number: "",
                date: "",
                type_of_claim: null,
                amount_paid_or_estimate: null,
                claim_amount_repaid_to_insurer: null,
                description: null,
              },
              {
                key: getUniqueId(),
                vehNo: "",
                driver_number: "",
                date: "",
                type_of_claim: null,
                amount_paid_or_estimate: null,
                claim_amount_repaid_to_insurer: null,
                description: null,
              },
              {
                key: getUniqueId(),
                vehNo: "",
                driver_number: "",
                date: "",
                type_of_claim: null,
                amount_paid_or_estimate: null,
                claim_amount_repaid_to_insurer: null,
                description: null,
              },
              {
                key: getUniqueId(),
                vehNo: "",
                driver_number: "",
                date: "",
                type_of_claim: null,
                amount_paid_or_estimate: null,
                claim_amount_repaid_to_insurer: null,
                description: null,
              },
            ],
            has_any_drivers: null,
            has_any_insurer: {
              insurer: "",
              reason: "",
              policy_no: "",
            },
            details_of_applicants_most_recent_automobile_insurance: {
              insurer: "",
              policy_no: "",
              expiry_date: moment().format("YYYY-MM-DD"),
            },
            does_applicant_owe_any_money: null,
            all_accidents_or_claims_tabel_two: [
              {
                key: getUniqueId(),
                VehNo: 1,
                state_the_usual_distance_driven_annually: null,
                the_vehicle_used_to_commute: null,
                distance_one_way: null,
                state_the_usual_percentage_of_annual_kilometer: null,
                is_the_vehicle_used_outside_of_canada: null,
                no_of_months: null,
              },
              {
                key: getUniqueId(),
                VehNo: 2,
                state_the_usual_distance_driven_annually: null,
                the_vehicle_used_to_commute: null,
                distance_one_way: null,
                state_the_usual_percentage_of_annual_kilometer: null,
                is_the_vehicle_used_outside_of_canada: null,
                no_of_months: null,
              },
              {
                key: getUniqueId(),
                VehNo: 3,
                state_the_usual_distance_driven_annually: null,
                the_vehicle_used_to_commute: null,
                distance_one_way: null,
                state_the_usual_percentage_of_annual_kilometer: null,
                is_the_vehicle_used_outside_of_canada: null,
                no_of_months: null,
              },
              {
                key: getUniqueId(),
                VehNo: 4,
                state_the_usual_distance_driven_annually: null,
                the_vehicle_used_to_commute: null,
                distance_one_way: null,
                state_the_usual_percentage_of_annual_kilometer: null,
                is_the_vehicle_used_outside_of_canada: null,
                no_of_months: null,
              },
            ],
            are_there_any_modification: null,
            all_accidents_or_claims_tabel_three: [
              {
                key: getUniqueId(),
                vehNo: 1,
                will_the_automobile_be_rented_leased_etc: null,
                will_the_automobile_be_used_for_the_transportation_of_goods: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                will_the_automobile_be_rented_leased_etc: null,
                will_the_automobile_be_used_for_the_transportation_of_goods: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                will_the_automobile_be_rented_leased_etc: null,
                will_the_automobile_be_used_for_the_transportation_of_goods: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                will_the_automobile_be_rented_leased_etc: null,
                will_the_automobile_be_used_for_the_transportation_of_goods: null,
              },
            ],
            unless_otherwise_stated: {
              the_actual_owner: null,
              the_registered_owner: null,
            },
            remarks: null,
            endorsements_vehicle_one: [
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
            ],
            endorsements_vehicle_two: [
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
            ],
            endorsements_vehicle_three: [
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
            ],
            endorsements_vehicle_four: [
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
              {
                key: getUniqueId(),
                sef_n: null,
                description: null,
                limit_one: null,
                limit_two: null,
                deductible: null,
                percentage: null,
                premium: null,
              },
            ],
            additional_information_for_drivers_part_one: [
              {
                key: getUniqueId(),
                driver_number: 1,
                driverName: null,
                driver_training_date_completed: "",
                type: null,
                sex: null,
                marital_status: null,
                relationship_to_applicant: null,
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                driverName: null,
                driver_training_date_completed: "",
                type: null,
                sex: null,
                marital_status: null,
                relationship_to_applicant: null,
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                driverName: null,
                driver_training_date_completed: "",
                type: null,
                sex: null,
                marital_status: null,
                relationship_to_applicant: null,
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                driverName: null,
                driver_training_date_completed: "",
                type: null,
                sex: null,
                marital_status: null,
                relationship_to_applicant: null,
              },
            ],
            additional_information_for_drivers_part_two: [
              {
                key: getUniqueId(),
                driver_number: 1,
                conviction_date_of_mvr: "",
                conviction_code: null,
                conviction_percentage: null,
                conviction_description: null,
                atFault_code: null,
                atFault_percentage: null,
                atFault_description: null,
                discountApplied_code: null,
                discountApplied_percentage: null,
                discountApplied_description: null,
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                conviction_date_of_mvr: "",
                conviction_code: null,
                conviction_percentage: null,
                conviction_description: null,
                atFault_code: null,
                atFault_percentage: null,
                atFault_description: null,
                discountApplied_code: null,
                discountApplied_percentage: null,
                discountApplied_description: null,
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                conviction_date_of_mvr: "",
                conviction_code: null,
                conviction_percentage: null,
                conviction_description: null,
                atFault_code: null,
                atFault_percentage: null,
                atFault_description: null,
                discountApplied_code: null,
                discountApplied_percentage: null,
                discountApplied_description: null,
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                conviction_date_of_mvr: "",
                conviction_code: null,
                conviction_percentage: null,
                conviction_description: null,
                atFault_code: null,
                atFault_percentage: null,
                atFault_description: null,
                discountApplied_code: null,
                discountApplied_percentage: null,
                discountApplied_description: null,
              },
            ],
            name_and_address_of_employer: [
              {
                key: getUniqueId(),
                driver_number: 1,
                name: null,
                address: null,
                date_hired: "",
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                name: null,
                address: null,
                date_hired: "",
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                name: null,
                address: null,
                date_hired: "",
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                name: null,
                address: null,
                date_hired: "",
              },
            ],
            list_all_residents_of_household: [
              {
                key: getUniqueId(),
                driver_number: 1,
                full_name: null,
                birth_date: "",
                driver_licence_no: null,
                own_a_vehicle: null,
                non_licensed_resident: null,
              },
              {
                key: getUniqueId(),
                driver_number: 2,
                full_name: null,
                birth_date: "",
                driver_licence_no: null,
                own_a_vehicle: null,
                non_licensed_resident: null,
              },
              {
                key: getUniqueId(),
                driver_number: 3,
                full_name: null,
                birth_date: "",
                driver_licence_no: null,
                own_a_vehicle: null,
                non_licensed_resident: null,
              },
              {
                key: getUniqueId(),
                driver_number: 4,
                full_name: null,
                birth_date: "",
                driver_licence_no: null,
                own_a_vehicle: null,
                non_licensed_resident: null,
              },
            ],
            is_vehicle_used_for_car_pools: [
              {
                key: getUniqueId(),
                veh_no: 1,
                type: null,
                no_of_passengers: null,
                frequency_of_times: null,
                frequency_per: null,
                fuel_if_not_powered_by_gas_diesel_engine: null,
              },
              {
                key: getUniqueId(),
                veh_no: 2,
                type: null,
                no_of_passengers: null,
                frequency_of_times: null,
                frequency_per: null,
                fuel_if_not_powered_by_gas_diesel_engine: null,
              },
              {
                key: getUniqueId(),
                veh_no: 3,
                type: null,
                no_of_passengers: null,
                frequency_of_times: null,
                frequency_per: null,
                fuel_if_not_powered_by_gas_diesel_engine: null,
              },
              {
                key: getUniqueId(),
                veh_no: 4,
                type: null,
                no_of_passengers: null,
                frequency_of_times: null,
                frequency_per: null,
                fuel_if_not_powered_by_gas_diesel_engine: null,
              },
            ],
            is_vehicle_used_for_car_pools2: [
              {
                key: getUniqueId(),
                is_there_any_unprepaired_damage_including_damage_to_glass: null,
                describe_and_give_value_for_any_special_equipment_value: null,
                describe_and_give_value_for_any_special_equipment_describe: null,
              },
              {
                key: getUniqueId(),
                is_there_any_unprepaired_damage_including_damage_to_glass: null,
                describe_and_give_value_for_any_special_equipment_value: null,
                describe_and_give_value_for_any_special_equipment_describe: null,
              },
              {
                key: getUniqueId(),
                is_there_any_unprepaired_damage_including_damage_to_glass: null,
                describe_and_give_value_for_any_special_equipment_value: null,
                describe_and_give_value_for_any_special_equipment_describe: null,
              },
              {
                key: getUniqueId(),
                is_there_any_unprepaired_damage_including_damage_to_glass: null,
                describe_and_give_value_for_any_special_equipment_value: null,
                describe_and_give_value_for_any_special_equipment_describe: null,
              },
            ],
            provide_details_of_vehicle_antitheft_device: [
              {
                key: getUniqueId(),
                vehNo: 1,
                deviceType: null,
                deviceCharacterstics: null,
                prouductCode: null,
              },
              {
                key: getUniqueId(),
                vehNo: 2,
                deviceType: null,
                deviceCharacterstics: null,
                prouductCode: null,
              },
              {
                key: getUniqueId(),
                vehNo: 3,
                deviceType: null,
                deviceCharacterstics: null,
                prouductCode: null,
              },
              {
                key: getUniqueId(),
                vehNo: 4,
                deviceType: null,
                deviceCharacterstics: null,
                prouductCode: null,
              },
            ],
            if_applicant_has_changed_address_within_the_last_three_year: null,
            total_number_of_private_passenger_vehicle_in_household: null,
            additonal_information_remarks: null,
            have_you_bound_this_risk: null,
            is_this_business_new_to_your_office: null,
            motor_vehicle_liability_insurance_card_issued: null,
            how_long_have_your_known: {
              the_applicant: null,
              driver_number_a: null,
              driver_number_b: null,
              the_principal_operators: null,
            },
            provide_applicants_email_address_if_applicabel: null,
            does_your_client_have_other_insurance_with_this_company: {
              type: null,
              policy_number_one: null,
              policy_number_two: null,
              if_yes_give_particular: null,
            },
            are_there_any_special_cicumstances_concerning_this_application: {
              type: null,
              if_yes_give_particular: null,
            },
            is_this_risk_eligible_for_the_residual_market: {
              type: null,
              if_yes_give_particular: null,
            },
            was_the_supplementary_market_availability_plan_accessed: {
              type: null,
              if_yes_provide_map_reference_number: null,
            },
          broker_agent_name: null,
          date: moment().format("YYYY-MM-DD"),
          describe_any_trailer_not_already_listed: null,
          commercial_rated_vehicles: null,
          commercial_vehicle_section: [
            {
              key: getUniqueId(),
              veh_no: 1,
              does_vehicle_weight_exceed_4500_kg: null,
              is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
            },
            {
              key: getUniqueId(),
              veh_no: 2,
              does_vehicle_weight_exceed_4500_kg: null,
              is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
            },
            {
              key: getUniqueId(),
              veh_no: 3,
              does_vehicle_weight_exceed_4500_kg: null,
              is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
            },
            {
              key: getUniqueId(),
              veh_no: 4,
              does_vehicle_weight_exceed_4500_kg: null,
              is_operating_radius_greater_than_40km_from_place_vehicle_usually_kept: null,
            },
          ],
          commercial_vehicle_use: [
            {
              key: getUniqueId(),
              vehNo: 1,
              percentage_of_pleasure_use: null,
              delivery: null,
              wholesale: null,
              retail: null,
              other: "Add in Remarks section below",
              hauling_done_for_others: null,
              specify: null,
            },
            {
              key: getUniqueId(),
              vehNo: 2,
              percentage_of_pleasure_use: null,
              delivery: null,
              wholesale: null,
              retail: null,
              other: "Add in Remarks section below",
              hauling_done_for_others: null,
              specify: null,
            },
            {
              key: getUniqueId(),
              vehNo: 3,
              percentage_of_pleasure_use: null,
              delivery: null,
              wholesale: null,
              retail: null,
              other: "Add in Remarks section below",
              hauling_done_for_others: null,
              specify: null,
            },
            {
              key: getUniqueId(),
              vehNo: 4,
              percentage_of_pleasure_use: null,
              delivery: null,
              wholesale: null,
              retail: null,
              other: "Add in Remarks section below",
              hauling_done_for_others: null,
              specify: null,
            },
          ],
          is_there_any_merchandise_or_material_carried: [
            {
              key: getUniqueId(),
              vehNo: 1,
              type: null,
              if_yes_describe: null,
              if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
            },
            {
              key: getUniqueId(),
              vehNo: 2,
              type: null,
              if_yes_describe: null,
              if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
            },
            {
              key: getUniqueId(),
              vehNo: 3,
              type: null,
              if_yes_describe: null,
              if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
            },
            {
              key: getUniqueId(),
              vehNo: 4,
              type: null,
              if_yes_describe: null,
              if_volatile_toxic_corrosive_radio_active_or_explosive_materials_carried_state_quantities: null,
            },
          ],
          is_there_any_machinery_or_equipment_mounted_on_or_attached: [
            {
              key: getUniqueId(),
              vehNo: 1,
              type: null,
              if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
            },
            {
              key: getUniqueId(),
              vehNo: 2,
              type: null,
              if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
            },
            {
              key: getUniqueId(),
              vehNo: 3,
              type: null,
              if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
            },
            {
              key: getUniqueId(),
              vehNo: 4,
              type: null,
              if_yes_describe_and_name_owner_if_not_owned_by_applicant: null,
            },
          ],
          total_remarks: null,
          status: "ACTIVE",
    },

  }
  
};
