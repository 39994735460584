import { LoadState } from "../../constants/enums";

export interface IGroupPlansState {
  list: IGroupPlans[];
  loading: LoadState;
  data: IGroupPlans;
  error: string | null;
}

export interface IGroupPlans {
  broker_group_plan_code: string | null;
  group_name: string | null;
  line_of_business: string | null;
  province: string | null;
  select_insurer_code: string | null;
  status: string;
}

export const initialGroupPlansState: IGroupPlansState = {
  list: [],
  loading: LoadState.NotLoaded,
  error: null,
  data: {
    broker_group_plan_code: null,
    group_name: null,
    line_of_business: null,
    province: null,
    select_insurer_code: null,
    status: "ACTIVE",
  },
};
