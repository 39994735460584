import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ICommAutoPolicyFormsState {
  policyFormsList: {
    list: ICommAutoPolicyForm[];
    loading: LoadState;
    totalRecords: number;
  };
  liabilitySlips: {
    data: ICommAutomobileLiabilitySlips;
    loading: LoadState;
    error: string | null;
  };
  liabilityInsurance: {
    data: ICommAutomobileLiabilityInsurance;
    loading: LoadState;
    error: string | null;
  };
  opcf16: {
    data: ICommAutomobileOCEP16;
    loading: LoadState;
    error: string | null;
  };
  opcf17: {
    data: ICommAutomobileOCEP17;
    loading: LoadState;
    error: string | null;
  };
  opcf28A: {
    data: ICommAutomobileOCEP28A;
    loading: LoadState;
    error: string | null;
  };
  paymentAuthorization: {
    data: ICommAutomobilePolicyAuthorization;
    loading: LoadState;
    error: string | null;
  };
  privacyConsent: {
    data: ICommAutomobilePrivacyConsentForm;
    loading: LoadState;
    error: string | null;
  };
  retireeDiscount: {
    data: ICommAutomobileRetireeDiscount;
    loading: LoadState;
    error: string | null;
  };
  rideSharing: {
    data: ICommAutomobileSharingFillable;
    loading: LoadState;
    error: string | null;
  };
}

export interface ICommAutoPolicyForm {
  incremental_number: number;
  task_code: string;
  policy_number: string;
  customer_name: string;
  customer_code: string;
  policy_form_type: string;
  create_ts: string | null;
  created_by_name: number;
  status: string;
}

export interface ICommAutomobileLiabilitySlips {
  customer_policy_id: number;
  incremental_number: number | null;
  insured: string | null;
  address: string | null;
  policy_number: string | null;
  policy_status: string | null;
  insurer_name: string | null;
  broker_name: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  effective_time_of_change: string | null;
  time_of_binding: string;
  list_of_vehicle_option_one: string | null;
  list_of_vehicle_option_two: string | null;
  list_of_vehicles: any[];
  status: string;
}

export interface ICommAutomobileLiabilityInsurance {
  customer_policy_id: number;
  incremental_number: number | null;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_amount: string;
    hired_deductible: string;
  };
  automobile_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: string | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

export interface ICommAutomobileOCEP16 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: string | null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface ICommAutomobileOCEP17 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies_only_to_automobile: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate_of_automobile: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface ICommAutomobileOCEP28A {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  effective_date_of_change: string | null;
  policy_number: string | null;
  automobile_number: string | null;
  model: string | null;
  year: string | null;
  trade_name: string | null;
  serial_number_or_vin: string | null;
  name_of_excluded_driver: string | null;
  driver_license_number: string | null;
  status: string;
}

export interface ICommAutomobileRetireeDiscount {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  effective_date_of_discount: string | null;
  insurer: string | null;
  branch_code: string | null;
  retiree_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobilePrivacyConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobileSharingFillable {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  policy_number: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobileuseColumn {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
}
export interface ICommAutomobileuseColumn4 {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
  total: string | null;
}
export interface ICommAutomobileuseColumn3 {
  location_a_yes_or_no: string | null;
  location_b_yes_or_no: string | null;
  location_c_yes_or_no: string | null;
  location_d_yes_or_no: string | null;
  comments_description: string | null;
}
export interface ICommAutomobileuseColumn2 {
  location_a_ft: string | null;
  location_a_pt: string | null;
  location_b_ft: string | null;
  location_b_pt: string | null;
  location_c_ft: string | null;
  location_c_pt: string | null;
  location_d_ft: string | null;
  location_d_pt: string | null;
}

export interface ICommAutomobileGarageForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string;
  new: string | null;
  replacing_policy_number: string | null;
  customer_name: string | null;
  customer_address: string | null;
  customer_unit_or_suite: string | null;
  customer_city: string | null;
  customer_province_or_state: string | null;
  customer_postal_code: string | null;
  customer_country: string | null;
  contact_business: string | null;
  contact_home: string | null;
  contact_fax: string | null;
  broker_client_id: string | null;
  company_client_id: string | null;
  broker_or_agent: string | null;
  branch: string | null;
  code: string | null;
  broker_or_agent_bill: string | null;
  company_bill: string | null;
  payment_plan: string | null;
  business_address: string | null;
  section_a: {
    key: string;
    srNo: string;
    location_of_other_premises: null;
    structure_type_one_lot: null;
    structure_type_two_lot: null;
  }[];
  effective_time: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  the_automobiles_in_respect: string | null;
  estimated_total_payroll: string | null;
  full_time: string | null;
  part_time: string | null;
  policy_commauto_vcs_schedule_d_id: number;
  coverage_summary_incremental_number: string | null;
  leinholder_name: string | null;
  insurer: string | null;
  reason: string | null;
  injury_to_persons: string | null;
  collision: string | null;
  other: string | null;
  not_in_care_of_applicant: string | null;
  in_care_of_applicant: string | null;
  remarks: string | null;
  print_name: string | null;
  brokerage_or_agent_name: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface ICommAutomobilePolicyAuthorization {
  incremental_number: number | null;
  customer_policy_id: number;
  applicant_name: string | null;
  policy_type: string | null;
  applicant_address: string | null;
  applicant_unit_or_suite: string | null;
  applicant_city: string | null;
  applicant_province_or_state: string | null;
  applicant_postal_code: string | null;
  applicant_contact_number: string | null;
  applicant_preferred_document_language: string | null;
  applicant_email_address: string;
  applicant_website_address: string;
  brokerage_branch_name: string | null;
  brokerage_full_address: string | null;
  brokerage_postal_code: string | null;
  brokerage_broker_code: string | null;
  brokerage_contact_name: string | null;
  brokerage_phone_number: string | null;
  brokerage_fax_number: string | null;
  brokerage_contract_number: string | null;
  brokerage_sub_contract_number: string | null;
  brokerage_group_or_program_name: string | null;
  brokerage_group_id: string | null;
  brokerage_broker_client_id: string | null;
  brokerage_company_client_id: string | null;
  customer_id: number | null;
  insurance_company_name: string | null;
  insurance_company_address: string | null;
  insurance_company_unit_or_suite: string | null;
  insurance_company_city: string | null;
  insurance_company_province_or_state: string | null;
  insurance_company_postal_code: string | null;
  insurance_company_country: string | null;
  policy_number: string | null;
  request: string | null;
  broker_code: string | null;
  total_estimated_policy_premium: string | null;
  installment_fee: number | null;
  tax_percentage: number | null;
  tax: string | null;
  percentage_finance: number | null;
  finance_charges: string | null;
  total_estimated_cost: string | null;
  mode_of_payment: string | null;
  plan_type: string | null;
  financial_institution_or_bank_name: string | null;
  address_bank: string | null;
  bank_unit_or_suite: string | null;
  bank_city: string | null;
  bank_province_or_state: string | null;
  bank_postal_code: string | null;
  bank_country: string | null;
  account_holder_name: string | null;
  address_accunt_holder: string | null;
  account_holder_unit_or_suite: string | null;
  accunt_holder_city: string | null;
  accunt_holder_province_or_state: string | null;
  accunt_holder_postal_code: string | null;
  accunt_holder_country: string | null;
  transit_number: string | null;
  institution_number: string | null;
  account_number: string | null;
  type_of_payment: string | null;
  down_payment_amount: string | null;
  insurance_company_additional_charges: string | null;
  broker_additional_charges: string | null;
  full_Payment_amount: string | null;
  installment_amount: string | null;
  next_payment_date: string | null;
  consent_and_disclosure: [
    {
      sentence: string;
      checked: boolean;
    }
  ];
  status: string;
}

export const intitialCommAutoPolicyFormState: ICommAutoPolicyFormsState = {
  policyFormsList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
  },
  liabilitySlips: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      insured: null,
      address: null,
      policy_number: null,
      policy_status: null,
      insurer_name: null,
      broker_name: null,
      effective_date: moment().format("YYYY-MM-DD"),
      expiry_date: null,
      effective_time_of_change: moment()
        .startOf("day")
        .add(1, "minute")
        .toString(),
      time_of_binding: moment().format(),
      list_of_vehicle_option_one: null,
      list_of_vehicle_option_two: null,
      list_of_vehicles: [],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  liabilityInsurance: {
    data: {
      incremental_number: null,
      customer_policy_id: 0,
      cgl_policy_number: null,
      automobile_policy: null,
      umbrella_or_excess_liability_policy: null,
      other_policy: null,
      non_owned_automobiles_policy: null,
      hired_automobiles: null,
      vehicle_unit_number_or_vin: null,
      certificate_holder_name: null,
      certificate_holder_address: null,
      certificate_holder_email: null,
      certificate_holder_phone: null,
      description_of_operations: [
        { key: getUniqueId(), label: "Line 1", value: "" },
        { key: getUniqueId(), label: "Line 2", value: "" },
        { key: getUniqueId(), label: "Line 3", value: "" },
        { key: getUniqueId(), label: "Line 4", value: "" },
      ],
      commercial_general_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      commercial_general_liability_section_two: {
        claim_mode: "N",
        claim_mode_deductible: "",
        claim_mode_amount: "5,000,000",
        occurance: "N",
        occurance_deductible: "1,000",
        occurance_amount: "2,000,000",
        products_completed_oprations: "N",
        products_completed_oprations_deductible: "",
        products_completed_oprations_amount: "2,000,000",
        empolyer_liability: "N",
        empolyer_liability_peronal_injury: "N",
        empolyer_liability_deductible: "",
        empolyer_liability_amount: "",
        waiver_of_subrogation: "N",
        waiver_of_subrogation_peronal_injury: "N",
        waiver_of_subrogation_deductible: "",
        waiver_of_subrogation_amount: "2,000,000",
        cross_liability: "N",
        cross_liability_deductible: "",
        cross_liability_amount: "5,000",
        tenants_Legal_Liability: "N",
        tenants_Legal_Liability_deductible: "1,000",
        tenants_Legal_Liability_amount: "250,000",
        pollution_Liability_Extension: "N",
        pollution_Liability_Extension_deductible: "",
        pollution_Liability_Extension_amount: "",
      },
      commercial_general_liability_section_three: [
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
      ],
      non_owned_automobiles_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      non_owned_automobiles_section_two: {
        non_owned: "N",
        non_ownder_amount: "",
        non_owned_deductible: "",
      },
      hired_automobiles_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      hired_automobiles_section_two: {
        hired_type_of_insurance: "N",
        hired_amount: "",
        hired_deductible: "",
      },
      automobile_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      automobile_liability_section_two: {
        described_automobiles: "N",
        described_automobiles_deductible: "",
        described_automobiles_amount: "",
        all_owned_automobiles: "N",
        all_owned_automobiles_deductible: "",
        all_owned_automobiles_amount: "",
        leased_automobiles: "N",
        leased_automobiles_deductible: "",
        leased_automobiles_amount: "",
        property_damage_deductible: "",
        property_damage_amount: "",
      },
      excess_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      excess_liability_section_two: {
        umbrella_form: "N",
        umbrella_form_amount: "",
        aggreate_deductible: "",
        umbrella_form_deductible: "",
        extra: "N",
        extra_amount: "",
        extra_deductible: "",
        aggreate_amount: "",
        extra_coverage: "",
        extra_type_of_insurance: "",
      },
      other_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      other_liability_section_two: [
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
      ],
      cancelation_number_of_days: null,
      brokerage_full_name: null,
      brokerage_mailing_address: null,
      brokerage_customer_code: null,
      additional_name_insured_yes_or_no: null,
      additional_name_insured_name: null,
      additional_name_insured_mailing_address: null,
      certificate_authorization_branch_name: null,
      certificate_authorization_assigned_to: null,
      certificate_authorization_phone_number: null,
      certificate_authorization_fax_number: null,
      certificate_authorization_email: null,
      certificate_authorization_date: moment().format("YYYY-MM-DD"),
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  opcf16: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      issued_to: null,
      policy_number: null,
      effective_date_of_change: moment().format("YYYY-MM-DD"),
      policy_status: null,
      automobile_data: [],
      status: "ACTIVE",
      checkbox_see_your_certificate_of_automobile: "N",
      checkbox_this_change_applies: "N",
      indicated_on_your_certificate: null,
      see_your_certificate_of_automobile: null,
      this_change_applies_only_to_automobile: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  opcf17: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      issued_to: null,
      policy_number: null,
      effective_date_of_change: moment().format("YYYY-MM-DD"),
      policy_status: null,
      automobile_data: [{}],
      status: "ACTIVE",
      checkbox_see_your_certificate_of_automobile: "N",
      checkbox_this_change_applies_only_to_automobile: "N",
      indicated_on_your_certificate_of_automobile: null,
      see_your_certificate_of_automobile: null,
      this_change_applies_only_to_automobile: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  opcf28A: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      issued_to: null,
      effective_date_of_change: moment().format("YYYY-MM-DD"),
      policy_number: null,
      automobile_number: null,
      model: null,
      year: null,
      trade_name: null,
      serial_number_or_vin: null,
      name_of_excluded_driver: null,
      driver_license_number: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  paymentAuthorization: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: null,
      policy_type: null,
      applicant_name: null,
      applicant_address: null,
      applicant_unit_or_suite: null,
      applicant_city: null,
      applicant_province_or_state: null,
      applicant_postal_code: null,
      applicant_contact_number: null,
      applicant_preferred_document_language: null,
      applicant_email_address: "",
      applicant_website_address: "",
      brokerage_branch_name: null,
      brokerage_full_address: null,
      brokerage_postal_code: null,
      brokerage_broker_code: null,
      brokerage_contact_name: null,
      brokerage_phone_number: null,
      brokerage_fax_number: null,
      brokerage_contract_number: null,
      brokerage_sub_contract_number: null,
      brokerage_group_or_program_name: null,
      brokerage_group_id: null,
      brokerage_broker_client_id: null,
      brokerage_company_client_id: null,
      insurance_company_name: null,
      insurance_company_address: null,
      insurance_company_unit_or_suite: null,
      insurance_company_city: null,
      insurance_company_province_or_state: null,
      insurance_company_postal_code: null,
      insurance_company_country: null,
      policy_number: null,
      request: null,
      broker_code: null,
      total_estimated_policy_premium: null,
      installment_fee: null,
      tax_percentage: null,
      tax: null,
      percentage_finance: null,
      finance_charges: null,
      total_estimated_cost: null,
      mode_of_payment: null,
      plan_type: null,
      financial_institution_or_bank_name: null,
      address_bank: null,
      bank_unit_or_suite: null,
      bank_city: null,
      bank_province_or_state: null,
      bank_postal_code: null,
      bank_country: null,
      account_holder_name: null,
      address_accunt_holder: null,
      account_holder_unit_or_suite: null,
      accunt_holder_city: null,
      accunt_holder_province_or_state: null,
      accunt_holder_postal_code: null,
      accunt_holder_country: null,
      transit_number: null,
      institution_number: null,
      account_number: null,
      type_of_payment: null,
      down_payment_amount: null,
      insurance_company_additional_charges: null,
      broker_additional_charges: null,
      full_Payment_amount: null,
      installment_amount: null,
      next_payment_date: null,
      consent_and_disclosure: [
        {
          sentence:
            "I/We hereby authorize the named financial institution above to debit my/our account for all payments payable to",
          checked: false,
        },
      ],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  privacyConsent: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: 0,
      customer_name: null,
      email_address: null,
      text_field: null,
      modified_by_id: null,
      insert_ts: null,
      create_ts: null,
      status: "ACTIVE",
      created_by_id: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  retireeDiscount: {
    data: {
      customer_policy_id: 0,
      customer_id: 0,
      incremental_number: null,
      customer_name: null,
      policy_number: null,
      effective_date_of_discount: moment().format("YYYY-MM-DD"),
      insurer: null,
      branch_code: null,
      retiree_name: null,
      modified_by_id: null,
      insert_ts: null,
      create_ts: null,
      status: "ACTIVE",
      created_by_id: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  rideSharing: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: 0,
      customer_name: null,
      policy_number: null,
      modified_by_id: null,
      insert_ts: null,
      create_ts: null,
      status: "ACTIVE",
      created_by_id: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  
};
