import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearBusinessPremiumFinanceState,
  fetchBusinessPolicyPremiumFinanceListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinanceActions";
import { IBusinessPolicyPremiumFinance } from "../../../../../../redux/businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinance.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const PremiumFinanceList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "premium-finance",
    },
  ];
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.premiumFinance
  );
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchBusinessPolicyPremiumFinanceListAsync(customerPolicyId, status)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPremiumFinanceState());
    };
  }, [customerPolicyId, status]);

  // React.useEffect(() => {
  //   dispatch(fetchBusinessPolicyPremiumFinanceListAsync(customerPolicyId));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customerPolicyId]);

  const premiumfinanceProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyPremiumFinance) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyPremiumFinance) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyPremiumFinance) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/others-tab/${customerPolicyId}/premium-finance/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "contact_name",
        headerName: "Contact Name",
        fieldName: "contact_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "contract_number",
        headerName: "Contract Number",
        fieldName: "contract_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },
      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 30,
      },
      {
        key: "created_date",
        headerName: "Created Date",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 30,
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Premium Finance"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...premiumfinanceProps} />
    </>
  );
};
