import produce from "immer";
import { HabitationalPolicyFormActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialHabitationalPolicyFormState } from "./HabitationalPolicyForm.types";
import {
  CLEAR_HABITATIONAL_POLICY_FORMS,
  FETCH_HABITATIONAL_CONSENT_FORM_FAILED,
  FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS,
  FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS,
  FETCH_HABITATIONAL_CSIO_BINDER_FAILED,
  FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS,
  FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS,
  FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED,
  FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS,
  FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS,
  FETCH_HABITATIONAL_DWELLING_FAILED,
  FETCH_HABITATIONAL_DWELLING_PROGRESS,
  FETCH_HABITATIONAL_DWELLING_SUCCESS,
  FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED,
  FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS,
  FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS,
  FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED,
  FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS,
  FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS,
  FETCH_HABITATIONAL_POLICY_FORMS_LIST_FAILED,
  FETCH_HABITATIONAL_POLICY_FORMS_LIST_PROGRESS,
  FETCH_HABITATIONAL_POLICY_FORMS_LIST_SUCCESS,
} from "./HabitationalPolicyFormActions";
import { LoadState } from "../../../constants/enums";

export const HabitationalPolicyFormReducer = (
  state: IStoreState["habitational"]["policyform"] = initialHabitationalPolicyFormState,
  action: HabitationalPolicyFormActions
) => {
  switch (action.type) {
    case FETCH_HABITATIONAL_POLICY_FORMS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.InProgress;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_FORMS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Loaded;
        draftState.policyFormsList.list = data;
        draftState.policyFormsList.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_POLICY_FORMS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Failed;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.InProgress;
        draftState.paymentAuthorization.data =
          initialHabitationalPolicyFormState["paymentAuthorization"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Loaded;
        draftState.paymentAuthorization.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Failed;
        draftState.paymentAuthorization.data =
          initialHabitationalPolicyFormState["paymentAuthorization"]["data"];
        draftState.consentform.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.InProgress;
        draftState.consentform.data =
          initialHabitationalPolicyFormState["consentform"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Loaded;
        draftState.consentform.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.consentform.loading = LoadState.Failed;
        draftState.consentform.data =
          initialHabitationalPolicyFormState["consentform"]["data"];
        draftState.consentform.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.InProgress;
        draftState.dwellingdeclaration.data =
          initialHabitationalPolicyFormState["dwellingdeclaration"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Loaded;
        draftState.dwellingdeclaration.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwellingdeclaration.loading = LoadState.Failed;
        draftState.dwellingdeclaration.data =
          initialHabitationalPolicyFormState["dwellingdeclaration"]["data"];
        draftState.dwellingdeclaration.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_DWELLING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.InProgress;
        draftState.dwelling.data =
          initialHabitationalPolicyFormState["dwelling"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Loaded;
        draftState.dwelling.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_DWELLING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dwelling.loading = LoadState.Failed;
        draftState.dwelling.data =
          initialHabitationalPolicyFormState["dwelling"]["data"];
        draftState.dwelling.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.binder.loading = LoadState.InProgress;
        draftState.binder.data =
          initialHabitationalPolicyFormState["binder"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.binder.loading = LoadState.Loaded;
        draftState.binder.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_CSIO_BINDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.binder.loading = LoadState.Failed;
        draftState.binder.data =
          initialHabitationalPolicyFormState["binder"]["data"];
        draftState.binder.error = errorMessage;
      });
      return newState;
    }

    case FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.nonCsioBinder.loading = LoadState.InProgress;
        draftState.nonCsioBinder.data =
          initialHabitationalPolicyFormState["nonCsioBinder"]["data"];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.nonCsioBinder.loading = LoadState.Loaded;
        draftState.nonCsioBinder.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.nonCsioBinder.loading = LoadState.Failed;
        draftState.nonCsioBinder.data =
          initialHabitationalPolicyFormState["nonCsioBinder"]["data"];
        draftState.nonCsioBinder.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_HABITATIONAL_POLICY_FORMS: {
      return initialHabitationalPolicyFormState;
    }
    default: {
      return state;
    }
  }
};
