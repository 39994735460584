import { action } from "typesafe-actions";
import {
  ICommAutomobileGarageForm,
  ICommAutomobileLiabilityInsurance,
  ICommAutomobileLiabilitySlips,
  ICommAutomobileOCEP16,
  ICommAutomobileOCEP17,
  ICommAutomobileOCEP28A,
  ICommAutomobilePolicyAuthorization,
  ICommAutomobileRetireeDiscount,
  ICommAutomobileSharingFillable,
  ICommAutoPolicyForm,
} from "./commAutoPolicyForm.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { ICommAutomobileConsentForm } from "../commAutoCertificates/commAutoCertificates.types";

export const FETCH_COMM_AUTO_POLICY_FORMS_LIST_PROGRESS =
  "FETCH_COMM_AUTO_POLICY_FORMS_LIST_PROGRESS";
export const FETCH_COMM_AUTO_POLICY_FORMS_LIST_SUCCESS =
  "FETCH_COMM_AUTO_POLICY_FORMS_LIST_SUCCESS";
export const FETCH_COMM_AUTO_POLICY_FORMS_LIST_FAILED =
  "FETCH_COMM_AUTO_POLICY_FORMS_LIST_FAILED";

export const fetchCommAutoPolicyFormsListProgress = () =>
  action(FETCH_COMM_AUTO_POLICY_FORMS_LIST_PROGRESS);
export const fetchCommAutoPolicyFormsListSuccess = (
  data: ICommAutoPolicyForm[],
  totalRecords: number
) => action(FETCH_COMM_AUTO_POLICY_FORMS_LIST_SUCCESS, { data, totalRecords });
export const fetchCommAutoPolicyFormsListFailed = () =>
  action(FETCH_COMM_AUTO_POLICY_FORMS_LIST_FAILED);

export const fetchCommAutoPolicyFormsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutoPolicyFormsListProgress());
      const url = `/policy/get-policy-commercial-and-automobile-all-policy-forms?customer_policy_id=${customerPolicyId}&policy_form_type=${type}&table_name=commauto&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: ICommAutoPolicyForm[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchCommAutoPolicyFormsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCommAutoPolicyFormsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/*---Liability Slip---*/

export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchCommAutomobileLiabilitySlipProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchCommAutomobileLiabilitySlipSuccess = (
  data: ICommAutomobileLiabilitySlips
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchCommAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addCommAutomobileLiabilitySlipAsync =
  (
    data: ICommAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilitySlipFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: string,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const previewCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      // dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/****** Liability Insurance */
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchCommAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchCommAutomobileLiabilityInsuranceSuccess = (
  data: ICommAutomobileLiabilityInsurance
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchCommAutomobileLiabilityInsuranceFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addCommAutomobileLiabilityInsuranceAsync =
  (
    data: ICommAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileLiabilityInsurancePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileLiabilityInsurancePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/***OCPF16 */

export const FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP16_FAILED";

export const fetchCommAutomobileOcep16rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS);
export const fetchCommAutomobileOcep16Success = (data: ICommAutomobileOCEP16) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS, { data });
export const fetchCommAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_FAILED, { errorMessage });

export const addCommAutomobileOcep16Async =
  (
    data: ICommAutomobileOCEP16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      //  dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      // dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//OCPF17
export const FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP17_FAILED";

export const fetchCommAutomobileOcep17rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS);
export const fetchCommAutomobileOcep17Success = (data: ICommAutomobileOCEP17) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS, { data });
export const fetchCommAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_FAILED, { errorMessage });

export const addCommAutomobileOcep17Async =
  (
    data: ICommAutomobileOCEP17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      //dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      // dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//OCPF28A

export const FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED";

export const fetchCommAutomobileOcep28Arogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS);
export const fetchCommAutomobileOcep28ASuccess = (
  data: ICommAutomobileOCEP28A
) => action(FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS, { data });
export const fetchCommAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED, { errorMessage });

export const addCommAutomobileOcep28Async =
  (
    data: ICommAutomobileOCEP28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      // dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      //dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//Payment authorization
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED";

export const fetchCommAutomobileAuthorizationProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS);
export const fetchCommAutomobileAuthorizationSuccess = (
  data: ICommAutomobilePolicyAuthorization
) =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS, {
    data,
  });
export const fetchCommAutomobileAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED, { errorMessage });

export const addCommAutomobileAuthorizationAsync =
  (
    data: ICommAutomobilePolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-paf", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileAuthorizationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-paf?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobilePolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileAuthorizationSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileAuthorizationFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const downloadCommAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_policy_commauto_paf_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//Consent Form
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED";

export const fetchCommAutomobileConsentFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS);
export const fetchCommAutomobileConsentFormSuccess = (
  data: ICommAutomobileConsentForm
) => action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS, { data });
export const fetchCommAutomobileConsentFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED, { errorMessage });

export const addCommAutomobileConsentFormAsync =
  (
    data: ICommAutomobileConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-privacy-consent-form", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//Retiree Discount

export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED =
  "FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED";

export const fetchCommAutomobileRetireeDiscountProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS);
export const fetchCommAutomobileRetireeDiscountSuccess = (
  data: ICommAutomobileRetireeDiscount
) => action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS, { data });
export const fetchCommAutomobileRetireeDiscountFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED, { errorMessage });

export const addCommAutomobileRetireeDiscountAsync =
  (
    data: ICommAutomobileRetireeDiscount,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-declaration-for-retiree-discount",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileRetireeDiscountAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileRetireeDiscountProgress());
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileRetireeDiscount[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileRetireeDiscountSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileRetireeDiscountFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_declaration_for_retiree_discount_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//Sharing Fillable

export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED =
  "FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED";

export const fetchCommAutomobileSharingFillableProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS);
export const fetchCommAutomobileSharingFillableSuccess = (
  data: ICommAutomobileSharingFillable
) => action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS, { data });
export const fetchCommAutomobileSharingFillableFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED, { errorMessage });

export const addCommAutomobileSharingFillableAsync =
  (
    data: ICommAutomobileSharingFillable,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-ride-sharing-fillable", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileSharingFillableAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSharingFillableProgress());
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileSharingFillable[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileSharingFillableSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileSharingFillableFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      fetchCommAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_ride_sharing_fillable_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      //dispatch(fetchCommAutomobileSharingFillableListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//**Garagre */
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED";

export const fetchCommAutomobileGarageFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_PROGRESS);
export const fetchCommAutomobileGarageFormSuccess = (
  data: ICommAutomobileGarageForm
) => action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_SUCCESS, { data });
export const fetchCommAutomobileGarageFormFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_FORM_FAILED, { errorMessage });

export const addCommAutomobileGarageFormAsync =
  (
    data: ICommAutomobileGarageForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-garage-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileGarageFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGarageFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileGarageForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileGarageFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileGarageFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTO_POLICY_FORMS_STATE =
  "CLEAR_COMM_AUTO_POLICY_FORMS_STATE";
export const clearCommAutoPolicyFormsState = () =>
  action(CLEAR_COMM_AUTO_POLICY_FORMS_STATE);
