/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import {
  clearAutomobileBasicPolicyState,
  fetchAutomobileCustomerInfoAsync,
} from "../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { batch } from "react-redux";
import { clearNotesState } from "../../../../../redux/notes/notesActions";
import { clearAutomobileDriverState } from "../../../../../redux/automobileDriver/automobileDriverActions";
import { clearAutomobileVehicleState } from "../../../../../redux/automobileVehicle/automobileVehicleActions";
import { clearAutomobileGarageApplicationState } from "../../../../../redux/automobileGarageApplication/automobileGarageApplicationActions";
import { clearAutomobileGaragePlatesState } from "../../../../../redux/automobileGaragePlates/automobileGaragePlatesActions";
import { clearAutomobileCSVState } from "../../../../../redux/automobileCSV/automobileCSVActions";
import { clearAutomobileScheduleState } from "../../../../../redux/automobileSchedule/automobileScheduleActions";
import { clearAutomobileApplicationState } from "../../../../../redux/automobileApplication/automobileApplicationActions";
import { clearAutonmobilePolicyMarketingState } from "../../../../../redux/automobileMarketingHistory/automobileMarketingHistoryActions";
import { clearCommAutoCertificatesState } from "../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";

export const AutomobilePolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  // const [tab, setTab] = useState<number>(0);
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { loading, error },
  } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/automobile-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };
  // const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
  //   setTab(newValue);
  // };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

   React.useEffect(() => {
     return () => {
        dispatch({type: "CLEAR_POLICY_STATE"})
     };
   }, []);

  // const tabStyle = {
  //   fontWeight: 800,
  //   color: "Black"
  // };

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout
          customerId={customerId}
          fromPolicy
          policyId={savedCustomerPolicyId as number}
        >
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />

              <PolicyTabCard
                label="Drivers"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("driver-tab")}
                isSelected={currentPathname.includes("driver-tab")}
              />

              <PolicyTabCard
                label="Vehicles"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-tab")}
                isSelected={currentPathname.includes("vehicle-tab")}
              />

              <PolicyTabCard
                label="Coverage"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-coverage-schedule-tab")}
                isSelected={currentPathname.includes(
                  "vehicle-coverage-schedule-tab"
                )}
              />
              <PolicyTabCard
                label="Applications"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-application-tab"
                )}
              />
              {/* <PolicyTabCard
                label="Alberta Applications"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-alberta-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-alberta-application-tab"
                )}
              /> */}
              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />

              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />
              <PolicyTabCard
                label="Attachments"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachment-tab")}
                isSelected={currentPathname.includes("attachment-tab")}
              />
              <PolicyTabCard
                label="Abeyance/Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="Business Tracker"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
              <PolicyTabCard
                label="CSIO"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("automobile-csio-application-tab")}
                isSelected={currentPathname.includes(
                  "automobile-csio-application-tab"
                )}
              />
              <PolicyTabCard
                label="eDocs"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edocs-list")}
                isSelected={currentPathname.includes(
                  "csio-edocs-list"
                )}
              />

              {/* <PolicyTabCard
                label="eDocs/EDI"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edi-list")}
                isSelected={currentPathname.includes("csio-edi-list")}
              /> */}
              <PolicyTabCard
                label="Policy Changes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-change-req")}
                isSelected={currentPathname.includes(
                  "policy-change-req"
                )}
              />
              <PolicyTabCard
                label="Invoices"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("invoices")}
                isSelected={currentPathname.includes("invoices")}
              />
              <PolicyTabCard
                label="Quote"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("quote")}
                isSelected={currentPathname.includes("quote")}
              />
            </Box>
          </StandadCard>

          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
