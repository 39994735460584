/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { useNavigate, useOutletContext } from "react-router-dom";
import { PageTitleBar } from "../../../../../components/PageTitleBar/PageTitleBar";
import { alpha, Button, Menu, MenuItem, MenuProps, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { useDataTableV2Pagination } from "../../../../../components/TableV2/hooks/useDataTableV2Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataTableV2 } from "../../../../../components/TableV2/DataTableV2";
import { useTableV2State } from "../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../components/formsComponents";
import { fetchAutomobileAllApplicationsListAsync } from "../../../../../redux/automobileApplication/automobileApplicationActions";
import { IAutomobileApplicationList } from "../../../../../redux/automobileApplication/automobileApplication.types";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { formatText } from "../../../../../helpers";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const APPLICATION_TYPES = [
  { label: "All Applications", value: "all_application" },
  { label: "Automobile (AB)", value: "automobile_ab" },
  { label: "Automobile (ON)", value: "automobile_on" },
];

export const AutomobileApplicationRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  // const { addBreadcrumb } = useBreadcrumbContext();
  // const { getParm } = useQueryParms();
  // const tabValue = getParm("tab");
  // const selectedTab = tabValue ? Number(tabValue) : 0;

  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.allApplications.applicationsList
  );

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { tablePagination, onPageChange, onRowsPerPageChange } =
    useDataTableV2Pagination({
      pageNumber: 1,
      rowsInPerPage: 25,
      extraFetchFactor: 1,
    });
  const [policyFormRoot, setPolicyFormRoot] = React.useState("all_application");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openPolicyCancel, setOpenPolicyCancel] =
    React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFormTypeClick = (value: string, incrementNumber?: any) => () => {
    setAnchorEl(null);
    let additionalparms = "";
    if (incrementNumber) {
      additionalparms = "/" + incrementNumber;
    }

    switch (value) {
      case "automobile_ab": {
        navigate(
          `/automobile-policy/${customerId}/automobile-application-tab/${customerPolicyId}/alberta-application${additionalparms}`
        );
        return;
      }
      case "automobile_on": {
        navigate(
          `/automobile-policy/${customerId}/automobile-application-tab/${customerPolicyId}/application${additionalparms}`
        );

        return;
      }
    }
  };

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSearchState,
    setSelectedTab,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last90Days",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    dispatch(
      fetchAutomobileAllApplicationsListAsync(
        customerPolicyId,
        policyFormRoot,
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage
      )
    );
  };

  const AutomobileApplicationDataProps: IDataTableV2Props = {
    isPagination: true,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    extraFetchFactor: tablePagination.extraFetchFactor,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "none",
    uniqueRowKeyName: "incemental_number",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                sx={{ minWidth: "120px" }}
                value={policyFormRoot}
                options={APPLICATION_TYPES}
                onChange={(e) => setPolicyFormRoot(e.target.value as string)}
              ></ControlledCustomSelect>
            ),
          },
        ],
      },
      rightItems: {
        customPlugins: [],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileApplicationList) => {
          return (
            <StandardTableActions
              onEditClick={handleFormTypeClick(
                row.application_type,
                row.incremental_number
              )}
              // onDownLoadClick={
              //   row.application_type === "garage_supplementary"
              //     ? handleFormTypeClick(
              //         row.application_type,
              //         "download",
              //         row.incremental_number
              //       )
              //     : undefined
              // }
              // onDownloadPreview={
              //   row.application_type === "garage_supplementary"
              //     ? handleFormTypeClick(
              //         row.application_type,
              //         "preview",
              //         row.incremental_number
              //       )
              //     : undefined
              // }
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileApplicationList) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "application_type",
        headerName: "Application Type",
        fieldName: "application_type",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
        onRowCellValueRender: (value, row) => {
          return formatText(value);
        },
      },
      {
        key: "create_ts",
        headerName: "Date Created",
        fieldName: "create_ts",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 30,
      },
      {
        key: "created_by_name",
        headerName: "Created By",
        fieldName: "created_by_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
      },
    ],
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "create_ts",
      },
    },
    rows: list,
    onPageChange: onPageChange,
    onRowsPerPageChange: onRowsPerPageChange,
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      fetchList()
      //   dispatch(fetchBindersListAsync(customerPolicyId));
      // dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <PageTitleBar
        heading="Automobile Applications"
        rightHeading={
          <Stack>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Create
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              {APPLICATION_TYPES.slice(1).map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={handleFormTypeClick(item.value)}
                    disableRipple
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </StyledMenu>
          </Stack>
        }
      />

      <DataTableV2 {...AutomobileApplicationDataProps} />
      {/* <TabsList
        sx={{ mb: 1 }}
        varaint="fullWidth"
        selectedTab={selectedTab}
        tabs={[
          {
            label: "Automobile (AB)",
            icon: <AdminPanelSettings />,
          },
          {
            label: "Automobile (ON)",
            icon: <AdminPanelSettings />,
          },
        ]}
        onChange={handleChange}
      />
      <TabPanel value={selectedTab} index={0}>
      <AutomobileAlbertaApplicationList />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
      <AutomobileApplicationList />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
      <AutomobileSupplementaryFormList
      customerId={customerId}
      customerPolicyId={customerPolicyId}
      />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
      <CommAutoInsuranceGarageApplicationList />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
      <CommAutoInsuranceGarageApplicationList />
      </TabPanel> */}
    </PageLoader>
  );
};
