/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState } from "../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../hooks";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileGaragePlates } from "../../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlates.types";
import {
  clearCommAutomobileGaragePlatesState,
  fetchCommAutomobileGaragePlatesListAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlatesActions";
import { CommAutoGaragePlatesDialog } from "./CommAutoGaragePlateDialog";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { AddOutlined } from "@mui/icons-material";

const INITIAL_GARAGE_PLATE: ICommAutomobileGaragePlates = {
  customer_policy_id: null,
  serial_number: null,
  type: null,
  plate_number: null,
  charge_details: null,
  assigned_to: null,
  attachment: null,
  status: "ACTIVE",
};

export const CommAutoGaragePlatesList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();

  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/comm-automobile-policy/comm-automobile/" +
        customerId +
        "/" +
        customerpolicyId,
      title: "comm-auto",
    },
    {
      title: "garage-plate",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearCommAutomobileGaragePlatesState());
    };
  }, []);

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.garagePlate
  );

  const dispatch = useDispatchWrapper();
  const [openGaragePlate, setOpenGaragePlate] =
    React.useState<ICommAutomobileGaragePlates | null>(null);

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(
        fetchCommAutomobileGaragePlatesListAsync(customerpolicyId, status)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearCommAutomobileGaragePlatesState());
    };
  }, [customerpolicyId, status]);

  const handleCloseGaragePlate = () => {
    setOpenGaragePlate(null);

  };
  const handleGaragePlateComplete = () => {
    dispatch(fetchCommAutomobileGaragePlatesListAsync(customerpolicyId));
    handleCloseGaragePlate();
  };

  const garagePlateTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
          // {
          //   key: "status",
          //   onRender: () => (
          //     <Button
          //       variant="contained"
                // onClick={() => {
                //   setOpenGaragePlate({
                //     ...INITIAL_GARAGE_PLATE,
                //     customer_policy_id: customerpolicyId,
                //   });
                // }}
          //     >
          //       Add Garage Plate
          //     </Button>
          //   ),
          // },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <StandardTableActions onEditClick={() => setOpenGaragePlate(row)} />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.serial_number}
            </Link>
          );
        },
      },
      {
        key: "plate_number",
        headerName: "Plate Number",
        fieldName: "plate_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.plate_number}
            </Link>
          );
        },
      },
      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        onRowCellRender: (value, row: ICommAutomobileGaragePlates) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenGaragePlate(row)}
            >
              {row.type}
            </Link>
          );
        },
      },
    ],
    rows: data,
  };

  return (
    <>
      <PageTitleBar
        heading="Garage Plates"
        rightHeading={
          <Button
            variant="contained"
            onClick={() => {
              setOpenGaragePlate({
                ...INITIAL_GARAGE_PLATE,
                customer_policy_id: customerpolicyId,
              });
            }}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...garagePlateTableProps} />
      {openGaragePlate && (
        <CommAutoGaragePlatesDialog
          open={true}
          data={openGaragePlate}
          onClose={handleCloseGaragePlate}
          onComplete={handleGaragePlateComplete}
        />
      )}
    </>
  );
};
