import React from "react";
import { CustomTextField } from "../formsComponents";
import { SxProps, TextFieldProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface IPhoneTextProps {
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  value: any;
  id?: TextFieldProps["id"];
  name?: TextFieldProps["name"];
  variant?: TextFieldProps["variant"];
  size?: TextFieldProps["size"];
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  disabled?: boolean;
  onChange?: (newValue: string) => void;
  setFieldValue?: (field: string, value: any) => void;
}

export const PhoneTextField: React.FC<IPhoneTextProps> = (props) => {
  const {
    value,
    fullWidth,
    sx,
    onChange,
    error,
    helperText,
    id,
    name,
    variant,
    size,
    disabled,
    setFieldValue,
  } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue  = e.target.value;
    if (!newValue || newValue.length < 11) {
      if (onChange) {
        onChange(newValue);
      } else if (setFieldValue) {
        setFieldValue(id || name || "", newValue);
      }
    }
  };

  return (
    <CustomTextField
      fullWidth={fullWidth}
      sx={sx}
      id={id}
      name={name}
      variant={variant}
      size={size}
      type="number"
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
