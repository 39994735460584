import { LoadState } from "../../../constants/enums";

export interface IAccountingVendorState {
  list: IVendor[];
  listLoading: LoadState;
  data: IVendor;
  loading: LoadState;
  error: string | null;
}

export interface IVendor {
  insurer_code_yes_or_no: string | null;
  insurer_code: string | null;
  vendor_code: string | null;
  vendor_name: string | null;
  mailing_address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  language_e_f: string | null;
  contact_name: string | null;
  phone: string | null;
  ext: string | null;
  toll_free: string | null;
  email: string | null;
  vendor_description: string | null;
  transit_number: string | null;
  bank_number: string | null;
  account_number: string | null;
  gl_account_code: string | null;
  account_name: string | null;
  details: string | null;
  status: string;
}

export const initialAccountingVendorState: IAccountingVendorState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    insurer_code_yes_or_no: null,
    insurer_code: null,
    vendor_code: null,
    vendor_name: null,
    mailing_address: null,
    unit_or_suite: null,
    city: null,
    state_or_province: null,
    postal_code: null,
    country: null,
    language_e_f: null,
    contact_name: null,
    phone: null,
    ext: null,
    toll_free: null,
    email: null,
    vendor_description: null,
    transit_number: null,
    bank_number: null,
    account_number: null,
    gl_account_code: null,
    account_name: null,
    details: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
