import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomobileApplication,
  IAutomobileApplicationList,
  initialAutomobileApplicationState,
} from "./automobileApplication.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IAutomobileAlbertaApplication } from "../automobileAlbertaApplication/automobileAlbertaApplication.types";


export const FETCH_AUTOMOBILE_ALL_APPLICATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ALL_APPLICATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILEALL__APPLICATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILEALL__APPLICATION_LIST_SUCCESS";
export const FETCH_AUTAMOBILELL_O_APPLICATION_LIST_FAILED =
  "FETCH_AUTAMOBILELL_O_APPLICATION_LIST_FAILED";

export const fetchAutomobileAllApplicationsListProgress = () =>
  action(FETCH_AUTOMOBILE_ALL_APPLICATION_LIST_PROGRESS);
export const fetchAutomobileAllApplicationsListSuccess = (
  data: IAutomobileApplicationList[],
  totalRecords: number
) =>
  action(FETCH_AUTOMOBILEALL__APPLICATION_LIST_SUCCESS, { data, totalRecords });
export const fetchAutomobileAllApplicationsListFailed = () =>
  action(FETCH_AUTAMOBILELL_O_APPLICATION_LIST_FAILED);

export const fetchAutomobileAllApplicationsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAllApplicationsListProgress());
      const url = `/policy/get-policy-auto-all-application?customer_policy_id=${customerPolicyId}&application_type=${type}&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: IAutomobileApplicationList[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchAutomobileAllApplicationsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAutomobileAllApplicationsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_APPLICATION_FAILED";

export const fetchAutomobileApplicationProgress = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_PROGRESS);
export const fetchAutomobileApplicationSuccess = (
  data: IAutomobileApplication
) => action(FETCH_AUTOMOBILE_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileApplicationFailed = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_FAILED);

export const fetchAutomobileApplicationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileApplicationProgress());
      const res = await api.get(
        `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`
      );
      const data: IAutomobileApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobileApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileApplicationSuccess(
            initialAutomobileApplicationState["application_on"]["application"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileApplicationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileApllication =
  (
    data: IAutomobileApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-auto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED";

export const fetchAutomobileApplicationListProgress = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS);

export const fetchAutomobileApplicationsListSuccess = (
  data: IAutomobileApplication[]
) =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileApplicationListFailed = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED);

export const fetchAutomobileApplicationListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileApplicationListProgress());

      let finalUrl = `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileApplication[] = res.data.data;
      dispatch(fetchAutomobileApplicationsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


  // alberta application


  export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS =
    "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS";
  export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS =
    "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS";
  export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED =
    "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED";
  
  export const fetchAutomobileAlbertaApplicationProgress = () =>
    action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_PROGRESS);
  export const fetchAutomobileAlbertaApplicationSuccess = (
    data: IAutomobileAlbertaApplication
  ) => action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_SUCCESSS, { data });
  export const fetchAutomobileAlbertaApplicationFailed = () =>
    action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_FAILED);
  
  export const fetchAutomobileAlbertaApplicationAsync =
    (
      customerPolicyId: number,
      incremental_number: number
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchAutomobileAlbertaApplicationProgress());
        const res = await api.get(
          `/policy/get-policy-auto-alberta-application?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`
        );
        const data: IAutomobileAlbertaApplication[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAutomobileAlbertaApplicationSuccess(data[0]));
        } else {
          dispatch(
            fetchAutomobileAlbertaApplicationSuccess(
              initialAutomobileApplicationState["application_ab"]["data"]
            )
          );
        }
      } catch (err: any) {
        dispatch(fetchAutomobileAlbertaApplicationFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  
  export const addAutomobileAlbertaApplication =
    (
      data: IAutomobileAlbertaApplication,
      onCallback: (isSuccess: boolean) => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        await api.post("/policy/edit-policy-auto-alberta-application", {
          ...data,
        });
        onCallback(true);
        dispatch(
          showMessage({
            type: "success",
            message: "Application saved successfully!",
            displayAs: "snackbar",
          })
        );
        dispatch(fetchAutomobileAlbertaApplicationSuccess(data));
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };
  




export const CLEAR_AUTOMOBILE_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_APPLICATION_STATE";

export const clearAutomobileApplicationState = () =>
  action(CLEAR_AUTOMOBILE_APPLICATION_STATE);
