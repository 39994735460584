import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalPolicyFormState {
  policyFormsList: {
    list: IHabitationalPolicyForm[];
    loading: LoadState;
    totalRecords: number;
  };
  paymentAuthorization: {
    data: IHabitationalPaymentAuthorization;
    loading: LoadState;
    error: string | null;
  };
  consentform: {
    data: IHabitationalConsentForm;
    loading: LoadState;
    error: string | null;
  };
  dwellingdeclaration: {
    data: IHabitationalDwellingDeclaration;
    loading: LoadState;
    error: string | null;
  };
  dwelling: {
    data: IHabitationalDwelling;
    loading: LoadState;
    error: string | null;
  };
  binder: {
    data: IHabitationalBinderCsio;
    loading: LoadState;
    error: string | null;
  };
  nonCsioBinder: {
    data: IHabitationalNonCsioBinder;
    loading: LoadState;
    error: string | null;
  };
}

export interface IHabitationalPolicyForm {
  incremental_number: number;
  task_code: string;
  policy_number: string;
  customer_name: string;
  customer_code: string;
  policy_form_type: string;
  create_ts: string | null;
  created_by_id: number;
  status: string;
}

export interface IHabitationalConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export interface IHabitationalDwellingDeclaration {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  policy_number: string | null;
  this_policy_is_for_rental_dwelling: string | null;
  this_policy_is_for_student_housing: string | null;
  we_understand_that_if_any_of_the_above: string | null;
  name_relation_living_since: {
    // key: string,
    name: string;
    relation: string;
    living_since: string;
  }[];
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
}

export interface IMortgagesLossPayee {
  key: string;
  full_name: string | null;
  address: string;
  city: string | null;
  province: string | null;
  postal_code: string | null;
}

export interface IHabitationalDwelling {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  applicant_name: string | null;
  is_the_applicant_a_company: string | null;
  name_of_principal: string | null;
  date_of_birth: string;
  any_additional_applicants: string | null;
  province_of_primary_residence: string | null;
  source_of_applicants_income: string | null;
  out_of_last_twelve_months: string | null;
  how_many_mortages_encumbrances_are_on_the_property: string | null;
  is_any_mortage_held_with_private_or_non_conve_lender: string | null;
  how_many_non_conv_or_private_lender_required_to_list_prop: string | null;
  if_three_or_more_what_is_the_reason_for_the_third_mortgage: string | null;
  how_much_equity_does_the_applicant_retain_the_home: string | null;
  is_the_applicant_behind_late_in_making_their_mortgage_payments: string;
  risk_information: {
    risk_location_address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province: string | null;
    postal_code: string | null;
    is_the_home_located_on_leased_land: string;
    if_yes_how_long_a_term_remains_in_the_land_lease_no_years: string;
    size_of_property_in_acres: string;
  };
  underwriting_building_information: {
    year_built: string;
    type_of_building: string;
    type_of_construction: string;
    type_of_foundation: string;
    no_of_stories: string;
    square_footage: string;
  };
  underwriting_renovation_or_demolition: {
    will_construction__renovation_activity_on_next_12_months: string;
    if_yes_please_check_all_that_apply: string;
    what_is_the_budget_for_renovations: string;
    will_there_be_any_structural_renov_adding_or_removing_walls: string;
    will_the_home_be_demolished_in_the_next_24_months: string;
    if_yes_please_describe: string;
  };
  underwriting_building_updates_electrical: {
    how_many_amps_is_the_electrical_system: string;
    electrical_details: string;
    type_of_wiring: string;
    if_there_is_any_aluminum_wiring: string;
    what_percentage_is_aluminum: string;
    has_it_been_inspected_and_approved_by_a_licensed_electrician: string;
    is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area: string;
    electrical_year_of_most_recent_electrical_update: string;
  };
  underwriting_building_updates_roof: {
    roof_material: string;
    year_of_most_recent_roof_update: string;
  };
  underwriting_building_updates_plumbing: {
    plumbing_types: string;
    year_of_plumbing_update: string;
    year_of_hot_water_tank: string;
  };
  underwriting_building_updates_heating: {
    primary_heat_device_appliance_to_regularly_heat_the_home: string;
    heating_other_please_describe: string;
    what_is_the_primary_heating_fuel_source: string;
    home_solid_fuel_heat_device_other_than_traditional_fireplace: string;
    if_yes_woo_heat_the_primary_pellets_burned_annually: string;
    was_the_solid_fuel_heat_device_professionally_installed: string;
    is_there_was_a_passed_wett_inspection_on_file: string;
    if_yes_please_attach_a_copy_of_the_wett_inspection: string;
    have_modification_been_made_to_the_solid_fuel_heat_device: string;
    solid_fuel_heat_device__chimney_cleaned_every_year: string;
    ashes_disposed_metal_lidded_cont_placed_non_flammable_surface: string;
    at_least_24_inches_of_clear_space_combustible_materials: string;
  };
  underwriting_building_updates_other: {
    does_the_home_have_an_oil_tank: string;
    if_yes_year_of_oil_tank: string;
    location_of_oil_tank: string;
    are_there_operational_smoke_detectors: string;
    is_there_an_operational_sprinkler_system: string;
    is_there_a_swimming_pool: string;
  };
  underwriting_building_updates_wildfire_and_flood: {
    risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood: string;
    wildfire_and_food_if_yes_please_provide_details: string;
    is_this_risk_located_within_25km_of_a_current_wildfire: string;
    risk_located_in_an_area_that_is_currently_under_flood_warning: string;
    of_common_kitchens: string;
    distance_to_fire_hydrant: string;
    distance_to_firehall: string;
  };
  occupancy: {
    confirm_occupancy: string;
    minimum_rental_arrangements_for_this_property: string;
    do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants: string;
    if_rented_how_long_is_the_rental: string;
    are_there_any_commercial_occupancies_in_the_building: string;
    if_yes_are_there_any_restaurants_in_the_building: string;
    does_the_owners_child_live_in_the_dwelling: string;
    how_many_rented_self_contained_units_are_there: string;
    who_lives_in_the_home: string;
    if_students_of_students: string;
    is_this_a_faternity_or_sorority_house: string;
    does_the_owners_child_or_relative_live_in_the_home: string;
    who_is_responsible_for_the_care_and_maintenance_of_the_property: string;
    is_there_farming_business_or_commercial_operations_on_premises: string;
    if_yes_please_describe: string;
  };
  coverage_information: {
    residential_building_limit: string;
    annual_rental_income: string;
    premises_liability: string;
    deductible: string;
    loss_assessment: string;
    unit_owners_imporovements_and_betterments: string;
    condominium_deductible_assessment_coverage: string;
    is_sewer_backup_coverage_requested: string;
    is_earthquake_coverage_requested: string;
  };
  mortgages_or_loss_payees: IMortgagesLossPayee[];
  policy_term_info_proposed_effective_date: string | null;
  mailing_address: string | null;
  mailing_unit_or_suite: string | null;
  mailing_city: string | null;
  mailing_province: string | null;
  mailing_postal_code: string | null;
  brokerage: string | null;
  broker_id: string | null;
  broker_email: string | null;
  tel: string | null;
  fax: string | null;
  status: string;
}

export interface IHabitationalPaymentAuthorization {
  customer_policy_id: number;
  customer_id: number | null;
  serial_number: string | null;
  policy_type: string | null;
  applicant_name: string | null;
  applicant_address: string | null;
  applicant_unit_or_suite: string | null;
  applicant_city: string | null;
  applicant_province_or_state: string | null;
  applicant_postal_code: string | null;
  applicant_contact_number: string | null;
  applicant_preferred_document_language: string | null;
  applicant_email_address: string;
  applicant_website_address: string;
  brokerage_branch_name: string | null;
  brokerage_full_address: string | null;
  brokerage_postal_code: string | null;
  brokerage_broker_code: string | null;
  brokerage_contact_name: string | null;
  brokerage_phone_number: string | null;
  brokerage_fax_number: string | null;
  brokerage_contract_number: string | null;
  brokerage_sub_contract_number: string | null;
  brokerage_group_or_program_name: string | null;
  brokerage_group_id: string | null;
  brokerage_broker_client_id: string | null;
  brokerage_company_client_id: string | null;
  insurance_company_name: string | null;
  insurance_company_address: string | null;
  insurance_company_unit_or_suite: string | null;
  insurance_company_province_or_state: string | null;
  insurance_company_postal_code: string | null;
  insurance_company_country: string | null;
  insurance_company_city: string | null;
  unit_or_suite: string | null;
  city: string | null;
  state_or_province: string | null;
  postal_code: string | null;
  country: string | null;
  policy_number: string | null;
  request_type: string | null;
  broker_code: string | null;
  total_estimated_policy_premium: string | null;
  installment_fee: number | null;
  tax_percentage: number | null;
  tax: string | null;
  percentage_finance: number | null;
  finance_charges: string | null;
  total_estimated_cost: string | null;
  mode_of_payment: string | null;
  plan_type: string | null;
  bank_name: string | null;
  bank_account_number: string | null;
  bank_transit_number: string | null;
  bank_institution_number: string | null;
  bank_address: string | null;
  bank_unit_or_suite: string | null;
  bank_city: string | null;
  bank_province_or_state: string | null;
  bank_postal_code: string | null;
  account_holder_name: string | null;
  account_holder_address: string | null;
  account_holder_unit_or_suite: string | null;
  account_holder_city: string | null;
  account_holder_state_or_province: string | null;
  account_holder_postal_code: string | null;
  type_of_payment: string | null;
  down_payment_amount: string | null;
  insurance_company_additional_charges: string | null;
  broker_additional_charges: string | null;
  full_payment_amount: string | null;
  installment_amount: string | null;
  next_payment_date: string | null;
  authorization: string | null;
  signature: string | null;
  status: string;
}

export interface IHabitationalBinderCsio {
  customer_policy_id: number;
  incremental_number: number | null;
  insurance_company: string | null;
  policy_binder_number: string | null;
  binder_effective_date: string;
  binding_time: Moment;
  applicant_full_name: string | null;
  applicant_address: string | null;
  applicant_postal_code: string | null;
  applicant_phone: string | null;
  applicant_mobile: string | null;
  applicant_preferred_document_language: string | null;
  applicant_email_address: string | null;
  applicant_website_address: string | null;
  brokerage_full_name: string | null;
  brokerage_address: string | null;
  brokerage_postal_code: string | null;
  brokerage_code: string | null;
  brokerage_contact_name: string | null;
  brokerage_phone: string | null;
  brokerage_fax: string | null;
  brokerage_contract_number: string | null;
  brokerage_sub_contract_number: string | null;
  brokerage_group_name: string | null;
  brokerage_group_id: string | null;
  broker_client_id: string | null;
  brokerage_company_client_id: string | null;
  policy_effective_date: string;
  policy_effective_time: string;
  policy_expiry_date: string;
  risk_address: string | null;
  mortgagee_one: string | null;
  nature_of_interest_one: string | null;
  mortgagee_two: string | null;
  nature_of_interest_two: string | null;
  mortgagee_three: string | null;
  nature_of_interest_three: string | null;
  package_form_and_type: string | null;
  deductible: string | null;
  single_limit: string | null;
  dwelling_limit: string | null;
  detached_private_structure: string | null;
  personal_property: string | null;
  additional_living_expense: string | null;
  legal_liability: string | null;
  voluntary_medical_payments: string | null;
  voluntary_property_damage: string | null;
  remarks: string | null;
  other_broker_name: string | null;
  signature_of_broker: string | null;
  other_date: string;
  status: string;
}

export interface IHabitationalNonCsioBinder {
  customer_policy_id: number;
  incremental_number: number | null;
  insurance_company: string | null;
  policy_binder_number: string | null;
  binder_effective_date: string;
  binding_time: string;
  applicant_full_name: string | null;
  applicant_address: string | null;
  applicant_postal_code: string | null;
  applicant_phone: string | null;
  applicant_mobile: string | null;
  applicant_preferred_document_language: string | null;
  applicant_email_address: string | null;
  applicant_website_address: string | null;
  brokerage_full_name: string | null;
  brokerage_address: string | null;
  brokerage_postal_code: string | null;
  brokerage_code: string | null;
  brokerage_contact_name: string | null;
  brokerage_phone: string | null;
  brokerage_fax: string | null;
  brokerage_contract_number: string | null;
  brokerage_sub_contract_number: string | null;
  brokerage_group_name: string | null;
  brokerage_group_id: string | null;
  broker_client_id: string | null;
  brokerage_company_client_id: string | null;
  policy_effective_date: string;
  policy_effective_time: string;
  policy_expiry_date: string;
  risk_location_and_coverage: [
    {
      key: string;
      Risk_Location_Address: string | null;
      Package_Form_and_Type: string | null;
      Deductible: string | null;
      Single_Limit: string | null;
      Dwelling_Limit: string | null;
      Detached_Private_Structure: string | null;
      Personal_Property: string | null;
      Additional_Living_Expense: string | null;
      Legal_Liability: string | null;
      Voluntary_Medical_Payments: string | null;
      Voluntary_Property_Damage: string | null;
    }
  ];
  mortgagee_one: string | null;
  nature_of_interest_one: string | null;
  mortgagee_two: string | null;
  nature_of_interest_two: string | null;
  mortgagee_three: string | null;
  nature_of_interest_three: string | null;
  remarks: string | null;
  other_broker_name: string | null;
  signature_of_broker: string | null;
  other_date: string;
  status: string;
}

export const initialHabitationalPolicyFormState: IHabitationalPolicyFormState =
  {
    policyFormsList: {
      list: [],
      loading: LoadState.NotLoaded,
      totalRecords: 0,
    },
    paymentAuthorization: {
      data: {
        customer_policy_id: 0,
        customer_id: null,
        serial_number: null,
        policy_type: null,
        applicant_name: null,
        applicant_address: null,
        applicant_unit_or_suite: null,
        applicant_city: null,
        applicant_province_or_state: null,
        applicant_postal_code: null,
        applicant_contact_number: null,
        applicant_preferred_document_language: null,
        applicant_email_address: "",
        applicant_website_address: "",
        brokerage_branch_name: null,
        brokerage_full_address: null,
        brokerage_postal_code: null,
        brokerage_broker_code: null,
        brokerage_contact_name: null,
        brokerage_phone_number: null,
        brokerage_fax_number: null,
        brokerage_contract_number: null,
        brokerage_sub_contract_number: null,
        brokerage_group_or_program_name: null,
        brokerage_group_id: null,
        brokerage_broker_client_id: null,
        brokerage_company_client_id: null,
        insurance_company_name: null,
        insurance_company_address: null,
        insurance_company_unit_or_suite: null,
        insurance_company_province_or_state: null,
        insurance_company_postal_code: null,
        insurance_company_country: null,
        insurance_company_city: null,
        unit_or_suite: null,
        city: null,
        state_or_province: null,
        postal_code: null,
        country: null,
        policy_number: null,
        request_type: null,
        broker_code: null,
        total_estimated_policy_premium: null,
        installment_fee: null,
        tax_percentage: null,
        tax: null,
        percentage_finance: null,
        finance_charges: null,
        total_estimated_cost: null,
        mode_of_payment: null,
        plan_type: null,
        bank_name: null,
        bank_account_number: null,
        bank_transit_number: null,
        bank_institution_number: null,
        bank_address: null,
        bank_unit_or_suite: null,
        bank_city: null,
        bank_province_or_state: null,
        bank_postal_code: null,
        account_holder_name: null,
        account_holder_address: null,
        account_holder_unit_or_suite: null,
        account_holder_city: null,
        account_holder_state_or_province: null,
        account_holder_postal_code: null,
        type_of_payment: null,
        down_payment_amount: null,
        insurance_company_additional_charges: null,
        broker_additional_charges: null,
        full_payment_amount: null,
        installment_amount: null,
        next_payment_date: null,
        authorization: "N",
        signature:
          "I/We hereby authorize the named financial institution above to debit my/our account for all payments payable to",
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    consentform: {
      loading: LoadState.NotLoaded,
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        email_address: null,
        text_field: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      error: null,
    },
    dwellingdeclaration: {
      loading: LoadState.NotLoaded,
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: null,
        customer_name: null,
        address: null,
        unit_or_suite: null,
        city: null,
        province_or_state: null,
        postal_code: null,
        country: null,
        policy_number: null,
        this_policy_is_for_rental_dwelling: null,
        this_policy_is_for_student_housing: null,
        we_understand_that_if_any_of_the_above: null,
        name_relation_living_since: [
          {
            // key: getUniqueId(),
            name: "",
            relation: "",
            living_since: "",
          },
        ],
        status: "ACTIVE",
        created_by_id: 0,
        modified_by_id: 0,
        insert_ts: "",
      },
      error: null,
    },
    dwelling: {
      loading: LoadState.NotLoaded,
      data: {
        customer_policy_id: 0,
        customer_id: null,
        incremental_number: null,
        policy_number: null,
        applicant_name: null,
        is_the_applicant_a_company: null,
        name_of_principal: null,
        date_of_birth: "",
        any_additional_applicants: null,
        province_of_primary_residence: "Ontario",
        source_of_applicants_income: null,
        out_of_last_twelve_months: null,
        how_many_mortages_encumbrances_are_on_the_property: null,
        is_any_mortage_held_with_private_or_non_conve_lender: null,
        how_many_non_conv_or_private_lender_required_to_list_prop: null,
        if_three_or_more_what_is_the_reason_for_the_third_mortgage: null,
        how_much_equity_does_the_applicant_retain_the_home: null,
        is_the_applicant_behind_late_in_making_their_mortgage_payments: "",
        risk_information: {
          risk_location_address: "",
          unit_or_suite: "",
          city: "",
          province: "",
          postal_code: "",
          is_the_home_located_on_leased_land: "",
          if_yes_how_long_a_term_remains_in_the_land_lease_no_years: "",
          size_of_property_in_acres: "",
        },
        underwriting_building_information: {
          year_built: "",
          type_of_building: "",
          type_of_construction: "",
          type_of_foundation: "",
          no_of_stories: "",
          square_footage: "",
        },
        underwriting_renovation_or_demolition: {
          will_construction__renovation_activity_on_next_12_months: "",
          if_yes_please_check_all_that_apply: "",
          what_is_the_budget_for_renovations: "",
          will_there_be_any_structural_renov_adding_or_removing_walls: "",
          will_the_home_be_demolished_in_the_next_24_months: "",
          if_yes_please_describe: "",
        },
        underwriting_building_updates_electrical: {
          how_many_amps_is_the_electrical_system: "",
          electrical_details: "",
          type_of_wiring: "",
          if_there_is_any_aluminum_wiring: "",
          what_percentage_is_aluminum: "",
          has_it_been_inspected_and_approved_by_a_licensed_electrician: "",
          is_there_a_knob_tube_wiring_in_any_kitchen_or_laundry_area: "",
          electrical_year_of_most_recent_electrical_update: "",
        },
        underwriting_building_updates_roof: {
          roof_material: "",
          year_of_most_recent_roof_update: "",
        },
        underwriting_building_updates_plumbing: {
          plumbing_types: "",
          year_of_plumbing_update: "",
          year_of_hot_water_tank: "",
        },
        underwriting_building_updates_heating: {
          primary_heat_device_appliance_to_regularly_heat_the_home: "",
          heating_other_please_describe: "",
          what_is_the_primary_heating_fuel_source: "",
          home_solid_fuel_heat_device_other_than_traditional_fireplace: "",
          if_yes_woo_heat_the_primary_pellets_burned_annually: "",
          was_the_solid_fuel_heat_device_professionally_installed: "",
          is_there_was_a_passed_wett_inspection_on_file: "",
          if_yes_please_attach_a_copy_of_the_wett_inspection: "",
          have_modification_been_made_to_the_solid_fuel_heat_device: "",
          solid_fuel_heat_device__chimney_cleaned_every_year: "",
          ashes_disposed_metal_lidded_cont_placed_non_flammable_surface: "",
          at_least_24_inches_of_clear_space_combustible_materials: "",
        },
        underwriting_building_updates_other: {
          does_the_home_have_an_oil_tank: "",
          if_yes_year_of_oil_tank: "",
          location_of_oil_tank: "",
          are_there_operational_smoke_detectors: "",
          is_there_an_operational_sprinkler_system: "",
          is_there_a_swimming_pool: "",
        },
        underwriting_building_updates_wildfire_and_flood: {
          risk_located_evacuated_or_notice_of_evacuation_wildfire_or_flood: "",
          wildfire_and_food_if_yes_please_provide_details: "",
          is_this_risk_located_within_25km_of_a_current_wildfire: "",
          risk_located_in_an_area_that_is_currently_under_flood_warning: "",
          of_common_kitchens: "",
          distance_to_fire_hydrant: "",
          distance_to_firehall: "",
        },
        occupancy: {
          confirm_occupancy: "",
          minimum_rental_arrangements_for_this_property: "",
          do_allow_your_tenants_to_sublet_or_are_any_occupants_subtenants: "",
          if_rented_how_long_is_the_rental: "",
          are_there_any_commercial_occupancies_in_the_building: "",
          if_yes_are_there_any_restaurants_in_the_building: "",
          does_the_owners_child_live_in_the_dwelling: "",
          how_many_rented_self_contained_units_are_there: "",
          who_lives_in_the_home: "",
          if_students_of_students: "",
          is_this_a_faternity_or_sorority_house: "",
          does_the_owners_child_or_relative_live_in_the_home: "",
          who_is_responsible_for_the_care_and_maintenance_of_the_property: "",
          is_there_farming_business_or_commercial_operations_on_premises: "",
          if_yes_please_describe: "",
        },
        coverage_information: {
          residential_building_limit: "",
          annual_rental_income: "",
          premises_liability: "",
          deductible: "",
          loss_assessment: "",
          unit_owners_imporovements_and_betterments: "",
          condominium_deductible_assessment_coverage: "",
          is_sewer_backup_coverage_requested: "",
          is_earthquake_coverage_requested: "",
        },
        mortgages_or_loss_payees: [],
        policy_term_info_proposed_effective_date: null,
        mailing_address: null,
        mailing_unit_or_suite: null,
        mailing_city: null,
        mailing_province: null,
        mailing_postal_code: null,
        brokerage: null,
        broker_id: null,
        broker_email: null,
        tel: null,
        fax: null,
        status: "ACTIVE",
      },
      error: null,
    },
    binder: {
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        insurance_company: null,
        policy_binder_number: null,
        binder_effective_date: moment().format("YYYY-MM-DD"),
        binding_time: moment(),
        applicant_full_name: null,
        applicant_address: null,
        applicant_postal_code: null,
        applicant_phone: null,
        applicant_mobile: null,
        applicant_preferred_document_language: null,
        applicant_email_address: null,
        applicant_website_address: null,
        brokerage_full_name: null,
        brokerage_address: null,
        brokerage_postal_code: null,
        brokerage_code: null,
        brokerage_contact_name: null,
        brokerage_phone: null,
        brokerage_fax: null,
        brokerage_contract_number: null,
        brokerage_sub_contract_number: null,
        brokerage_group_name: null,
        brokerage_group_id: null,
        broker_client_id: null,
        brokerage_company_client_id: null,
        policy_effective_date: moment().format("YYYY-MM-DD"),
        policy_effective_time: moment().format(),
        policy_expiry_date: moment().add(1, "year").format("YYYY-MM-DD"),
        risk_address: null,
        mortgagee_one: null,
        nature_of_interest_one: null,
        mortgagee_two: null,
        nature_of_interest_two: null,
        mortgagee_three: null,
        nature_of_interest_three: null,
        package_form_and_type: null,
        deductible: null,
        single_limit: null,
        dwelling_limit: null,
        detached_private_structure: null,
        personal_property: null,
        additional_living_expense: null,
        legal_liability: null,
        voluntary_medical_payments: null,
        voluntary_property_damage: null,
        remarks: null,
        other_broker_name: null,
        signature_of_broker: null,
        other_date: moment().format("YYYY-MM-DD"),
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    nonCsioBinder: {
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        insurance_company: null,
        policy_binder_number: null,
        binder_effective_date: moment().format("YYYY-MM-DD"),
        binding_time: moment().format(),
        applicant_full_name: null,
        applicant_address: null,
        applicant_postal_code: null,
        applicant_phone: null,
        applicant_mobile: null,
        applicant_preferred_document_language: null,
        applicant_email_address: null,
        applicant_website_address: null,
        brokerage_full_name: null,
        brokerage_address: null,
        brokerage_postal_code: null,
        brokerage_code: null,
        brokerage_contact_name: null,
        brokerage_phone: null,
        brokerage_fax: null,
        brokerage_contract_number: null,
        brokerage_sub_contract_number: null,
        brokerage_group_name: null,
        brokerage_group_id: null,
        broker_client_id: null,
        brokerage_company_client_id: null,
        policy_effective_date: moment().format("YYYY-MM-DD"),
        policy_effective_time: moment().format(),
        policy_expiry_date: moment().add(1, "year").format("YYYY-MM-DD"),
        risk_location_and_coverage: [
          {
            key: getUniqueId(),
            Risk_Location_Address: null,
            Package_Form_and_Type: null,
            Deductible: null,
            Single_Limit: null,
            Dwelling_Limit: null,
            Detached_Private_Structure: null,
            Personal_Property: null,
            Additional_Living_Expense: null,
            Legal_Liability: null,
            Voluntary_Medical_Payments: null,
            Voluntary_Property_Damage: null,
          },
        ],
        mortgagee_one: null,
        nature_of_interest_one: null,
        mortgagee_two: null,
        nature_of_interest_two: null,
        mortgagee_three: null,
        nature_of_interest_three: null,
        remarks: null,
        other_broker_name: null,
        signature_of_broker: null,
        other_date: moment().format("YYYY-MM-DD"),
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
  };
