import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomationState,
  IConditionTableRow,
  ICreateWorkflow,
  IGraphEdge,
  IGraphNode,
  IWorkFlowAction,
  IWorkFlowCondition,
  IWorkflowFinishEmail,
  IWorkflowFinishEmailAPIPayloadResponse,
  IWorkflowGET,
} from "./automation.types";
import { getUniqueId } from "../../helpers";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { parseFinialEmailApiToLocalPayload } from "./parseFinialEmailApiToLocalPayload";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_GRAPH_DATA_PROGRESS = "FETCH_GRAPH_DATA_PROGRESS";
export const FETCH_GRAPH_DATA_SUCCESS = "FETCH_GRAPH_DATA_SUCCESS";
export const FETCH_GRAPH_DATA_FAILED = "FETCH_GRAPH_DATA_FAILED";

export const fetchGraphDataProgress = () => action(FETCH_GRAPH_DATA_PROGRESS);
export const fetchGraphDataSuccess = (
  graphData: IAutomationState["graphData"]
) => action(FETCH_GRAPH_DATA_SUCCESS, { graphData });
export const fetchGraphDataFailed = () => action(FETCH_GRAPH_DATA_FAILED);

export const fetchAutomationAsync =
  (code: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGraphDataProgress());
      const res = await api.get(
        "/workflow/get-workflow-all-definition?workflow_basic_code=" + code
      );
      const data = res.data.data;
      if (data.length > 0) {
        const finalGraphData: IAutomationState["graphData"] = {
          nodes: [],
          edges: [],
          workflow_basic_code: null,
        };

        for (const item of data) {
          const { nodes, ...rest } = item;
          if (
            nodes &&
            (rest.status === "ACTIVE" ||
              item.nodes.type === "parentWorkflowNode")
          ) {
            if (item.nodes.type === "performEmailAction") {
              const { nodes, ...rest } =
                parseFinialEmailApiToLocalPayload(item);
              const graphNode: IGraphNode = {
                id: item.nodes.id,
                data: {
                  label: item.nodes.data.label,
                  payload: rest as IWorkflowFinishEmail,
                },
                position: item.nodes.position,
                type: item.nodes.type,
              };

              finalGraphData.nodes.push(graphNode);
            } else {
              const graphNode: IGraphNode = {
                id: item.nodes.id,
                data: {
                  label: item.nodes.data.label,
                  payload: rest as IWorkFlowCondition,
                },
                position: item.nodes.position,
                type: item.nodes.type,
              };

              finalGraphData.nodes.push(graphNode);
            }
          }
        }

        const placeholderId = getUniqueId();

        finalGraphData.nodes.push({
          id: placeholderId,
          data: { label: "+", payload: {} as any },
          position: { x: 0, y: 0 },
          type: "placeholder",
        });

        for (let i = 0; i < finalGraphData.nodes.length; i++) {
          if (i !== 0) {
            const targetId = finalGraphData.nodes[i].id;
            const sourceId = finalGraphData.nodes[i - 1].id;
            finalGraphData.edges.push({
              id: sourceId + "=>" + targetId,
              source: sourceId,
              target: targetId,
              type: finalGraphData.nodes[i].type,
            });
          }
        }

        dispatch(fetchGraphDataSuccess(finalGraphData));
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addPlaceholderNode =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const nodes: any = [
      {
        id: getUniqueId(),
        data: { label: "+", payload: {} as any },
        position: { x: 0, y: 0 },
        type: "placeholder",
      },
    ];
    const edges: any = [];

    for (let i = 0; i < nodes.length; i++) {
      if (i !== 0) {
        const targetId = nodes[i].id;
        const sourceId = nodes[i - 1].id;
        edges.push({
          id: sourceId + "=>" + targetId,
          source: sourceId,
          target: targetId,
          type: nodes[i].type,
        });
      }
    }

    dispatch(
      fetchGraphDataSuccess({
        edges: edges,
        nodes: nodes,
        workflow_basic_code: "",
      })
    );
  };

export const ADD_WORKFLOW_NODE_IN_GRAPH = "ADD_WORKFLOW_NODE_IN_GRAPH";

export const addNewWorkflowNodeInAGraph = (node: IGraphNode) =>
  action(ADD_WORKFLOW_NODE_IN_GRAPH, { node });

export const upsertWorkflow =
  (
    data: ICreateWorkflow,
    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/workflow/upsert-workflow-basic", {
        ...data,
      });
      const { nodes, ...rest } = res.data.data;
      const finalData: IGraphNode = {
        id: nodes.id,
        data: {
          label: nodes.data.label,
          payload: rest as ICreateWorkflow,
        },
        position: nodes.position,
        type: nodes.type,
      };
      dispatch(addNewWorkflowNodeInAGraph(finalData));

      dispatch(
        showMessage({
          type: "success",
          message: "Workflow node saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true, (rest as ICreateWorkflow).workflow_basic_code as string);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const ADD_CONDITION_NODE_IN_GRAPH = "ADD_CONDITION_NODE_IN_GRAPH";

export const addNewConditionNodeInAGraph = (
  node: IGraphNode,
  isDelete: boolean
) => action(ADD_CONDITION_NODE_IN_GRAPH, { node, isDelete });

export const upsertContitionNode =
  (
    data: IWorkFlowCondition,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const isDelete = data.status === "INACTIVE";
      const workflowNode = getState().automation.graphData.nodes[0].data
        .payload as ICreateWorkflow;
      const isSelfChanges = workflowNode.real_time_type === "SELF_CHANGES";
      const isCron = workflowNode.run_as == "CRON";
      const conditionVariables: string[] = [];
      let finalDataList: IWorkFlowCondition = {
        ...data,
        conditions: !isCron ? [...data.conditions]:[],
      };
      let consitionsList: { [key: string]: IConditionTableRow[] } = {};
      for (let i = 0; i < data.conditions.length; i++) {
        const condition = data.conditions[i];
        const firstKey = Object.keys(condition)[0];
        if (firstKey) {
          for (const row of condition[firstKey]) {
            let column = row.view + "." + row.viewColumn;
            let columnOrValue = "";
            if (row.isCompareWithOldValue && isSelfChanges) {
              columnOrValue =
                row.view + "." + row.columnCompColumn + ".old_value";
            } else {
              if (row.isColumnComparison) {
                columnOrValue = row.columnCompView + "." + row.columnCompColumn;
              } else {
                columnOrValue = row.compareColumnValue as string;
              }
            }
            if (isSelfChanges) {
              column = column + ".new_value";
            }
            conditionVariables.push(column);
            if (consitionsList[firstKey]) {
              consitionsList[firstKey].push({
                ...row,
                column: column,
                columnOrValue: columnOrValue,
              });
            } else {
              consitionsList[firstKey] = [
                {
                  ...row,
                  column: column,
                  columnOrValue: columnOrValue,
                },
              ];
            }
          }
          if (condition[firstKey].length === 0) {
            consitionsList["and"] = [];
            finalDataList.conditions[i] = consitionsList;
            consitionsList = {};
          } else {
            finalDataList.conditions[i] = consitionsList;
            consitionsList = {};
          }
        } else {
          consitionsList["and"] = [];
          finalDataList.conditions[i] = consitionsList;
          consitionsList = {};
        }
      }
      if(!isCron){
      finalDataList.condition_variables = conditionVariables;
      }

      dispatch(saveLoaderProgress());
      const res = await api.post("/workflow/upsert-workflow-condition", {
        ...finalDataList,
        workflow_basic_code: workflowNode.workflow_basic_code,
        conditions: isCron ? {} : finalDataList.conditions,
      });
      const { nodes, ...rest } = res.data.data;
      const finalData: IGraphNode = {
        id: nodes.id,
        data: {
          label: nodes.data.label,
          payload: rest as IWorkFlowCondition,
        },
        position: nodes.position,
        type: nodes.type,
      };

      dispatch(addNewConditionNodeInAGraph(finalData, isDelete));

      dispatch(
        showMessage({
          type: "success",
          message: "condition node saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const ADD_ACTION_NODE_IN_GRAPH = "ADD_ACTION_NODE_IN_GRAPH";

export const addNewActionNodeInAGraph = (node: IGraphNode, isDelete: boolean) =>
  action(ADD_ACTION_NODE_IN_GRAPH, { node, isDelete });

export const upsertActionNode =
  (
    data: IWorkFlowAction,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const isDelete = data.status === "INACTIVE";
      const workflowNode = getState().automation.graphData.nodes[0].data
        .payload as ICreateWorkflow;
      const conditionNode = getState().automation.graphData.nodes.find(
        (x) => x.type === "conditionNode"
      );
      if (conditionNode) {
        dispatch(saveLoaderProgress());
        const res = await api.post("/workflow/upsert-workflow-action", {
          ...data,
          workflow_basic_code: workflowNode.workflow_basic_code,
          workflow_condition_code: (
            conditionNode.data.payload as IWorkFlowCondition
          ).workflow_condition_code,
        });
        const { nodes, ...rest } = res.data.data;
        const finalData: IGraphNode = {
          id: nodes.id,
          data: {
            label: nodes.data.label,
            payload: rest as IWorkFlowAction,
          },
          position: nodes.position,
          type: nodes.type,
        };

        dispatch(addNewActionNodeInAGraph(finalData, isDelete));

        dispatch(
          showMessage({
            type: "success",
            message: "Action node saved successfully",
            displayAs: "snackbar",
          })
        );
        onCallback(true);
      } else {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: "Parent node should be condition node",
            displayAs: "snackbar",
          })
        );
      }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const ADD_FINISH_ACTION_NODE_IN_GRAPH =
  "ADD_FINISH_ACTION_NODE_IN_GRAPH";

export const addFinishActionNodeInAGraph = (
  node: IGraphNode,
  isDelete: boolean
) => action(ADD_FINISH_ACTION_NODE_IN_GRAPH, { node, isDelete });

export const upsertFinishEmailActionNode =
  (
    data: IWorkflowFinishEmail,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const isDelete = data.status === "INACTIVE";
      dispatch(saveLoaderProgress());
      const workflowNode = getState().automation.graphData.nodes[0].data
        .payload as ICreateWorkflow;
      const actionNode = getState().automation.graphData.nodes.find((x) =>
        [
          "actionNodeEmail",
          "actionNodeSMS",
          "actionNodeWhatsApp",
          "actionNodeModification",
        ].includes(x.type)
      );

      if(actionNode){

        const finalPayload: IWorkflowFinishEmailAPIPayloadResponse = {
          workflow_action_email_code: data.workflow_action_email_code,
          workflow_basic_code: workflowNode.workflow_basic_code as string,
          workflow_action_code: (actionNode.data.payload as IWorkFlowAction).workflow_action_code as string,
          call_type: data.call_type,
          to: [],
          cc: [],
          bcc: [],
          sending_order: data.sending_order,
          template_code: data.template_code,
          nodes: data.nodes,
          status: data.status,
        };
  
        if (data.call_type === "VARIABLE") {
          const varaibleTo: string[] = [];
          const varaibleCC: string[] = [];
          const varaibleBcc: string[] = [];
          for (const variable of data.variablesTo) {
            varaibleTo.push(variable.view + "." + variable.columnName);
          }
          for (const variable of data.variablesCC) {
            varaibleCC.push(variable.view + "." + variable.columnName);
          }
          for (const variable of data.variablesBCC) {
            varaibleBcc.push(variable.view + "." + variable.columnName);
          }
          finalPayload.to = varaibleTo;
          finalPayload.cc = varaibleCC;
          finalPayload.bcc = varaibleBcc;
        } else if (data.call_type === "API") {
          for (const endPoint of data.apiTo) {
            finalPayload.to.push({
              ...endPoint,
              api: endPoint.api,
              emailColumn: endPoint.emailColumn,
              userName: endPoint.userName,
            });
          }
          for (const endPoint of data.apiCC) {
            finalPayload.cc.push({
              ...endPoint,
              api: endPoint.api,
              emailColumn: endPoint.emailColumn,
              userName: endPoint.userName,
            });
          }
          for (const endPoint of data.apiBCC) {
            finalPayload.bcc.push({
              ...endPoint,
              api: endPoint.api,
              emailColumn: endPoint.emailColumn,
              userName: endPoint.userName,
            });
          }
        }
        else if(data.call_type === "EMAIL_ADDRESS"){
          finalPayload.to = data.emailsTo;
          finalPayload.cc = data.emailsCC;
          finalPayload.bcc = data.emailsBCC;
        }
  
        const res = await api.post("/workflow/upsert-workflow-action-email", {
          ...finalPayload,
        });
  
        const { nodes, ...rest } = parseFinialEmailApiToLocalPayload(
          res.data.data
        );
  
        const finalData: IGraphNode = {
          id: nodes.id,
          data: {
            label: nodes.data.label,
            payload: rest as IWorkflowFinishEmail,
          },
          position: nodes.position,
          type: nodes.type,
        };
  
        dispatch(addFinishActionNodeInAGraph(finalData, isDelete));
        dispatch(
          showMessage({
            type: "success",
            message: "Finish node saved successfully!",
            displayAs: "snackbar",
          })
        );
        onCallback(true);
      }
      else {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: "Parent node should be action node",
            displayAs: "snackbar",
          })
        );
      }

    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };




export const FETCH_WORKFLOWS_LIST_PROGRESS = "FETCH_WORKFLOWS_LIST_PROGRESS";
export const FETCH_WORKFLOWS_LIST_SUCCESS = "FETCH_WORKFLOWS_LIST_SUCCESS";
export const FETCH_WORKFLOWS_LIST_FAILED = "FETCH_WORKFLOWS_LIST_FAILED";

export const fetchWorkflowListProgress = () =>
  action(FETCH_WORKFLOWS_LIST_PROGRESS);
export const fetchWorkflowListSuccess = (
  data: IWorkflowGET[],
  totalRecords: number
) =>
  action(FETCH_WORKFLOWS_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchWorkflowListFailed = () =>
  action(FETCH_WORKFLOWS_LIST_FAILED);

export const fetchWorkflowListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchWorkflowListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/workflow/get-workflow-basic?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchValue.length > 0) {
        finalUrl = `/workflow/get-workflow-basic?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      const res = await api.get(finalUrl);
      const data: IWorkflowGET[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchWorkflowListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWorkflowListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMATION_STATE = "CLEAR_AUTOMATION_STATE";
export const clearAutomationState = () => action(CLEAR_AUTOMATION_STATE);
