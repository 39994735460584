import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile } from "../../helpers";
import moment from "moment";
import { endPoints } from "../../constants/endPoints";
import { makeApiCall } from "../../helpers/postRequest";
import { IGroupPlans } from "./groupPlans.types";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_GROUP_PLANS_LIST_PROGRESS =
  "FETCH_GROUP_PLANS_LIST_PROGRESS";
export const FETCH_GROUP_PLANS_LIST_SUCCESS = "FETCH_GROUP_PLANS_LIST_SUCCESS";
export const FETCH_GROUP_PLANS_LIST_FAILED = "FETCH_GROUP_PLANS_LIST_FAILED";

export const fetchGroupPlansListProgress = () =>
  action(FETCH_GROUP_PLANS_LIST_PROGRESS);
export const fetchGroupPlansListSuccess = (
  data: IGroupPlans[],
) => action(FETCH_GROUP_PLANS_LIST_SUCCESS, { data });
export const fetchGroupPlansListFailed = (errorMessage: string) =>
  action(FETCH_GROUP_PLANS_LIST_FAILED, { errorMessage });

export const fetchGroupPlansListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGroupPlansListProgress());

      let finalUrl = `/insurance/get-broker-group-plan?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl += `&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IGroupPlans[] = res.data.data;

      dispatch(fetchGroupPlansListSuccess(data));
    } catch (err: any) {
      dispatch(fetchGroupPlansListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_GROUP_PLANS_PROGRESS = "FETCH_GROUP_PLANS_PROGRESS";
export const FETCH_GROUP_PLANS_SUCCESS = "FETCH_GROUP_PLANS_SUCCESS";
export const FETCH_GROUP_PLANS_FAILED = "FETCH_GROUP_PLANS_FAILED";

export const fetchGroupPlansProgress = () => action(FETCH_GROUP_PLANS_PROGRESS);
export const fetchGroupPlansSuccess = (data: IGroupPlans) =>
  action(FETCH_GROUP_PLANS_SUCCESS, { data });
export const fetchGroupPlansFailed = (errorMessage: string) =>
  action(FETCH_GROUP_PLANS_FAILED, { errorMessage });

export const fetchGroupPlansAsync =
  (brokerPlanCode: string | number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGroupPlansProgress());
      const res = await api.get(
        `/insurance/get-broker-group-plan?broker_group_plan_code=${brokerPlanCode}`
      );
      const data: IGroupPlans[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchGroupPlansSuccess(data[0]));
      } else {
        dispatch(
          fetchGroupPlansFailed(
            "Something went to be wrong!"
          )
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertGroupPlansAsync =
  (
    data: IGroupPlans,
    initialData: IGroupPlans,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await makeApiCall(
        {
          url: endPoints.groupPlans.upsertGroupPlan,
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "broker_group_plan_code",
          },
        },
        initialData,
        {
          ...data,
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "Your Plan is saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_GROUP_PLANS = "CLEAR_GROUP_PLANS";
export const clearGroupPlans = () => action(CLEAR_GROUP_PLANS);
