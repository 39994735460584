/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { useFormik } from "formik";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";

import { fetchInsurerCodeWithCallbackAsync } from "../../../../../../redux/insurer/insurerActions";
import { fetchBranchOfficeWithCallback } from "../../../../../../redux/branchOffice/branchOfficeActions";
import { fetchCommAutomobilePolicyAdminAsync } from "../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { useCommAutoDriverList } from "../../../../../../hooks/useCommAutoDriverList";
import { addCommAutoCSIOApplication, fetchCommAutoCSIOApplicationAsync } from "../../../../../../redux/commAutoPolicy/commAutoCsioApplication/commAutoCsioApplicationActions";
import { clearAutomobileCSIOApplicationState } from "../../../../../../redux/automobileCSIOApplication/automobileCSIOApplicationActions";

export const CommAutomobileCsioApplicationForm: React.FC = () => {
  const { customerId, customerPolicyId, messageGUID } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    messageGUID?: string;
  };

  const { csioData, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.csioapplication
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      to:
        "/comm-automobile-policy/comm-automobile/" +
        customerId +
        "/" +
        customerPolicyId,
      title: "comm-auto",
    },
    {
      title: "application-form",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearAutomobileCSIOApplicationState());
    };
  }, []);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: csioData,
    validate: (values) => {
      const errors: any = {};
      // if(values.certificate_holder_phone)
      return errors;
    },
    onSubmit: (values) => {
      if (customerId && customerPolicyId) {
        setSaveLoading(true);
        dispatch(
          addCommAutoCSIOApplication(
            { ...values, customer_policy_id: customerPolicyId ?? null },
            (isSuccess) => {
              if (isSuccess) {
                // navigate(
                //   `/comm-automobile-policy/${customerId}/application-tab/${customerPolicyId}?tab=0`
                // );
                setSaveLoading(false);
              }
            }
          )
        );
      }
    },
  });

  const onTabChange = (value: number) => {
    window.scrollTo(0, 400);
    setTab(value);
  };

  React.useEffect(() => {
    if (messageGUID) {
      dispatch(fetchCommAutoCSIOApplicationAsync(messageGUID));
    }
  }, [messageGUID]);

  React.useEffect(() => {
    setValues(csioData);
  }, [csioData]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileCSIOApplicationState());
    };
  }, []);
  


  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard sx={{ mt: 2 }} heading="Automobile Application Form">
        <Stepper sx={{ mb: 3, mt: 3 }} activeStep={tab} alternativeLabel>
          {[
            "Basic Details",
            "Described Automobiles",
            "Driver Information",
            "Previous Accidents and Insurance Claims",
            "History of Convictions",
            "Raiting Information",
            "Insurance Coverages Applied for",
            "Payment/Report of broker",
          ].map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography variant="h4" sx={{ fontSize: ".9rem" }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          {/* <TabPanel index={0} value={tab}>
            <CommAutoApplicationStep1
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
              changeTab={onTabChange}
              customerPolicyId={customerPolicyId}
            />
          </TabPanel>

          

          <TabPanel index={7} value={tab}>
            <CommAutoApplicationStep8
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setValues={setValues}
              saveLoading={saveLoading}
              changeTab={onTabChange}
            />
          </TabPanel> */}
        </form>
      </StandadCard>
    </PageLoader>
  );
};
