import React from "react";
import { IDatatableV2State } from "../hooks/useTableV2State";

interface IDataTableV2Config {
  tableConfig?: {
    stickyHeader?: boolean;
    stickyHeaderTableMaxHeight?: string;
  };
  cachingFilters?: {
    enableCaching: boolean;
  };
}

interface IDataTableV2Context extends IDataTableV2Config {
  getCachedDataByKey: (uniqueKey: string) => void;
  saveDataInCache: (uniqueKey: string, updatedData: any) => void;
}

const DataTableContext = React.createContext<IDataTableV2Context>({
  tableConfig: {},
  getCachedDataByKey: () => {},
  saveDataInCache: () => {},
});

export interface IDataTableV2CachedDataState {
  [key: string]: any;
}

export const useDataTableV2Context = () => React.useContext(DataTableContext);

export const DataTableV2Provider: React.FC<
  { children?: React.ReactNode } & IDataTableV2Config
> = (props) => {
  const { cachingFilters = { enableCaching: false }, tableConfig } = props;
  const { enableCaching } = cachingFilters;

  const [cachingDataState, setCachingDataState] = React.useState<IDataTableV2CachedDataState>({});

  const getCachedDataByKey = (uniqueKey: string) => {
    if(cachingDataState[uniqueKey]){
      return cachingDataState[uniqueKey];
    }
    return null;
  };

  const saveDataInCache = (uniqueKey: string, updatedData: any) => {
   if(enableCaching){
    const cachedData = localStorage.getItem("tablePreferences");
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const finalData = {...parsedData};
      finalData[uniqueKey]  = updatedData;
      setCachingDataState(finalData);
     localStorage.setItem("tablePreferences", JSON.stringify(finalData));
    }
    else {
      const finalData: any = {};
      finalData[uniqueKey]  = updatedData;
      setCachingDataState(finalData);
     localStorage.setItem("tablePreferences", JSON.stringify(finalData));
    }
   }
  }

  React.useEffect(() => {
    if (enableCaching) {
      const cachedData = localStorage.getItem("tablePreferences");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setCachingDataState(parsedData);
      }
    }
  }, []);

  return (
    <DataTableContext.Provider
      value={{
        tableConfig: tableConfig,
        getCachedDataByKey: getCachedDataByKey,
        saveDataInCache
      }}
    >
      {props.children}
    </DataTableContext.Provider>
  );
};
