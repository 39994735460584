import { action } from "typesafe-actions";

import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { ICommAutoApplicationList, ICommAutomobileApplication, ICommAutomobileGarageApplicationData, ICommAutomobileSupplementaryForm } from "./commAutoAllApplications.types";
import { uploadFile } from "../../../helpers";

export const FETCH_COMM_AUTO_ALL_APPLICATION_LIST_PROGRESS =
  "FETCH_COMM_AUTO_ALL_APPLICATION_LIST_PROGRESS";
export const FETCH_COMM_AUTOALL__APPLICATION_LIST_SUCCESS =
  "FETCH_COMM_AUTOALL__APPLICATION_LIST_SUCCESS";
export const FETCH_COMM_AUTALL_O_APPLICATION_LIST_FAILED =
  "FETCH_COMM_AUTALL_O_APPLICATION_LIST_FAILED";

export const fetchCommAutoAllApplicationsListProgress = () =>
  action(FETCH_COMM_AUTO_ALL_APPLICATION_LIST_PROGRESS);
export const fetchCommAutoAllApplicationsListSuccess = (
  data: ICommAutoApplicationList[],
  totalRecords: number
) =>
  action(FETCH_COMM_AUTOALL__APPLICATION_LIST_SUCCESS, { data, totalRecords });
export const fetchCommAutoAllApplicationsListFailed = () =>
  action(FETCH_COMM_AUTALL_O_APPLICATION_LIST_FAILED);

export const fetchCommAutoAllApplicationsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutoAllApplicationsListProgress());
      const url = `/policy/get-policy-commauto-all-application?customer_policy_id=${customerPolicyId}&application_type=${type}&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: ICommAutoApplicationList[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchCommAutoAllApplicationsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCommAutoAllApplicationsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Application On

export const FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS =
  "FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS";
export const FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED";

export const fetchCommAutomobileApplicationProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS);
export const fetchCommAutomobileApplicationSuccess = (
  data: ICommAutomobileApplication
) => action(FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS, { data });
export const fetchCommAutomobileApplicationFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED, { errorMessage });

export const fetchCommAutomobileApplicationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileApplicationProgress());
      const res = await api.get(
        `/policy/get-commauto-automobile-application?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCommAutomobileApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchCommAutomobileApplicationFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileApplicationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCommAutomobileApllication =
  (
    data: ICommAutomobileApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-commauto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// Garage On

export const FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS =
  "FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS";
export const FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED =
  "FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED";

export const fetchCommAutomobileGarageApplicationProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS);
export const fetchCommAutomobileGarageApplicationSuccess = (
  data: ICommAutomobileGarageApplicationData
) => action(FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS, { data });
export const fetchCommAutomobileGarageApplicationFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED, { errorMessage });

export const fetchCommAutomobileGarageApplicationAsync =
  (
    customerPolicyId: number,
    garageNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileGarageApplicationProgress());
      const res = await api.get(
        `/policy/get-commauto-garage-application?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${garageNumber}`
      );
      const data: ICommAutomobileGarageApplicationData[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCommAutomobileGarageApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchCommAutomobileGarageApplicationFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileGarageApplicationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addCommAutomobileGarageApplication =
  (
    data: ICommAutomobileGarageApplicationData,
    customerID: number,
    signatureFile: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "COMM_AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Comm-Auto Garage Application",
      };

      const path = await uploadFile(
        signatureFile,
        "POLICY",
        data.signature_of_broker || "",
        asPayload
      );
      await api.post("/policy/edit-commauto-garage-application", {
        ...data,
        signature_of_broker: path,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Garage Application  saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileGarageApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// supplementary form

export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED =
  "FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED";

export const fetchCommAutomobileSupplementaryFormProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS);
export const fetchCommAutomobileSupplementaryFormSuccess = (
  data: ICommAutomobileSupplementaryForm
) => action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS, { data });
export const fetchCommAutomobileSupplementaryFormFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED, { errorMessage });

export const addCommAutomobileSupplementaryFormAsync =
  (
    data: ICommAutomobileSupplementaryForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-garage-automobile-supplementary-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileSupplementaryFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileSupplementaryFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileSupplementaryForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileSupplementaryFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileSupplementaryFormFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      // onCallback();
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    // onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_automobile_supplementary_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      // onCallback();
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTO_ALL_APPLICATION_STATE =
  "CLEAR_COMM_AUTO_ALL_APPLICATION_STATE";
export const clearCommAutoAllApplicationState = () =>
  action(CLEAR_COMM_AUTO_ALL_APPLICATION_STATE);
