import produce from "immer";
import { IStoreState } from "../../initialStoreState";

import { LoadState } from "../../../constants/enums";
import { CommAutomobileAllApplicationActions } from ".";
import { initialCommAutoAllApplicationState } from "./commAutoAllApplications.types";
import {
  CLEAR_COMM_AUTO_ALL_APPLICATION_STATE,
  FETCH_COMM_AUTALL_O_APPLICATION_LIST_FAILED,
  FETCH_COMM_AUTO_ALL_APPLICATION_LIST_PROGRESS,
  FETCH_COMM_AUTOALL__APPLICATION_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED,
  FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS,
  FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED,
  FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS,
} from "./commAutoAllApplicationsActions";

export const commAutoAllApplicationReducer = (
  state: IStoreState["commAuto"]["allApplications"] = initialCommAutoAllApplicationState,
  action: CommAutomobileAllApplicationActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTO_ALL_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.applicationsList.loading = LoadState.InProgress;
        draftState.applicationsList.list = [];
        draftState.applicationsList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_COMM_AUTOALL__APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.applicationsList.loading = LoadState.Loaded;
        draftState.applicationsList.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTALL_O_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.applicationsList.loading = LoadState.Failed;
        draftState.applicationsList.list = [];
      });
      return newState;
    }
    // Application on
    case FETCH_COMM_AUTOMOBILE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.application_on.loading = LoadState.InProgress;
        draftState.application_on.data =
          initialCommAutoAllApplicationState["application_on"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.application_on.loading = LoadState.Loaded;
        draftState.application_on.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.application_on.loading = LoadState.Failed;
        draftState.application_on.data =
          initialCommAutoAllApplicationState["application_on"]["data"];
      });
      return newState;
    }
    // garage on
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.garage_on.loading = LoadState.InProgress;
        draftState.garage_on.data =
          initialCommAutoAllApplicationState["garage_on"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.garage_on.loading = LoadState.Loaded;
        draftState.garage_on.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_GARAGE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.garage_on.loading = LoadState.Failed;
        draftState.garage_on.data =
          initialCommAutoAllApplicationState["garage_on"]["data"];
      });
      return newState;
    }
    // supplementary form
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.InProgress;
        draftState.supplementaryform.data =
          initialCommAutoAllApplicationState["supplementaryform"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.Loaded;
        draftState.supplementaryform.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.supplementaryform.loading = LoadState.Failed;
        draftState.supplementaryform.data =
          initialCommAutoAllApplicationState["supplementaryform"]["data"];
        draftState.supplementaryform.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_COMM_AUTO_ALL_APPLICATION_STATE: {
      return initialCommAutoAllApplicationState
    }

    default:
      return state;
  }
};
