import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { makeApiCall } from "../../helpers/postRequest";
import { QuoteDefaultCoverage } from "./quoteSettings.types";

export const FETCH_QUOTE_DEFAULT_COVERAGE_PROGRESS =
  "FETCH_QUOTE_DEFAULT_COVERAGE_PROGRESS";
export const FETCH_QUOTE_DEFAULT_COVERAGE_SUCCESS =
  "FETCH_QUOTE_DEFAULT_COVERAGE_SUCCESS";
export const FETCH_QUOTE_DEFAULT_COVERAGE_FAILURE =
  "FETCH_QUOTE_DEFAULT_COVERAGE_FAILURE";

export const fetchQuotedefaultCoverageProgress = () =>
  action(FETCH_QUOTE_DEFAULT_COVERAGE_PROGRESS);

export const fetchQuotedefaultCoverageSuccess = (data: QuoteDefaultCoverage) =>
  action(FETCH_QUOTE_DEFAULT_COVERAGE_SUCCESS, { data });

export const fetchQuotedefaultCoverageFailed = (errorMessage: string) =>
  action(FETCH_QUOTE_DEFAULT_COVERAGE_FAILURE, { errorMessage });

export const fetchQuotedefaultCoverageAsync =
  (
    defaultCoverageCode: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuotedefaultCoverageProgress());

      const res = await api.get(
        `/policy/get-policy-auto-default-vcs-schedule-a?default_coverage_code=${defaultCoverageCode}`
      );
      const data: QuoteDefaultCoverage[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchQuotedefaultCoverageSuccess(data[0]));
      } else {
        dispatch(
          fetchQuotedefaultCoverageFailed(
            "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchQuotedefaultCoverageFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addQuotedefaultCoverageAysnc =
  (
    data: QuoteDefaultCoverage,
    onCallback: (isSuccess: boolean, response?: any[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await makeApiCall(
        {
          url: "/policy/edit-policy-auto-default-vcs-schedule-a",
          method: "POST",
        },
        {},
        {
          ...data,
        }
      );

      const response = res?.data.data || [];
      console.log("response", response);

      dispatch(
        showMessage({
          type: "success",
          message: "Information saved successfully.",
          displayAs: "snackbar",
        })
      );
      onCallback(true, response);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_QUOTE_DEFAULT_COVERAGE_LIST_PROGRESS =
  "FETCH_QUOTE_DEFAULT_COVERAGE_LIST_PROGRESS";
export const FETCH_QUOTE_DEFAULT_COVERAGE_LIST_SUCCESS =
  "FETCH_QUOTE_DEFAULT_COVERAGE_LIST_SUCCESS";
export const FETCH_QUOTE_DEFAULT_COVERAGE_LIST_FAILURE =
  "FETCH_QUOTE_DEFAULT_COVERAGE_LIST_FAILURE";

export const fetchQuotedefaultCoverageListProgress = () =>
  action(FETCH_QUOTE_DEFAULT_COVERAGE_LIST_PROGRESS);

export const fetchQuotedefaultCoverageListSuccess = (
  data: QuoteDefaultCoverage[],
  totalRecords: number
) => action(FETCH_QUOTE_DEFAULT_COVERAGE_LIST_SUCCESS, { data, totalRecords });

export const fetchQuotedefaultCoverageListFailed = (errorMessage: string) =>
  action(FETCH_QUOTE_DEFAULT_COVERAGE_LIST_FAILURE, { errorMessage });

export const fetchQuotedefaultCoverageListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuotedefaultCoverageListProgress());
      let finalUrl = `/policy/get-policy-auto-default-vcs-schedule-a?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl += `&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: QuoteDefaultCoverage[] = res.data.data;
      dispatch(
        fetchQuotedefaultCoverageListSuccess(data, res.data.totalRecords)
      );
    } catch (err: any) {
      dispatch(
        fetchQuotedefaultCoverageListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const FETCH_QUOTE_DEFAULT_COVERAGES_LIST_PROGRESS =
  "FETCH_QUOTE_DEFAULT_COVERAGES_LIST_PROGRESS";
export const FETCH_QUOTE_DEFAULT_COVERAGES_LIST_SUCCESS =
  "FETCH_QUOTE_DEFAULT_COVERAGES_LIST_SUCCESS";
export const FETCH_QUOTE_DEFAULT_COVERAGES_LIST_FAILURE =
  "FETCH_QUOTE_DEFAULT_COVERAGES_LIST_FAILURE";

export const fetchQuotedefaultCoveragesListProgress = () =>
  action(FETCH_QUOTE_DEFAULT_COVERAGES_LIST_PROGRESS);

export const fetchQuotedefaultCoveragesListSuccess = (
  data: QuoteDefaultCoverage[],
  totalRecords: number
) => action(FETCH_QUOTE_DEFAULT_COVERAGES_LIST_SUCCESS, { data, totalRecords });

export const fetchQuotedefaultCoveragesListFailed = (errorMessage: string) =>
  action(FETCH_QUOTE_DEFAULT_COVERAGES_LIST_FAILURE, { errorMessage });

export const fetchQuotedefaultCoverageList =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuotedefaultCoveragesListProgress());
      let finalUrl = `/policy/get-policy-auto-default-vcs-schedule-a`;

      const res = await api.get(finalUrl);
      const data: QuoteDefaultCoverage[] = res.data.data;
      dispatch(
        fetchQuotedefaultCoveragesListSuccess(data, res.data.totalRecords)
      );
    } catch (err: any) {
      dispatch(
        fetchQuotedefaultCoveragesListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_QUOTE_DEFAULT_COVERAGE = "CLEAR_QUOTE_DEFAULT_COVERAGE";
export const clearQuotedefaultCoverage = () =>
  action(CLEAR_QUOTE_DEFAULT_COVERAGE);
