import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";



export const OtherUserLogin: React.FC = () => {
    const location = useLocation();
    const key = new URLSearchParams(location.search).get("token");
    const userId = new URLSearchParams(location.search).get("user_fact_id");
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);
    const navigate = useNavigate();
    const { otherUserLogin} = useAuth();
  
    React.useEffect(() => {
      const fetchDetails = async (token: string) => {
        setLoading(true);
        otherUserLogin(token,userId as any, (isSuccess, error) => {
          setLoading(false)
          if (!isSuccess && error) {
            setError(error);
          } else {
            navigate("/dashboard");
          }
        });
      };
      if (key) {
    
        fetchDetails(key);
      }
    }, []);

    if(loading){
        return <>Loading....</>
    }

    return(
        <></>
    )
}