import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyUnderLying } from "./businessPolicyUnderLyingInsurance.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_UNDERLYING_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_UNDERLYING_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_UNDERLYING_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_UNDERLYING_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_UNDERLYING_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_UNDERLYING_LIST_FAILED";

export const fetchBusinessPolicyUnderLyingListProgress = () =>
  action(FETCH_BUSINESS_POLICY_UNDERLYING_LIST_PROGRESS);
export const fetchBusinessPolicyUnderLyingListSuccess = (
  data: IBusinessPolicyUnderLying[]
) => action(FETCH_BUSINESS_POLICY_UNDERLYING_LIST_SUCCESS, { data });
export const fetchBusinessPolicyUnderLyingListFailed = () =>
  action(FETCH_BUSINESS_POLICY_UNDERLYING_LIST_FAILED);

export const fetchBusinessPolicyUnderLyingListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyUnderLyingListProgress());

      let finalUrl = `/policy/get-policy-business-underlying-insurance?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyUnderLying[] = res.data.data;
      dispatch(fetchBusinessPolicyUnderLyingListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyUnderLyingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_UNDERLYING_PROGRESS =
  "FETCH_BUSINESS_POLICY_UNDERLYING_PROGRESS";
export const FETCH_BUSINESS_POLICY_UNDERLYING_SUCCESS =
  "FETCH_BUSINESS_POLICY_UNDERLYING_SUCCESS";
export const FETCH_BUSINESS_POLICY_UNDERLYING_FAILED =
  "FETCH_BUSINESS_POLICY_UNDERLYING_FAILED";

export const fetchBusinessPolicyUnderLyingProgress = () =>
  action(FETCH_BUSINESS_POLICY_UNDERLYING_PROGRESS);
export const fetchBusinessPolicyUnderLyingSuccess = (
  data: IBusinessPolicyUnderLying
) => action(FETCH_BUSINESS_POLICY_UNDERLYING_SUCCESS, { data });
export const fetchBusinessPolicyUnderLyingFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_UNDERLYING_FAILED, { errorMessage });

export const fetchBusinessPolicyUnderLyingAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyUnderLyingProgress());
      const res = await api.get(
        `/policy/get-policy-business-underlying-insurance?customer_policy_id=${customerPolicyId}&value=${incrementNumber}`
      );
      const data: IBusinessPolicyUnderLying[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyUnderLyingSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyUnderLyingFailed(
            "Oops! We couldn't find any records associated with your number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyUnderLyingFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyUnderLyingAsync =
  (
    data: IBusinessPolicyUnderLying,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-underlying-insurance`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Fine art saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BUSINES_POLICY_UNDERLYING_STATE =
  "CLEAR_BUSINES_POLICY_UNDERLYING_STATE";
export const CLEAR_BUSINES_POLICY_UNDERLYING =
  "CLEAR_BUSINES_POLICY_UNDERLYING";

export const clearBusinessUnderLyingState = () =>
  action(CLEAR_BUSINES_POLICY_UNDERLYING_STATE);

export const clearBusinessPolicyUnderLying = () =>
  action(CLEAR_BUSINES_POLICY_UNDERLYING);
