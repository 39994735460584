import { Refresh } from "@mui/icons-material";
import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IDataTableV2RefreshPlugin } from "./DataTableV2Refresh.types";

export const DatatableV2Refresh: React.FC<IDataTableV2RefreshPlugin> = (
  props
) => {
  const { onClick } = props;
  const theme = useTheme();

  return (
    <Box width={"fit-content"}>
      <Tooltip title="Refresh Data">
        <Button
          variant="text"
          onClick={onClick}
          sx={{
            padding: "0px 0px",
            [theme.breakpoints.up("md")]: {
              padding: "2px 2px",
            },
            [theme.breakpoints.up("lg")]: {
              padding: "2px 4px",
            },
            [theme.breakpoints.up("xl")]: {
              padding: "6px 8px",
            },
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Refresh
              color="primary"
              sx={{
                fontSize: 16,
                [theme.breakpoints.up("md")]: {
                  fontSize: 18,
                },
                [theme.breakpoints.up("lg")]: {
                  fontSize: 23,
                },
                [theme.breakpoints.up("xl")]: {
                  fontSize: 25,
                },
              }}
            />
            <Typography
              variant="body1"
              fontSize={"1.2rem"}
              fontWeight={600}
              color="primary.main"
            >
              Refresh
            </Typography>{" "}
          </Stack>
        </Button>
      </Tooltip>
    </Box>
  );
};
