import { action } from "typesafe-actions";
import {
  IBusinessConsentForm,
  IBusinessGarageCertificate,
  IBusinessPolicyAuthorization,
  IBusinessPolicyForm,
  IBusinessPolicyLiability,
  IBusinessPropertyInsurance,
} from "./businessPolicyForms.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSIENSS_POLICY_FORMS_LIST_PROGRESS =
  "FETCH_BUSIENSS_POLICY_FORMS_LIST_PROGRESS";
export const FETCH_BUSIENSS_POLICY_FORMS_LIST_SUCCESS =
  "FETCH_BUSIENSS_POLICY_FORMS_LIST_SUCCESS";
export const FETCH_BUSIENSS_POLICY_FORMS_LIST_FAILED =
  "FETCH_BUSIENSS_POLICY_FORMS_LIST_FAILED";

export const fetchBusinessPolicyFormsListProgress = () =>
  action(FETCH_BUSIENSS_POLICY_FORMS_LIST_PROGRESS);
export const fetchBusinessPolicyFormsListSuccess = (
  data: IBusinessPolicyForm[],
  totalRecords: number
) => action(FETCH_BUSIENSS_POLICY_FORMS_LIST_SUCCESS, { data, totalRecords });
export const fetchBusinessPolicyFormsListFailed = () =>
  action(FETCH_BUSIENSS_POLICY_FORMS_LIST_FAILED);

export const fetchBusinessPolicyFormsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyFormsListProgress());
      const url = `/policy/get-policy-business-all-policy-forms?customer_policy_id=${customerPolicyId}&policy_form_type=${type}&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: IBusinessPolicyForm[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchBusinessPolicyFormsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyFormsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/** Liability Certificate */
export const FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS =
  "FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS";
export const FETCH_BUSINESS_POLICY_LIABILITY_FAILED =
  "FETCH_BUSINESS_POLICY_LIABILITY_FAILED";

export const fetchBusinessLiabilityProgress = () =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_PROGRESS);
export const fetchBusineLiabilitySuccess = (data: IBusinessPolicyLiability) =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_SUCCESS, { data });
export const fetchBusinessLiabilityfailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_LIABILITY_FAILED, { errorMessage });

export const fetchBusinessLiabilityAsync =
  (
    customerPolicyId: number,
    liabilityNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessLiabilityProgress());
      const res = await api.get(
        `/policy/get-policy-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${liabilityNumber}`
      );
      const data: IBusinessPolicyLiability[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusineLiabilitySuccess(data[0]));
      } else {
        dispatch(fetchBusinessLiabilityfailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchBusinessLiabilityfailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessLiabilityAsync =
  (
    data: IBusinessPolicyLiability,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          `/policy/edit-policy-add-liability-insurance-certificate`,
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          `/policy/edit-policy-add-liability-insurance-certificate`,
          data
        );
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Liability certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusineLiabilitySuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const downloadBusinessLiabilityListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,

  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
  
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const previewBusinessLiabilityListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

/**Insurance Certificate */
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS";
export const FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED =
  "FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED";

export const fetchBusinessPropertyInsuranceProgress = () =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_PROGRESS);
export const fetchBusinePropertyInsuranceSuccess = (
  data: IBusinessPropertyInsurance
) => action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_SUCCESS, { data });
export const fetchBusinessPropertyInsurancefailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_PROPERTY_INSURANCE_FAILED, { errorMessage });

export const fetchBusinessPropertyInsuranceAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPropertyInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-add-property-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${serialNumber}`
      );
      const data: IBusinessPropertyInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinePropertyInsuranceSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPropertyInsurancefailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPropertyInsurancefailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessPropertyInsuranceAsync =
  (
    data: IBusinessPropertyInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          `/policy/edit-policy-add-property-insurance-certificate`,
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          `/policy/edit-policy-add-property-insurance-certificate`,
          { ...data }
        );
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Property insurance certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusinePropertyInsuranceSuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const downloadBusinessPropertyInsuranceListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-property-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
    
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewBusinessPropertyInsuranceListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-property-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_property_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

/**Garage Certificate */

export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS";
export const FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED =
  "FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED";

export const fetchBusinessGarageCertiicateProgress = () =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_PROGRESS);
export const fetchBusineGarageCertiicateSuccess = (
  data: IBusinessGarageCertificate
) => action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_SUCCESS, { data });
export const fetchBusinessGarageCertiicatefailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_GARAGE_CERTIFICATE_FAILED, { errorMessage });

export const fetchBusinessGarageCertiicateAsync =
  (
    customerPolicyId: number,
    garageNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessGarageCertiicateProgress());
      const res = await api.get(
        `/policy/get-policy-add-garage-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${garageNumber}`
      );
      const data: IBusinessGarageCertificate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusineGarageCertiicateSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessGarageCertiicatefailed(ERROR_MESSAGES.NO_RECORD_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessGarageCertiicatefailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinessGarageCertiicateAsync =
  (
    data: IBusinessGarageCertificate,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(`/policy/edit-policy-add-garage-insurance-certificate`, {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post(`/policy/edit-policy-add-garage-insurance-certificate`, {
          ...data,
        });
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Property insurance certificate saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchBusineGarageCertiicateSuccess(data));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };



export const downloadBusinessGarageCertificateListPDFAsync =
(
  customerPolicyId: number,
  incremental_number: number,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(saveLoaderProgress());
    const res = await api.get(
      `/policy/get-policy-garage-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
      {
        responseType: "arraybuffer",
      }
    );

    const pdfBuffer = res.data;

    const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "downloaded.pdf";
    link.click();

    URL.revokeObjectURL(pdfUrl);
  } catch (err: any) {
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
  finally {
    dispatch(saveLoaderCompleted());
  }
};

export const previewBusinessGarageCertificateListPDFAsync =
(
  customerPolicyId: number,
  incremental_number: number,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(saveLoaderProgress());
    const res = await api.get(
      `/policy/get-policy-garage-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_insurance_certificate_preview=true`
    );

    for (const key in res.data.data) {
      const newTab = window.open("", "_blank");
      if (newTab) {
        newTab.document.write(res.data.data[key]);
        newTab.document.close();
      }
    }
  } catch (err: any) {
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
  finally {
    dispatch(saveLoaderCompleted());
  }
};


/**Payment Authorization*/
export const addBusinesPolicyAuthorizationAsync =
  (
    data: IBusinessPolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      //dispatch(fetchBusinessPolicyRiskProgress());
      await api.post(`/policy/edit-policy-business-pre-authorization-form`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS";
export const FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED =
  "FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED";

export const fetchBusinessPolicyAuthorizationProgress = () =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_PROGRESS);
export const fetchBusinessPolicyAuthorizationSuccess = (
  data: IBusinessPolicyAuthorization
) => action(FETCH_BUSINESS_POLICY_AUTHORIZATION_SUCCESS, { data });
export const fetchBusinessPolicyAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_AUTHORIZATION_FAILED, { errorMessage });

export const fetchBusinessPolicyAuthorizationAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-business-pre-authorization-form?customer_policy_id=${customerPolicyId}&value=${incrementNumber}&column=incremental_number`
      );
      const data: IBusinessPolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyAuthorizationSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyAuthorizationFailed(
            "Oops! We couldn't find any records associated with your number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };



export const downloadCommPolicyAuthorizationListPDFAsync =
(
  customerPolicyId: number,
  incremental_number: number,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(saveLoaderProgress());
    const res = await api.get(
      `/policy/get-policy-business-pre-authorization-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
      {
        responseType: "arraybuffer",
      }
    );

    const pdfBuffer = res.data;

    const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "downloaded.pdf";
    link.click();

    URL.revokeObjectURL(pdfUrl);
  } catch (err: any) {
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
  finally {
    dispatch(saveLoaderCompleted());
  }
};

export const previewBusinessPolicyAuthorizationListPDFAsync =
(
  customerPolicyId: number,
  incremental_number: number,
): ThunkAction<void, IStoreState, {}, AnyAction> =>
async (dispatch, getState) => {
  try {
    dispatch(saveLoaderProgress());
    const res = await api.get(
      `/policy/get-policy-business-pre-authorization-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_pre_authorization_form_preview=true`
    );

    for (const key in res.data.data) {
      const newTab = window.open("", "_blank");
      if (newTab) {
        newTab.document.write(res.data.data[key]);
        newTab.document.close();
      }
    }
  } catch (err: any) {
    dispatch(
      showMessage({
        type: "error",
        message: err.response.data.message,
        displayAs: "snackbar",
      })
    );
  }
  finally {
    dispatch(saveLoaderCompleted());
  }
};

/** Privacy Consent*/
export const FETCH_BUSINESS_CONSENT_FORM_PROGRESS =
  "FETCH_BUSINESS_CONSENT_FORM_PROGRESS";
export const FETCH_BUSINESS_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_BUSINESS_CONSENT_FORM_FAILED =
  "FETCH_BUSINESS_CONSENT_FORM_FAILED";

export const fetchBusinessConsentFormProgress = () =>
  action(FETCH_BUSINESS_CONSENT_FORM_PROGRESS);
export const fetchBusinessConsentFormSuccess = (data: IBusinessConsentForm) =>
  action(FETCH_BUSINESS_CONSENT_FORM_SUCCESS, { data });
export const fetchBusinessConsentFormFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_CONSENT_FORM_FAILED, { errorMessage });

export const addBusinessConsentFormAsync =
  (
    data: IBusinessConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-privacy-consent-form", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchBusinessConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IBusinessConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchBusinessConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchBusinessConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const downloadBusinessConsentFormPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      // onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
          {
            responseType: "arraybuffer",
          }
        );
  
        const pdfBuffer = res.data;
  
        const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
  
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "downloaded.pdf";
        link.click();
  
        URL.revokeObjectURL(pdfUrl);
        // onCallback();
      } catch (err: any) {
        fetchBusinessConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR)
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  
  export const previewBusinessConsentFormPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      // onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
        );
  
        for (const key in res.data.data) {
          const newTab = window.open("", "_blank");
          if (newTab) {
            newTab.document.write(res.data.data[key]);
            newTab.document.close();
          }
        }
        // onCallback();
      } catch (err: any) {
        fetchBusinessConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR)
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  

export const CLEAR_BUSINESS_POLICY_FORMS_STATE =
  "CLEAR_BUSINESS_POLICY_FORMS_STATE";
export const clearBusinessPolicyFormsState = () =>
  action(CLEAR_BUSINESS_POLICY_FORMS_STATE);
