import { LoadState } from "../../constants/enums";

export interface IControlLogState {
  list: IControlLog[];
  loading: LoadState;
  totalRecords: number;
  data: IControlLog;
  error: string | null;
}

export interface IControlLog {
  control_id: string;
  customer_id: string;
  customer_policy_id: string | null;
  senders_reference_information: string | null;
  subject: string | null;
  in_reply_to: string | null;
  policy_number: string | null;
  csio_edi_policy_control_log_code: string | null;
  maximum_line_number_used: string | null;
  maximum_number_of_characters_or_line: string | null;


  details: string | null;
  transaction_details: string | null;
  message_details: string | null; 
  message_guid: string | null; 
  communication_details: string | null;
  status: string;
}

export const initialControlLogState: IControlLogState = {
  list: [],
  loading: LoadState.NotLoaded,
  totalRecords: 0,
  data: {
    control_id: "",
    customer_id: "",
    customer_policy_id: "",
    details: "",
    maximum_line_number_used: "",
    maximum_number_of_characters_or_line: "",
    senders_reference_information: "",
    subject: "",
    in_reply_to: "",
    policy_number: "",
    transaction_details: "",
    message_details: "",
    message_guid: "",
    csio_edi_policy_control_log_code: "",
    communication_details: "",
    status: "ACTIVE",
  },
  error: null,
};
