import React from "react";
import { Box, Button, Grid, MenuItem, Stack, Typography } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../../components/formsComponents";
import { Dialog } from "../../../../../../../../components/Dialogs/Dialog";
import { useFormik } from "formik";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { useDispatchWrapper } from "../../../../../../../../hooks";
import { IAdditionalInsuredProps } from "./AdditionalInsured.types";
import { upsertAutomobileAdditionalInsuredAsync } from "../../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { CustomDatePicker } from "../../../../../../../../components/formsComponents/CustomDatePicker";
import {
  COMMON_STATUS,
  gender,
  InsuredOrPrincipal,
} from "../../../../../../../../constants/constants";
import { ExtendableSelect } from "../../../../../../../../components/formsComponents/ExtendableSelect";
import { AddressSearchAutoComplete } from "../../../../../../../../components/AddressAutoComplete/AddressSearchAutoComplete";

export const AdditionalInsuredForm: React.FC<IAdditionalInsuredProps> = (
  props
) => {
  const { open, insured, onClose, onComplete } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [addressDialogOpen, setAddressDialogOpen] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: insured,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertAutomobileAdditionalInsuredAsync(values, (isSuccess) => {
          if (isSuccess) {
            onComplete();
          }
          setSaveLoading(false);
        })
      );
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });

    setAddressDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        title="Create/Edit Contact"
        onClose={onClose}
        size="md"
        contentWrappedWithForm={{ onSubmit: handleSubmit }}
        actions={[
          { type: "button", label: "Close", variant: "text", onClick: onClose },
          {
            type: "submit",
            label: "Save",
            variant: "contained",
            disabled: saveLoading,
          },
        ]}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Type</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.type}
              options={InsuredOrPrincipal.map((template) => {
                return { label: template, value: template };
              })}
              onChange={(newValue) => {
                setFieldValue("type", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomFormLabel>Customer Name</CustomFormLabel>
            <CustomTextField
              name="customer_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.customer_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <AddressSearchAutoComplete
              address={values.address}
              onAddressChange={handleAddress}
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <CustomFormLabel>Unit/Suite</CustomFormLabel>
            <CustomTextField
              name="unit_or_suite"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.unit_or_suite}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomFormLabel>City</CustomFormLabel>
            <CustomTextField
              name="city"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.city}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomFormLabel>State/Province</CustomFormLabel>
            <CustomTextField
              name="province_or_state"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.province_or_state}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Postal Code</CustomFormLabel>
            <CustomTextField
              name="postal_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.postal_code}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Country</CustomFormLabel>
            <CustomTextField
              name="country"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Phone</CustomFormLabel>
            <CustomTextField
              name="phone_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.phone_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Mobile</CustomFormLabel>
            <CustomTextField
              name="mobile_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.mobile_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Gender</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.gender}
              name="gender"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={gender}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>DOB</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.birth_date || ""}
              onChange={(newValue) => {
                setFieldValue("birth_date", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Marital Status</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.marital_status}
              name="marital_status"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={[
                "Single",
                "Married",
                "Common-Law",
                "Divorced",
                "Widowed",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.status}
              name="status"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={COMMON_STATUS}
            />
          </Grid>
        </Grid>
      </Dialog>
      {/* <Dialog
        open={addressDialogOpen}
        title="Search Address"
        onClose={() => setAddressDialogOpen(false)}
        size="md"
        actions={[
          {
            type: "button",
            label: "Close",
            variant: "text",
            onClick: () => setAddressDialogOpen(false),
          },
        ]}
        // PaperProps={{
        //   style: {
        //     height: '400px', // Set your desired height here
        //   },
        // }}
      >
        <Grid item xs={12} md={3.5} marginBottom={30}>
          <CustomFormLabel>Enter Address</CustomFormLabel>
          <LocationAutoComplete
            id="address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address}
            onLocationChange={handleAddress}
          />
        </Grid>
      </Dialog> */}
    </>
  );
};
