import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ICommAutoAllApplicationState {
  applicationsList: {
    list: ICommAutoApplicationList[];
    loading: LoadState;
    totalRecords: number;
  };
  application_on: {
    data: ICommAutomobileApplication;
    loading: LoadState;
    error: string | null;
  };
  garage_on: {
    data: ICommAutomobileGarageApplicationData;
    loading: LoadState;
    error: string | null;
  };
  supplementaryform: {
    data: ICommAutomobileSupplementaryForm,
    loading: LoadState;
    error: string | null;
  }
}

//Garage Supplement
export interface ICommAutomobileuseColumn {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
}
export interface ICommAutomobileuseColumn4 {
  location_a: string | null;
  location_b: string | null;
  location_c: string | null;
  location_d: string | null;
  total: string | null;
}
export interface ICommAutomobileuseColumn3 {
  location_a_yes_or_no: string | null;
  location_b_yes_or_no: string | null;
  location_c_yes_or_no: string | null;
  location_d_yes_or_no: string | null;
  comments_description: string | null;
}
export interface ICommAutomobileuseColumn2 {
  location_a_ft: string | null;
  location_a_pt: string | null;
  location_b_ft: string | null;
  location_b_pt: string | null;
  location_c_ft: string | null;
  location_c_pt: string | null;
  location_d_ft: string | null;
  location_d_pt: string | null;
}

export interface ICommAutomobileSupplementaryForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  broker: string | null;
  effective_date: string | null;
  section_a: {
    in_business_since: ICommAutomobileuseColumn;
    sales_of_new_vehicles: ICommAutomobileuseColumn;
    sales_of_used_vehicles: ICommAutomobileuseColumn;
    leasing_of_new_or_used_vehicles: ICommAutomobileuseColumn;
    body_shop: ICommAutomobileuseColumn;
    body_shop_including_car_sales: ICommAutomobileuseColumn;
    repair_garage: ICommAutomobileuseColumn;
    repair_garage_including_car_sales: ICommAutomobileuseColumn;
    service_station: ICommAutomobileuseColumn;
    service_station_self_serve: ICommAutomobileuseColumn;
    parking_lot: ICommAutomobileuseColumn;
    parking_lot_self_park: ICommAutomobileuseColumn;
    storage_garage: ICommAutomobileuseColumn;
    storage_garage_self_store: ICommAutomobileuseColumn;
    valet_parking: ICommAutomobileuseColumn;
    valet_parking_fee_charged: ICommAutomobileuseColumn;
    car_wash: ICommAutomobileuseColumn;
    car_wash_automatic: ICommAutomobileuseColumn;
    other_detail_remarks: string | null;
  };

  private_passenger_checkbox: string | null;
  light_commercial_checkbox: string | null;
  heavy_commercial_checkbox: string | null;
  trailers_checkbox: string | null;
  commercial_trailers_checkbox: string | null;
  antique_or_classic_checkbox: string | null;
  exotic_racing_checkbox: string | null;
  motorcycles_checkbox: string | null;
  atv_checkbox: string | null;
  snowmobiles_checkbox: string | null;
  motorhomes_checkbox: string | null;
  other_checkbox: string | null;
  other_value: string | null;

  proprietors: ICommAutomobileuseColumn2;
  sales_staff: ICommAutomobileuseColumn2;
  mechanics: ICommAutomobileuseColumn2;
  lot_persons: ICommAutomobileuseColumn2;
  body_shop_workers: ICommAutomobileuseColumn2;
  full_service_station_attendants: ICommAutomobileuseColumn2;
  clerical: ICommAutomobileuseColumn2;
  parts_department_staff: ICommAutomobileuseColumn2;
  parking_lot_attendant: ICommAutomobileuseColumn2;
  valet_parking: ICommAutomobileuseColumn2;
  summary_of_employees_other_details: string | null;

  employee_information_including_owners: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    position: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    full_or_part_time: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  other_operators: {
    key: string;
    name: string | null;
    drivers_licence_number: string | null;
    birth_date: string;
    relationship_to_the_named_insured: string | null;
    years_lincence: string | null;
    licence_class: string | null;
    convictions_or_suspensions_for_the_past_six_years: string | null;
  }[];

  claims: {
    key: string;
    driver_name: string | null;
    date_of_loss: string;
    type_of_loss: string | null;
    amount_paid: string | null;
    descriptiom: string | null;
  }[];
  are_all_active_vehicles_owned_by_yes_or_no: string | null;
  are_all_active_vehicles_owned_by_no_reason: string | null;
  number_of_dealer_plates: ICommAutomobileuseColumn4;
  number_of_service_plates: ICommAutomobileuseColumn4;
  total_number_of_white_plates: ICommAutomobileuseColumn4;
  dealer_plate_numbers: string | null;
  service_plate_numbers: string | null;
  private_passenger: ICommAutomobileuseColumn4;
  commercial_tow_trucks: ICommAutomobileuseColumn4;
  commercial_tilt_and_load: ICommAutomobileuseColumn4;
  commercial_parts_truck: ICommAutomobileuseColumn4;
  commercial_service_trucks: ICommAutomobileuseColumn4;
  commercial_shuttle_vehicles: ICommAutomobileuseColumn4;
  commercial_other: ICommAutomobileuseColumn4;
  courtesy_cars: ICommAutomobileuseColumn4;
  summary_of_all_active_vehicles_other_details: string | null;
  details_of_all_vehicles_owned_by_the_applicant: {
    key: string;
    year: string | null;
    make_model_body_type: string | null;
    vin: string | null;
    gvw: string | null;
    list_price_new: string | null;
    use: string | null;
    driver_principal_and_occasional: string | null;
  }[];
  owned_automobiles_including_vehicles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  customer_automobiles: {
    maximum_number_in_building_location_a: string | null;
    maximum_number_in_building_location_b: string | null;
    maximum_number_in_building_location_c: string | null;
    maximum_number_in_building_location_d: string | null;
    maximum_number_on_lot_location_a: string | null;
    maximum_number_on_lot_location_b: string | null;
    maximum_number_on_lot_location_c: string | null;
    maximum_number_on_lot_location_d: string | null;
    maximum_value_location_a: string | null;
    maximum_value_location_b: string | null;
    maximum_value_location_c: string | null;
    maximum_value_location_d: string | null;
    average_value_location_a: string | null;
    average_value_location_b: string | null;
    average_value_location_c: string | null;
    average_value_location_d: string | null;
  };
  during_business_hours: string | null;
  after_business_hours: string | null;
  are_driving_record_abstracts_obtained_yes_or_no: string | null;
  how_often: string | null;

  outside_floodlights: ICommAutomobileuseColumn3;
  burglary_alarm_system: ICommAutomobileuseColumn3;
  fenced_compound: ICommAutomobileuseColumn3;
  guard_dogs: ICommAutomobileuseColumn3;
  night_watchman: ICommAutomobileuseColumn3;
  other_describe_below: ICommAutomobileuseColumn3;

  security_measures_other_details: string | null;
  does_the_applicant_pick_up_or_deliver_yes_or_no: string | null;
  does_the_applicant_pick_up_or_deliver_value: string | null;
  number_of_spray_booths: string | null;
  inspected_and_approved_yes_or_no: string | null;
  spraying: string | null;
  welding: string | null;
  does_the_applicant_dispense_propane_yes_or_no: string | null;
  number_of_licensed_employees: string | null;
  give_details_of_any_contractual_liability: string | null;
  what_is_the_maximum_number_of_automobiles: string | null;
  how_many_tow_trucks_can_carry: string | null;
  does_the_applicant_have_written_rules_yes_or_no: string | null;
  does_the_applicant_have_written_rules_value: string | null;
  is_demonstrator_use_restricted_yes_or_no: string | null;
  including_spouse_yes_or_no: string | null;
  including_children_yes_or_no: string | null;
  others: string | null;
  is_vacation_use_of_owned_vehicles_yes_or_no: string | null;
  are_customers_permitted_to_test_drive_yes_or_no: string | null;
  is_customer_always_accompanied_yes_or_no: string | null;
  describe_procedures_for_other_precautions_taken: string | null;
  if_sales_include_recreational_vehicles_yes_or_no: string | null;
  is_applicant_currently_registered_yes_or_no: string | null;
  where_and_how_are_vehicles_held: string | null;
  lien_checks_yes_or_no: string | null;
  consignment_yes_or_no: string | null;
  if_yes_what_policies_and_procedures: string | null;
  indicate_number_and_average_retail_values_sold: {
    third_year_back_number_sold: string | null;
    third_year_back_average_retail_value: string | null;
    second_year_back_number_sold: string | null;
    second_year_back_average_retail_value: string | null;
    expiring_year_number_sold: string | null;
    expiring_year_average_retail_value: string | null;
    expected_for_coming_year_number_sold: string | null;
    expected_for_coming_year_average_retail_value: string | null;
  };
  have_you_bound_this_risk_yes_or_no: string | null;
  is_this_business_new_to_you_yes_or_no: string | null;
  how_long_have_you_known_the_applicant: string | null;
  report_of_broker_other_details: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}


// Garage On
export interface ICommAutomobileGarageApplicationData {
  customer_policy_id: number;
  incremental_number: string | null;
  policy_type: string | null;
  replacing_policy_no: string | null;
  policy_number_assigned: string | null;
  language_preferred: string | null;
  insurance_company: string | null;
  broke_or_agent: string | null;
  applicant_full_name: string | null;
  applicant_full_address: string | null;
  applicant_email: string | null;
  applicant_phone: string | null;
  applicant_full_business_address_a: string | null;
  applicant_full_business_address_a_type: string;
  applicant_full_business_address_b: string | null;
  applicant_full_business_address_b_type: string | null;
  applicant_full_business_address_c: string | null;
  applicant_full_business_address_c_type: string | null;
  applicant_full_business_address_d: string | null;
  applicant_full_business_address_d_type: string | null;
  policy_period_effective_date: string;
  policy_period_effective_time: string;
  policy_period_expiry_date: string;
  policy_period_expiry_time: string;
  applicant_business_info: string | null;
  estimated_total_payroll_for_policy_period: string | null;
  no_of_employees_full_time: string | null;
  no_of_employees_part_time: string | null;
  coverage: string | null;
  policy_cancelled_in_last_3_years: string | null;
  policy_cancelled_no_of_insurer: string | null;
  policy_cancelled_policy_no: string | null;
  list_of_all_accidents_losses_or_claims:
    {
      key: string;
      Losses_date: string;
      bi: string | null;
      pd: string | null;
      ab: string | null;
      dc_pd: string | null;
      ua: string | null;
      coll: string | null;
      comp_sp: string | null;
    }[];
  insurer: string | null;
  policy_no: string | null;
  expiry_date: string | null;
  remarks: string | null;
  item_no: string | null;
  signature_of_broker: string | null
  status: string;
}
export interface ICommAutoApplicationList {
  incremental_number: number;
  policy_number: string;
  customer_name: string;
  customer_code: string;
  application_type: string;
  create_ts: string | null;
  created_by_name: number;
  status: string;
}
export interface ICommAutoDescribedSectionOne {
  key: string;
  autoNo: string | null;
  modelYear: string | null;
  makeTradeName: string | null;
  model: string | null;
  bodyType: string | null;
  noCylinders: string | null;
  grossVehicleWeight: string | null;
}
export interface ICommAutoDescribedSectionTwo {
  key: string;
  autoNo: string | null;
  vinNumber: string | null;
  owned: string | null;
  leased: string | null;
  purchaseLeasedDate: string | null;
  purchaseLeased: string | null;
  purchasePrice: string | null;
  automobileUsed: string | null;
  automobileUsedOneWay: string | null;
  automobileUsedPrecentage: string | null;
}
export interface ICommAutoDescribedSectionThree {
  key: string;
  autoNo: string | null;
  annualDrivingDistance: number | null;
  automobileUsedForPooling: string | null;
  automobileUsedForPoolingIfYes: string | null;
  typefFuelUsed: string | null;
  typefFuelUsedIfYes: string | null;
  unrepairedDamage: string | null;
  modifiedCustomized: string | null;
}
export interface ICommAutoDescribedSectionFour {
  key: string;
  autoNo: string;
  lienholderName: string;
  postalAddress: string;
}
export interface ICommAutoDriverInformationOne {
  key: string;
  driverNo: string | null;
  nameofDriver: string | null;
  driverLicenseNumber: string | null;
  dateOfBirth: string;
  sex: string;
  maritalStatus: string;
}
export interface ICommAutoDriverInformationTwo {
  key: string;
  driverNo: string | null;
  driverTrainingCertificateAttached: string | null;
  dateFirstLicencedInCanadaorUSClass: string | null;
  dateFirstLicencedInCanadaorUSYear: string | null;
  otherClassofLicenseIfAnyClass: string | null;
  otherClassofLicenseIfAnyYear: string | null;
  percentageUseByEachDriverAuto1: string | null;
  percentageUseByEachDriverAuto2: string | null;
  percentageUseByEachDriverAuto3: string | null;
  areAnyOtherPersonsInTheHousehold: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
  doAnyDriversQualifyForRetireeDiscount: {
    questionCd: string | null;
    yesNoCd: string | null;
  };
}
export interface ICommAutoPreviousClaims {
  key: string;
  driverNo: string | null;
  autoNo: string | null;
  date: string | null;
  coverageClaimPaidUnder: {
    bi: string | null;
    pd: string | null;
    Ab: string | null;
    dcpd: string | null;
    ua: string | null;
    col: string | null;
    comp: string | null;
  };
  amountPaidOrEstimate: string | null;
  details: string | null;
}
export interface ICommAutoHistory {
  key: string;
  driverNo: string | null;
  dateConvicted: string | null;
  details: string;
}
export interface ICommAutoRatingInformationOne {
  key: string;
  autoNo: string | null;
  class: string | null;
  drivingRecord: string | null;
  bi: string | null;
  pd: string | null;
  ab: string | null;
  dcpd: string | null;
  coll_ap: string | null;
  driverNoPrimary: string | null;
  driverNoSceondary: string | null;
  atFaultClaimSurcharges: string | null;
  atFaultClaimPrecentage: string | null;
  convictionsSurcharges: string | null;
  convictionsPrecentage: string | null;
}
export interface ICommAutoRatingInformationTwo {
  key: string;
  autoNo: string | null;
  listPriceView: string | null;
  vehicleCode: string | null;
  ratingGroupAb: string | null;
  ratingGroupDcpd: string | null;
  ratingGroupCollAp: string | null;
  ratingGroupcompSp: string | null;
  ratinratingGroupDcpd: string | null;
  location: string | null;
  territory: string | null;
  discountDescription: string | null;
  discountPrecentage: string | null;
}
export interface ICommAutomobilePremiumPaymentSchedule {
  id: string;
  installment_number: string;
  installment_amount: string;
  installment_date: string;
}
export interface ICommAutoInsuranceApplied {
  key: string;
  code: string;
  label: string;
  description: string;
  isTypeOther: boolean;
  automobiles: {
    [key: string]: {
      limit: string | null;
      premimum: string | null;
      deductible: string | null;
      isOnlyPremimum: boolean;
      
    };
  };
}
export interface ICommAutomobileApplication {
  customer_policy_id: number | null;
  incremental_number: number | null;
  policy_number_assigned: string | null;
  replacing_policy_no: string | null;
  new_policy: string | null;
  billing_method: string | null;
  language_preferred: string | null;
  policy_period_effective_date: string | null;
  policy_period_effective_time: string | null;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string | null;
  insurance: {
    insurance_name: string | null;
    insurance_address: string | null;
    insurance_unit_or_suite: string | null;
    insurance_city: string | null;
    insurance_state: string | null;
    insurance_country: string | null;
    insurance_zip: string | null;
  };
  broker: {
    broker_agent: string | null;
    broker_agent_address: string | null;
    broker_agent_unit_or_suite: string | null;
    broker_agent_city: string | null;
    broker_agent_state: string | null;
    broker_agent_country: string | null;
    broker_agent_zip: string | null;
    broker_code: string | null;
    broker_sub_code: string | null;
  };
  application_details: {
    name: string | null;
    address: string | null;
    address_two: string | null;
    country: string | null;
    city: string | null;
    state: string | null;
    postalcode: string | null;
    phone: string | null;
    mobile: string | null;
  };
  lessor: {
    lessor_name: string | null;
    lessor_full_address: string | null;
    lessor_phone: string | null;
    lessor_email: string | null;
  };
  described_automobiles: {
    described_automobiles_part_one: ICommAutoDescribedSectionOne[];
    described_automobiles_part_two: ICommAutoDescribedSectionTwo[];
    described_automobiles_part_three: ICommAutoDescribedSectionThree[];
    described_automobiles_part_four: ICommAutoDescribedSectionFour[];
    owner_actual_owner_yesNoCd: string | null;
    total_automobiles_in_business: string | null;
    rented_or_leased_to_other: string | null;
    carry_passengers_for_compensation: string | null;
    haul_a_trailer: string | null;
    carry_explosives: string | null;
  };
  driver_information: {
    section_one: ICommAutoDriverInformationOne[];
    section_two: ICommAutoDriverInformationTwo[];
    insurance_company: string | null;
    prior_policy_number: string | null;
    expiry_date: string | null;
    has_any_driver_license: string | null;
    has_any_insurance_company: string | null;
    during_the_last_three_years: string | null;
    has_the_applicant_or_any_listed_driver: string | null;
  };
  previous_accidents_and_insurance_claims: ICommAutoPreviousClaims[];
  history_of_convictions: ICommAutoHistory[];
  rating_information: {
    section_one: ICommAutoRatingInformationOne[];
    section_two: ICommAutoRatingInformationTwo[];
  };
  payment_reporter_of_broker: {
    type_of_payment_plan: string | null;
    estimated_policy_premium: string | null;
    provincial_sales_tax: string | null;
    interest_amount: string | null;
    total_estimated_cost: string | null;
    amount_paid_with_application: string | null;
    amount_due: string | null;
    no_of_remaining_installments: string | null;
    amount_of_each_installment: string | null;
    installment_due_date: string | null;
    have_you_bound_the_risk: string | null;
    is_this_business_new_to_you: string | null;
    type_of_motor_vehicle_liability_insurance_card: string | null;
    how_long_you_have_known_the_applicant: string | null;
    how_long_you_have_known_the_principal_driver: string | null;
    has_an_inspection_been_done: string | null;
  };
  payment_schedule: ICommAutomobilePremiumPaymentSchedule[];
  incurance_coverage_applied_for: {
    columns: string[];
    rows: ICommAutoInsuranceApplied[];
  };
  remarks: string | null;
  status: string;
}

export const initialCommAutoAllApplicationState: ICommAutoAllApplicationState =
  {
    applicationsList: {
      list: [],
      loading: LoadState.NotLoaded,
      totalRecords: 0,
    },
    application_on: {
      data: {
        customer_policy_id: null,
        incremental_number: null,
        policy_number_assigned: null,
        replacing_policy_no: null,
        new_policy: null,
        billing_method: null,
        language_preferred: null,
        policy_period_effective_date: null,
        policy_period_effective_time: null,
        policy_period_expiry_date: null,
        policy_period_expiry_time: null,
        insurance: {
          insurance_name: null,
          insurance_address: null,
          insurance_unit_or_suite: null,
          insurance_city: null,
          insurance_state: null,
          insurance_country: null,
          insurance_zip: null,
        },
        broker: {
          broker_agent: null,
          broker_agent_address: null,
          broker_agent_unit_or_suite: null,
          broker_agent_city: null,
          broker_agent_state: null,
          broker_agent_country: null,
          broker_agent_zip: null,
          broker_code: null,
          broker_sub_code: null,
        },
        application_details: {
          name: null,
          address: null,
          address_two: null,
          country: null,
          city: null,
          state: null,
          postalcode: null,
          phone: null,
          mobile: null,
        },
        lessor: {
          lessor_name: null,
          lessor_full_address: null,
          lessor_phone: null,
          lessor_email: null,
        },
        described_automobiles: {
          described_automobiles_part_one: [
            {
              key: getUniqueId(),
              autoNo: null,
              modelYear: null,
              makeTradeName: null,
              model: null,
              bodyType: null,
              noCylinders: null,
              grossVehicleWeight: null,
            },
          ],
          described_automobiles_part_two: [
            {
              key: getUniqueId(),
              autoNo: null,
              vinNumber: null,
              owned: null,
              leased: null,
              purchaseLeasedDate: null,
              purchaseLeased: null,
              purchasePrice: null,
              automobileUsed: null,
              automobileUsedOneWay: null,
              automobileUsedPrecentage: null,
            },
          ],
          described_automobiles_part_three: [
            {
              key: getUniqueId(),
              autoNo: null,
              annualDrivingDistance: null,
              automobileUsedForPooling: null,
              automobileUsedForPoolingIfYes: null,
              typefFuelUsed: null,
              typefFuelUsedIfYes: null,
              unrepairedDamage: null,
              modifiedCustomized: null,
            },
          ],
          described_automobiles_part_four: [
            {
              key: getUniqueId(),
              autoNo: "",
              lienholderName: "",
              postalAddress: "",
            },
          ],
          owner_actual_owner_yesNoCd: null,
          total_automobiles_in_business: null,
          rented_or_leased_to_other: null,
          carry_passengers_for_compensation: null,
          haul_a_trailer: null,
          carry_explosives: null,
        },
        driver_information: {
          section_one: [
            {
              key: getUniqueId(),
              driverNo: null,
              nameofDriver: null,
              driverLicenseNumber: null,
              dateOfBirth: "",
              sex: "",
              maritalStatus: "",
            },
          ],
          section_two: [
            {
              key: getUniqueId(),
              driverNo: null,
              driverTrainingCertificateAttached: null,
              dateFirstLicencedInCanadaorUSClass: null,
              dateFirstLicencedInCanadaorUSYear: null,
              otherClassofLicenseIfAnyClass: null,
              otherClassofLicenseIfAnyYear: null,
              percentageUseByEachDriverAuto1: null,
              percentageUseByEachDriverAuto2: null,
              percentageUseByEachDriverAuto3: null,
              areAnyOtherPersonsInTheHousehold: {
                questionCd: null,
                yesNoCd: null,
              },
              doAnyDriversQualifyForRetireeDiscount: {
                questionCd: null,
                yesNoCd: null,
              },
            },
          ],
          insurance_company: null,
          prior_policy_number: null,
          expiry_date: null,
          has_any_driver_license: null,
          has_any_insurance_company: null,
          during_the_last_three_years: null,
          has_the_applicant_or_any_listed_driver: null,
        },
        previous_accidents_and_insurance_claims: [
          {
            key: getUniqueId(),
            driverNo: null,
            autoNo: null,
            date: null,
            coverageClaimPaidUnder: {
              bi: null,
              pd: null,
              Ab: null,
              dcpd: null,
              ua: null,
              col: null,
              comp: null,
            },
            amountPaidOrEstimate: null,
            details: null,
          },
        ],
        history_of_convictions: [
          {
            key: getUniqueId(),
            driverNo: null,
            dateConvicted: null,
            details: "",
          },
        ],
        rating_information: {
          section_one: [
            {
              key: getUniqueId(),
              autoNo: null,
              class: null,
              drivingRecord: null,
              bi: null,
              pd: null,
              ab: null,
              dcpd: null,
              coll_ap: null,
              driverNoPrimary: null,
              driverNoSceondary: null,
              atFaultClaimSurcharges: null,
              atFaultClaimPrecentage: null,
              convictionsSurcharges: null,
              convictionsPrecentage: null,
            },
          ],
          section_two: [
            {
              key: getUniqueId(),
              autoNo: null,
              listPriceView: null,
              vehicleCode: null,
              ratingGroupAb: null,
              ratingGroupDcpd: null,
              ratingGroupCollAp: null,
              ratingGroupcompSp: null,
              ratinratingGroupDcpd: null,
              location: null,
              territory: null,
              discountDescription: null,
              discountPrecentage: null,
            },
          ],
        },
        payment_reporter_of_broker: {
          type_of_payment_plan: null,
          estimated_policy_premium: null,
          provincial_sales_tax: null,
          interest_amount: null,
          total_estimated_cost: null,
          amount_paid_with_application: null,
          amount_due: null,
          no_of_remaining_installments: null,
          amount_of_each_installment: null,
          installment_due_date: null,
          have_you_bound_the_risk: null,
          is_this_business_new_to_you: null,
          type_of_motor_vehicle_liability_insurance_card: null,
          how_long_you_have_known_the_applicant: null,
          how_long_you_have_known_the_principal_driver: null,
          has_an_inspection_been_done: null,
        },
        payment_schedule: [
          {
            id: getUniqueId(),
            installment_number: "",
            installment_amount: "",
            installment_date: "",
          },
        ],
        incurance_coverage_applied_for: {
          columns: [],
          rows: [
            {
              key: getUniqueId(),
              code: "",
              label: "",
              description: "",
              isTypeOther: false,
              automobiles: {
                "": {
                  limit: null,
                  premimum: null,
                  deductible: null,
                  isOnlyPremimum: false,
                },
              },
            },
          ],
        },
        remarks: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    garage_on: {
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        policy_type: null,
        replacing_policy_no: null,
        policy_number_assigned: null,
        language_preferred: "English",
        insurance_company: null,
        broke_or_agent: null,
        applicant_full_name: null,
        applicant_full_address: null,
        applicant_email: null,
        applicant_phone: null,
        applicant_full_business_address_a: null,
        applicant_full_business_address_a_type: "Building",
        applicant_full_business_address_b: null,
        applicant_full_business_address_b_type: null,
        applicant_full_business_address_c: null,
        applicant_full_business_address_c_type: null,
        applicant_full_business_address_d: null,
        applicant_full_business_address_d_type: null,
        policy_period_effective_date: moment().format("YYYY-MM-DD"),
        policy_period_effective_time: moment().format(),
        policy_period_expiry_date: moment().format("YYYY-MM-DD"),
        policy_period_expiry_time: moment()
          .startOf("day")
          .add(1, "minutes")
          .format(),
        applicant_business_info: null,
        estimated_total_payroll_for_policy_period: null,
        no_of_employees_full_time: null,
        no_of_employees_part_time: null,
        coverage: null,
        policy_cancelled_in_last_3_years: null,
        policy_cancelled_no_of_insurer: null,
        policy_cancelled_policy_no: null,
        list_of_all_accidents_losses_or_claims: [
          {
            key: getUniqueId(),
            Losses_date: moment().format("YYYY-MM-DD"),
            bi: null,
            pd: null,
            ab: null,
            dc_pd: null,
            ua: null,
            coll: null,
            comp_sp: null,
          },
          {
            key: getUniqueId(),
            Losses_date: moment().format("YYYY-MM-DD"),
            bi: null,
            pd: null,
            ab: null,
            dc_pd: null,
            ua: null,
            coll: null,
            comp_sp: null,
          },
        ],
        insurer: null,
        policy_no: null,
        expiry_date: null,
        remarks: null,
        item_no: null,
        signature_of_broker: null,
        status: "ACTIVE",
      },
      loading: LoadState.NotLoaded,
      error: null,
    },
    supplementaryform: {
      data: {
        customer_policy_id: 0,
        incremental_number: null,
        customer_id: 0,
        customer_name: null,
        broker: null,
        effective_date: null,
        section_a: {
          in_business_since: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_new_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          sales_of_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          leasing_of_new_or_used_vehicles: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          body_shop_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          repair_garage_including_car_sales: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          service_station_self_serve: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          parking_lot_self_park: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          storage_garage_self_store: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          valet_parking_fee_charged: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          car_wash_automatic: {
            location_a: null,
            location_b: null,
            location_c: null,
            location_d: null,
          },
          other_detail_remarks: null,
        },

        private_passenger_checkbox: null,
        light_commercial_checkbox: null,
        heavy_commercial_checkbox: null,
        trailers_checkbox: null,
        commercial_trailers_checkbox: null,
        antique_or_classic_checkbox: null,
        exotic_racing_checkbox: null,
        motorcycles_checkbox: null,
        atv_checkbox: null,
        snowmobiles_checkbox: null,
        motorhomes_checkbox: null,
        other_checkbox: null,
        other_value: null,
        proprietors: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        sales_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        mechanics: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        lot_persons: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        body_shop_workers: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        full_service_station_attendants: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        clerical: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parts_department_staff: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        parking_lot_attendant: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        valet_parking: {
          location_a_ft: null,
          location_a_pt: null,
          location_b_ft: null,
          location_b_pt: null,
          location_c_ft: null,
          location_c_pt: null,
          location_d_ft: null,
          location_d_pt: null,
        },
        summary_of_employees_other_details: null,
        employee_information_including_owners: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            position: null,
            years_lincence: null,
            licence_class: null,
            full_or_part_time: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        other_operators: [
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
          {
            key: getUniqueId(),
            name: null,
            drivers_licence_number: null,
            birth_date: "",
            relationship_to_the_named_insured: null,
            years_lincence: null,
            licence_class: null,
            convictions_or_suspensions_for_the_past_six_years: null,
          },
        ],
        claims: [
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
          {
            key: getUniqueId(),
            driver_name: null,
            date_of_loss: "",
            type_of_loss: null,
            amount_paid: null,
            descriptiom: null,
          },
        ],
        are_all_active_vehicles_owned_by_yes_or_no: null,
        are_all_active_vehicles_owned_by_no_reason: null,
        number_of_dealer_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        number_of_service_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        total_number_of_white_plates: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        dealer_plate_numbers: null,
        service_plate_numbers: null,
        private_passenger: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tow_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_tilt_and_load: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_parts_truck: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_service_trucks: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_shuttle_vehicles: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        commercial_other: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        courtesy_cars: {
          location_a: null,
          location_b: null,
          location_c: null,
          location_d: null,
          total: null,
        },
        summary_of_all_active_vehicles_other_details: null,
        details_of_all_vehicles_owned_by_the_applicant: [
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
          {
            key: getUniqueId(),
            year: null,
            make_model_body_type: null,
            vin: null,
            gvw: null,
            list_price_new: null,
            use: null,
            driver_principal_and_occasional: null,
          },
        ],
        owned_automobiles_including_vehicles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        customer_automobiles: {
          maximum_number_in_building_location_a: null,
          maximum_number_in_building_location_b: null,
          maximum_number_in_building_location_c: null,
          maximum_number_in_building_location_d: null,
          maximum_number_on_lot_location_a: null,
          maximum_number_on_lot_location_b: null,
          maximum_number_on_lot_location_c: null,
          maximum_number_on_lot_location_d: null,
          maximum_value_location_a: null,
          maximum_value_location_b: null,
          maximum_value_location_c: null,
          maximum_value_location_d: null,
          average_value_location_a: null,
          average_value_location_b: null,
          average_value_location_c: null,
          average_value_location_d: null,
        },
        during_business_hours: null,
        after_business_hours: null,
        are_driving_record_abstracts_obtained_yes_or_no: null,
        how_often: null,
        outside_floodlights: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        burglary_alarm_system: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        fenced_compound: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        guard_dogs: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        night_watchman: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        other_describe_below: {
          location_a_yes_or_no: null,
          location_b_yes_or_no: null,
          location_c_yes_or_no: null,
          location_d_yes_or_no: null,
          comments_description: null,
        },
        security_measures_other_details: null,
        does_the_applicant_pick_up_or_deliver_yes_or_no: null,
        does_the_applicant_pick_up_or_deliver_value: null,
        number_of_spray_booths: null,
        inspected_and_approved_yes_or_no: null,
        spraying: null,
        welding: null,
        does_the_applicant_dispense_propane_yes_or_no: null,
        number_of_licensed_employees: null,
        give_details_of_any_contractual_liability: null,
        what_is_the_maximum_number_of_automobiles: null,
        how_many_tow_trucks_can_carry: null,
        does_the_applicant_have_written_rules_yes_or_no: null,
        does_the_applicant_have_written_rules_value: null,
        is_demonstrator_use_restricted_yes_or_no: null,
        including_spouse_yes_or_no: null,
        including_children_yes_or_no: null,
        others: null,
        is_vacation_use_of_owned_vehicles_yes_or_no: null,
        are_customers_permitted_to_test_drive_yes_or_no: null,
        is_customer_always_accompanied_yes_or_no: null,
        describe_procedures_for_other_precautions_taken: null,
        if_sales_include_recreational_vehicles_yes_or_no: null,
        is_applicant_currently_registered_yes_or_no: null,
        where_and_how_are_vehicles_held: null,
        lien_checks_yes_or_no: null,
        consignment_yes_or_no: null,
        if_yes_what_policies_and_procedures: null,
        indicate_number_and_average_retail_values_sold: {
          third_year_back_number_sold: null,
          third_year_back_average_retail_value: null,
          second_year_back_number_sold: null,
          second_year_back_average_retail_value: null,
          expiring_year_number_sold: null,
          expiring_year_average_retail_value: null,
          expected_for_coming_year_number_sold: null,
          expected_for_coming_year_average_retail_value: null,
        },
        have_you_bound_this_risk_yes_or_no: null,
        is_this_business_new_to_you_yes_or_no: null,
        how_long_have_you_known_the_applicant: null,
        report_of_broker_other_details: null,
        modified_by_id: null,
        insert_ts: null,
        create_ts: null,
        status: "ACTIVE",
        created_by_id: 0,
      },
      loading: LoadState.NotLoaded,
      error: null,
    },

  };
