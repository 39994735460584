/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { CommAutomobileScheduleAList } from "./CommAutoSchedule/CommAutoScheduleA/CommAutoScheduleAList";
import { CommAutomobileScheduleBList } from "./CommAutoSchedule/CommAutoScheduleB/CommAutoScheduleBList";
import { CommAutomobileScheduleCList } from "./CommAutoSchedule/CommAutoScheduleC/CommAutoScheduleCList";
import { fetchCommAutomobileCustomerInfoAsync } from "../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { CommAutomobileScheduleDList } from "./CommAutoSchedule/CommAutoScheduleD/CommAutoScheduleDList";

export const CommAutoVehicleCoverageScheduleRoot: React.FC = () => {

  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/comm-automobile-policy/comm-automobile/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "vehicle-coverage-schedule",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            { label: "IRCA/Scheduled Fleet", icon: <AccountCircle /> },
            {
              label: "Blanket Fleet",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Garage (Ontario)",
              icon: <StickyNote2 />,
            },
            {
              label: "Garage (Alberta)",
              icon: <StickyNote2 />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <CommAutomobileScheduleAList
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <CommAutomobileScheduleBList
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          <CommAutomobileScheduleCList
            
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <CommAutomobileScheduleDList
            
          />
        </TabPanel>
      </>
    </PageLoader>
  );
};
