import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin } from "../../../../../../components/Table/plugins";
import { fetchCSIOPolicyChangeListAsync } from "../../../../../../redux/automobileCSIOApplication/automobileCSIOApplicationActions";
import { ILegal } from "../../../../../../redux/legals/legals.types";
import { IAutomobileCSIOEDIApplication } from "../../../../../../redux/automobileCSIOEDIApplication/automobileCSIOEdiApplication.types";
import {
  clearAutomobileApplicationEDIState,
  fetchAutomobileEDIListAsync,
} from "../../../../../../redux/automobileCSIOEDIApplication/automobileCSIOEDIApplicationActions";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";

export const AutomobileCSIOEDIApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];

  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.edi
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchAutomobileEDIListAsync(customerpolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearAutomobileApplicationEDIState());
    };
  }, [customerpolicyId, status]);

  const automobileApplicationTableProps: IDataTableV2Props = {
    isPagination: false,
    // totalRecords: totalRecords,
    isDataLoading: loading === LoadState.InProgress,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {
        customPlugins: [],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-csio-application-tab/${customerpolicyId}/edi/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "new_policy",
        headerName: "Policy Status",
        fieldName: "new_policy",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "message_guid",
        headerName: "Message Guid",
        fieldName: "message_guid",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
      },
      {
        key: "application_details.name",
        headerName: "Customer Name",
        fieldName: "application_details.name",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/automobile-csio-application-tab/${customerpolicyId}/edi/${row.incremental_number}`
                );
              }}
            >
              {row.application_details.name}
            </Link>
          );
        },
      },
      {
        key: "policy_number_assigned",
        headerName: "Policy #",
        fieldName: "policy_number_assigned",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 30,
      },
      {
        key: "insurance.insurance_name",
        headerName: "Insurance Company",
        fieldName: "insurance.insurance_name",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        onRowCellValueRender: (value, row: IAutomobileCSIOEDIApplication) => {
          return row.insurance.insurance_name || "";
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CHIP_SUCCESS,
      },
    ],
    rows: list,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DataTableV2 {...automobileApplicationTableProps} />
    </Box>
  );
};
