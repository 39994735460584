import { action } from "typesafe-actions";
import {
  IAutomobileGarageForm,
  IAutomobileRetireeDiscount,
  IAutomobileSharingFillable,
  IAutomobileSupplementaryForm,
} from "./automobileCertificates.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
  
//----------------------Garage Form ------------------->

    
export const CLEAR_AUTOMOBILE_GARAGE_FORM = "CLEAR_AUTOMOBILE_GARAGE_FORM";
export const clearAutomobileGarageForm = () =>
  action(CLEAR_AUTOMOBILE_GARAGE_FORM);

export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS =
"FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS =
"FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED";


export const fetchAutomobileGarageFormListProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS);
export const fetchAutomobileGarageFormListSuccess = (
  data: IAutomobileGarageForm[]
) => action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS, { data });
export const fetchAutomobileGarageFormListFailed = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED);

export const fetchAutomobileGarageFormListAsync = (customerPolicyId: number):ThunkAction<void, IStoreState, {}, AnyAction> => 
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGarageFormListProgress());
      const res = await api.get(`/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}`);

      const data :IAutomobileGarageForm[] =res.data.data;
      dispatch(fetchAutomobileGarageFormListSuccess(data))
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  }
  

