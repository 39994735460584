/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";

import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import {
  addCommAutomobilAdditionalInfoAsync,
  fetchCommAutomobilAdditionalInfoVehicleAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useNavigate } from "react-router-dom";

export const CommAutomobileAdditionalInformation: React.FC<{
  customerId: number;
  customerPolicyId: number;
  vehcileNumber: number;
  changeStep: (value: number) => void;
}> = (props) => {
  const { customerId, customerPolicyId, vehcileNumber } = props;

  const { additionalInformation } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.vehicle
  );
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: additionalInformation.data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        if (customerId && customerPolicyId) {
          setLoading(true);
          dispatch(
            addCommAutomobilAdditionalInfoAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
              },
              vehcileNumber,
              (isSuccess) => {
                if (isSuccess) {
                  if (buttonClicked === "submit") {
                    navigate(
                      `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}?tab=0`
                    );
                  } else {
                    window.scrollTo(0, 0);
                    props.changeStep(2);
                  }
                }
                setLoading(false);
              }
            )
          );
        }
      },
    });

  React.useEffect(() => {
    setValues(additionalInformation.data);
  }, [additionalInformation.data]);

  React.useEffect(() => {
    if (customerPolicyId && vehcileNumber) {
      dispatch(
        fetchCommAutomobilAdditionalInfoVehicleAsync(
          customerPolicyId,
          vehcileNumber
        )
      );
    }
  }, [customerPolicyId, vehcileNumber]);

  return (
    <PageLoader
      loading={additionalInformation.loading === LoadState.InProgress}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Facility Indicator</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="facility_indicator"
              value={values.facility_indicator}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Rent/Lease to Others</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="rent_or_lease_to_others"
              value={values.rent_or_lease_to_others}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Explosive/Radioactive Material</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="explosive_or_radioactive_material"
              value={values.explosive_or_radioactive_material}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Multi Car Discount</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="multi_car_discount"
              value={values.multi_car_discount}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Pre Inspection Code</CustomFormLabel>
            <CustomTextField
              id="pre_inspection_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.pre_inspection_code}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Used Outside Canada</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_used_outside_canada"
              value={values.vehicle_used_outside_canada}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          {values.vehicle_used_outside_canada === "Y" && (
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>No. of Months</CustomFormLabel>
              <CustomTextField
                id="no_of_months"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={values.no_of_months}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Used in Car Pools</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_used_in_car_pools"
              value={values.vehicle_used_in_car_pools}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>No. of Car Pool Passengers</CustomFormLabel>
            <CustomTextField
              id="no_of_car_pool_passengers"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.no_of_car_pool_passengers}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>No. of Car Pool Uses</CustomFormLabel>
            <CustomTextField
              id="no_of_car_pool_uses"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.no_of_car_pool_uses}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Time Peroid</CustomFormLabel>
            <CustomTextField
              id="time_period"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.time_period}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Salvaged</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_salvaged"
              value={values.vehicle_salvaged}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Carry Goods for Compensation</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="carry_goods_for_compensation"
              value={values.carry_goods_for_compensation}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Carry Passengers for Compensation</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="carry_passengers_for_compensation"
              value={values.carry_passengers_for_compensation}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Existing Unrepaired Damage</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="existing_unrepaired_damage"
              value={values.existing_unrepaired_damage}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Equiped With Winter Trees</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_equipped_with_winter_tires"
              value={values.vehicle_equipped_with_winter_tires}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>
              Have a Copy of the Winter Tire Invoice
            </CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_the_winter_tire_invoice"
              value={values.have_a_copy_of_the_winter_tire_invoice}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Winter Tire Purchase Date</CustomFormLabel>
            <CustomDatePicker
              value={values.winter_tire_purchase_date}
              onChange={(newvalue) => {
                setFieldValue("winter_tire_purchase_date", newvalue);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>
              Reason Pre-Inspection Code not Required
            </CustomFormLabel>
            <CustomTextField
              id="reason_pre_inspection_code_not_required"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.reason_pre_inspection_code_not_required}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3.2}>
            <CustomFormLabel>Have Photos of this Vehicle</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_photos_of_this_vehicle"
              value={values.have_photos_of_this_vehicle}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Have a Copy of Vehicle Ownership</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_vehicle_ownership"
              value={values.have_a_copy_of_vehicle_ownership}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Have a Copy of the bill of Sale</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="have_a_copy_of_the_bill_of_sale"
              value={values.have_a_copy_of_the_bill_of_sale}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("submit")}
          >
            Save & Exit
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
