import { action } from "typesafe-actions";
import {
  IHabitationalBinderCsio,
  IHabitationalConsentForm,
  IHabitationalDwelling,
  IHabitationalDwellingDeclaration,
  IHabitationalNonCsioBinder,
  IHabitationalPaymentAuthorization,
  IHabitationalPolicyForm,
} from "./HabitationalPolicyForm.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { uploadFile } from "../../../components/FileUpload/utils";


export const FETCH_HABITATIONAL_POLICY_FORMS_LIST_PROGRESS =
  "FETCH_HABITATIONAL_POLICY_FORMS_LIST_PROGRESS";
export const FETCH_HABITATIONAL_POLICY_FORMS_LIST_SUCCESS =
  "FETCH_HABITATIONAL_POLICY_FORMS_LIST_SUCCESS";
export const FETCH_HABITATIONAL_POLICY_FORMS_LIST_FAILED =
  "FETCH_HABITATIONAL_POLICY_FORMS_LIST_FAILED";

export const fetchHabitationalPolicyFormsListProgress = () =>
  action(FETCH_HABITATIONAL_POLICY_FORMS_LIST_PROGRESS);
export const fetchHabitationalPolicyFormsListSuccess = (
  data: IHabitationalPolicyForm[],
  totalRecords: number
) => action(FETCH_HABITATIONAL_POLICY_FORMS_LIST_SUCCESS, { data, totalRecords });
export const fetchHabitationalPolicyFormsListFailed = () =>
  action(FETCH_HABITATIONAL_POLICY_FORMS_LIST_FAILED);

export const fetchHabitationalPolicyFormsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPolicyFormsListProgress());
      const url = `/policy/get-policy-habitational-all-policy-forms?customer_policy_id=${customerPolicyId}&policy_form_type=${type}&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: IHabitationalPolicyForm[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchHabitationalPolicyFormsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchHabitationalPolicyFormsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/** Payment Authorization */


export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS";
export const FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED =
  "FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED";

export const fetchHabitationalPaymentAuthorizationProgress = () =>
  action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_PROGRESS);
export const fetchHabitationalPaymentAuthorizationSuccess = (
  data: IHabitationalPaymentAuthorization
) => action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_SUCCESS, { data });
export const fetchHabitationalPaymentAuthorizationFailed = (
  errorMessage: string
) => action(FETCH_HABITATIONAL_PAYMENT_AUTHORIZATION_FAILED, { errorMessage });

export const fetchHabitationalPaymentAuthorizationAsync =
  (
    customerPolicyId: number,
    payAuth: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalPaymentAuthorizationProgress());
      const res = await api.get(
        `/policy/get-habitational-payment-authorization?customer_policy_id=${customerPolicyId}&column=serial_number&value=${payAuth}`
      );
      const data: IHabitationalPaymentAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalPaymentAuthorizationSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalPaymentAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalPaymentAuthorizationFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalPaymentAuthorizationAsync =
  (
    payAuth: IHabitationalPaymentAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-habitational-payment-authorization`, {
        ...payAuth,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Payment Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const downloadHabitationalAuthorizationPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      // onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
          {
            responseType: "arraybuffer",
          }
        );
  
        const pdfBuffer = res.data;
  
        const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
  
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "downloaded.pdf";
        link.click();
  
        URL.revokeObjectURL(pdfUrl);
        // onCallback();
      } catch (err: any) {
        dispatch(
          fetchHabitationalPaymentAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
        );
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };
  
  export const previewHabitationalAuthorizationPDFAsync =
    (
      customerPolicyId: number,
      incremental_number: number,
      // onCallback: () => void
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.get(
          `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_auto_paf_preview=true`
        );
  
        for (const key in res.data.data) {
          const newTab = window.open("", "_blank");
          if (newTab) {
            newTab.document.write(res.data.data[key]);
            newTab.document.close();
          }
        }
        // onCallback();
      } catch (err: any) {
        dispatch(
          fetchHabitationalPaymentAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
        );
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };


  /**Privacy Consent */

export const FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS =
  "FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_HABITATIONAL_CONSENT_FORM_FAILED =
  "FETCH_HABITATIONAL_CONSENT_FORM_FAILED";

export const fetchHabitationalConsentFormProgress = () =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_PROGRESS);
export const fetchHabitationalConsentFormSuccess = (
  data: IHabitationalConsentForm
) => action(FETCH_HABITATIONAL_CONSENT_FORM_SUCCESS, { data });
export const fetchHabitationalConsentFormFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_CONSENT_FORM_FAILED, { errorMessage });


export const addHabitationalConsentFormAsync =
  (
    data: IHabitationalConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-privacy-consent-form", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IHabitationalConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchHabitationalConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchHabitationalConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadHabitationalConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewHabitationalConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

/**Dwelling Declaration */

export const FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS =
  "FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS =
  "FETCH_AUTOMOBILE_DWELLING_DECLARATION_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED =
  "FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED";

export const fetchHabitationalDwellingDeclarationProgress = () =>
  action(FETCH_HABITATIONAL_DWELLING_DECLARATION_PROGRESS);
export const fetchHabitationalDwellingDeclarationSuccess = (
  data: IHabitationalDwellingDeclaration
) => action(FETCH_HABITATIONAL_DWELLING_DECLARATION_SUCCESS, { data });
export const fetchHabitationalDwellingDeclarationFailed = (
  errorMessage: string
) => action(FETCH_HABITATIONAL_DWELLING_DECLARATION_FAILED, { errorMessage });

export const addHabitationalDwellingDeclarationAsync =
  (
    data: IHabitationalDwellingDeclaration,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-rental-dwelling-declaration", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalDwellingDeclarationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalDwellingDeclarationProgress());
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IHabitationalDwellingDeclaration[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchHabitationalDwellingDeclarationSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchHabitationalDwellingDeclarationFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalDwellingDeclarationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadHabitationalDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
   
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewHabitationalDwellingDeclarationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-declaration-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_declaration_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
     
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };


  /**Dwelling */

export const FETCH_HABITATIONAL_DWELLING_PROGRESS =
  "FETCH_HABITATIONAL_DWELLING_PROGRESS";
export const FETCH_HABITATIONAL_DWELLING_SUCCESS =
  "FETCH_AUTOMOBILE_DWELLING_SUCCESS";
export const FETCH_HABITATIONAL_DWELLING_FAILED =
  "FETCH_HABITATIONAL_DWELLING_FAILED";

export const fetchHabitationalDwellingProgress = () =>
  action(FETCH_HABITATIONAL_DWELLING_PROGRESS);
export const fetchHabitationalDwellingSuccess = (data: IHabitationalDwelling) =>
  action(FETCH_HABITATIONAL_DWELLING_SUCCESS, { data });
export const fetchHabitationalDwellingFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_DWELLING_FAILED, { errorMessage });

export const addHabitationalDwellingAsync =
  (
    data: IHabitationalDwelling,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-rental-dwelling", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchHabitationalDwellingAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalDwellingProgress());
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IHabitationalDwelling[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchHabitationalDwellingSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchHabitationalDwellingFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalDwellingFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadHabitationalDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewHabitationalDwellingPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-rental-dwelling-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_rental_dwelling_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  
  };

/**Binder CSIO */

export const FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS =
  "FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS";
export const FETCH_HABITATIONAL_CSIO_BINDER_FAILED =
  "FETCH_HABITATIONAL_CSIO_BINDER_FAILED";

export const fetchHabitationalCsioBinderProgress = () =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_PROGRESS);
export const fetchHabitationalCsioBinderSuccess = (
  data: IHabitationalBinderCsio
) => action(FETCH_HABITATIONAL_CSIO_BINDER_SUCCESS, { data });
export const fetchHabitationalCsioBinderFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_CSIO_BINDER_FAILED, { errorMessage });

export const fetchHabitationalCsioBinderAsync =
  (
    customerPolicyId: number,
    Csio: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalCsioBinderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${Csio}`
      );
      const data: IHabitationalBinderCsio[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalCsioBinderSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalCsioBinderFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalCsioBinderFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalCsioBinderAsync =
  (
    Csio: IHabitationalBinderCsio,
    customerID: number,
    signature: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "HABITATIONAL",
        customer_policy_id: Csio.customer_policy_id,
        page_name: "Habitational Binder",
      };

      const path = await uploadFile(
        signature,
        "POLICY",
        Csio.signature_of_broker || "",
        asPayload
      );
      await api.post(`/policy/edit-habitational-binder`, {
        ...Csio,
        signature_of_broker: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Non-Csio Binder saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };


/**Non-Binder CSIO */


export const downloadHabitationalNonCsioBinderPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewHabitationalNonCsioBinderPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_habitational_binder_non_csio_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS";
export const FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED =
  "FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED";

export const fetchHabitationalNonCsioBinderProgress = () =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_PROGRESS);
export const fetchHabitationalNonCsioBinderSuccess = (
  data: IHabitationalNonCsioBinder
) => action(FETCH_HABITATIONAL_NON_CSIO_BINDER_SUCCESS, { data });
export const fetchHabitationalNonCsioBinderFailed = (errorMessage: string) =>
  action(FETCH_HABITATIONAL_NON_CSIO_BINDER_FAILED, { errorMessage });

export const fetchHabitationalNonCsioBinderAsync =
  (
    customerPolicyId: number,
    nonCsio: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalNonCsioBinderProgress());
      const res = await api.get(
        `/policy/get-habitational-binder-non-csio?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${nonCsio}`
      );
      const data: IHabitationalNonCsioBinder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchHabitationalNonCsioBinderSuccess(data[0]));
      } else {
        dispatch(
          fetchHabitationalNonCsioBinderFailed(
            "Oops! We couldn't find any records associated with your restrutant number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchHabitationalNonCsioBinderFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalNonCsioBinderAsync =
  (
    nonCsio: IHabitationalNonCsioBinder,
    customerID: number,
    signature: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerID,
        policy_type: "HABITATIONAL",
        customer_policy_id: nonCsio.customer_policy_id,
        page_name: "Habitational Non-Binder",
      };

      const path = await uploadFile(
        signature,
        "POLICY",
        nonCsio.signature_of_broker || "",
        asPayload
      );
      await api.post(`/policy/edit-habitational-binder-non-csio`, {
        ...nonCsio,
        signature_of_broker: path,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Non-Csio Binder saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };


export const CLEAR_HABITATIONAL_POLICY_FORMS =
  "CLEAR_HABITATIONAL_POLICY_FORMS";
export const clearHabitationalPolicyForms = () =>
  action(CLEAR_HABITATIONAL_POLICY_FORMS);
