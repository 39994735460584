import { action } from "typesafe-actions";
import { IBusinessPolicyCoverage } from "./businessPolicyCoverage.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_COVERAG_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_COVERAG_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_COVERAGE_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_COVERAGE_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_COVERAGE_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_COVERAGE_LIST_FAILED";

export const fetchBusinessPolicyCoverageListProgress = () =>
  action(FETCH_BUSINESS_POLICY_COVERAG_LIST_PROGRESS);
export const fetchBusinessPolicyCoverageListSuccess = (
  data: IBusinessPolicyCoverage[]
) => action(FETCH_BUSINESS_POLICY_COVERAGE_LIST_SUCCESS, { data });
export const fetchBusinessPolicyCoverageListFailed = () =>
  action(FETCH_BUSINESS_POLICY_COVERAGE_LIST_FAILED);

export const fetchBusinessPolicyCoverageListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyCoverageListProgress());

      let finalUrl = `/policy/get-policy-business-coverage-summary?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyCoverage[] = res.data.data;
      dispatch(fetchBusinessPolicyCoverageListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyCoverageListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyCoverageAsync =
  (
    coverage: IBusinessPolicyCoverage,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-coverage-summary`, {
        ...coverage,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Policy coverage saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_COVERAG_PROGRESS =
  "FETCH_BUSINESS_POLICY_COVERAG_PROGRESS";
export const FETCH_BUSINESS_POLICY_COVERAGE_SUCCESS =
  "FETCH_BUSINESS_POLICY_COVERAGE_SUCCESS";
export const FETCH_BUSINESS_POLICY_COVERAGE_FAILED =
  "FETCH_BUSINESS_POLICY_COVERAGE_FAILED";

export const fetchBusinessPolicyCoverageProgress = () =>
  action(FETCH_BUSINESS_POLICY_COVERAG_PROGRESS);
export const fetchBusinessPolicyCoverageSuccess = (
  data: IBusinessPolicyCoverage
) => action(FETCH_BUSINESS_POLICY_COVERAGE_SUCCESS, { data });
export const fetchBusinessPolicyCoverageFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_COVERAGE_FAILED, { errorMessage });

export const fetchBusinessPolicyCoverageAsync =
  (
    customerPolicyId: number,
    coverageId: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyCoverageProgress());
      const res = await api.get(
        `/policy/get-policy-business-coverage-summary?customer_policy_id=${customerPolicyId}&column=coverage_number&value=${coverageId}`
      );
      const data: IBusinessPolicyCoverage[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyCoverageSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyCoverageFailed(
            "Oops! We couldn't find any records associated with your coverage number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPolicyCoverageFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_COVERAGE_STATE =
  "CLEAR_BUSINES_POLICY_COVERAGE_STATE";
export const CLEAR_BUSINES_POLICY_COVERAGE = "CLEAR_BUSINES_POLICY_COVERAGE";

export const clearBusinessPolicyCoverageState = () =>
  action(CLEAR_BUSINES_POLICY_COVERAGE_STATE);

export const clearBusinessPolicyCoverage = () =>
  action(CLEAR_BUSINES_POLICY_COVERAGE);
