import React from "react";
import { Avatar, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { ITableTab } from "../../../../../../components/Table/components/TableTabs/TableTabs.types";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import {
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../components/Table/plugins";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { Breadcrumb } from "../../../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { fetchCsioEdocsListAsync } from "../../../../../../redux/csioEdocs/csioEdocsActions";
import { ICSIOEDocs } from "../../../../../../redux/csioEdocs/csioEdocs.types";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";
import { createTabsWithRecordcounts } from "../../../../../../helpers";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../../../../redux/common/commonActions";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
} from "../../../../../../redux/messages/messagesActions";
import {
  downLoadFile,
  previewFile,
} from "../../../../../../components/FileUpload/utils";
import { EmailComposeRightPanel } from "../../../../../../components/MessagesDialogs/EmailCompose/EmailCompose";
export const CommAutoCSIOEDocsList: React.FC = () => {
  const data1 = useOutletContext() as any;
  const customerpolicyId = data1[1];
  // const { addBreadcrumb } = useBreadcrumbContext();

  // const BCrumb: IBreadcrumbProps["items"] = [
  //   {
  //     to: "/dashboard/",
  //     title: "Dashboard",
  //   },
  //   {
  //     title: "Csio Edocs",
  //   },
  // ];
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.csioEdocs
  );

  const [tableTabs, setTableTabs] = React.useState<ITableTab[]>([]);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const [searchType, setSearchType] = React.useState<string[]>([
    "business_purpose_type",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "-1"
  );
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  const fetchList = async () => {
    dispatch(
      fetchCsioEdocsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType,
        customerpolicyId
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, search, status]);

  const handleRedirect = () => {
    navigate(`/csio-edocs-list/manage-csio-edocs`);
  };

  const handleEditRedirect = (messageGUId: string) => () => {
    navigate(`/csio-edocs-list/manage-csio-edoc/${messageGUId}`);
  };

  const csioEdocsTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
                moduleId={MODULE_IDS.EQUIPMENT}
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Customer Name", value: "customer_name" },
                    { label: "Policy Number", value: "policy_number" },
                    { label: "Type", value: "type" },
                    {
                      label: "Business Purspose",
                      value: "business_purpose_type",
                    },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },

    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        isFirstColumnSticky: true,
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: ICSIOEDocs) => {
          return (
            <StandardTableActions
              onDownloadPreview={
                row.attachment
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await previewFile(row.attachment);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
              }
              onDownLoadClick={
                row.attachment
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await downLoadFile(row.attachment);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
              }
              onEditClick={handleEditRedirect(row.message_guid as string)}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "message_guid",
        headerName: "Message ID",
        fieldName: "message_guid",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/csio-edocs-list/manage-csio-edoc/" + row.message_guid}
              label={row.message_guid}
            />
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },

      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row: ICSIOEDocs) => {
          if (!row.customer_email) {
            return <>--</>;
          }
          return (
            <EmailComposeRightPanel
              displayLabel={row.customer_email}
              toEmail={row.customer_email}
              attachements={[row.attachment]}
            />
          );
        },
      },
      {
        key: "business_purpose_type",
        headerName: "Busines Purpose Type",
        fieldName: "business_purpose_type",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
      },
      {
        key: "insurance_company",
        headerName: "Insurance Company",
        fieldName: "insurance_company",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "line_of_business",
        headerName: "Line of Business",
        fieldName: "line_of_business",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "producer_code",
        headerName: "Producer Code",
        fieldName: "producer_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    tableTabProps: {
      selectedTab: status,
      tabs: tableTabs,
      onTabChange: (newSelectedTab) => {
        setStatus(newSelectedTab);
      },
    },
  };

  React.useEffect(() => {
    const tabsData = createTabsWithRecordcounts(
      [
        { label: "Active", value: "ACTIVE", variant: "success" },
        { label: "Inactive", value: "INACTIVE", variant: "error" },
      ],
      recordsCounts
    );
    setTableTabs(tabsData);
  }, [recordsCounts]);

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_csio_edoc",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer title="View eDocs" description="this is innerpage">
      {/* <Breadcrumb title="" items={BCrumb} /> */}

      <PageTitleBar heading="eDocs" />
      <DataTable {...csioEdocsTableProps} />
    </PageContainer>
  );
};
