import produce from "immer";
import {
  CLEAR_QUOTE_DEFAULT_COVERAGE,
  FETCH_QUOTE_DEFAULT_COVERAGE_LIST_PROGRESS,
  FETCH_QUOTE_DEFAULT_COVERAGE_LIST_FAILURE,
  FETCH_QUOTE_DEFAULT_COVERAGE_LIST_SUCCESS,
  FETCH_QUOTE_DEFAULT_COVERAGES_LIST_PROGRESS,
  FETCH_QUOTE_DEFAULT_COVERAGES_LIST_FAILURE,
  FETCH_QUOTE_DEFAULT_COVERAGES_LIST_SUCCESS,
  FETCH_QUOTE_DEFAULT_COVERAGE_PROGRESS,
  FETCH_QUOTE_DEFAULT_COVERAGE_FAILURE,
  FETCH_QUOTE_DEFAULT_COVERAGE_SUCCESS,
} from "./quoteSettingsActions";
import { IStoreState } from "../initialStoreState";
import { initialQuoteSettingsState } from "./quoteSettings.types";
import { QuoteSettingsActions } from ".";
import { LoadState } from "../../constants/enums";

export const QuoteSettingsReducer = (
  state: IStoreState["quoteConfig"] = initialQuoteSettingsState,
  action: QuoteSettingsActions
) => {
  switch (action.type) {
    case FETCH_QUOTE_DEFAULT_COVERAGE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Loaded;
        draftState.defaultCoverage.coverage = data;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Failed;
        draftState.defaultCoverage.error = errorMessage;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Loaded;
        draftState.defaultCoverage.coverageList = data;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGE_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_QUOTE_DEFAULT_COVERAGES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGES_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Loaded;
        draftState.defaultCoverage.coverageList = data;
      });
      return newState;
    }

    case FETCH_QUOTE_DEFAULT_COVERAGES_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.defaultCoverage.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_QUOTE_DEFAULT_COVERAGE: {
      return initialQuoteSettingsState;
    }

    default:
      return state;
  }
};
