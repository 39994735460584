import { LoadState } from "../../constants/enums";

export interface IAutomobileFraudMisRepresentState {
  list: IAutomobileFraudMisRepresent[];
  loading: LoadState;
  data: IAutomobileFraudMisRepresent;
  error: string | null;
}

export interface IAutomobileFraudMisRepresent {
  customer_policy_id: number;
  incremental_number: number | null;
  date: string | null;
  description: string | null;
  driver_number: string | null;
  status: string;
}

export const INITIAL_FRAUD_MISREPRESENT_STATE: IAutomobileFraudMisRepresent = {
  customer_policy_id: 0,
  incremental_number: null,
  date: null,
  description: null,
  driver_number: null,
  status: "ACTIVE",
};

export const initialAutomobileFraudMisRepresentState: IAutomobileFraudMisRepresentState =
  {
    list: [],
    loading: LoadState.NotLoaded,
    error: null,
    data: INITIAL_FRAUD_MISREPRESENT_STATE,
  };
