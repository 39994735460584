import React from "react";
import { IStandardTableActionsProps } from "./StandardTableActions.types";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import { ContentCopyOutlined, Delete, Download, Email, HistoryOutlined, SettingsBackupRestore } from "@mui/icons-material";

import { Dialog } from "../Dialogs/Dialog";
import { CommentsSideBar } from "../CommentsSideBar/CommentsSideBar";
import { HistorySideBar } from "../HistorySideBar/HistorySideBar";

export const StandardTableActions: React.FC<IStandardTableActionsProps> = (
  props
) => {
  const {
    onViewClick,
    onEditClick,
    onDownLoadClick,
    onDownloadPreview,
    onDuplicateClick,
    onDeleteClick,
    onRestoreClick,
    onEmailClick,
    downloadLoading,
    more,
    commentBoxTypeId,
    historyCompData
  } = props;


  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        sx={{

          svg: {
            cursor: "pointer",
          },
        }}
        spacing={{
          md: 1,
          lg: 1.8,
          xl:2
        }}
      >
        {historyCompData && <HistorySideBar module_code={historyCompData.module_code}
        />}
    
        
        {commentBoxTypeId && <CommentsSideBar typeId={commentBoxTypeId} />}

        {onViewClick && (
          <Box onClick={onViewClick}>
            <Tooltip title="View">
              <VisibilityIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onEditClick && (
          <Box onClick={onEditClick}>
            <Tooltip title="Edit">
              <EditIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onEmailClick && (
          <Box onClick={onEmailClick}>
            <Tooltip title="Email">
              <Email  color="primary" />
            </Tooltip>
          </Box>
        )}
         {onDuplicateClick && (
          <Box onClick={onDuplicateClick}>
            <Tooltip title="Duplicate">
              <ContentCopyOutlined color="primary" />
            </Tooltip>
          </Box>
        )}
        {onDownloadPreview && (
          <Box onClick={onDownloadPreview}>
            <Tooltip title="Preview">
              <ContentPasteSearchOutlinedIcon color="primary" />
            </Tooltip>
          </Box>
        )}
        {onDownLoadClick && (
          <Box onClick={!downloadLoading ? onDownLoadClick : undefined}>
            <Tooltip title="Download">
              <Download color="primary" />
            </Tooltip>
          </Box>
        )}
          {onDeleteClick && (
          <Box onClick={onDeleteClick}>
            <Tooltip title="Delete">
              <Delete color="primary" />
            </Tooltip>
          </Box>
        )}
          {onRestoreClick && (
          <Box onClick={onRestoreClick}>
            <Tooltip title="Restore">
              <SettingsBackupRestore color="primary" />
            </Tooltip>
          </Box>
        )}
        {more && <ContextMenu menuOptions={more.menuItems}></ContextMenu>}
      </Stack>
      {downloadLoading && (
        <Dialog
          open={downloadLoading || false}
          size="sm"
          onClose={() => { }}
          title=""
        >
          <Stack
            minHeight={"200px"}
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
          >
            <CircularProgress size={40} />
            <Typography variant="h3" marginTop={2}>
              Download in progress..
            </Typography>
          </Stack>
        </Dialog>
      )}
    </>
  );
};