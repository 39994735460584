import { action } from "typesafe-actions";
import {
  IAutomobileAdditionalInsured,
  IAutomobileAttachment,
  IAutomobileCustomerInfo,
  IAutomobilePolicyAdmin,
  IAutomobilePolicyAdminManager,
  IAutomobilePremium,
  IAutomobilePriorPolicy,
  initialAutomobileDetails,
} from "./automobileBasicDetails.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES, LoadState } from "../../constants/enums";
import moment from "moment";
import { uploadFile } from "../../helpers";
import { makeApiCall } from "../../helpers/postRequest";

export const FETCH_AUTOMOBILE_CUSTOMER_INFO_PROGRESS =
  "FETCH_AUTOMOBILE_CUSTOMER_INFO_PROGRESS";
export const FETCH_AUTOMOBILE_CUSTOMER_INFO_SUCCESS =
  "FETCH_AUTOMOBILE_CUSTOMER_INFO_SUCCESS";
export const FETCH_AUTOMOBILE_CUSTOMER_INFO_FAILED =
  "FETCH_AUTOMOBILE_CUSTOMER_INFO_FAILED";

export const fetchAutomobileCustomerInfoProgress = () =>
  action(FETCH_AUTOMOBILE_CUSTOMER_INFO_PROGRESS);
export const fetchAutomobileCustomerInfoSuccess = (
  customerInfo: IAutomobileCustomerInfo,
  customerPolicyId: number,
  isNewPolicy: boolean
) =>
  action(FETCH_AUTOMOBILE_CUSTOMER_INFO_SUCCESS, {
    customerInfo,
    customerPolicyId,
    isNewPolicy,
  });
export const fetchAutomobileCustomerInfoFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_CUSTOMER_INFO_FAILED, { errorMessage });

export const addAutomobileCustomerInfoAsync =
  (
    data: IAutomobileCustomerInfo,
    initialData: IAutomobileCustomerInfo,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean, policyId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await makeApiCall(
        {
          url: "/policy/edit-policy-auto-customer-info",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "customer_policy_id",
          },
        },
        initialData,
        {
          ...data,
          policy_id: data.sub_type,
          effective_time: data.effective_time,
        }
      );

      const customerPolicyId = res.data.data.customer_policy_id;

      dispatch(
        fetchAutomobileCustomerInfoSuccess(
          res.data.data,
          customerPolicyId,
          true
        )
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Customer information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, customerPolicyId);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileCustomerInfoAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.customerInfo.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobileCustomerInfoProgress());
        const res = await api.get(
          `/policy/get-policy-auto-customer-info?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobileCustomerInfo[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            fetchAutomobileCustomerInfoSuccess(
              {
                ...data[0],
              },
              customerPolicyId,
              false
            )
          );
        } else {
          dispatch(
            fetchAutomobileCustomerInfoFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchAutomobileCustomerInfoFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Additional Insured

export const FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED =
  "FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED";

export const fetchAutomobileAdditionalInsuredListProgress = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_PROGRESS);

export const fetchAutomobileAdditionalInsuredListSuccess = (
  data: IAutomobileAdditionalInsured[]
) => action(FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_SUCCESS, { data });

export const fetchAutomobileAdditionalInsuredListFailed = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INSURED_LIST_FAILED);

export const fetchAutomobileAdditionalInsuredListAsync =
  (
    customerpolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAdditionalInsuredListProgress());

      let finalUrl = `/policy/get-additional-insured?customer_policy_id=${customerpolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileAdditionalInsured[] = res.data.data;
      dispatch(fetchAutomobileAdditionalInsuredListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const upsertAutomobileAdditionalInsuredAsync =
  (
    data: IAutomobileAdditionalInsured,
    onCallback: (isSucces?: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-additional-insured", {
        ...data,
        status: "ACTIVE",
      });

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Insured saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );

      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_AUTOMOBILE_PRIOR_POLICY_PROGRESS =
  "FETCH_AUTOMOBILE_PRIOR_POLICY_PROGRESS";
export const FETCH_AUTOMOBILE_PRIOR_POLICY_SUCCESS =
  "FETCH_AUTOMOBILE_PRIOR_POLICY_SUCCESS";
export const FETCH_AUTOMOBILE_PRIOR_POLICY_FAILED =
  "FETCH_AUTOMOBILE_PRIOR_POLICY_FAILED";

export const fetchAutomobilePriorPolicyProgress = () =>
  action(FETCH_AUTOMOBILE_PRIOR_POLICY_PROGRESS);
export const addAutomobilePriorPolicy = (priorPolicy: IAutomobilePriorPolicy) =>
  action(FETCH_AUTOMOBILE_PRIOR_POLICY_SUCCESS, { priorPolicy });
export const fetchAutomobilePriorPolicyFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_PRIOR_POLICY_FAILED, { errorMessage });

export const addAutomobilePriorPolicyInfoAsync =
  (
    data: IAutomobilePriorPolicy,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-prior-policies", {
        ...data,
        status: "ACTIVE",
      });
      dispatch(addAutomobilePriorPolicy(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Prior policy saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchAutomobilePriorPolicyAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.priorPolicy.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobilePriorPolicyProgress());
        const res = await api.get(
          `/policy/get-policy-auto-prior-policies?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobilePriorPolicy[] = res.data.data;
        if (data.length > 0) {
          dispatch(addAutomobilePriorPolicy(data[0]));
        } else {
          dispatch(
            addAutomobilePriorPolicy(
              initialAutomobileDetails["priorPolicy"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchAutomobilePriorPolicyFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_PREMIUM_PROGRESS =
  "FETCH_AUTOMOBILE_PREMIUM_PROGRESS";

export const FETCH_AUTOMOBILE_PREMIUM_SUCCESS =
  "FETCH_AUTOMOBILE_PREMIUM_SUCCESS";
export const FETCH_AUTOMOBILE_PREMIUM_FAILED =
  "FETCH_AUTOMOBILE_PREMIUM_FAILED";

export const fetchAutomobilePremiumProgress = () =>
  action(FETCH_AUTOMOBILE_PREMIUM_PROGRESS);
export const fetchAutomobilePremiumSuccess = (premium: IAutomobilePremium) =>
  action(FETCH_AUTOMOBILE_PREMIUM_SUCCESS, { premium });
export const fetchAutomobilePremiumFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_PREMIUM_FAILED, { errorMessage });

export const addAutomobilePremiumAsync =
  (
    data: IAutomobilePremium,
    onCallback: (isSuccess: boolean, error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post("/policy/edit-policy-auto-premium", {
        ...data,
      });
      dispatch(fetchAutomobilePremiumSuccess(data));

      onCallback(true);
    } catch (err: any) {
      onCallback(false, err);
    }
  };
export const fetchAutomobilePremiumAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.premium.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobilePremiumProgress());
        const res = await api.get(
          `/policy/get-policy-auto-premium?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobilePremium[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAutomobilePremiumSuccess(data[0]));
        } else {
          dispatch(
            fetchAutomobilePremiumSuccess(
              initialAutomobileDetails["premium"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchAutomobilePremiumFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//policy admin

export const FETCH_AUTOMOBILE_POLICY_ADMIN_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_ADMIN_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_ADMIN_FAILED =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_FAILED";

export const fetchAutomobilePolicyAdminProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_ADMIN_PROGRESS);

export const fetchAutomobilePolicyAdminSuccess = (
  data: IAutomobilePolicyAdmin
) => action(FETCH_AUTOMOBILE_POLICY_ADMIN_SUCCESS, { data });
export const fetchAutomobilePolicyAdminFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_POLICY_ADMIN_FAILED, { errorMessage });

export const addAutomobilePolicyAdminsync =
  (
    data: IAutomobilePolicyAdmin,
    onCallback: (isSuccess: boolean, error?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-policy-admin", {
        ...data,
      });
      dispatch(fetchAutomobilePolicyAdminSuccess(data));

      onCallback(true);
    } catch (err: any) {
      onCallback(false, err);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchAutomobilePolicyAdminAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.policyAdmin.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobilePolicyAdminProgress());
        const res = await api.get(
          `/policy/get-policy-auto-policy-admin?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobilePolicyAdmin[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAutomobilePolicyAdminSuccess(data[0]));
        } else {
          dispatch(
            fetchAutomobilePolicyAdminSuccess(
              initialAutomobileDetails["policyAdmin"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchAutomobilePolicyAdminFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//policy admin manager
export const FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED =
  "FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED";

export const fetchAutomobilePolicyAdminManagerProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_PROGRESS);

export const fetchAutomobilePolicyAdminManagerSuccess = (
  data: IAutomobilePolicyAdminManager
) => action(FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_SUCCESS, { data });
export const fetchAutomobilePolicyAdminManagerFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_POLICY_ADMIN_MANAGER_FAILED, { errorMessage });

export const addAutomobilePolicyAdminManagerAsync =
  (
    data: IAutomobilePolicyAdminManager,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-policy-admin-manager", data);
      dispatch(fetchAutomobilePolicyAdminManagerSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy admin manager saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchAutomobilePolicyAdminManagerAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.policyAdminManager.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobilePolicyAdminManagerProgress());
        const res = await api.get(
          `/policy/get-policy-auto-policy-admin-manager?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobilePolicyAdminManager[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAutomobilePolicyAdminManagerSuccess(data[0]));
        } else {
          dispatch(
            fetchAutomobilePolicyAdminManagerSuccess(
              initialAutomobileDetails["policyAdminManager"]["data"]
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobilePolicyAdminManagerFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH__AUTOMOBILE_ATTACHMENT_PROGRESS =
  "FETCH__AUTOMOBILE_ATTACHMENT_PROGRESS";
export const FETCH__AUTOMOBILE_ATTACHMENT_SUCCESS =
  "FETCH__AUTOMOBILE_ATTACHMENT_SUCCESS";
export const FETCH__AUTOMOBILE_ATTACHMENT_FAILED =
  "FETCH__AUTOMOBILE_ATTACHMENT_FAILED";

export const fetchAutomobileAttachmentProgress = () =>
  action(FETCH__AUTOMOBILE_ATTACHMENT_PROGRESS);
export const fetchAutomobileAttachmentSuccess = (data: IAutomobileAttachment) =>
  action(FETCH__AUTOMOBILE_ATTACHMENT_SUCCESS, { data });
export const fetchAutomobileAttachmentFailed = (errorMessage: string) =>
  action(FETCH__AUTOMOBILE_ATTACHMENT_FAILED, { errorMessage });

export const addAutomobileAttachmentAsync =
  (
    data: IAutomobileAttachment,
    customerId: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerId,
        policy_type: "AUTOMOBILE",
        customer_policy_id: data.customer_policy_id,
        page_name: "Auto Attachment",
      };

      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );

      await api.post("/policy/edit-policy-auto-attachments", {
        ...data,
        file_upload: path,
      });
      // const data = res.data.data;

      dispatch(fetchAutomobileAttachmentSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileAttachmentAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().automobile.basicDetails.attachment.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchAutomobileAttachmentProgress());
        const res = await api.get(
          `/policy/get-policy-auto-attachments?customer_policy_id=${customerPolicyId}`
        );
        const data: IAutomobileAttachment[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchAutomobileAttachmentSuccess(data[0]));
        } else {
          dispatch(
            fetchAutomobileAttachmentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
          );
        }
      }
    } catch (err: any) {
      dispatch(fetchAutomobileAttachmentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_ATTACHMENT_LIST_FAILED =
  "FETCH_AUTOMOBILE_ATTACHMENT_LIST_FAILED";

export const fetchAutomobileAttachmentListProgress = () =>
  action(FETCH_AUTOMOBILE_ATTACHMENT_LIST_PROGRESS);
export const fetchAutomobileAttachmentListSuccess = (
  data: IAutomobileAttachment[]
) => action(FETCH_AUTOMOBILE_ATTACHMENT_LIST_SUCCESS, { data });
export const fetchAutomobileAttachmentListFailed = () =>
  action(FETCH_AUTOMOBILE_ATTACHMENT_LIST_FAILED);

export const fetchAutomobileAttachmentListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAttachmentListProgress());

      let finalUrl = `/policy/get-policy-auto-attachments?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileAttachment[] = res.data.data;
      dispatch(fetchAutomobileAttachmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileAttachmentListAsync =
  (
    data: IAutomobileAttachment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-auto-attachments`, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLAER_AUTOMOBILE_POLICY_ATTACHMENT_STATE =
  "CLAER_AUTOMOBILE_POLICY_ATTACHMENT_STATE";
export const clearAutomobilePolicyAttachment = () =>
  action(CLAER_AUTOMOBILE_POLICY_ATTACHMENT_STATE);

export const CLEAR_AUTOMOBILE_BASIC_DETAILS_STATE =
  "CLEAR_AUTOMOBILE_BASIC_DETAILS_STATE";

export const clearAutomobileBasicPolicyState = () =>
  action(CLEAR_AUTOMOBILE_BASIC_DETAILS_STATE);
