import React from "react";
import { api } from "../api/api";
import { useDispatchWrapper } from "./useDispatch";
import { showMessage } from "../redux/messages/messagesActions";
import { QuoteDefaultCoverage } from "../redux/QuoteSettings/quoteSettings.types";

export const useDefaultQuote = () => {
  const [coverageList, setCoverageList] = React.useState<QuoteDefaultCoverage>();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const fetchDefaultCoverageByCode = async (defaultCoverageCode: string) => {
    try {
      setLoading(true);
      const res = await api.get(
        `/policy/get-policy-auto-default-vcs-schedule-a?default_coverage_code=${defaultCoverageCode}`
      );
      const coverageList: QuoteDefaultCoverage[] = res.data.data || [];

      console.log("coverageList", coverageList)
      
      setCoverageList(coverageList[0] || null);

      return coverageList[0];
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };
 

  return {
    coverageList,
    fetchDefaultCoverageByCode,
    coverageLoading: loading,
    
  };
};
