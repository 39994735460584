import { action } from "typesafe-actions";
import { ICSIOEDocs } from "./csioEdocs.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { fetchCSIOListProgress } from "../csio/csioActions";
import { makeApiCall } from "../../helpers/postRequest";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { transformCSIOToOurEDOCS } from "./transformCSIOToEDOCS";

export const FETCH_CSIO_EDOCS_LIST_PROGRESS = "FETCH_CSIO_EDOCS_LIST_PROGRESS";
export const FETCH_CSIO_EDOCS_LIST_SUCCESS = "FETCH_CSIO_EDOCS_LIST_SUCCESS";
export const FETCH_CSIO_EDOCS_LIST_FAILED = "FETCH_CSIO_EDOCS_LIST_FAILED";

export const fetchCsioEdocsListProgress = () =>
  action(FETCH_CSIO_EDOCS_LIST_PROGRESS);
export const fetchCsioEdocsListSuccess = (
  data: ICSIOEDocs[],
  totalRecords: number
) =>
  action(FETCH_CSIO_EDOCS_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchCsioEdocsListFailed = () =>
  action(FETCH_CSIO_EDOCS_LIST_FAILED);

export const fetchCsioEdocsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    customerPolicyId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/csio/get-csio-edocs?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-edocs?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (customerPolicyId) {
        finalUrl += "&customer_policy_id=" + customerPolicyId;
      }
      const res = await makeApiCall({
        method: "GET",
        url: finalUrl,
      });
      const data: ICSIOEDocs[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCsioEdocsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCsioEdocsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_CSIO_EDOCS_PROGRESS = "FETCH_CSIO_EDOCS_PROGRESS";
export const FETCH_CSIO_EDOCS_SUCCESS = "FETCH_CSIO_EDOCS_SUCCESS";
export const FETCH_CSIO_EDOCS_FAILED = "FETCH_CSIO_EDOCS_FAILED";

export const fetchCsioEdocsProgress = () => action(FETCH_CSIO_EDOCS_PROGRESS);
export const fetchCsioEdocsSuccess = (data: ICSIOEDocs) =>
  action(FETCH_CSIO_EDOCS_SUCCESS, {
    data,
  });
export const fetchCsioEdocsFailed = (errorMessage: string) =>
  action(FETCH_CSIO_EDOCS_FAILED, { errorMessage });

export const fetchCsioEdocsAsync =
  (
    CustomerPolicyId: string,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCsioEdocsProgress());

      const res = await makeApiCall({
        method: "GET",
        url: `/csio/get-csio-edocs?customer_policy_id=${CustomerPolicyId}`,
      });
      const data = res.data.data;
      if (data.length > 0) {
        if (CustomerPolicyId && status) {
          dispatch(fetchCsioEdocsSuccess(data));
        } else {
          dispatch(fetchCsioEdocsSuccess(data[0]));
        }
      } else {
        dispatch(
          fetchCsioEdocsFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchCsioEdocsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_MANAGE_CSIO_EDOCS_PROGRESS =
  "FETCH_MANAGE_CSIO_EDOCS_PROGRESS";
export const FETCH_MANAGE_CSIO_EDOCS_SUCCESSS =
  "FETCH_MANAGE_CSIO_EDOCS_SUCCESSS";
export const FETCH_MANAGE_CSIO_EDOCS_FAILED = "FETCH_MANAGE_CSIO_EDOCS_FAILED";

export const fetchManageCsioEdocsProgress = () =>
  action(FETCH_MANAGE_CSIO_EDOCS_PROGRESS);
export const fetchManageCsioEdocsSuccess = (data: ICSIOEDocs) =>
  action(FETCH_MANAGE_CSIO_EDOCS_SUCCESSS, { data });
export const fetchManageCsioEdocsFailed = (error?: string) =>
  action(FETCH_MANAGE_CSIO_EDOCS_FAILED, { error });

export const fetchManageCsioEdocsAsync =
  (messageGUid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchManageCsioEdocsProgress());

      const res = await makeApiCall({
        method: "GET",
        url: `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`,
      });

      if (res && res.data && res.data.data) {
        const data = res.data.data;

        if (data.length > 0) {
          const { xml_json_message, attachment } = data[0];
          try {
            const parsedData = JSON.parse(xml_json_message);
            const result = transformCSIOToOurEDOCS(parsedData, attachment);
            const enhancedresult = { ...result, attachment };
            dispatch(fetchManageCsioEdocsSuccess(enhancedresult));
          } catch (parseError) {
            dispatch(
              fetchManageCsioEdocsFailed("Failed to parse JSON message")
            );
          }
        } else {
          console.log("No record found.");
          dispatch(fetchManageCsioEdocsFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
        }
      } else {
        console.log("No record found in response data.");
        dispatch(fetchManageCsioEdocsFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      console.error("Fetch error:", err);
      dispatch(fetchManageCsioEdocsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertCsioEdocsAsync =
  (
    data: ICSIOEDocs,
    initialData: ICSIOEDocs,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      //   const asPayload = {
      //     brokerage_equipment_code: data.brokerage_equipment_code,
      //   };
      //   const path = await uploadFile(
      //     file,
      //     "BROKERAGE_EQUIPMENT",
      //     data.attachments || "",
      //     asPayload
      //   );
      await makeApiCall(
        {
          url: "/csio/create-csio-edocs",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "brokerage_equipment_code",
          },
        },
        initialData,
        {
          ...data,
          //    attachments: path,
        }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "CSIO EDOCS saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_CSIO_EDOCS_STATE = "CLEAR_CSIO_EDOCS_STATE";
export const clearCsioEdocsState = () => action(CLEAR_CSIO_EDOCS_STATE);
