/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useQueryParms } from "../../hooks";
import { StandadCard } from "../../components/Cards";
import { AccountCircle, AdminPanelSettings, Assignment } from "@mui/icons-material";
import { TabPanel } from "../../components/Tabs/TabPanel";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../components/Tabs/utils";
import { PageContainer } from "../../components/container/PageContainer";
import { Leads } from "./Leads";
import { IPremissions } from "../../security/RolesProvider/RolesProvider";
import { BeforeCreateTask } from "../tasks/BeforeCreate/BeforeCreate";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { MODULE_IDS, MODULE_NAMES } from "../../constants/enums";
import { BusinessTasks } from "../../components/Activity/BusinessTasks/BusinessTasks";

export const LeadsTabs: React.FC= () => {

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "All-leads",
    },
  ];
  // const { premissions } = props;
  const [tab, setTab] = React.useState(0);
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const navigate = useNavigate();

  const handleChangetab = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/leads?tab=${newValue}`);
  };

  return (
    <PageContainer title="View Leads" description="this is innerpage">
        <Breadcrumb title="View Leads" items={BCrumb} /> 
    
       <Box display={'flex'} justifyContent={'center'}>
       <Tabs
          value={selectedTab}
          onChange={handleChangetab}
          sx={{mb: {
            md: 0.5,
            lg: 0.8,
            xl: 1
          }, mt: {
            md: 0.5,
            lg: 1.5,
            xl: 3,
          }}}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="All Leads"
          
            sx={{fontSize: '1.1rem',fontWeight: 600, minWidth: 200}}
            {...a11yProps(0)}
          />
          <Tab
            sx={{fontSize: '1.1rem',fontWeight: 600, minWidth: 200 }}
            icon={<Assignment />}
            iconPosition="start"
            label="All Tasks"
            {...a11yProps(1)}
          />
        </Tabs>

       </Box>
       
   
      {/* <Divider /> */}
      <TabPanel value={selectedTab} index={0}>
        <Leads />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <BusinessTasks moduleName={MODULE_NAMES.LEADS} subModuleName={MODULE_NAMES.LEADS}/>
      </TabPanel>
    </PageContainer>
  );
};
