import { LoadState } from "../../constants/enums";
import { IInterestedParty } from "../InterestedParties/IntrestedParties.types";
import { ICommAutomobileVehicleInformation } from "../commAutoPolicy/commAutoVehicle/commAutoVehicle.types";

export interface ICommonState {
  policies: {
    list: IPolicies[];
    loadState: LoadState;
  };
  generalHistory: {
    list: IGeneralHistory[];
    loading: LoadState;
  };
  recordCounts: {
    list: IRecordCount[];
    loading: LoadState;
  };
  vehiclesByPolicyNumber: {
    list: IVehcileByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
  driversByPolicy: {
    list: IDriverByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
  riskLocationByPolicy: {
    list: IRiskLocationByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
  customerDetail: {
    data: ICustomerDetail;
    loading: LoadState;
  };
  modules: {
    tableNames: {
      data: string[];
      loading: LoadState;
    };
    tableViews: {
      data: string[];
      loading: LoadState;
    };
  };
  endPointsByModule: {
    data: {
      [key: string]: string[];
    };
    loading: LoadState;
  };
}

export interface IVehcileByPolicyNumber {
  customer_name: string;
  policy_number: string;
  incremental_number: number;
  unit_number: string;
  year: string;
  make: string;
  model: string;
  vin: string;
  puchase_condition: string | null;
  purchase_price: string | null;
  plate_number: string | null;
  purchase_date: string | null;
  annual_kms: string | null;
  list_price_new: string | null;
}

export interface IDriverByPolicyNumber {
  customer_policy_id: number;
  driver_number: number;
  driver_name: string;
  date_of_birth: string;
  age: string;
  gender: string;
  marital_status: string;
  driver_phone_number: string;
  license_number: string;
  expiry_date_of_license: string;
  province: string;
  license_status: string;
  mvr_status: string;
  last_mvr_date: string;
}

export interface IRiskLocationByPolicyNumber {
  policy_business_risk_location_id: number;
  customer_policy_id: number;
  location_number: number;
  customer_id: number;
  address: string;
  unit_or_suite: string;
  city: string;
  province_or_state: string;
  postal_code: string;
  country: string;
  year_built: string;
  number_of_stories: string;
  total_building_area: string;
  area_occupied: string;
  risk_inspected: string;
  date_inspected: string;
  walls: string;
  floors: string;
  roof: string;
  roof_coverings: string;
  electrical: string;
  heating: string;
  plumbing: string;
  basement: string;
}

export interface IRecordCount {
  name: string;
  cnt: number;
}

export interface IPolicies {
  type: string;
  subTypes: {
    policy_id: number;
    name: string;
  }[];
}

export interface IGeneralHistory {
  history_id: number;
  history_code: string;
  module_name: string;
  module_code: string;
  record_id: number;
  message: string;
  module_column_name: string;
  created_by_id: number;
  modified_by_id: number;
  create_ts: string;
  insert_ts: string;
  created_by_name: string;
}

export interface ICustomerDetail {
  customer_policy_id: string | null;
  customer_id: string | null;
  customer_name: string | null;
  customer_code: string | null;
  form_of_business: string | null;
  language: string | null;
  line_of_business: string | null;
  segment: string | null;
  sub_type: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  transaction_type: string | null;
  residential_or_business: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  policy_status: string | null;
  date_cancelled: string | null;
  cancellation_reason: string | null;
  cancellation_type: string | null;
  program: string | null;
  type_of_policy: string | null;
  quote_number: string | null;
  binder_number: string | null;
  show_binder_number: string | null;
  policy_number: string | null;
  effective_time: string | null;
  send_invoices_renewal_and_receivable_letter_via_emails: string | null;
  send_statements_via_email: string | null;
  send_eDocs_via_email: string | null;
  status: string | null;
  policy_sub_type: string | null;
  csr: string | null;
  marketer: string | null;
  branch_code: string | null;
  policy_premium: number | null;
  agency_bill_or_direct_bill: string | null;
  payment_plan: string | null;
  premium_finance_contract_number: string | null;
  continuous: boolean | null;
  financed_by: string | null;
  default_tax_region: string | null;
  tax_1: number | null;
  tax_2: number | null;
  tax_exempt: boolean | null;
  tax_exempt_reason: string | null;
  insurer: string | null;
  broker_code: string | null;
  commission_code: string | null;
  policy_fee: number | null;
  house_percentage: number | null;
  producer_1: string | null;
  producer_1_code: string | null;
  producer_1_percentage: number | null;
  producer_2: string | null;
  producer_2_code: string | null;
  producer_2_percentage: number | null;
  producer_3: string | null;
  producer_3_code: string | null;
  producer_3_percentage: number | null;
  marketer_code: string | null;
  csr_code: string | null;
  billing_account_number: string | null;
}

export const defaultCommonState: ICommonState = {
  policies: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  generalHistory: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  recordCounts: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  vehiclesByPolicyNumber: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
  driversByPolicy: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
  riskLocationByPolicy: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
  customerDetail: {
    data: {
      customer_policy_id: null,
      customer_id: null,
      customer_name: null,
      customer_code: null,
      form_of_business: null,
      language: null,
      line_of_business: null,
      segment: null,
      sub_type: null,
      effective_date: null,
      expiry_date: null,
      transaction_type: null,
      residential_or_business: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      policy_status: null,
      date_cancelled: null,
      cancellation_reason: null,
      cancellation_type: null,
      program: null,
      type_of_policy: null,
      quote_number: null,
      binder_number: null,
      show_binder_number: null,
      policy_number: null,
      effective_time: null,
      send_invoices_renewal_and_receivable_letter_via_emails: null,
      send_statements_via_email: null,
      send_eDocs_via_email: null,
      status: null,
      policy_sub_type: null,
      csr: null,
      marketer: null,
      branch_code: null,
      policy_premium: null,
      agency_bill_or_direct_bill: null,
      payment_plan: null,
      premium_finance_contract_number: null,
      continuous: null,
      financed_by: null,
      default_tax_region: null,
      tax_1: null,
      tax_2: null,
      tax_exempt: null,
      tax_exempt_reason: null,
      insurer: null,
      broker_code: null,
      commission_code: null,
      policy_fee: null,
      house_percentage: null,
      producer_1: null,
      producer_1_code: null,
      producer_1_percentage: null,
      producer_2: null,
      producer_2_code: null,
      producer_2_percentage: null,
      producer_3: null,
      producer_3_code: null,
      producer_3_percentage: null,
      marketer_code: null,
      csr_code: null,
      billing_account_number: null,
    },
    loading: LoadState.NotLoaded,
  },
  modules: {
    tableNames: {
      data: [],
      loading: LoadState.NotLoaded,
    },
    tableViews: {
      data: [],
      loading: LoadState.NotLoaded,
    },
  },
  endPointsByModule: {
    data: {},
    loading: LoadState.NotLoaded,
  },
};

export interface IMail {
  from_email: string | null;
  from_name: string | null;
  emails: string[];
  subject: string;
  body: string;
  templateName: string | null;
  templateId: string | null;
  objectVariables: any;
  cc: string[];
  bcc: string[];
  reply_to?: {
    email: string;
    name: string;
  };
  attachments?: {
    content: string;
    filename: string;
  }[];
  history?: {
    module_name: string;
    module_code: string;
  };
}
