/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Link } from "@mui/material";
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { MarketingDialog } from "./MarketingDialog";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IBusinessPolicyMarketingHistory } from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import {
  clearBusinessPolicyMarketingState,
  fetchBusinessPolicyMarketingHistListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { AddOutlined } from "@mui/icons-material";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { COMMON_STATUS } from "../../../../../../constants/constants";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const MarketingList = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerpolicyId = data[1];
  const navigate = useNavigate();

  const [openSubmitMarketing, setOpenSubmitMarketing] = React.useState(false);
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.marketing.marketingHistory
  );
  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last90Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/business-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history`
    );
  };

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(
        fetchBusinessPolicyMarketingHistListAsync(customerpolicyId, status)
      );
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearBusinessPolicyMarketingState());
    };
  }, [customerpolicyId, status]);

  const handleToggleSubmitMarketing = () => {
    setOpenSubmitMarketing(!openSubmitMarketing);
  };

  const handleComplete = () => {
    dispatch(fetchBusinessPolicyMarketingHistListAsync(customerpolicyId));
    setOpenSubmitMarketing(false);
  };

  const marketingActivityTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyMarketingHistory) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history/${row.serial_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyMarketingHistory) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "serial_number",
        headerName: "Serial Number",
        fieldName: "serial_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row: IBusinessPolicyMarketingHistory) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/marketing-tab/${customerpolicyId}/marketing-history/${row.serial_number}`
                );
              }}
            >
              {row.serial_number}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: "Cutomer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "underwriter",
        headerName: "Underwriter",
        fieldName: "underwriter",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "quote_number",
        headerName: "Quote Number",
        fieldName: "quote_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "quote_status",
        headerName: "Quote Status",
        fieldName: "quote_status",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        enableSorting: true,
      },

      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        // exportCellWidth: 25,
        enableSorting: true,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "submission_date",
        headerName: "Submission Date",
        fieldName: "submission_date",
        // exportCellWidth: 25,
        enableSorting: true,
        renderType: DataTableV2RowRenderType.DATE,
      },
    ],
    rows: list,
  };

  return (
    <>
      <PageLoader loading={false}>
        <PageTitleBar
          heading="Marketing"
          rightHeading={
            <>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                onClick={handleToggleSubmitMarketing}
              >
                Submit for Marketing
              </Button>
              <Button
                variant="contained"
                onClick={handleCreate}
                startIcon={<AddOutlined />}
              >
                Create
              </Button>
            </>
          }
        />
        <DataTableV2 {...marketingActivityTableProps} />
      </PageLoader>
      {openSubmitMarketing && (
        <MarketingDialog
          open={true}
          customerPolicyId={customerpolicyId}
          onClose={handleToggleSubmitMarketing}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
