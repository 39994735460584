import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  IAutomobileQuote,
  initialAutomobileQuoteState,
} from "./automobileQuote.types";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

export const FETCH_AUTOMOBILE_QUOTE_PROGRESS =
  "FETCH_AUTOMOBILE_QUOTE_PROGRESS";
export const FETCH_AUTOMOBILE_QUOTE_SUCCESSS =
  "FETCH_AUTOMOBILE_QUOTE_SUCCESSS";
export const FETCH_AUTOMOBILE_QUOTE_FAILED = "FETCH_AUTOMOBILE_QUOTE_FAILED";

export const fetchAutomobileQuoteProgress = () =>
  action(FETCH_AUTOMOBILE_QUOTE_PROGRESS);
export const fetchAutomobileQuoteSuccess = (data: IAutomobileQuote) =>
  action(FETCH_AUTOMOBILE_QUOTE_SUCCESSS, { data });
export const fetchAutomobileQuoteFailed = () =>
  action(FETCH_AUTOMOBILE_QUOTE_FAILED);

export const fetchAutomobileQuoteAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileQuoteProgress());
      const res = await api.get(
        `/policy/get-policy-auto-quote?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileQuote[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobileQuoteSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileQuoteSuccess(initialAutomobileQuoteState["data"])
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileQuoteFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileQuote =
  (
    data: IAutomobileQuote,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-quote", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Quote saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileQuoteSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_AUTOMOBILE_QUOTE_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_QUOTE_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_QUOTE_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_QUOTE_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_QUOTE_LIST_FAILED =
  "FETCH_AUTOMOBILE_QUOTE_LIST_FAILED";

export const fetchAutomobileQuoteListProgress = () =>
  action(FETCH_AUTOMOBILE_QUOTE_LIST_PROGRESS);

export const fetchAutomobileQuotesListSuccess = (data: IAutomobileQuote[]) =>
  action(FETCH_AUTOMOBILE_QUOTE_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileQuoteListFailed = () =>
  action(FETCH_AUTOMOBILE_QUOTE_LIST_FAILED);

export const fetchAutomobileQuoteListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileQuoteListProgress());

      let finalUrl = `/policy/get-policy-auto-quote?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileQuote[] = res.data.data;
      dispatch(fetchAutomobileQuotesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileQuoteListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_QUOTE_STATE =
  "CLEAR_AUTOMOBILE_QUOTE_STATE";

export const clearAutomobileQuoteState = () =>
  action(CLEAR_AUTOMOBILE_QUOTE_STATE);
