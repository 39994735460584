import { action } from "typesafe-actions";
import {
  IAutomobileConsentForm,
  IAutomobileGarageForm,
  IAutomobileLiabilityInsurance,
  IAutomobileLiabilitySlips,
  IAutomobileOPCF16,
  IAutomobileOPCF17,
  IAutomobileOPCF28A,
  IAutomobilePolicyAuthorization,
  IAutomobilePolicyForm,
  IAutomobileRetireeDiscount,
  IAutomobileSharingFillable,
  IAutomobileSupplementaryForm,
} from "./automobliePolicyForms.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_POLICY_FORMS_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_POLICY_FORMS_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_POLICY_FORMS_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_POLICY_FORMS_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_POLICY_FORMS_LIST_FAILED =
  "FETCH_AUTOMOBILE_POLICY_FORMS_LIST_FAILED";

export const fetchAutomobilePolicyFormsListProgress = () =>
  action(FETCH_AUTOMOBILE_POLICY_FORMS_LIST_PROGRESS);
export const fetchAutomobilePolicyFormsListSuccess = (
  data: IAutomobilePolicyForm[],
  totalRecords: number
) => action(FETCH_AUTOMOBILE_POLICY_FORMS_LIST_SUCCESS, { data, totalRecords });
export const fetchAutomobilePolicyFormsListFailed = () =>
  action(FETCH_AUTOMOBILE_POLICY_FORMS_LIST_FAILED);

export const fetchAutomobilePolicyFormsListAsync =
  (
    customerPolicyId: number,
    type: string,
    pageNumber: number,
    rowPerPage: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilePolicyFormsListProgress());
      const url = `/policy/get-policy-commercial-and-automobile-all-policy-forms?customer_policy_id=${customerPolicyId}&policy_form_type=${type}&table_name=automobile&pageNo=${pageNumber}&itemPerPage=${rowPerPage}`;
      const res = await api.get(url);
      const data: IAutomobilePolicyForm[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchAutomobilePolicyFormsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAutomobilePolicyFormsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Liability Slip

export const FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchAutomobileLiabilitySlipProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchAutomobileLiabilitySlipSuccess = (
  data: IAutomobileLiabilitySlips
) => action(FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addAutomobileLiabilitySlipAsync =
  (
    data: IAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Liability Insurance

export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchAutomobileLiabilityInsuranceSuccess = (
  data: IAutomobileLiabilityInsurance
) => action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchAutomobileLiabilityInsuranceFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addAutomobileLiabilityInsuranceAsync =
  (
    data: IAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileLiabilityInsurancePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileLiabilityInsurancePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_insurance_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// OpCF16

export const FETCH_AUTOMOBILE_OPCF16_PROGRESS =
  "FETCH_AUTOMOBILE_OPCF16_PROGRESS";
export const FETCH_AUTOMOBILE_OPCF16_SUCCESS =
  "FETCH_AUTOMOBILE_OPCF16_SUCCESS";
export const FETCH_AUTOMOBILE_OPCF16_FAILED = "FETCH_AUTOMOBILE_OPCF16_FAILED";

export const fetchAutomobileOcep16rogress = () =>
  action(FETCH_AUTOMOBILE_OPCF16_PROGRESS);
export const fetchAutomobileOcep16Success = (data: IAutomobileOPCF16) =>
  action(FETCH_AUTOMOBILE_OPCF16_SUCCESS, { data });
export const fetchAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OPCF16_FAILED, { errorMessage });

export const addAutomobileOcep16sync =
  (
    data: IAutomobileOPCF16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileOPCF16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR))
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR))
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// OPCF17
export const FETCH_AUTOMOBILE_OPCF17_PROGRESS =
  "FETCH_AUTOMOBILE_OPCF17_PROGRESS";
export const FETCH_AUTOMOBILE_OPCF17_SUCCESS =
  "FETCH_AUTOMOBILE_OPCF17_SUCCESS";
export const FETCH_AUTOMOBILE_OPCF17_FAILED = "FETCH_AUTOMOBILE_OPCF17_FAILED";

export const fetchAutomobileOcep17rogress = () =>
  action(FETCH_AUTOMOBILE_OPCF17_PROGRESS);
export const fetchAutomobileOcep17Success = (data: IAutomobileOPCF17) =>
  action(FETCH_AUTOMOBILE_OPCF17_SUCCESS, { data });
export const fetchAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OPCF17_FAILED, { errorMessage });

export const addAutomobileOcep17sync =
  (
    data: IAutomobileOPCF17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileOPCF17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_OPCF28A_PROGRESS =
  "FETCH_AUTOMOBILE_OPCF28A_PROGRESS";
export const FETCH_AUTOMOBILE_OPCF28A_SUCCESS =
  "FETCH_AUTOMOBILE_OPCF28A_SUCCESS";
export const FETCH_AUTOMOBILE_OPCF28A_FAILED =
  "FETCH_AUTOMOBILE_OPCF28A_FAILED";

export const fetchAutomobileOcep28Arogress = () =>
  action(FETCH_AUTOMOBILE_OPCF28A_PROGRESS);
export const fetchAutomobileOcep28ASuccess = (data: IAutomobileOPCF28A) =>
  action(FETCH_AUTOMOBILE_OPCF28A_SUCCESS, { data });
export const fetchAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OPCF28A_FAILED, { errorMessage });

export const addAutomobileOcep28Async =
  (
    data: IAutomobileOPCF28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileOPCF28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// Payment Authorization

export const FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS =
  "FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS";
export const FETCH_AUTOMOBILE_AUTHORIZATION_FAILED =
  "FETCH_AUTOMOBILE_AUTHORIZATION_FAILED";

export const fetchAutomobileAuthorizationProgress = () =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_PROGRESS);
export const fetchAutomobileAuthorizationSuccess = (
  data: IAutomobilePolicyAuthorization
) =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_SUCCESS, {
    data,
  });
export const fetchAutomobileAuthorizationFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_AUTHORIZATION_FAILED, { errorMessage });

export const addAutomobileAuthorizationAsync =
  (
    data: IAutomobilePolicyAuthorization,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-paf", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Authorization saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileAuthorizationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAuthorizationProgress());
      const res = await api.get(
        `/policy/get-policy-auto-paf?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobilePolicyAuthorization[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileAuthorizationSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileAuthorizationFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const downloadAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      fetchAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileAuthorizationPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-paf-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_auto_paf_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      fetchAutomobileAuthorizationFailed(ERROR_MESSAGES.SERVER_ERROR)
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//----------Consent Form--------------------->

export const FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS";
export const FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS";
export const FETCH_AUTOMOBILE_CONSENT_FORM_FAILED =
  "FETCH_AUTOMOBILE_CONSENT_FORM_FAILED";

export const fetchAutomobileConsentFormProgress = () =>
  action(FETCH_AUTOMOBILE_CONSENT_FORM_PROGRESS);
export const fetchAutomobileConsentFormSuccess = (
  data: IAutomobileConsentForm
) => action(FETCH_AUTOMOBILE_CONSENT_FORM_SUCCESS, { data });
export const fetchAutomobileConsentFormFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_CONSENT_FORM_FAILED, { errorMessage });

export const addAutomobileConsentFormAsync =
  (
    data: IAutomobileConsentForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-privacy-consent-form", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileConsentFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileConsentFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileConsentForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileConsentFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileConsentFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileConsentFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-privacy-consent-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_privacy_consent_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileConsentFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//---------------Retiree Discount-------------------->

export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS";
export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS";
export const FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED =
  "FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED";

export const fetchAutomobileRetireeDiscountProgress = () =>
  action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS);
export const fetchAutomobileRetireeDiscountSuccess = (
  data: IAutomobileRetireeDiscount
) => action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS, { data });
export const fetchAutomobileRetireeDiscountFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_RETIREE_DISCOUNT_FAILED, { errorMessage });

export const addAutomobileRetireeDiscountAsync =
  (
    data: IAutomobileRetireeDiscount,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-declaration-for-retiree-discount",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileRetireeDiscountAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileRetireeDiscountProgress());
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileRetireeDiscount[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileRetireeDiscountSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileRetireeDiscountPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-declaration-for-retiree-discount-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_declaration_for_retiree_discount_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileRetireeDiscountFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//----------------------Garage Form ------------------->

export const FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS =
  "FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS";
export const FETCH_AUTOMOBILE_GARAGE_FORM_FAILED =
  "FETCH_AUTOMOBILE_GARAGE_FORM_FAILED";

export const fetchAutomobileGarageFormProgress = () =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_PROGRESS);
export const fetchAutomobileGarageFormSuccess = (data: IAutomobileGarageForm) =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_SUCCESS, { data });
export const fetchAutomobileGarageFormFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_GARAGE_FORM_FAILED, { errorMessage });

export const addAutomobileGarageFormAsync =
  (
    data: IAutomobileGarageForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-alberta-application-garage-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileGarageFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileGarageFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileGarageForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileGarageFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileGarageFormFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileGarageFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-alberta-application-garage-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_business_privacy_consent_form_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileGarageFormFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//----------------Ride Sharing-------------->

export const FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS";
export const FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED =
  "FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED";

export const fetchAutomobileSharingFillableProgress = () =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_PROGRESS);
export const fetchAutomobileSharingFillableSuccess = (
  data: IAutomobileSharingFillable
) => action(FETCH_AUTOMOBILE_SHARING_FILLABLE_SUCCESS, { data });
export const fetchAutomobileSharingFillableFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_SHARING_FILLABLE_FAILED, { errorMessage });

export const addAutomobileSharingFillableAsync =
  (
    data: IAutomobileSharingFillable,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-form-ride-sharing-fillable", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileSharingFillableAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSharingFillableProgress());
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileSharingFillable[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileSharingFillableSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileSharingFillablePDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-ride-sharing-fillable-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_ride_sharing_fillable_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileSharingFillableFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

//---------------Supplementary Form---------------->

export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS";
export const FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED =
  "FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED";

export const fetchAutomobileSupplementaryFormProgress = () =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_PROGRESS);
export const fetchAutomobileSupplementaryFormSuccess = (
  data: IAutomobileSupplementaryForm
) => action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_SUCCESS, { data });
export const fetchAutomobileSupplementaryFormFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_SUPPLEMENTARY_FORM_FAILED, { errorMessage });

export const addAutomobileSupplementaryFormAsync =
  (
    data: IAutomobileSupplementaryForm,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(
        "/policy/edit-policy-form-garage-automobile-supplementary-form",
        {
          ...data,
        }
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Conset Form saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAutomobileSupplementaryFormAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileSupplementaryFormProgress());
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileSupplementaryForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileSupplementaryFormSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileSupplementaryFormFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileSupplementaryFormPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-form-garage-automobile-supplementary-form-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_garage_automobile_supplementary_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(
        fetchAutomobileSupplementaryFormFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_POLICY_FORMS_STATE =
  "CLEAR_AUTOMOBILE_POLICY_FORMS_STATE";
export const clearAutomobilePolicyFormsState = () =>
  action(CLEAR_AUTOMOBILE_POLICY_FORMS_STATE);
