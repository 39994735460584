import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IGroupedInsuranceReports,
  IInsuranceReports,
} from "./insuranceReports.types";
import { INoPolicyInsuranceReports } from "../../views/tasks/InsuranceReports/insuranceReport.types";
import { ERROR_MESSAGES } from "../../constants/enums";
import { uploadFile } from "../../components/FileUpload/utils";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_INSURANCE_REPORTS_LIST_PROGRESS =
  "FETCH_INSURANCE_REPORTS_LIST_PROGRESS";
export const FETCH_INSURANCE_REPORTS_LIST_SUCCESS =
  "FETCH_INSURANCE_REPORTS_LIST_SUCCESS";
export const FETCH_INSURANCE_REPORTS_LIST_FAILED =
  "FETCH_INSURANCE_REPORTS_LIST_FAILED";

export const FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_PROGRESS =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_PROGRESS";
export const FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_SUCCESS =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_SUCCESS";
export const FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_FAILED =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_FAILED";
  
  export const FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_PROGRESS =
    "FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_PROGRESS";
  export const FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_SUCCESS =
    "FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_SUCCESS";
  export const FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_FAILED =
    "FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_FAILED";
    
export const fetchInsuranceReportsListProgress = () =>
  action(FETCH_INSURANCE_REPORTS_LIST_PROGRESS);
export const fetchInsuraceReportsListSuccess = (
  list: IGroupedInsuranceReports[],
  totalRecords: number
) => action(FETCH_INSURANCE_REPORTS_LIST_SUCCESS, { list, totalRecords });
export const fetchInsuranceReportsListFailed = () =>
  action(FETCH_INSURANCE_REPORTS_LIST_FAILED);

export const fetchNoPolicyInsuranceReportsListProgress = () =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_PROGRESS);
export const fetchNoPolicyInsuraceReportsListSuccess = (
  noPolicyList: INoPolicyInsuranceReports[],
  noPolicyTotalRecords: number
) =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_SUCCESS, {
    noPolicyList,
    noPolicyTotalRecords,
  });
export const fetchNoPolicyInsuranceReportsListFailed = () =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_FAILED);

export const fetchNoPolicyInsuranceReportsLeadListProgress = () =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_PROGRESS);
export const fetchNoPolicyInsuranceReportsLeadListSuccess = (
  noPolicyList: INoPolicyInsuranceReports[],
  noPolicyTotalRecords: number
) =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_SUCCESS, {
    noPolicyList,
    noPolicyTotalRecords,
  });
export const fetchNoPolicyInsuranceReportsLeadListFailed = () =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_LEAD_LIST_FAILED);

const execGroupData = (
  data: IInsuranceReports[]
): IGroupedInsuranceReports[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.customer_policy_id]) {
        //@ts-ignore
        acc[obj.customer_policy_id] = {
          id: obj.customer_policy_id,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.customer_policy_id].childs.push(obj);
      }
      return acc;
    }, {})
  );
  return groupedData as IGroupedInsuranceReports[];
};

export const fetchInsuranceReportsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInsuranceReportsListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&column=request_status&value=" + status;
      }
      let finalUrl = `/tasks/get-tasks-driver-insurance-report?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/tasks/get-tasks-driver-insurance-report?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);

      const data: IInsuranceReports[] = res.data.data;
      const finalData = execGroupData(data);

      const totalRecords = res.data.totalRecords;

      dispatch(fetchInsuraceReportsListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchInsuranceReportsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchNoPolicyInsuranceReportsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    compliantStatus: string,
    leadId?: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoPolicyInsuranceReportsListProgress());
      
      let finalUrl = `/policy/get-no-policy-driver-insurance-report?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl = `/policy/get-no-policy-driver-insurance-report?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&column=request_status&value=" + status;
      }
      if(compliantStatus !== "-1"){
        finalUrl = finalUrl+"&status=" + compliantStatus;
      }
      if(leadId){
        finalUrl = finalUrl+"&lead_id=" + leadId;
        
      }
      const res = await api.get(finalUrl);

      const data: INoPolicyInsuranceReports[] = res.data.data;
      //  const finalData = execGroupData(data);

      const totalRecords = res.data.totalRecords;

      dispatch(fetchNoPolicyInsuraceReportsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchNoPolicyInsuranceReportsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_NO_POLICY_INSURANCE_REPORTS_PROGRESS =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_PROGRESS";
export const FETCH_NO_POLICY_INSURANCE_REPORTS_SUCCESS =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_SUCCESS";
export const FETCH_NO_POLICY_INSURANCE_REPORTS_FAILED =
  "FETCH_NO_POLICY_INSURANCE_REPORTS_FAILED";

export const fetchNoPolicyInsuranceReportsProgress = () =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_PROGRESS);
export const fetchNoPolicyInsuranceReportsSuccess = (
  data: INoPolicyInsuranceReports
) => action(FETCH_NO_POLICY_INSURANCE_REPORTS_SUCCESS, { data });
export const fetchNoPolicyInsuranceReportsFailed = (errorMessage: string) =>
  action(FETCH_NO_POLICY_INSURANCE_REPORTS_FAILED, { errorMessage });

export const addNoPolicyDriverInsuranceReportAsync =
  (
    data: INoPolicyInsuranceReports,
    file: any | null,
    file_two: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        license_number: data.license_number,
      };
      const path = await uploadFile(
        file,
        "NO_POLICY",
        data.file_upload || "",
        asPayload
      );
      const path_two = await uploadFile(
        file_two,
        "NO_POLICY",
        data.file_upload_two || "",
        asPayload
      );
      const res = await api.post(
        "/policy/edit-no-policy-driver-insurance-report",
        {
          ...data,
          file_upload: path,
          file_upload_two: path_two,
        }
      );
      // dispatch(fetchNoPolicyInsuranceReportsSuccess(res.data.data[0]));
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "No Policy Insurance Report saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchNoPolicyDriverInsuranceReportAsync =
  (licenseNumber: string,incremental_number: string,): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoPolicyInsuranceReportsProgress());
      const res = await api.get(
        `/policy/get-no-policy-driver-insurance-report?license_number=${licenseNumber}&incremental_number=${incremental_number}`
      );
      const data: INoPolicyInsuranceReports[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchNoPolicyInsuranceReportsSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchNoPolicyInsuranceReportsFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(
        fetchNoPolicyInsuranceReportsFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

