import produce from "immer";
import { AccountingDashboardActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialAccountingDashboardState } from "./accountingDashboard.types";
import {
  CLEAR_ACCOUNTING_DASHBOARD_STATE,
  FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_FAILED,
  FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_PROGRESS,
  FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_SUCCESS,
} from "./accountingDashboardActions";
import { LoadState } from "../../constants/enums";

export const AccountingDashboardReducer = (
  state: IStoreState["accountingDashbaord"] = initialAccountingDashboardState,
  action: AccountingDashboardActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.earningGraph = initialAccountingDashboardState.earningGraph;
        draftState.earningGraphLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.earningGraph = data;
        draftState.earningGraphLoading = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_DASHBOARD_EARNING_GRAPH_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.earningGraph = initialAccountingDashboardState.earningGraph;
        draftState.earningGraphLoading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_ACCOUNTING_DASHBOARD_STATE: {
      return initialAccountingDashboardState;
    }
    default: {
      return state;
    }
  }
};
