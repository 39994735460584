import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IDataTableV2DateState } from "../../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import {
  IDataTableV2SearchFilterSearchItem,
  IDatatableV2AdvancedSearchFilter,
} from "../../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { IReportAttachment, IReports } from "./Reports.types";
import { makeApiCall } from "../../../helpers/postRequest";
import {
  downLoadFile,
  downLoadMultipleFiles,
} from "../../../components/FileUpload/utils";
import { formatText, getUniqueId } from "../../../helpers";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";

export const FETCH_ACCOUNTING_REPORTS_PROGRESS =
  "FETCH_ACCOUNTING_REPORTS_PROGRESS";
export const FETCH_ACCOUNTING_REPORTS_SUCCESS =
  "FETCH_ACCOUNTING_REPORTS_SUCCESS";
export const FETCH_ACCOUNTING_REPORTS_FAILURE =
  "FETCH_ACCOUNTING_REPORTS_FAILURE";

export const fetchAccountingReportsProgress = () =>
  action(FETCH_ACCOUNTING_REPORTS_PROGRESS);

export const fetchAccountingReportsSuccess = (data: IReports) =>
  action(FETCH_ACCOUNTING_REPORTS_SUCCESS, { data });

export const fetchAccountingReportsFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_REPORTS_FAILURE, { errorMessage });

export const fetchAccountingReportsAsync =
  (reportCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingReportsProgress());

      const res = await api.get(
        `/accounting/get-accn-transaction-report-history?accn_transaction_report_history_code=${reportCode}`
      );
      const data: IReports[] = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of (data[0].attachment || []) as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(
          fetchAccountingReportsSuccess({ ...data[0], attachment: attachments })
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingReportsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingReportsAysnc =
  (
    data: IReports,
    onCallback: (isSuccess: boolean, response?: any[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await makeApiCall(
        {
          url: "/accounting/edit-accn-transaction-report",
          method: "POST",
        },
        {},
        {
          ...data,
        }
      );

      const response = res?.data.data || []
      console.log("response",response)

      if (data.is_transaction_report_preview === 0) {
        dispatch(
          showMessage({
            type: "success",
            message: "Report Information saved successfully.",
            displayAs: "snackbar",
          })
        );
      }
      onCallback(true, response);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchAccountReports =
  (
    selectedType: string,
    onSuccess: (columns: any[]) => void,
    subType?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    if (!selectedType) {
      onSuccess([]);
      return;
    }

    try {
      dispatch(saveLoaderProgress());

      console.log("selectedType:", selectedType); // Log selected type

      let url = `/accounting/get-accn-reports-fields-to-exports?type=${selectedType}`;

      if (subType) {
        url += `&sub_type=${subType}`;
      }

      const res = await makeApiCall(
        {
          url,
          method: "GET",
        },
        {}
      );

      const responseData = Array.isArray(res.data.data) ? res.data.data : [];
      onSuccess(responseData);
    } catch (err: any) {
      console.error("API Error:", err.response?.data?.message || err.message);
      dispatch(
        showMessage({
          type: "error",
          message: err.response?.data?.message || err.message,
          displayAs: "snackbar",
        })
      );
      onSuccess([]); // Handle error case, clear columns
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchGLReports =
  (
    selectedType: string,
    subType: string,
    generalLedgerReport: string,
    onSuccess: (columns: any[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    if (!selectedType || !subType || !generalLedgerReport) {
      onSuccess([]);
      return;
    }

    try {
      dispatch(saveLoaderProgress());

      console.log("selectedType:", selectedType); // Should be "GL"
      console.log("subType:", subType); // Log subType to ensure it's defined
      console.log("generalLedgerReport:", generalLedgerReport);

      let url = `/accounting/get-accn-reports-fields-to-exports?type=${selectedType}&sub_type=${subType}&general_ledger_report=${generalLedgerReport}`;

      const res = await makeApiCall(
        {
          url,
          method: "GET",
        },
        {}
      );

      const responseData = Array.isArray(res.data.data) ? res.data.data : [];
      onSuccess(responseData);
    } catch (err: any) {
      console.error("API Error:", err.response?.data?.message || err.message);
      // dispatch(
      //   showMessage({
      //     type: "error",
      //     message: err.response?.data?.message || err.message,
      //     displayAs: "snackbar",
      //   })
      // );
      onSuccess([]); // Handle error case, clear columns
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_REPORTS_LIST_PROGRESS =
  "FETCH_ACCOUNTING_REPORTS_LIST_PROGRESS";
export const FETCH_ACCOUNTING_REPORTS_LIST_SUCCESS =
  "FETCH_ACCOUNTING_REPORTS_LIST_SUCCESS";
export const FETCH_ACCOUNTING_REPORTS_LIST_FAILURE =
  "FETCH_ACCOUNTING_REPORTS_LIST_FAILURE";

export const fetchAccountingReportsListProgress = () =>
  action(FETCH_ACCOUNTING_REPORTS_LIST_PROGRESS);

export const fetchAccountingReportsListSuccess = (
  data: IReports[],
  totalRecords: number
) => action(FETCH_ACCOUNTING_REPORTS_LIST_SUCCESS, { data, totalRecords });

export const fetchAccountingReportsListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_REPORTS_LIST_FAILURE, { errorMessage });

export const fetchAccountingReportsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    limit: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingReportsListProgress());
      let finalUrl = `/accounting/get-accn-transaction-report-history?pageNo=${pageNumber}&pageLimit=${limit}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;
      if (searchValue.length > 0) {
        finalUrl += `&advanceFilter=${JSON.stringify(searchValue)}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }

      const res = await api.get(finalUrl);
      const data: IReports[] = res.data.data;
      dispatch(fetchAccountingReportsListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchAccountingReportsListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_ACCOUNTING_ATTACHMENTS_PROGRESS =
  "FETCH_ACCOUNTING_ATTACHMENTS_PROGRESS";
export const FETCH_ACCOUNTING_ATTACHMENTS_SUCCESS =
  "FETCH_ACCOUNTING_ATTACHMENTS_SUCCESS";
export const FETCH_ACCOUNTING_ATTACHMENTS_FAILURE =
  "FETCH_ACCOUNTING_ATTACHMENTS_FAILURE";

export const fetchAccountingAttachmentsProgress = () =>
  action(FETCH_ACCOUNTING_ATTACHMENTS_PROGRESS);

export const fetchAccountingAttachmentsSuccess = (
  attachmentList: IReportAttachment[]
) => action(FETCH_ACCOUNTING_ATTACHMENTS_SUCCESS, { attachmentList });

export const fetchAccountingAttachmentsFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_ATTACHMENTS_FAILURE, { errorMessage });

export const fetchAccountingAttachmentsAsync =
  (reportCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingAttachmentsProgress());

      const res = await api.get(
        `/accounting/get-accn-report-attachments?report_code=${reportCode}`
      );
      const data: IReportAttachment[] = res.data.data;

      dispatch(fetchAccountingAttachmentsSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingAttachmentsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_REPORTS = "CLEAR_ACCOUNTING_REPORTS";
export const clearAccountingReports = () => action(CLEAR_ACCOUNTING_REPORTS);

export const CLEAR_ACCOUNTING_REPORTS_STATE = "CLEAR_ACCOUNTING_REPORTS_STATE";
export const clearAccountingReportState = () => action(CLEAR_ACCOUNTING_REPORTS_STATE);
