import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomobileScheduleA,
  initialAutomoblieSchedule,
} from "./automobileSchedule.types";

export const CLEAR_AUTOMOBILE_SCHEDULE_STATE =
  "CLEAR_AUTOMOBILE_SCHEDULE_STATE";

export const clearAutomobileScheduleState = () =>
  action(CLEAR_AUTOMOBILE_SCHEDULE_STATE);

export const FETCH_AUTOMOBILE_SCHEDULE_A_PROGRESS =
  "FETCH_AUTOMOBILE_SCHEDULE_A_PROGRESS";
export const FETCH_AUTOMOBILE_SCHEDULE_A_SUCCESS =
  "FETCH_AUTOMOBILE_SCHEDULE_A_SUCCESS";
export const FETCH_AUTOMOBILE_SCHEDULE_A_FAILED =
  "FETCH_AUTOMOBILE_SCHEDULE_A_FAILED";

export const fetchAutomobileScheduleAProgress = () =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_PROGRESS);
export const fetchAutomobileScheduleASuccess = (data: IAutomobileScheduleA) =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_SUCCESS, { data });
export const fetchAutomobileScheduleAFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_FAILED, { errorMessage });

export const fetchAutomobileScheduleAAsync =
  (
    customerPolicyId: number,
    driverNumber: number,
    vehicleNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileScheduleAProgress());
      const res = await api.get(
        `/policy/get-policy-auto-vcs-schedule-a?customer_policy_id=${customerPolicyId}&vehicle_number=${vehicleNumber}&column=incremental_number&value=${driverNumber}`
      );
      const data: IAutomobileScheduleA[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchAutomobileScheduleASuccess({ ...data[0] }));
      } else {
        dispatch(
          fetchAutomobileScheduleAFailed(
            "Oops! We couldn't find any records associated with your Coverage number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileScheduleAFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileScheduleAAsync =
  (
    data: IAutomobileScheduleA,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-auto-vcs-schedule-a", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Schedule A saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileScheduleASuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_SCHEDULE_A_LIST_FAILED =
  "FETCH_AUTOMOBILE_SCHEDULE_A_LIST_FAILED";

export const fetchAutomobileScheduleAsListProgress = () =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS);

export const fetchAutomobileScheduleAListSuccess = (
  data: IAutomobileScheduleA[]
) =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileScheduleAsListFailed = () =>
  action(FETCH_AUTOMOBILE_SCHEDULE_A_LIST_FAILED);

export const fetchAutomobileScheduleAListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileScheduleAsListProgress());

      let finalUrl = `/policy/get-policy-auto-vcs-schedule-a?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileScheduleA[] = res.data.data;
      dispatch(fetchAutomobileScheduleAListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileScheduleAsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
