import moment from "moment";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { LoadState } from "../../../constants/enums";
import { formatDate } from "../../../helpers";

export interface IAccountingPaymentAccountsRecievableState {
  list: IPaymentAccountsRecievable[];
  listLoading: LoadState;
  totalRecords: number;
  data: IPaymentAccountsRecievable;
  loading: LoadState;
  error: string | null;
}

export interface IPaymentAccountsRecievable {
  accn_invoice_payment_accounts_receivable_id?: any;
  invoice_id: number | null;
  payment_id: number | null;
  entry_type: string | null;
  payment_number: string | null;
  gl_accounts: string | null;
  invoice_payment_gl_account_name: string | null;
  date: string | null;
  batch_id: string | null;
  receipt_id: string | null;
  code: string | null;
  name: string | null;
  payment_for: string | null;
  policy_id: string | null;
  policy_number: string | null;
  default_tax_region: string | null;
  payment_type: string | null;
  payment_currency: string | null;
  payment_amount: string | null;
  service_fee: string | null;
  payment_method: string | null;
  bank: string | null;
  reference_info: string | null;
  status: string;
  file_upload: IFileUpload[] | null;
  invoice_details: IInvoiceDetails[];
  total_paid: string | null;
  amount_excess: string | null;
  total_due: string | null;
  is_mannual_invoice: boolean;
}

export interface IInvoiceDetails {
  key: string;
  invoice_number: string | null;
  invoice_id: string | null;
  date: string | null;
  amount_due: string | null;
  payment_paid: string | null;
  total_due_dollar: string | null;
}

export const initialAccountingPaymentAccountsRecievableState: IAccountingPaymentAccountsRecievableState =
  {
    list: [],
    totalRecords: 0,
    listLoading: LoadState.NotLoaded,
    data: {
      invoice_id: null,
      payment_id: null,
      payment_number: null,
      entry_type: null,
      gl_accounts: null,
      invoice_payment_gl_account_name: null,
      date: formatDate(moment().format()),
      batch_id: null,
      receipt_id: null,
      code: null,
      name: null,
      payment_for: null,
      policy_id: null,
      policy_number: null,
      default_tax_region: "ON",
      payment_type: null,
      payment_currency: "CAD",
      payment_amount: null,
      service_fee: null,
      payment_method: null,
      bank: null,
      reference_info: null,
      
      status: "ACTIVE",
      file_upload: null,
      invoice_details: [],
     
      total_due: null,
      is_mannual_invoice: false,
      amount_excess: null,
      total_paid: null,
      
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
