import { LoadState } from "../../constants/enums";

export interface IAccountingDashboardState {
  earningGraph: IAccountingEarningGraph;
  earningGraphLoading: LoadState;
}

export interface IAccountingEarningGraph {
  balance: string;
  totalReceive: string;
  totalPaid: string;
  categories: string[];
  data: {
    name: string;
    data: string[];
  }[];
}

export const initialAccountingDashboardState: IAccountingDashboardState = {
  earningGraph: {
    balance: "",
    totalReceive: "",
    totalPaid: "",
    categories: [],
    data: [],
  },
  earningGraphLoading: LoadState.NotLoaded,
};
