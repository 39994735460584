import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";

import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IEditMyProfile,
  IUserCommission,
  IUserCreation,
  IUserProfile,
  IUserProfileDocument,
  IUserSaveProfile,
  IUserSecuirtyGroup,
} from "./userProfile.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { makeApiCall } from "../../helpers/postRequest";
import { initialCommision, initialSecurityGroup } from "./userprofileState";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";
import { uploadFile } from "../../components/FileUpload/utils";

export const FETCH_USERS_LIST_PROGRESS = "FETCH_USERS_LIST_PROGRESS";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_FAILED = "FETCH_USERS_LIST_FAILED";

export const fetchUsersListProgress = () => action(FETCH_USERS_LIST_PROGRESS);
export const fetchUsersListSuccess = (
  data: IUserProfile[],
  totalNumberOfRecords: number
) => action(FETCH_USERS_LIST_SUCCESS, { data: data, totalNumberOfRecords });
export const fetchUsersListFailed = () => action(FETCH_USERS_LIST_FAILED);

export const fetchUsersListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUsersListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/user/get-users?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/user/get-users?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}${url}`;
      }
      const res = await api.get(finalUrl);

      // const res = await api.get(
      //   `/user/get-users?pageNo=${pageNumber}&itemPerPage=${itemsPerPage}`
      // );
      const data: IUserProfile[] = res.data.data;
      dispatch(fetchUsersListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchUsersListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_USERS_PROGRESS = "FETCH_USERS_PROGRESS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const fetchUsersProgress = () => action(FETCH_USERS_PROGRESS);
export const fetchUsersSuccess = (data: IUserProfile) =>
  action(FETCH_USERS_SUCCESS, { data: data });
export const fetchUsersFailed = (errorMessage: string) =>
  action(FETCH_USERS_FAILED, { errorMessage });

export const fetchUsersAsync =
  (userId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUsersProgress());
      const res = await api.get(
        `/user/get-user-profile?user_fact_id=${userId}`
      );
      const data = res.data.data;
      if (data) {
        dispatch(fetchUsersSuccess(data));
      } else {
        dispatch(
          fetchUsersFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchUsersFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertUserAysnc =
  (
    user: IUserProfile,
    initialData: IUserProfile,
    isUpdate: boolean,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const userProfile = getState().userprofile.users.user;
      const finalUser: IUserSaveProfile = {
        account: user.account,
        assigned_phone_number: user.assigned_phone_number,
        bank: user.bank,
        bank_name: user.bank_name,
        bonus_expense_code: user.bonus_expense_code,
        bonus_liability_code: user.bonus_liability_code,
        branch: user.branch,
        branch_code: user.branch_code,
        branch_name: user.branch,
        broker: user.broker,
        business_number: user.business_number,
        city: user.city,
        commission_expense_code: user.commission_expense_code,
        commission_liability_code: user.commission_liability_code,
        country: user.country,
        csr: user.csr,
        date_of_birth: user.date_of_birth,
        defaults_producer: null,
        defaults_role: user.role_id,
        department: user.department,
        documents: user.documents,
        email: user.email,
        enable_sendgrid: null,
        fax: user.fax,
        first_name: user.first_name,
        hire_date: user.hire_date,
        home_phone: user.home_phone,
        job_title: user.job_title,
        languages_known: user.languages_known,
        last_day_at_work: user.last_day_at_work,
        last_name: user.last_name,
        linkedin_profile: user.linkedin_profile,
        location_city: user.location_city,
        manager_id: user.manager_id,
        marketer: user.marketer,
        mobile: user.mobile,
        mother_maiden_name: user.mother_maiden_name,
        non_active_firm: user.non_active_firm,
        non_active_firm_name: user.non_active_firm_name,
        non_active_firm_registered_with_ribo:
          user.non_active_firm_registered_with_ribo,
        personal_email: user.personal_email,
        phone_ext: user.phone_ext,
        photo: user.photo,
        postal_code: user.postal_code,
        producer_one: user.producer_one,
        producer_two: user.producer_two,
        producer_three: user.producer_three,
        province_or_state: user.province_or_state,
        reports_to: user.reports_to,
        shared_email: user.shared_email,
        signature: user.signature,
        sin: user.sin,
        status: user.status as "ACTIVE",
        street_address: user.street_address,
        transit: user.transit,
        unit_or_suite: user.unit_or_suite,
        user_code: user.user_code,
        user_fact_id: user.user_fact_id,
        user_type: user.user_type,
        role_id: user.role_id,
        username: user.username,
        producer_one_code: user.producer_one_code,
        producer_three_code: user.producer_three_code,
        producer_two_code: user.producer_two_code,
        marketer_code: user.marketer_code,
        csr_code: user.csr_code,
      };
      const finalInitialDatta: IUserSaveProfile = {
        account: initialData.account,
        assigned_phone_number: initialData.assigned_phone_number,
        bank: initialData.bank,
        bank_name: initialData.bank_name,
        bonus_expense_code: initialData.bonus_expense_code,
        bonus_liability_code: initialData.bonus_liability_code,
        branch: initialData.branch,
        branch_code: initialData.branch_code,
        branch_name: initialData.branch,
        broker: initialData.broker,
        business_number: initialData.business_number,
        city: initialData.city,
        commission_expense_code: initialData.commission_expense_code,
        commission_liability_code: initialData.commission_liability_code,
        country: initialData.country,
        csr: initialData.csr,
        date_of_birth: initialData.date_of_birth,
        defaults_producer: null,
        defaults_role: initialData.role_id,
        department: initialData.department,
        documents: initialData.documents,
        email: initialData.email,
        enable_sendgrid: null,
        fax: initialData.fax,
        first_name: initialData.first_name,
        hire_date: initialData.hire_date,
        home_phone: initialData.home_phone,
        job_title: initialData.job_title,
        languages_known: initialData.languages_known,
        last_day_at_work: initialData.last_day_at_work,
        last_name: initialData.last_name,
        linkedin_profile: initialData.linkedin_profile,
        location_city: initialData.location_city,
        manager_id: initialData.manager_id,
        marketer: initialData.marketer,
        mobile: initialData.mobile,
        mother_maiden_name: initialData.mother_maiden_name,
        non_active_firm: initialData.non_active_firm,
        non_active_firm_name: initialData.non_active_firm_name,
        non_active_firm_registered_with_ribo:
          initialData.non_active_firm_registered_with_ribo,
        personal_email: initialData.personal_email,
        phone_ext: initialData.phone_ext,
        photo: initialData.photo,
        postal_code: initialData.postal_code,
        producer_one: initialData.producer_one,
        producer_two: initialData.producer_two,
        producer_three: initialData.producer_three,
        province_or_state: initialData.province_or_state,
        reports_to: initialData.reports_to,
        shared_email: initialData.shared_email,
        signature: initialData.signature,
        sin: initialData.sin,
        status: initialData.status as "ACTIVE",
        street_address: initialData.street_address,
        transit: initialData.transit,
        unit_or_suite: initialData.unit_or_suite,
        user_code: initialData.user_code,
        user_fact_id: initialData.user_fact_id,
        user_type: initialData.user_type,
        role_id: initialData.role_id,
        username: initialData.username,
        producer_one_code: initialData.producer_one_code,
        producer_three_code: initialData.producer_three_code,
        producer_two_code: initialData.producer_two_code,
        marketer_code: initialData.marketer_code,
        csr_code: initialData.csr_code,
      };
      const asPayload = {
        user_fact_id: finalUser.user_fact_id,
      };
      const path = await uploadFile(
        file,
        "USER_PROFILE",
        finalUser.photo || "",
        asPayload
      );
      if (userProfile.role_id !== user.role_id) {
        await api.put("/user/change-role", {
          user_fact_id: finalUser.user_fact_id,
          role_id: user.role_id,
        });
      }
      await makeApiCall(
        {
          url: "/user/edit-user-profile",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "user_fact_id",
          },
        },
        finalInitialDatta,
        { ...finalUser, photo: path }
      );

      dispatch(
        showMessage({
          type: "success",
          message: "User profile is updated successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const createNewUserAsync =
  (
    user: IUserCreation,
    initialData: IUserCreation,
    isUpdate: boolean,
    onCallback: (isSuccess: boolean, user_fact_id?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await makeApiCall(
        {
          url: "/user/create-user",
          method: "POST",
          automation: {
            isUpdate: isUpdate,
            primaryFieldName: "user_code",
          },
        },
        initialData,
        user
      );

      dispatch(
        showMessage({
          type: "success",
          message: "User is created successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true, res.data.data.user_fact_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const generateOTP =
  (
    email: string,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post("/user/generate-otp", {
        email: email,
        otp_for: "FORGET_PASSWORD",
      });

      dispatch(
        showMessage({
          type: "success",
          message: "OTP send successfully.",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const changeForgotPassword =
  (
    data: {
      email: string;
      otp: string;
      user_password: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post("/user/forget-password", data);

      dispatch(
        showMessage({
          type: "success",
          message: "Password changed successfully.",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_MY_PROFILE_PROGRESS = "FETCH_MY_PROFILE_PROGRESS";
export const FETCH_MY_PROFILE_SUCCESS = "FETCH_MY_PROFILE_SUCCESS";
export const FETCH_MY_PROFILE_FAILED = "FETCH_MY_PROFILE_FAILED";

export const fetchMyProfileProgress = () => action(FETCH_MY_PROFILE_PROGRESS);
export const fetchMyProfileSuccess = (data: IUserProfile) =>
  action(FETCH_MY_PROFILE_SUCCESS, { data: data });
export const fetchMyProfileFailed = (errorMessage: string) =>
  action(FETCH_MY_PROFILE_FAILED, { errorMessage });

export const fetchMyProfileAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMyProfileProgress());
      const res = await api.get(`/user/get-user-profile-only-data`);
      const data: IUserProfile = res.data.data;
      if (data) {
        dispatch(fetchMyProfileSuccess(data));
      } else {
        dispatch(
          fetchMyProfileFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchMyProfileFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertMyProfileAysnc =
  (
    user: IUserProfile,
    // file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      // const userProfile = getState().userprofile.users.user;
      const finalUser: IEditMyProfile = {
        account: user.account,
        bank: user.bank,
        bank_name: user.bank_name,
        bank_number: user.bank_name,
        business_number: user.business_number,
        city: user.city,
        country: user.country,
        date_of_birth: user.date_of_birth,
        documents: user.documents,
        first_name: user.first_name,
        home_phone: user.home_phone,
        languages_known: user.languages_known,
        last_name: user.last_name,
        linkedin_profile: user.linkedin_profile,
        mobile: user.mobile,
        mother_maiden_name: user.mother_maiden_name,
        non_active_firm: user.non_active_firm,
        non_active_firm_name: user.non_active_firm_name,
        non_active_firm_registered_with_ribo:
          user.non_active_firm_registered_with_ribo,
        personal_email: user.personal_email,
        photo: user.photo,
        postal_code: user.postal_code,
        province_or_state: user.province_or_state,
        signature: user.signature,
        sin: user.sin,
        street_address: user.street_address,
        transit: user.transit,
        unit_or_suite: user.unit_or_suite,
        user_type: user.user_type,
      };
      // const asPayload = {
      //   user_fact_id: finalUser.user_fact_id,
      // };
      // const path = await uploadFile(
      //   file,
      //   "USER_PROFILE",
      //   finalUser.photo || "",
      //   asPayload
      // );
      // if (userProfile.role_id !== user.role_id) {
      //   await api.put("/user/change-role", {
      //     user_fact_id: finalUser.user_fact_id,
      //     role_id: user.role_id,
      //   });
      // }
      await api.post("/user/edit-user-profile-only-data", {
        ...finalUser,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Your profile is updated successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_USER_PROFILE_DOCUMENTS_LIST_PRGRESS =
  "FETCH_USER_PROFILE_DOCUMENTS_LIST_PRGRESS";
export const FETCH_USER_PROFILE_DOCUMENTS_LIST_SUCCESS =
  "FETCH_USER_PROFILE_DOCUMENTS_LIST_SUCCESS";
export const FETCH_USER_PROFILE_DOCUMENTS_LIST_FAILED =
  "FETCH_USER_PROFILE_DOCUMENTS_LIST_FAILED";

export const fetchUserDocumentsListProgress = () =>
  action(FETCH_USER_PROFILE_DOCUMENTS_LIST_PRGRESS);
export const fetchUserDocumentsListSuccess = (data: IUserProfileDocument[]) =>
  action(FETCH_USER_PROFILE_DOCUMENTS_LIST_SUCCESS, { data });
export const fetchUserDocumentsListFailed = () =>
  action(FETCH_USER_PROFILE_DOCUMENTS_LIST_FAILED);

export const fetchUserDocumentsListAsync =
  (userFactId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserDocumentsListProgress());
      const res = await api.get(
        `/user/get-user-document?user_fact_id=${userFactId}`
      );
      const data: IUserProfileDocument[] = res.data.data;

      dispatch(fetchUserDocumentsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchUserDocumentsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertProfileDocument =
  (
    data: IUserProfileDocument,

    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const asPayload = {
        user_fact_id: data.user_fact_id,
      };
      const path = await uploadFile(
        file,
        "USER_PROFILE",
        data.file_upload || "",
        asPayload
      );
      await api.post("/user/create-user-document", {
        ...data,
        file_upload: Array.isArray(path) ? path: [path]
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Document saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

/*-----------*/
export const FETCH_USERS_SECURITY_GROUP_PROGRESS =
  "FETCH_USERS_SECURITY_GROUP_PROGRESS";
export const FETCH_USERS_SECURITY_GROUP_SUCCESS =
  "FETCH_USERS_SECURITY_GROUP_SUCCESS";
export const FETCH_USERS_SECURITY_GROUP_FAILED =
  "FETCH_USERS_SECURITY_GROUP_FAILED";

export const fetchUsersSecurityGroupProgress = () =>
  action(FETCH_USERS_SECURITY_GROUP_PROGRESS);
export const fetchUsersSecurityGroupSuccess = (data: IUserSecuirtyGroup) =>
  action(FETCH_USERS_SECURITY_GROUP_SUCCESS, { data: data });
export const fetchUsersSecurityGroupFailed = (errorMessage: string) =>
  action(FETCH_USERS_SECURITY_GROUP_FAILED, { errorMessage });

export const fetchUsersSecurityGroupAsync =
  (userId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUsersSecurityGroupProgress());
      const res = await api.get(
        `/security/get-user-group-securtiy?user-fact-id=${userId}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUsersSecurityGroupSuccess(data[0]));
      } else {
        dispatch(fetchUsersSecurityGroupSuccess(initialSecurityGroup));
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertUserSecurityGroupAysnc =
  (
    user: IUserSecuirtyGroup,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/security/upsert-user-group-security", user);

      dispatch(
        showMessage({
          type: "success",
          message: "User security is saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// -----------------User-Commision--------------->

export const FETCH_USERS_COMMISSION_LIST_PROGRESS =
  "FETCH_USERS_COMMISSION_LIST_PROGRESS";
export const FETCH_USERS_COMMISSION_LIST_SUCCESS =
  "FETCH_USERS_COMMISSION_LIST_SUCCESS";
export const FETCH_USERS_COMMISSION_LIST_FAILED =
  "FETCH_USERS_COMMISSION_LIST_FAILED";

export const fetchUsersCommissionListProgress = () =>
  action(FETCH_USERS_COMMISSION_LIST_PROGRESS);
export const fetchUsersCommissionListSuccess = (
  list: IUserCommission[],
  totalRecords: number
) => action(FETCH_USERS_COMMISSION_LIST_SUCCESS, { list, totalRecords });
export const fetchUsersCommissionListFailed = () =>
  action(FETCH_USERS_COMMISSION_LIST_FAILED);

export const fetchUsersCommissionListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDataTableV2DateState["dates"],
    searchValue: IDatatableV2AdvancedSearchFilter,
    userId?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUsersCommissionListProgress());
      let finalUrl = `/user/get-user-commission?`;
      if (searchValue.length > 0) {
        finalUrl = `/user/get-user-commission?`;
      }
      if (status !== "-1") {
        finalUrl += "status=" + status;
      }
      if (userId) {
        finalUrl += "&user_fact_id=" + userId;
      }
      const res = await api.get(finalUrl);
      const data: IUserCommission[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchUsersCommissionListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchUsersCommissionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  

export const FETCH_USERS_COMMISSION_PROGRESS =
  "FETCH_USERS_COMMISSION_PROGRESS";
export const FETCH_USERS_COMMISSION_SUCCESS = "FETCH_USERS_COMMISSION_SUCCESS";
export const FETCH_USERS_COMMISSION_FAILED = "FETCH_USERS_COMMISSION_FAILED";

export const fetchUsersCommissionProgress = () =>
  action(FETCH_USERS_COMMISSION_PROGRESS);
export const fetchUsersCommissionSuccess = (data: IUserCommission) =>
  action(FETCH_USERS_COMMISSION_SUCCESS, { data: data });
export const fetchUsersCommissionFailed = (errorMessage: string) =>
  action(FETCH_USERS_COMMISSION_FAILED, { errorMessage });

export const fetchUsersCommissionAsync =
  (userId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUsersCommissionProgress());
      const res = await api.get(
        `/user/get-user-commission?user-fact-id=${userId}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUsersCommissionSuccess(data[0]));
      } else {
        dispatch(fetchUsersCommissionSuccess(initialCommision));
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertUserCommissionAysnc =
  (
    commission: IUserCommission,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/user/edit-user-commission", commission);

      dispatch(
        showMessage({
          type: "success",
          message: "User Commission is saved successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const createDefaultCommissionByFactId =
  (
    userFactId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/user/create-user-commission-for-old-user-records", {
        "user_fact_id": userFactId,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Default commission created successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_MY_PROFILE = "CLEAR_MY_PROFILE";
export const clearMyProfile = () => action(CLEAR_MY_PROFILE);
