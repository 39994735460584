import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_AUTOMOBILE_QUOTE_STATE,
  
  FETCH_AUTOMOBILE_QUOTE_FAILED,
  FETCH_AUTOMOBILE_QUOTE_LIST_FAILED,
  FETCH_AUTOMOBILE_QUOTE_LIST_PROGRESS,
  FETCH_AUTOMOBILE_QUOTE_LIST_SUCCESS,
  FETCH_AUTOMOBILE_QUOTE_PROGRESS,
  FETCH_AUTOMOBILE_QUOTE_SUCCESSS,
} from "./automobileQuoteActions";
import { initialAutomobileQuoteState } from "./automobileQuote.types";
import { AutomobileQuoteActions } from ".";

export const AutomobileQuoteReducer = (
  state: IStoreState["automobile"]["quote"] = initialAutomobileQuoteState,
  action: AutomobileQuoteActions
) => {
  switch (action.type) {

    case FETCH_AUTOMOBILE_QUOTE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data =
          initialAutomobileQuoteState["data"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_QUOTE_SUCCESSS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_QUOTE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data =
          initialAutomobileQuoteState["data"];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_QUOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_QUOTE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_QUOTE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_QUOTE_STATE: {
      return initialAutomobileQuoteState;
    }

    default: {
      return state;
    }
  }
};
