import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import {
  clearHabitationalApplicationState,
  fetchHabitationalApplicationListAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplication.Actions";
import { IHabitationalApplication } from "../../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplicationNew.types";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { CustomSelect } from "../../../../../../components/formsComponents";

export const HabitationalApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();
  const { list, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.applications
  );
  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application`
    );
  };

  const fetchList = () => {
    if (customerpolicyId) {
      dispatch(fetchHabitationalApplicationListAsync(customerpolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearHabitationalApplicationState());
    };
  }, [customerpolicyId, status]);

  const habitationalApplicationTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                {" "}
                {/* <MenuItem value="-1">All Status</MenuItem> */}
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        headingAlign: "center",
        fieldName: "",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalApplication) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalApplication) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "Loc #",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 15,
      },

      // {
      //   key: "address",
      //   headerName: "Address",
      //   fieldName: "address",
      //   renderType: DataTableV2RowRenderType.TEXT,
      // exportCellWidth: 30,
      //   onRowCellValueRender: (value, row: IHabitationalApplication) => {
      //     return (
      //       row.applicant_details +
      //       ", " +
      //       row.applicant_unit_or_suite +
      //       ", " +
      //       row.applicant_city +
      //       ", " +
      //       row.applicant_province_or_state +
      //       ", " +
      //       row.applicant_postal_or_zip_code
      //     );
      //   },
      // },

      {
        key: "policy_number",
        headerName: "Policy No.",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "applicant_name",
        headerName: "Applicant Name",
        fieldName: "applicant_name",
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: IHabitationalApplication) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application/${row.incremental_number}`
                )
              }
            >
              {row.applicant_details.applicant_name}
            </Link>
          );
        },
      },
      {
        key: "policy_period_effective_date",
        headerName: "Effective Date",
        fieldName: "policy_period_effective_date",
        renderType: DataTableV2RowRenderType.DATE,
        // exportCellWidth: 20,
      },
    ],
    rows: list,
  };

  return (
    <>
      <PageTitleBar
        heading="Application"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...habitationalApplicationTableProps} />
    </>
  );
};
