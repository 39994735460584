import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IAutomobileFraudMisRepresent } from "./automobileFraudMisRepresent.types";

export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_PROGRESS =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_PROGRESS";
export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_SUCCESS =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_SUCCESS";
export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_FAILED =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_FAILED";
export const CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_INFO = "CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_INFO";

export const fetchAutomobileFraudMisRepresentProgress = () =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_PROGRESS);
export const fetchAutomobileFraudMisRepresentSuccess = (
  data: IAutomobileFraudMisRepresent
) =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_SUCCESS, {
    data,
  });
export const fetchAutomobileFraudMisRepresentFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_FAILED, { errorMessage });
export const clearAutomobileFraudMisRepresentInfo = () =>
  action(CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_INFO);

export const addAutomobileFraudMisRepresentAsync =
  (
    data: IAutomobileFraudMisRepresent,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-fraud-or-misrepresentation", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileFraudMisRepresentAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileFraudMisRepresentProgress());
      const res = await api.get(
        `/policy/get-policy-auto-fraud-or-misrepresentation?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileFraudMisRepresent[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileFraudMisRepresentSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileFraudMisRepresentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileFraudMisRepresentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_FAILED =
  "FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_FAILED";

export const fetchAutomobileFraudMisRepresentsListProgress = () =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_PROGRESS);

export const fetchAutomobileFraudMisRepresentListSuccess = (
  data: IAutomobileFraudMisRepresent[]
) =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileFraudMisRepresentsListFailed = () =>
  action(FETCH_AUTOMOBILE_FRAUD_MIS_REPRESENT_LIST_FAILED);

export const fetchAutomobileFraudMisRepresentListAsync =
  (customerPolicyId: number, status?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileFraudMisRepresentsListProgress());
      
      let finalUrl = `/policy/get-policy-auto-fraud-or-misrepresentation?customer_policy_id=${customerPolicyId}`;
      
      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileFraudMisRepresent[] = res.data.data;
      dispatch(fetchAutomobileFraudMisRepresentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileFraudMisRepresentsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_STATE = "CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_STATE";
export const clearAutomobileFraudMisRepresentState = () =>
  action(CLEAR_AUTOMOBILE_FRAUD_MIS_REPRESENT_STATE);