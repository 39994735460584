import produce from "immer";
import {
  CLEAR_GROUP_PLANS,
  FETCH_GROUP_PLANS_LIST_PROGRESS,
  FETCH_GROUP_PLANS_LIST_FAILED,
  FETCH_GROUP_PLANS_LIST_SUCCESS,
  FETCH_GROUP_PLANS_PROGRESS,
  FETCH_GROUP_PLANS_FAILED,
  FETCH_GROUP_PLANS_SUCCESS,
} from "./groupPlansActions";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialGroupPlansState } from "./groupPlans.types";
import { GroupPlansAction } from ".";

export const GroupPlansReducer = (
  state: IStoreState["borkerage"]["groupPlans"] = initialGroupPlansState,
  action: GroupPlansAction
) => {
  switch (action.type) {
    case FETCH_GROUP_PLANS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_GROUP_PLANS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_GROUP_PLANS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_GROUP_PLANS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_GROUP_PLANS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_GROUP_PLANS_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_GROUP_PLANS: {
      return initialGroupPlansState;
    }

    default:
      return state;
  }
};
