/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { AddOutlined, AppRegistration } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { fetchAutomobileVehicleListAsync } from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { IAutomobileDriverActivity } from "../../../../../../../redux/automobileDriver/automobileDriver.types";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import {
  clearAutomobileActivityTask,
  fetchAutomobileDriverTasksListAsync,
} from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";

export const AutomobileDriverActivityList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { driverActivityList, driverActivityListLoading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver.driverActivity
  );

  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverTasksListAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearAutomobileActivityTask());
    };
  }, [customerPolicyId, status]);

  const automobileDriverTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: driverActivityListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileDriverActivity) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IAutomobileDriverActivity) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: IAutomobileDriverActivity) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
        onRowCellRender: (value, row: IAutomobileDriverActivity) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            >
              {row.driver_name}
            </Link>
          );
        },
      },
      {
        key: "license_number",
        headerName: "License Number",
        fieldName: "license_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
      },

      {
        key: "business_reason",
        headerName: "Business Reason",
        fieldName: "business_reason",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "report_requested",
        headerName: "Report Requested",
        fieldName: "report_requested",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "request_status",
        headerName: "Request Status",
        fieldName: "request_status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
    ],
    rows: driverActivityList,
  };

  return (
    <>
      <PageTitleBar
        heading="Insurance Reports"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...automobileDriverTableProps} />
    </>
  );
};
