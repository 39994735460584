import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IAutomobileInsuranceLapse } from "./automobileInsuranceLapse.types";

export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_PROGRESS =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_PROGRESS";
export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_SUCCESS =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_SUCCESS";
export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_FAILED =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_FAILED";
export const CLEAR_AUTOMOBILE_INSURANCE_LAPSE_INFO = "CLEAR_AUTOMOBILE_INSURANCE_LAPSE_INFO";

export const fetchAutomobileInsuranceLapseProgress = () =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_PROGRESS);
export const fetchAutomobileInsuranceLapseSuccess = (
  data: IAutomobileInsuranceLapse
) =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_SUCCESS, {
    data,
  });
export const fetchAutomobileInsuranceLapseFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_FAILED, { errorMessage });
export const clearAutomobileInsuranceLapseInfo = () =>
  action(CLEAR_AUTOMOBILE_INSURANCE_LAPSE_INFO);

export const addAutomobileInsuranceLapseAsync =
  (
    data: IAutomobileInsuranceLapse,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-insurance-lapse", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Driver information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileInsuranceLapseAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileInsuranceLapseProgress());
      const res = await api.get(
        `/policy/get-policy-auto-insurance-lapse?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileInsuranceLapse[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileInsuranceLapseSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileInsuranceLapseFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileInsuranceLapseFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_FAILED =
  "FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_FAILED";

export const fetchAutomobileInsuranceLapsesListProgress = () =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_PROGRESS);

export const fetchAutomobileInsuranceLapseListSuccess = (
  data: IAutomobileInsuranceLapse[]
) =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileInsuranceLapsesListFailed = () =>
  action(FETCH_AUTOMOBILE_INSURANCE_LAPSE_LIST_FAILED);

export const fetchAutomobileInsuranceLapseListAsync =
  (customerPolicyId: number, status?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileInsuranceLapsesListProgress());
      
      let finalUrl = `/policy/get-policy-auto-insurance-lapse?customer_policy_id=${customerPolicyId}`;
      
      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileInsuranceLapse[] = res.data.data;
      dispatch(fetchAutomobileInsuranceLapseListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileInsuranceLapsesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_INSURANCE_LAPSE_STATE = "CLEAR_AUTOMOBILE_INSURANCE_LAPSE_STATE";
export const clearAutomobileInsuranceLapseState = () =>
  action(CLEAR_AUTOMOBILE_INSURANCE_LAPSE_STATE);