/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileVehicleInformation } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicle.types";
import {
  clearCommAutoMobileVehicleInfo,
  fetchCommAutomobileVehicleListAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { useTableV2State } from "../../../../../../../components/TableV2/hooks/useTableV2State";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { ControlledCustomSelect } from "../../../../../../../components/formsComponents";
import { COMMON_STATUS } from "../../../../../../../constants/constants";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { PageTitleBar } from "../../../../../../../components/PageTitleBar/PageTitleBar";
import { AddOutlined } from "@mui/icons-material";
import { DataTableV2 } from "../../../../../../../components/TableV2/DataTableV2";

export const CommAutomobileVehicleInformationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { vehiclesList, vehiclesListLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.vehicle.VehicleInformation
  );

  const dispatch = useDispatchWrapper();

  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );

  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "allTimes",
      selectedTab: "-1",
    },
  });

  const handleCreate = () => {
    navigate(
      `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileVehicleListAsync(customerPolicyId, status));
    }
  };

  React.useEffect(() => {
    fetchList();

    return () => {
      dispatch(clearCommAutoMobileVehicleInfo());
    };
  }, [customerPolicyId, status]);

  const automobileVehicleTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: vehiclesListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                fullWidth
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileVehicleInformation) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileVehicleInformation) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "vehcile_number",
        headerName: "Vehicle Number",
        fieldName: "vehcile_number",
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileVehicleInformation) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}/vehicle-information/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "customer_name",
        headerName: " Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
      },
      {
        key: "unit_number",
        headerName: "Unit Name",
        fieldName: "unit_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.CHIP_WARNING,
      },
      {
        key: "year",
        headerName: "Year",
        fieldName: "year",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "make",
        headerName: "Make",
        fieldName: "make",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },

      {
        key: "model",
        headerName: "Model",
        fieldName: "model",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "vin",
        headerName: "VIN",
        fieldName: "vin",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "owned_or_leased",
        headerName: "Owned/Leased",
        fieldName: "owned_or_leased",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "purchase_date",
        headerName: "Purchase Date",
        fieldName: "purchase_date",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
      {
        key: "puchase_condition",
        headerName: "Purchase Condition",
        fieldName: "puchase_condition",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT,
      },
    ],
    rows: vehiclesList,
  };

  return (
    <>
      <PageTitleBar
        heading="Vehicle Information"
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...automobileVehicleTableProps} />
    </>
  );
};
