import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IAutomobileAlbertaApplication,
  initialAutomobileAlbertaApplicationState,
} from "./automobileAlbertaApplication.types";


export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED";

export const fetchAutomobileAlbertaApplicationListProgress = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_PROGRESS);

export const fetchAutomobileAlbertaApplicationsListSuccess = (
  data: IAutomobileAlbertaApplication[]
) =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileAlbertaApplicationListFailed = () =>
  action(FETCH_AUTOMOBILE_ALBERTA_APPLICATION_LIST_FAILED);

export const fetchAutomobileAlbertaApplicationListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAlbertaApplicationListProgress());

      let finalUrl = `/policy/get-policy-auto-alberta-application?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IAutomobileAlbertaApplication[] = res.data.data;
      dispatch(fetchAutomobileAlbertaApplicationsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileAlbertaApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE";

export const clearAutomobileAlbertaApplicationState = () =>
  action(CLEAR_AUTOMOBILE_ALBERTA_APPLICATION_STATE);
