import produce from "immer";
import { EnvConfigActions } from ".";
import { IStoreState } from "../initialStoreState";
import { initialEnvConfigState } from "./envConfig.types";
import {
  CLEAR_ENV_CONFIG_STATE,
  FETCH_ENV_CONFIG_LIST_FAILED,
  FETCH_ENV_CONFIG_LIST_PROGRESS,
  FETCH_ENV_CONFIG_LIST_SUCCESS,
} from "./envConfigActions";
import { LoadState } from "../../constants/enums";

export const envConfigReducer = (
  state: IStoreState["envConfig"] = initialEnvConfigState,
  action: EnvConfigActions
) => {
  switch (action.type) {
    case FETCH_ENV_CONFIG_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list = [];
        draftState.loading = LoadState.NotLoaded;
      });

      return newState;
    }
    case FETCH_ENV_CONFIG_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;

      const newState = produce(state, (draftState) => {
        draftState.list = data;
        draftState.loading = LoadState.Loaded;
      });

      return newState;
    }

    case FETCH_ENV_CONFIG_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list = [];
        draftState.loading = LoadState.Failed;
      });

      return newState;
    }

    case CLEAR_ENV_CONFIG_STATE: {
      return initialEnvConfigState;
    }

    default: {
      return state;
    }
  }
};
