import moment from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileCSIOPolicyChangeReqState {
  list: IAutomobileCSIOPolicyState[];
  policyChangeData: IAutomobileCSIOPolicyState;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export interface IAutomobilePolicyReqVehicleInfo {
  key: string;
  vehNo: string;
  year: string;
  trade_name: string;
  body_type: string;
  vin_serial_number: string;
}
export interface IAutomobilePolicyReqModelInfo {
  key: string;
  model_cc: string;
  no_of_cyls: string;
  truck_gross_weight: string;
  purchase_price: string;
  purchased_by_insured_date: string;
  purchased_by_insured: string;
}

export interface IAutomobileOperatorOne {
  key: string;
  name_of_driver: string;
  driver_licence_number: string;
  assigned_vin_number: string;
  use: string;
  date_of_birth: string;
  sex: string;
  marital_status: string;
}
export interface IAutomobileOperatorTwo {
  key: string;
  number: number;
  class: string;
  date_first_licensed: string;
  year_licensed: string;
  held_insurance_since: string;
}
export interface IAutomobileConviction {
  key: string;
  driver_no: string;
  date1: string;
  description1: string;
  date2: string;
  description2: string;
  date3: string;
  description3: string;
}
export interface IAutomobileAccidentsSuspension {
  key: string;
  driver_no: string;
  date1: string;
  description1: string;
  date2: string;
  description2: string;
  date3: string;
  description3: string;
}
export interface IAutomobileCSIOPolicyState {
  customer_policy_id: number | null;
  incremental_number: number | null;
  policy_number: string | null;
  insurance_company: string | null;
  policy_type: string | null;
  language_preferred: string | null;
  broker: {
    broker_agent: string | null;
    broker_agent_address: string | null;
    broker_agent_unit_or_suite: string | null;
    broker_agent_city: string | null;
    broker_agent_state: string | null;
    broker_agent_country: string | null;
    broker_agent_zip: string | null;
    broker_code: string | null;
    broker_subcode: string | null;
  };
  applicant_details: {
    name: string | null;
    address: string | null;
    address_two: string | null;
    country: string | null;
    city: string | null;
    province_or_state: string | null;
    postalcode: string | null;
    contact_number: string | null;
    home: string | null;
    cell: string | null;
    business: string | null;
    fax: string | null;
    email: string | null;
    website: string | null;
  };
  policy_period_effective_date: string | null;
  policy_period_effective_time: string | null;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: string | null;

  name_changed: string | null;
  address_changed: string | null;
  vehicle_checkbox: string | null;
  previous_vin_number: string | null;
  vehicle_information: IAutomobilePolicyReqVehicleInfo[];
  vehicle_model_info: IAutomobilePolicyReqModelInfo[];
  pleasure_business: string | null;
  vehicle_used_commute: string | null;
  km_one_way: string | null;
  annual_distance: string | null;
  fuel: string | null;

  coverage_data: {
    bi_pd_checkbox: string | null;
    bi_pd_limit: string | null;
    all_perils_checkbox: string | null;
    all_perils_deductible: string | null;
    income_replacement_checkbox: string | null;
    income_replacement_limit: string | null;
    accidents_benfits_checkbox: string | null;
    collision_checkbox: string | null;
    collision_deductible: string | null;
    caregiver_dependant_checkbox: string | null;
    caregiver_dependant_deductible: string | null;
    uninsured_automobile_checkbox: string | null;
    uninsured_automobile_deductible: string | null;
    comprehensive_checkbox: string | null;
    comprehensive_deductible: string | null;
    medical_rehab_checkbox: string | null;
    medical_rehab_deductible: string | null;
    dcpd_checkbox: string | null;
    dcpd_deductible: string | null;
    specific_perils_checkbox: string | null;
    specific_perils_deductible: string | null;
    death_funeral_benefits_checkbox: string | null;
    death_funeral_benefits_deductible: string | null;
    endorsements_checkbox: string | null;
    endorsements_text: string | null;
  };

  additioanl_interest: string | null;
  additional_interest_vin_number: string | null;
  additional_interest_details: {
    address: string | null;
    unit_or_suite: string | null;
    city: string | null;
    province_or_state: string | null;
    postal_code: string | null;
    country: string | null;
  };
  nature_of_interest: string | null;
  nature_of_interest_text: string | null;
  operator: string | null;
  operator_name: string | null;
  same_as_section_one: string | null;
  described_operators_one: IAutomobileOperatorOne[];
  described_operators_two: IAutomobileOperatorTwo[];
  occupation: string | null;
  operator_policy_number: string | null;
  driver_training_course: string | null;
  current_or_previous_insurance: string | null;
  relation_to_insured: string | null;
  convictions_during_the_past_years: IAutomobileConviction[];
  accidents_suspension_in_the_past_years: IAutomobileAccidentsSuspension[];
  remarks: string | null;
  status: string;
}

export const initialAutomobileCSIOPolicyChangeReqState: IAutomobileCSIOPolicyChangeReqState =
  {
    policyChangeData: {
      customer_policy_id: null,
      incremental_number: null,
      policy_number: null,
      insurance_company: null,
      policy_type: null,
      language_preferred: null,
      broker: {
        broker_agent: null,
        broker_agent_address: null,
        broker_agent_unit_or_suite: null,
        broker_agent_city: null,
        broker_agent_state: null,
        broker_agent_country: null,
        broker_agent_zip: null,
        broker_code: null,
        broker_subcode: null,
      },
      applicant_details: {
        name: null,
        address: null,
        address_two: null,
        country: null,
        city: null,
        province_or_state: null,
        postalcode: null,
        contact_number: null,
        home: null,
        cell: null,
        business: null,
        fax: null,
        email: null,
        website: null,
      },
      policy_period_effective_date: null,
      policy_period_effective_time: null,
      policy_period_expiry_date: null,
      policy_period_expiry_time: null,

      name_changed: null,
      address_changed: null,
      vehicle_checkbox: null,
      previous_vin_number: null,
      vehicle_information: [
        {
          key: getUniqueId(),
          vehNo: "",
          year: "",
          trade_name: "",
          body_type: "",
          vin_serial_number: "",
        },
      ],
      vehicle_model_info: [
        {
          key: getUniqueId(),
          model_cc: "",
          no_of_cyls: "",
          truck_gross_weight: "",
          purchase_price: "",
          purchased_by_insured_date: moment().format("YYYY-MM"),
          purchased_by_insured: "",
        },
      ],
      pleasure_business: null,
      annual_distance: null,
      km_one_way: null,
      vehicle_used_commute: null,
      fuel: null,
      coverage_data: {
        bi_pd_checkbox: null,
        bi_pd_limit: null,
        all_perils_checkbox: null,
        all_perils_deductible: null,
        income_replacement_checkbox: null,
        income_replacement_limit: null,
        accidents_benfits_checkbox: null,
        collision_checkbox: null,
        collision_deductible: null,
        caregiver_dependant_checkbox: null,
        caregiver_dependant_deductible: null,
        uninsured_automobile_checkbox: null,
        uninsured_automobile_deductible: null,
        comprehensive_checkbox: null,
        comprehensive_deductible: null,
        medical_rehab_checkbox: null,
        medical_rehab_deductible: null,
        dcpd_checkbox: null,
        dcpd_deductible: null,
        specific_perils_checkbox: null,
        specific_perils_deductible: null,
        death_funeral_benefits_checkbox: null,
        death_funeral_benefits_deductible: null,
        endorsements_checkbox: null,
        endorsements_text: null,
      },
      additioanl_interest: null,
      additional_interest_vin_number: null,
      additional_interest_details: {
        address: null,
        unit_or_suite: null,
        city: null,
        province_or_state: null,
        postal_code: null,
        country: null,
      },
      nature_of_interest: null,
      nature_of_interest_text: null,
      operator: null,
      operator_name: null,
      same_as_section_one: null,
      described_operators_one: [
        {
          key: getUniqueId(),
          name_of_driver: "",
          driver_licence_number: "",
          assigned_vin_number: "",
          use: "",
          date_of_birth: "",
          sex: "",
          marital_status: "",
        },
      ],
      described_operators_two: [
        {
          key: getUniqueId(),
          number: 1,
          class: "AZ",
          date_first_licensed: "",
          year_licensed: "",
          held_insurance_since: "",
        },
        {
          key: getUniqueId(),
          number: 2,
          class: "B",
          date_first_licensed: "",
          year_licensed: "",
          held_insurance_since: "",
        },
        {
          key: getUniqueId(),
          number: 3,
          class: "D",
          date_first_licensed: "",
          year_licensed: "",
          held_insurance_since: "",
        },
      ],
      occupation: null,
      operator_policy_number: null,
      driver_training_course: null,
      current_or_previous_insurance: null,
      relation_to_insured: null,
      convictions_during_the_past_years: [
        {
          key: getUniqueId(),
          driver_no: "",
          date1: "",
          description1: "",
          date2: "",
          description2: "",
          date3: "",
          description3: "",
        },
      ],
      accidents_suspension_in_the_past_years: [
        {
          key: getUniqueId(),
          driver_no: "",
          date1: "",
          description1: "",
          date2: "",
          description2: "",
          date3: "",
          description3: "",
        },
      ],
      remarks: null,
      status: "ACTIVE"
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
