import moment from "moment";
import {
  CSIO_LINE_OF_BUSINESS,
  CSIO_POLICY_NAME,
} from "../../constants/constants";
import { IAutomobileCSIOPolicyState } from "./automobileCSIOPolicyChangeReq.types";
import { getUniqueId } from "../../helpers";

export const transformCSIOToPolicyChangeReq = (parsedJSON: any) => {
  const persAutoPolicyModRs =
    parsedJSON.ACORD?.InsuranceSvcRs?.PersAutoPolicyModRs;

  const {
    PersPolicy,
    Producer: { ProducerInfo },
    InsuredOrPrincipal,
  } = persAutoPolicyModRs;

  const customerDetails = Array.isArray(InsuredOrPrincipal)
    ? InsuredOrPrincipal
    : [InsuredOrPrincipal];

  const applicantDetails = {
    name: "",
    address: "",
    address_two: "",
    country: "",
    city: "",
    province_or_state: "",
    postalcode: "",
    contact_number: "",
    home: "",
    cell: "",
    business: "",
    fax: "",
    email: "",
    website: "",
  };
  const names: any[] = [];
  customerDetails.forEach((detail: any) => {
    const nameInfo = detail?.GeneralPartyInfo?.NameInfo;
    const commercialName = nameInfo?.CommlName;
    const personName = nameInfo?.PersonName;
    if (personName) {
      const fullName = `${personName?.GivenName} ${personName?.Surname}`.trim();
      names.push(fullName);
    }
    if (commercialName) {
      names.push(commercialName?.CommercialName);
    }

    const addr = detail?.GeneralPartyInfo?.Addr;
    if (addr) {
      applicantDetails.address = addr?.Addr1;
      applicantDetails.address_two = addr?.Addr2;
      applicantDetails.city = addr?.City;
      applicantDetails.province_or_state = addr?.StateProvCd;
      applicantDetails.postalcode = addr?.PostalCode;
      applicantDetails.country = addr?.CountryCd;
    }
    const phoneInfo = Array.isArray(
      detail?.GeneralPartyInfo?.Communications?.PhoneInfo
    )
      ? detail?.GeneralPartyInfo?.Communications?.PhoneInfo
      : [detail?.GeneralPartyInfo?.Communications?.PhoneInfo];
    phoneInfo.forEach((phone: any) => {
      if (phone?.CommunicationUseCd === "Alternate") {
        applicantDetails.cell = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Home") {
        applicantDetails.home = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Fax") {
        applicantDetails.fax = phone?.PhoneNumber;
      }
      if (phone?.CommunicationUseCd === "Business") {
        applicantDetails.cell = phone?.PhoneNumber;
        applicantDetails.business = phone?.PhoneNumber;
      }
    });
    applicantDetails.email =
      detail?.GeneralPartyInfo?.Communications?.EmailInfo?.EmailAddr || "";
    applicantDetails.website =
      detail?.GeneralPartyInfo?.Communications?.WebsiteInfo?.WebsitUrl || "";
  });

  applicantDetails.name = names.join(" & ");

  return {
    customer_policy_id: null,
    incremental_number: null,
    policy_number: PersPolicy?.PolicyNumber,
    insurance_company: PersPolicy["csio:CompanyCd"],
    policy_type: CSIO_LINE_OF_BUSINESS[PersPolicy?.LOBCd] || PersPolicy?.LOBCd,
    language_preferred: null,
    broker: {
      broker_agent: null,
      broker_agent_address: null,
      broker_agent_unit_or_suite: null,
      broker_agent_city: null,
      broker_agent_state: null,
      broker_agent_country: null,
      broker_agent_zip: null,
      broker_code: ProducerInfo.ContractNumber,
      broker_subcode: ProducerInfo.ProducerSubCode,
    },
    applicant_details: applicantDetails,
    policy_period_effective_date: PersPolicy?.ContractTerm?.EffectiveDt,
    policy_period_effective_time: PersPolicy?.ContractTerm?.StartTime || null,
    policy_period_expiry_date: PersPolicy?.ContractTerm?.ExpirationDt,
    policy_period_expiry_time: moment().hours(0).minutes(1).format(),
    name_changed: null,
    address_changed: null,
    vehicle_checkbox: null,
    previous_vin_number: null,
    vehicle_information: [
      {
        key: getUniqueId(),
        vehNo: "",
        year: "",
        trade_name: "",
        body_type: "",
        vin_serial_number: "",
      },
    ],
    vehicle_model_info: [
      {
        key: getUniqueId(),
        model_cc: "",
        no_of_cyls: "",
        truck_gross_weight: "",
        purchase_price: "",
        purchased_by_insured_date: "",
        purchased_by_insured: "",
      },
    ],
    pleasure_business: null,
    annual_distance: null,
    km_one_way: null,
    vehicle_used_commute: null,
    fuel: null,
    coverage_data: {
      bi_pd_checkbox: null,
      bi_pd_limit: null,
      all_perils_checkbox: null,
      all_perils_deductible: null,
      income_replacement_checkbox: null,
      income_replacement_limit: null,
      accidents_benfits_checkbox: null,
      collision_checkbox: null,
      collision_deductible: null,
      caregiver_dependant_checkbox: null,
      caregiver_dependant_deductible: null,
      uninsured_automobile_checkbox: null,
      uninsured_automobile_deductible: null,
      comprehensive_checkbox: null,
      comprehensive_deductible: null,
      medical_rehab_checkbox: null,
      medical_rehab_deductible: null,
      dcpd_checkbox: null,
      dcpd_deductible: null,
      specific_perils_checkbox: null,
      specific_perils_deductible: null,
      death_funeral_benefits_checkbox: null,
      death_funeral_benefits_deductible: null,
      endorsements_checkbox: null,
      endorsements_text: null,
    },
    additioanl_interest: null,
    additional_interest_vin_number: null,
    additional_interest_details: {
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
    },
    nature_of_interest: null,
    nature_of_interest_text: null,
    operator: null,
    operator_name: null,
    same_as_section_one: null,
    described_operators_one: [
      {
        key: getUniqueId(),
        name_of_driver: "",
        driver_licence_number: "",
        assigned_vin_number: "",
        use: "",
        date_of_birth: "",
        sex: "",
        marital_status: "",
      },
    ],
    described_operators_two: [
      {
        key: getUniqueId(),
        license_class_one: "",
        license_class_two: "",
        license_class_three: "",
        date_licensed_one: "",
        date_licensed_two: "",
        date_licensed_three: "",
      },
    ],
    occupation: null,
    operator_policy_number: null,
    driver_training_course: null,
    current_or_previous_insurance: null,
    relation_to_insured: null,
    convictions_during_the_past_years: [
      {
        key: getUniqueId(),
        driver_no: "",
        date1: "",
        description1: "",
        date2: "",
        description2: "",
        date3: "",
        description3: "",
      },
    ],
    accidents_suspension_in_the_past_years: [
      {
        key: getUniqueId(),
        driver_no: "",
        date1: "",
        description1: "",
        date2: "",
        description2: "",
        date3: "",
        description3: "",
      },
    ],
    remarks: null,
    status: "ACTIVE",
  };
};
