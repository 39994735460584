/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
  SelectChangeEvent,
  TableContainer,
  Grid,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import produce from "immer";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  addCommAutomobileScheduleCAsync,
  addCommAutomobileScheduleDAsync,
  fetchCommAutomobileScheduleCAsync,
  fetchCommAutomobileScheduleDAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { ICommScheduleDCoverage } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoSchedule.types";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../../../../components/Cards";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { COMMON_STATUS, vehicle_schedule_dropdown } from "../../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const CommAutomobileScheduleD = () => {
  const { customerId, customerPolicyId, driverNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    driverNumber?: number;
  };

  const { loading, data } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleD
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: data,
      validate: (values) => {},
      onSubmit: (values) => {
        if (customerPolicyId) {
          setSaveLoading(true);
          dispatch(
            addCommAutomobileScheduleDAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                // incremental_number: driverNumber
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                }
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=3`
                );
                setSaveLoading(false);
              }
            )
          );
        }
      },
    });

  const handleThirdPartyLiability =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_a_third_party_liability,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue("section_a_third_party_liability", newvalues);
    };
  const handleThirdPartyLiabilityCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_a_third_party_liability,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue("section_a_third_party_liability", newvalues);
    };

  const handleAccidentBenefits =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_b_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue("section_b_accident_benefits", newvalues);
    };
  const handleAccidentBenefitsCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_b_accident_benefits,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue("section_b_accident_benefits", newvalues);
    };

  const handleDamageOwnedAutomobiles =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_c_lossof_or_damage_to_owned_automobiles,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue(
        "section_c_lossof_or_damage_to_owned_automobiles",
        newvalues
      );
    };

  const handleDamageOwnedAutomobilesCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_c_lossof_or_damage_to_owned_automobiles,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue(
        "section_c_lossof_or_damage_to_owned_automobiles",
        newvalues
      );
    };

  const handleLiabilityforDamage =
    (key: string, rowIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_e_liability_for_damage_to_a_customer_automobile,
        (draftState) => {
          draftState[rowIndex][key as "label"] = e.target.value;
        }
      );
      setFieldValue(
        "section_e_liability_for_damage_to_a_customer_automobile",
        newvalues
      );
    };
  const handleLiabilityforDamageCurrencyChange =
    (key: string, rowIndex: number) => (value: string) => {
      const newvalues = produce(
        values.section_e_liability_for_damage_to_a_customer_automobile,
        (draftState) => {
          draftState[rowIndex][key as "label"] = value;
        }
      );
      setFieldValue(
        "section_e_liability_for_damage_to_a_customer_automobile",
        newvalues
      );
    };

  const handleCollisionOrupsetChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newvalues = produce(
        values.section_c_collision_or_upset,
        (draftState) => {
          draftState[key as keyof typeof draftState] = e.target.value;
        }
      );
      setFieldValue("section_c_collision_or_upset", newvalues);
    };

  const accidentBenefits: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleAccidentBenefitsCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_b_accident_benefits,
  };

  const damageOwnedAutomobiles: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "locationPerItem1",
        headerName: "Location Per Item 1",
        fieldName: "locationPerItem1",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "subsectionInsured",
        headerName: "Subsections Insured",
        fieldName: "subsectionInsured",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CustomTextField
              fullWidth
              value={row.subsectionInsured}
              onChange={handleDamageOwnedAutomobiles(
                "subsectionInsured",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "limitofLiability",
        headerName: "Limit of Liability",
        fieldName: "limitofLiability",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limitofLiability}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "limitofLiability",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "deductibleOfEachOccurrence",
        headerName: "Sum payable by insured in respect of occurrence",
        fieldName: "deductibleOfEachOccurrence",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOfEachOccurrence}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "deductibleOfEachOccurrence",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "advacnePremimum",
        headerName: "Advance Premium",
        fieldName: "advacnePremimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.advacnePremimum}
              onChange={handleDamageOwnedAutomobilesCurrencyChange(
                "advacnePremimum",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_c_lossof_or_damage_to_owned_automobiles,
  };

  const liabilityforDamage: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,

    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "label",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return <Typography>{row.label}</Typography>;
        },
      },

      {
        key: "locationPerItem1",
        headerName: "Location Per Item 1",
        fieldName: "locationPerItem1",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "maximumNumberOfCustomers",
        headerName: "Maximum Number of Customer's Automobiles",
        fieldName: "maximumNumberOfCustomers",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CustomTextField
              fullWidth
              value={row.maximumNumberOfCustomers}
              onChange={handleLiabilityforDamage(
                "maximumNumberOfCustomers",
                value.column.rowIndex
              )}
            />
          );
        },
      },

      {
        key: "limitofLiability",
        headerName: "Limit of Liability Any One Occurence",
        fieldName: "limitofLiability",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.limitofLiability}
              onChange={handleLiabilityforDamageCurrencyChange(
                "limitofLiability",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "advacnePremimum",
        headerName: "Advance Premium",
        fieldName: "advacnePremimum",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleDCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.advacnePremimum}
              onChange={handleLiabilityforDamageCurrencyChange(
                "advacnePremimum",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_e_liability_for_damage_to_a_customer_automobile,
  };

  React.useEffect(() => {
    const sectionTotal = (premium: any) => {
      let inputString = String(premium);
      let outputString = inputString?.replace(/,/g, "");
      return Number(outputString);
    };

    const Section_A = values.section_a_third_party_liability.reduce(
      (acc, item) => acc + sectionTotal(item.premium),
      0
    );

    const Section_B = values.section_b_accident_benefits.reduce(
      (acc, item) => acc + sectionTotal(item.premium),
      0
    );

    const Section_C =
      values.section_c_lossof_or_damage_to_owned_automobiles.reduce(
        (acc, item) => acc + sectionTotal(item.advacnePremimum),
        0
      );

    const Section_C_Collision = sectionTotal(
      values.section_c_collision_or_upset?.advacnePremimum || 0
    );

    const Section_E =
      values.section_e_liability_for_damage_to_a_customer_automobile.reduce(
        (acc, item) => acc + sectionTotal(item.advacnePremimum),
        0
      );

    const Section_E_Collision = sectionTotal(
      values.section_e_collision_or_upset?.advacnePremimum || 0
    );

    const Section_E_Endorsements = sectionTotal(
      values.endorsements?.advacnePremimum || 0
    );

    let total =
      Section_A +
      Section_B +
      Section_C +
      Section_C_Collision +
      Section_E +
      Section_E_Collision +
      Section_E_Endorsements;

    setFieldValue("total_premium", total);
  }, [
    values.section_a_third_party_liability,
    values.section_b_accident_benefits,
    values.section_c_lossof_or_damage_to_owned_automobiles,
    values.section_e_liability_for_damage_to_a_customer_automobile,
    values.section_c_collision_or_upset,
    values.section_e_collision_or_upset,
    values.endorsements,
  ]);

  React.useEffect(() => {
    if (customerPolicyId && driverNumber) {
      dispatch(
        fetchCommAutomobileScheduleDAsync(customerPolicyId, driverNumber)
      );
    }
  }, [customerPolicyId, driverNumber]);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard heading="Garage Coverage(Alberta)">
        <form onSubmit={handleSubmit}>
          <LabelCard heading="Section A. and Section A.1" />
          {/* <DataTable {...thirdPartyLiability} /> */}
          <TableContainer>
            <Table
              aria-label="collapsible table"
              sx={{
                whiteSpace: {
                  xs: "nowrap",
                  sm: "unset",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={600} variant="h4">
                      Limits and Amounts
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={600} variant="h4">
                      Advance Premium
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.section_a_third_party_liability.map((item, index) => {
                  return (
                    <TableRow
                      key={item.key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{item.label}</TableCell>
                      <TableCell>
                        <CurrencyTextField
                          fullWidth
                          value={item.deductible}
                          onChange={handleThirdPartyLiabilityCurrencyChange(
                            "deductible",
                            index
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <CurrencyTextField
                          fullWidth
                          value={item.premium}
                          onChange={handleThirdPartyLiabilityCurrencyChange(
                            "premium",
                            index
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <LabelCard heading="Section B. Accident Benefits" />
          <DataTable {...accidentBenefits} />

          <LabelCard heading="Section C. Loss of or Damage to Owned Automobiles" />
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"> </TableCell>
                <TableCell sx={{ width: "30%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Actual cash value at time of loss or damage not exceeding
                    the actual cost to the insured
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "30%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Sum payable by insured in respect of ech separate
                    occurrence.
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Advance Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
              // sx={{ "": { border: 0 } }}
              >
                <TableCell>Collision or Upset</TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={
                      values.section_c_collision_or_upset
                        .actual_cash_value_at_time_of_loss
                    }
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_c_collision_or_upset.actual_cash_value_at_time_of_loss",
                        newVal
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={
                      values.section_c_collision_or_upset
                        .sum_payable_by_insured_in_respect
                    }
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_c_collision_or_upset.sum_payable_by_insured_in_respect",
                        newVal
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={values.section_c_collision_or_upset.advacnePremimum}
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_c_collision_or_upset.advacnePremimum",
                        newVal
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow
              // sx={{ "": { border: 0 } }}
              >
                <TableCell>
                  <Typography variant="body2">
                    The Premium under sub-section 2,3, and 4 shall be computed
                    on A
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      value={values.MCO}
                      checked={values.MCO === "M"}
                      onChange={(e, checked) => {
                        setFieldValue("MCO", checked ? "M" : null);
                      }}
                    />
                    <Typography>Monthly Average Basis</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      value={values.MCO}
                      checked={values.MCO === "C"}
                      onChange={(e, checked) => {
                        setFieldValue("MCO", checked ? "C" : null);
                      }}
                    />
                    <Typography>Co-Insurance Basis</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      value={values.MCO}
                      checked={values.MCO === "O"}
                      onChange={(e, checked) => {
                        setFieldValue("MCO", checked ? "O" : null);
                      }}
                    />
                    <Typography>Other</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <DataTable {...damageOwnedAutomobiles} />

          <LabelCard heading="Section E. Liability for damage to a customer’s automobile while in the care, custody, or control of the applicant" />
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"> </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Limit of liability
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h5">
                    Sum payable by insured in respect of Each separate
                    occurrence
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Advance Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell> Collision or Upset</TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={values.section_e_collision_or_upset.limitofLiability}
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_e_collision_or_upset.limitofLiability",
                        newVal
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={
                      values.section_e_collision_or_upset
                        .deductibleOfEachOccurrence
                    }
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_e_collision_or_upset.deductibleOfEachOccurrence",
                        newVal
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={values.section_e_collision_or_upset.advacnePremimum}
                    onChange={(newVal) => {
                      setFieldValue(
                        "section_e_collision_or_upset.advacnePremimum",
                        newVal
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <DataTable {...liabilityforDamage} />

          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"> </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell sx={{ width: "20%" }} align="center">
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Advance Premium
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell> Endorsements</TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="endorsements.limitofLiability"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.endorsements.limitofLiability}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={values.endorsements.advacnePremimum}
                    onChange={(newVal) => {
                      setFieldValue("endorsements.advacnePremimum", newVal);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Minimum Retained Premium</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <CurrencyTextField
                    fullWidth
                    value={values.minimum_retained_premium}
                    onChange={(newVal)=> {
                      setFieldValue("minimum_retained_premium", newVal)
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Total Premium
                  </Typography>
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CurrencyTextField
                    fullWidth
                    value={values.total_premium}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid container>
            <Grid item xs={12} lg={3.5}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.status}
                name="status"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={COMMON_STATUS}
              />
            </Grid>
          </Grid>
                    
          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
