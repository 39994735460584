/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import { Box, Button, Grid, Stack, Typography, Checkbox } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { useBreadcrumbContext } from "../../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { renderDropdownValue } from "../../../../../../../helpers";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { fetchCommAutomobileVehicleListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { BranchCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { addAutomobileRetireeDiscountAsync, clearAutomobilePolicyFormsState, fetchAutomobileRetireeDiscountAsync } from "../../../../../../../redux/automobilePolicyForms/automobilePolicyFormsActions";

export const AutomobileRetireeDiscount: React.FC<{isDuplicate?: boolean}> = (props) => {
  const { customerId, customerPolicyId, serialNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    serialNumber?: number;
  };

  const { isDuplicate } = props;
  const { addBreadcrumb } = useBreadcrumbContext();

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/view-customers",
      title: "customers",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer-overview",
    },
    {
      title: "consent-form",
    },
  ];

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearAutomobilePolicyFormsState());
    };
  }, []);

  const { data:retiree, loading:retireeLoading, error } = useSelector(
    (storeState: IStoreState) => storeState.automobile.policyForms.retireediscount
  );
  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.basicDetails.customerInfo.data
  );
  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: retiree,
    validate: (values) => {
      const errors: any = {};
      if(!values.branch_code){
        errors.branch_code = "Broker/Agent is required!";
      }
      return errors;
     },
    onSubmit: (values) => {
      if (customerPolicyId && customerId) {
        setSaveLoading(true);
        dispatch(
          addAutomobileRetireeDiscountAsync(
            {
              ...values,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess) => {
              if (isSuccess) {
                window.scrollTo(0, 0);
                navigate(
                  `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=0`
                );
              }
              setSaveLoading(false);
            },
            isDuplicate,
          )
        );
      }
    },
  });

  React.useEffect(() => {
    if (customerPolicyId && serialNumber) {
      dispatch(fetchAutomobileRetireeDiscountAsync(customerPolicyId, serialNumber));
    }
  }, [customerPolicyId, serialNumber]);

  React.useEffect(() => {
    if (!serialNumber) {
      setValues({
        ...retiree,
        customer_name: customerInfo.customer_name,
        policy_number: customerInfo.policy_number,
      });
    } else {
      setValues(retiree);
    }
  }, [retiree, customerInfo]);

  // React.useEffect(() => {
  //   setFieldValue("customer_name", customerInfo.customer_name);
  //   setFieldValue("policy_number", customerInfo.policy_number);
  // }, [customerInfo])

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobilePolicyFormsState());
    };
  }, []);

  return (
    <PageLoader
      loading={retireeLoading === LoadState.InProgress}
      error={error ? { message: error } : undefined}
    >
      <StandadCard sx={{ mt: 2 }} heading="Retiree Discount">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <CustomTextField
                name="customer_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.customer_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Policy Number</CustomFormLabel>
              <CustomTextField
                name="policy_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.policy_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Effective Date of Discount</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.effective_date_of_discount || ""}
                onChange={(newValue) => {
                  setFieldValue("effective_date_of_discount", newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Insurance Company</CustomFormLabel>
              <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Broker/Agent</CustomFormLabel>
              <BranchCodeAutoSearch
                value={values.branch_code}
                onSelect={(value) => {
                  setFieldValue("branch", value.label);
                  setFieldValue("branch_code", value.value);
                }}
                errorMessage={errors.branch_code}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Retiree Name</CustomFormLabel>
              <CustomTextField
                name="retiree_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.retiree_name}
                onChange={handleChange}
              />
            </Grid>
            

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                name="status"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                displayEmpty
                placeholder="Select One"
                options={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ].map((data) => {
                  return { label: data.label, value: data.value };
                })}
                value={values.status}
                onChange={handleChange}
              />
            </Grid> 
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
      {/* </CustomerBaiscDetailsLayout> */}
    </PageLoader>
  );
};
