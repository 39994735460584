import React from "react";
import { api } from "../../api/api";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";

export enum userRoles {
  ADMIN = "ADMIN",
  None = "None",
}

export interface IAuth {
  user: {
    name: string;
    userId: number;
    email: string;
    isLogin: boolean;
    role: keyof typeof userRoles;
    tokens: { access: string } | null;
  };
  twoFactorData: {
    userFactId: string;
  };
  login: (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  resendOTP: () => void;
  verifyOTP: (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  forgotPassword: (
    email: string,
    otp_for: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  setNewPassword: (
    email: string,
    otp: string,
    new_password: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  logout: () => void;
  addUserName: (name: string) => void;
  otherUserLogin: (
    token: string,
    userId: number,
    onComplete: (isSuccess: boolean, error?: string) => void
  ) => void;
}

const AuthContext = React.createContext<IAuth>({
  user: {
    name: "",
    email: "",
    userId: 0,
    role: userRoles.None,
    isLogin: false,
    tokens: null,
  },
  twoFactorData: {
    userFactId: "",
  },
  login: () => {},
  resendOTP: () => {},
  verifyOTP: () => {},
  forgotPassword: () => {},
  setNewPassword: () => {},
  logout: () => {},
  addUserName: () => {},
  otherUserLogin: ()=> {},
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [user, setUser] = React.useState<IAuth["user"]>({
    name: "",
    email: "",
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    userId: 0,
  });
  const [otherUser, setOtherUser] = React.useState<IAuth["user"]>({
    name: "",
    email: "",
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    userId: 0,
  });
  const [twoFactorData, setTwoFactorData] = React.useState<
    IAuth["twoFactorData"]
  >({
    userFactId: "",
  });
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const key = new URLSearchParams(location.search).get("token");

  React.useEffect(() => {
    const auth = localStorage.getItem("auth");
    if(!key){
      if (auth) {
        const data = JSON.parse(auth);
        const date1 = new Date(data.date);
        const date2 = new Date();
        const diff = date2.getHours() - date1.getHours();
        if (diff <= 1) {
          setUser(data);
        }
      }
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }, []);

  const handleLogin = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      const res = await api.post("/user/login", {
        email: email,
        password: password,
      });
      const data = res.data;
   

      const userData = {
        name: email, //user.user_name,
        email: email, //user.email,
        role: userRoles.ADMIN,
        isLogin: false,
        userId: data.data.userFactId,
        tokens: { access: "" }, //res.data.Tokens,
      };
      setUser(userData);
      setPassword(password)
      setTwoFactorData({ userFactId: data.data.userFactId });
      onSuccess();

    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const resendOTP = async()=>{
    try{
      await api.post("/user/login", {
        email: user.email,
        password: password,
      });
    }
    catch (error: any) {
     console.log(error)
    }
  }

  const handleVerfiyOTP = async (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      const res = await api.post("/user/validate-otp-get-token", {
        user_fact_id: twoFactorData.userFactId,
        otp: Number(otp),
      });
      const data = res.data;

      const userData = {
        ...user,
        isLogin: true,
        tokens: { access: data.data.access_token },
      };
      setUser(userData);
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...userData, date: new Date() })
      );
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    // setUser({
    //   isLogin: false,
    //   name: "",
    //   email: "",
    //   tokens: null,
    //   role: userRoles["None"],
    // });
    // dispatch({ type: USER_LOGOUT });
    // navigate("/auth/login");
    window.open("/auth/login", "_self");
  };

  const handleForgotPassword = async (
    email: string,
    otp_for: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      await api.post("/user/generate-otp", {
        email: email,
        otp_for: otp_for,
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleNewPassword = async (
    email: string, 
    otp: string,
    new_password: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      await api.post("/user/forget-password", {
        email: email,
        otp: otp,
        user_password: new_password,
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  }

  const addUserName = (name: string) => {
    setUser({ ...user, name: name });
  };

  const otherUserAccess = async (
    token: string,
    userId: number,
    onComplete: (isSuccess: boolean, error?: string) => void
  ) => {
    try {
      setLoading(true);
      const userData = {
        name: "",
        email: "",
        role: userRoles.None,
        isLogin: true,
        userId: userId,
        tokens: { access: token },
      };
      setUser(userData);
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...userData, date: new Date() })
      );
      onComplete(true);
      setLoading(false);
    } catch (error: any) {
     // onError(error.response.data.message);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        user:   user,
        login: handleLogin,
        twoFactorData,
        verifyOTP: handleVerfiyOTP,
        forgotPassword: handleForgotPassword,
        setNewPassword: handleNewPassword,
        logout: handleLogout,
        resendOTP: resendOTP,
        addUserName: addUserName,
        otherUserLogin: otherUserAccess,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
