import { action } from "typesafe-actions";
import { IBusinessPolicyInterested } from "./businessPolicyInterested.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_INTERESTED_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_INTERESTED_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_INTERESTED_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_INTERESTED_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_INTERESTED_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_INTERESTED_LIST_FAILED";

export const fetchBusinessPolicyIntrestedListProgress = () =>
  action(FETCH_BUSINESS_POLICY_INTERESTED_LIST_PROGRESS);
export const fetchBusinessPolicyIntrestedListSuccess = (
  data: IBusinessPolicyInterested[]
) => action(FETCH_BUSINESS_POLICY_INTERESTED_LIST_SUCCESS, { data });
export const fetchBusinessPolicyIntrestedListFailed = () =>
  action(FETCH_BUSINESS_POLICY_INTERESTED_LIST_FAILED);

export const fetchBusinessPolicyIntrestedListAsync =
  (customerPolicyId: number, status?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyIntrestedListProgress());
      let finalUrl = `/policy/get-policy-business-interested-parties?customer_policy_id=${customerPolicyId}`;

      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyInterested[] = res.data.data;
      dispatch(fetchBusinessPolicyIntrestedListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyIntrestedListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyInterestedAsync =
  (
    interested: IBusinessPolicyInterested,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-interested-parties`, {
        ...interested,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested Parties saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_INTERESTED_PROGRESS =
  "FETCH_BUSINESS_POLICY_INTERESTED_PROGRESS";
export const FETCH_BUSINESS_POLICY_INTERESTED_SUCCESS =
  "FETCH_BUSINESS_POLICY_INTERESTED_SUCCESS";
export const FETCH_BUSINESS_POLICY_INTERESTED_FAILED =
  "FETCH_BUSINESS_POLICY_INTERESTED_FAILED";

export const fetchBusinessPolicyIntrestedProgress = () =>
  action(FETCH_BUSINESS_POLICY_INTERESTED_PROGRESS);
export const fetchBusinessPolicyIntrestedSuccess = (
  data: IBusinessPolicyInterested
) => action(FETCH_BUSINESS_POLICY_INTERESTED_SUCCESS, { data });
export const fetchBusinessPolicyIntrestedFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_INTERESTED_FAILED, { errorMessage });

export const fetchBusinessPolicyIntrestedAsync =
  (
    customerPolicyId: number,
    interestedNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyIntrestedProgress());
      const res = await api.get(
        `/policy/get-policy-business-interested-parties?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${interestedNumber}`
      );
      const data: IBusinessPolicyInterested[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyIntrestedSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyIntrestedFailed(
            "Oops! We couldn't find any records associated with your intrested parties number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchBusinessPolicyIntrestedFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_INTERESTED_STATE =
  "CLEAR_BUSINES_POLICY_INTERESTED_STATE";
export const CLEAR_BUSINES_POLICY_INTERESTED =
  "CLEAR_BUSINES_POLICY_INTERESTED";

export const clearBusinessPolicyIntrestedState = () =>
  action(CLEAR_BUSINES_POLICY_INTERESTED_STATE);

export const clearBusinessPolicyIntrested = () =>
  action(CLEAR_BUSINES_POLICY_INTERESTED);
