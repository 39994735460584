import moment from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IBusinessPolicyFormsState {
  policyFormsList: {
    list: IBusinessPolicyForm[];
    loading: LoadState;
    totalRecords: number;
  };
  liability: {
    data: IBusinessPolicyLiability;
    loading: LoadState;
    error: string | null;
  };
  propertyInsurance: {
    data: IBusinessPropertyInsurance;
    loading: LoadState;
    error: string | null;
  };
  garageCertificate: {
    data: IBusinessGarageCertificate;
    loading: LoadState;
    error: string | null;
  };
  paymentAuthorization: {
    data: IBusinessPolicyAuthorization;
    loading: LoadState;
    error: string | null;
  };
  privacyConsent: {
    data: IBusinessConsentForm;
    loading: LoadState;
    error: string | null;
  };
}

export interface IBusinessPolicyForm {
  incremental_number: number;
  task_code: string;
  policy_number: string;
  customer_name: string;
  customer_code: string;
  policy_form_type: string;
  create_ts: string | null;
  created_by_id: number;
  status: string;
}

export interface IBusinessPolicyLiability {
  incremental_number: number | null;
  customer_policy_id: number;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_deductible: string;
    hired_amount: string;
  };
  automobile_liability_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string | null;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: number | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

interface ICommercialDeductRow {
  deductible: number | null;
  amount: number | null;
}

interface ICommercialSectionDropdown {
  key: string;
  label: string;
  deductible: number | null;
  amount: number | null;
}

interface ICommercialPropertySectionFive {
  deductible: number | null;
  amount: number | null;
}

export interface IBusinessPropertyInsurance {
  incremental_number: number | null;
  customer_policy_id: number;
  cgl: string | null;
  excess_liability: string | null;
  other_liability: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    generalAggregate: ICommercialDeductRow;
    eachOccurrence: ICommercialDeductRow;
    productsAndCompletedOperationsAggregate: ICommercialDeductRow;
    employerLiability: ICommercialDeductRow;
    dropDownPersonalAndAdvertInjury: ICommercialDeductRow & { value: string };
    medicalPayments: ICommercialDeductRow;
    tenantsLegalLiability: ICommercialDeductRow;
    nonOwnedAutomobiles: ICommercialDeductRow;
    hiredAutomobiles: ICommercialDeductRow;
  };
  commercial_general_liability_section_three: ICommercialSectionDropdown[];

  commercial_general_liability_section_four: {
    description: { label: string } & ICommercialPropertySectionFive;
    building: ICommercialPropertySectionFive;
    equipment: ICommercialPropertySectionFive;
    stock: ICommercialPropertySectionFive;
    earthquake: ICommercialPropertySectionFive;
    sewerBackup: ICommercialPropertySectionFive;
    flood: ICommercialPropertySectionFive;
  };
  commercial_general_liability_section_five: ICommercialSectionDropdown[];
  commercial_general_liability_section_six: ICommercialSectionDropdown[];
  commercial_general_liability_section_seven: ICommercialSectionDropdown[];
  commercial_general_liability_section_eight: ICommercialSectionDropdown[];
  commercial_general_liability_section_nine: ICommercialSectionDropdown[];

  excess_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    type1: string;
    type2: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggregate_form_deductible: string;
    aggregate_form_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: ICommercialSectionDropdown[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: number | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

interface ICommercialDeductRow {
  deductible: number | null;
  amount: number | null;
}

interface IDynamicRow {
  key: string;
  coverage: string;
  deductible: number | null;
  amount: number | null;
}

interface ICommercialSectionDropdown {
  key: string;
  label: string;
  deductible: number | null;
  amount: number | null;
}

interface ICommercialPropertySectionFive {
  deductible: number | null;
  amount: number | null;
}

export interface IBusinessGarageCertificate {
  customer_policy_id: number;
  cgl: string | null;
  incremental_number: number | null;
  garage_liability: string | null;
  other_liability: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_section_one: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    generalAggregate: ICommercialDeductRow;
    eachOccurrence: ICommercialDeductRow;
    productsAndCompletedOperationsAggregate: ICommercialDeductRow;
    employerLiability: ICommercialDeductRow;
    dropDownPersonalAndAdvertInjury: ICommercialDeductRow & { value: string };
    medicalPayments: ICommercialDeductRow;
    tenantsLegalLiability: ICommercialDeductRow;
    nonOwnedAutomobiles: ICommercialDeductRow;
    hiredAutomobiles: ICommercialDeductRow;
  };
  commercial_general_liability_section_three: ICommercialSectionDropdown[];

  commercial_general_liability_section_four: {
    description: { label: string } & ICommercialPropertySectionFive;
    building: ICommercialPropertySectionFive;
    equipment: ICommercialPropertySectionFive;
    stock: ICommercialPropertySectionFive;
    earthquake: ICommercialPropertySectionFive;
    sewerBackup: ICommercialPropertySectionFive;
    flood: ICommercialPropertySectionFive;
  };
  commercial_general_liability_section_five: ICommercialSectionDropdown[];
  commercial_general_liability_section_six: ICommercialSectionDropdown[];
  commercial_general_liability_section_seven: ICommercialSectionDropdown[];
  commercial_general_liability_section_eight: ICommercialSectionDropdown[];
  commercial_general_liability_section_nine: ICommercialSectionDropdown[];

  garage_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };

  garage_liability_section_two: ICommercialSectionDropdown[];
  garage_liability_section_three: {
    lossOrDamage: string;
    deductible: string | null;
    amount: string | null;
  };
  garage_liability_section_four: {
    label1: string;
    label1Amount: string | null;
    label1Deductible: string | null;

    label2: string;
    label2Amount: string | null;
    label2Deductible: string | null;
  };
  // garage_liability_section_two: {
  //   thirdPartyLiability: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   accidentBenefits: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   uninsuredAutomobileCoverage: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  //   dcpd: {
  //     deductible: string | null;
  //     amount: string | null;
  //   };
  // };

  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggregate_form_deductible: string;
    aggregate_form_amount: string;
  };
  // other_liability_section_one: {
  //   insurance_company: string;
  //   policy_number: string;
  //   effective_date: string;
  //   expiry_date: string;
  // };

  // other_liability_section_two: ICommercialSectionDropdown[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: number | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;

  other_liability_section_one: {
    insurance_company: string | null;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: IDynamicRow[];
}

export interface IBusinessPolicyAuthorization {
  customer_policy_id: number | null;
  customer_id: number | null;
  policy_type: string | null;
  applicant_name: string | null;
  applicant_address: string | null;
  applicant_unit_or_suite: string | null;
  applicant_city: string | null;
  applicant_province_or_state: string | null;
  applicant_postal_code: string | null;
  applicant_contact_number: string | null;
  contact_type: string | null;
  applicant_preferred_document_language: string | null;
  applicant_email_address: string;
  applicant_website_address: string;
  brokerage_branch_name: string | null;
  brokerage_full_address: string | null;
  brokerage_postal_code: string | null;
  brokerage_broker_code: string | null;
  brokerage_contact_name: string | null;
  brokerage_phone_number: string | null;
  brokerage_fax_number: string | null;
  brokerage_contract_number: string | null;
  brokerage_sub_contract_number: string | null;
  brokerage_group_or_program_name: string | null;
  brokerage_group_id: string | null;
  brokerage_broker_client_id: string | null;
  brokerage_company_client_id: string | null;
  incremental_number: number | null;
  insurance_company_name: string | null;
  insurance_company_address: string | null;
  insurance_company_unit_or_suite: string | null;
  insurance_company_city: string | null;
  insurance_company_province_or_state: string | null;
  insurance_company_postal_code: string | null;
  insurance_company_country: string | null;
  policy_number: string | null;
  request: string | null;
  broker_code: string | null;
  total_estimated_policy_premium: string | null;
  installment_fee: number | null;
  tax_percentage: number | null;
  tax: string | null;
  percentage_finance: number | null;
  finance_charges: string | null;
  total_estimated_cost: string | null;
  mode_of_payment: string | null;
  plan_type: string | null;
  financial_institution_or_bank_name: string | null;
  address_bank: string | null;
  bank_unit_or_suite: string | null;
  bank_city: string | null;
  bank_province_or_state: string | null;
  bank_postal_code: string | null;
  bank_country: string | null;
  account_holder_name: string | null;
  account_holder_unit_or_suite: string | null;
  account_holder_city: string | null;
  account_holder_province_or_state: string | null;
  account_holder_postal_code: string | null;
  account_holder_country: string | null;
  address_account_holder: string | null;
  transit_number: string | null;
  institution_number: string | null;
  account_number: string | null;
  type_of_payment: string | null;
  down_payment_amount: string | null;
  insurance_company_additional_charges: string | null;
  broker_additional_charges: string | null;
  full_payment_amount: string | null;
  installment_amount: string | null;
  next_payment_date: string | null;
  electrical_year: string | null;
  consent_and_disclosure: [
    {
      sentence: "I/We hereby authorize the named financial institution above to debit my/our account for all payments payable to";
      checked: false;
    }
  ];
  status: "ACTIVE";
}

export interface IAuthorizationRow {
  customer_policy_id: string;
  insurance_company_name: string | null;
  broker_code: string | null;
  policy_number: string | null;
}

export interface IBusinessConsentForm {
  customer_policy_id: number;
  customer_id: number | null;
  incremental_number: number | null;
  customer_name: string | null;
  email_address: string | null;
  text_field: string | null;
  status: string;
  created_by_id: number | null;
  modified_by_id: number | null;
  insert_ts: string | null;
  create_ts: string | null;
}

export const initialBusinessPolicyFormState: IBusinessPolicyFormsState = {
  policyFormsList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
  },
  liability: {
    data: {
      incremental_number: null,
      customer_policy_id: 0,
      cgl_policy_number: null,
      automobile_policy: null,
      umbrella_or_excess_liability_policy: null,
      other_policy: null,
      non_owned_automobiles_policy: null,
      hired_automobiles: null,
      vehicle_unit_number_or_vin: null,
      certificate_holder_name: null,
      certificate_holder_address: null,
      certificate_holder_email: null,
      certificate_holder_phone: null,
      description_of_operations: [
        { key: getUniqueId(), label: "Line 1", value: "" },
        { key: getUniqueId(), label: "Line 2", value: "" },
        { key: getUniqueId(), label: "Line 3", value: "" },
        { key: getUniqueId(), label: "Line 4", value: "" },
      ],
      commercial_general_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      commercial_general_liability_section_two: {
        claim_mode: "N",
        claim_mode_deductible: "",
        claim_mode_amount: "5,000,000",
        occurance: "N",
        occurance_deductible: "1,000",
        occurance_amount: "2,000,000",
        products_completed_oprations: "N",
        products_completed_oprations_deductible: "",
        products_completed_oprations_amount: "2,000,000",
        empolyer_liability: "N",
        empolyer_liability_peronal_injury: "N",
        empolyer_liability_deductible: "",
        empolyer_liability_amount: "",
        waiver_of_subrogation: "N",
        waiver_of_subrogation_peronal_injury: "N",
        waiver_of_subrogation_deductible: "",
        waiver_of_subrogation_amount: "2,000,000",
        cross_liability: "N",
        cross_liability_deductible: "",
        cross_liability_amount: "5,000",
        tenants_Legal_Liability: "N",
        tenants_Legal_Liability_deductible: "1,000",
        tenants_Legal_Liability_amount: "250,000",
        pollution_Liability_Extension: "N",
        pollution_Liability_Extension_deductible: "",
        pollution_Liability_Extension_amount: "",
      },
      commercial_general_liability_section_three: [
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
      ],
      non_owned_automobiles_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      non_owned_automobiles_section_two: {
        non_owned: "N",
        non_ownder_amount: "",
        non_owned_deductible: "",
      },
      hired_automobiles_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      hired_automobiles_section_two: {
        hired_type_of_insurance: "N",
        hired_amount: "",
        hired_deductible: "",
      },
      automobile_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      automobile_liability_section_two: {
        described_automobiles: "N",
        described_automobiles_deductible: "",
        described_automobiles_amount: "1,000,000",
        all_owned_automobiles: "N",
        all_owned_automobiles_deductible: "",
        all_owned_automobiles_amount: "",
        leased_automobiles: "N",
        leased_automobiles_deductible: "",
        leased_automobiles_amount: "",
        property_damage_deductible: "",
        property_damage_amount: "",
      },
      excess_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      excess_liability_section_two: {
        umbrella_form: "N",
        umbrella_form_amount: "",
        aggreate_deductible: "",
        umbrella_form_deductible: "",
        extra: "N",
        extra_coverage: "",
        extra_type_of_insurance: "",
        extra_amount: "",
        extra_deductible: "",
        aggreate_amount: "",
      },
      other_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      other_liability_section_two: [
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
        {
          key: getUniqueId(),
          type_of_insurance_check: "",
          type_of_insurance: "",
          coverage: "",
          deductible: "",
          amount: "",
        },
      ],
      cancelation_number_of_days: "0",
      brokerage_full_name: null,
      brokerage_mailing_address: null,
      brokerage_customer_code: null,
      additional_name_insured_yes_or_no: null,
      additional_name_insured_name: null,
      additional_name_insured_mailing_address: null,
      certificate_authorization_branch_name: null,
      certificate_authorization_assigned_to: null,
      certificate_authorization_phone_number: null,
      certificate_authorization_fax_number: null,
      certificate_authorization_email: null,
      certificate_authorization_date: moment().format("YYYY-MM-DD"),
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  propertyInsurance: {
    data: {
      incremental_number: null,
      customer_policy_id: 0,
      cgl: null,
      excess_liability: null,
      other_liability: null,
      vehicle_unit_number_or_vin: null,
      certificate_holder_name: null,
      certificate_holder_address: null,
      certificate_holder_email: null,
      certificate_holder_phone: null,
      description_of_operations: [
        { key: getUniqueId(), label: "Line 1", value: "" },
        { key: getUniqueId(), label: "Line 2", value: "" },
        { key: getUniqueId(), label: "Line 3", value: "" },
        { key: getUniqueId(), label: "Line 4", value: "" },
      ],
      commercial_general_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      commercial_general_liability_section_two: {
        eachOccurrence: { deductible: null, amount: null },
        dropDownPersonalAndAdvertInjury: {
          deductible: null,
          amount: null,
          value: "",
        },
        employerLiability: { deductible: null, amount: null },
        generalAggregate: { deductible: null, amount: null },
        hiredAutomobiles: { deductible: null, amount: null },
        medicalPayments: { deductible: null, amount: null },
        nonOwnedAutomobiles: { deductible: null, amount: null },
        productsAndCompletedOperationsAggregate: {
          deductible: null,
          amount: null,
        },
        tenantsLegalLiability: { deductible: null, amount: null },
      },
      commercial_general_liability_section_three: [
        {
          key: getUniqueId(),
          label: "",
          amount: null,
          deductible: null,
        },
      ],
      commercial_general_liability_section_five: [
        {
          key: getUniqueId(),
          label: "",
          amount: null,
          deductible: null,
        },
      ],
      commercial_general_liability_section_four: {
        description: {
          label: "",
          amount: null,
          deductible: null,
        },
        building: { amount: null, deductible: null },
        earthquake: { amount: null, deductible: null },
        equipment: { amount: null, deductible: null },
        flood: { amount: null, deductible: null },
        sewerBackup: { amount: null, deductible: null },
        stock: { amount: null, deductible: null },
      },
      commercial_general_liability_section_six: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_seven: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_eight: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_nine: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],

      excess_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      excess_liability_section_two: {
        type1: "",
        type2: "",
        umbrella_form_amount: "",
        umbrella_form_deductible: "",

        aggregate_form_amount: "",
        aggregate_form_deductible: "",
      },

      other_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      other_liability_section_two: [
        { key: getUniqueId(), label: "", deductible: null, amount: null },
      ],

      cancelation_number_of_days: null,
      brokerage_full_name: null,
      brokerage_mailing_address: null,
      brokerage_customer_code: null,
      additional_name_insured_yes_or_no: null,
      additional_name_insured_name: null,
      additional_name_insured_mailing_address: null,
      certificate_authorization_branch_name: null,
      certificate_authorization_assigned_to: null,
      certificate_authorization_phone_number: null,
      certificate_authorization_fax_number: null,
      certificate_authorization_email: null,
      certificate_authorization_date: moment().format("YYYY-MM-DD"),
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  garageCertificate: {
    data: {
      customer_policy_id: 0,
      other_liability: null,
      cgl: "",
      garage_liability: "",
      incremental_number: null,
      vehicle_unit_number_or_vin: null,
      certificate_holder_name: null,
      certificate_holder_address: null,
      certificate_holder_email: null,
      certificate_holder_phone: null,
      description_of_operations: [
        { key: getUniqueId(), label: "Line 1", value: "" },
        { key: getUniqueId(), label: "Line 2", value: "" },
        { key: getUniqueId(), label: "Line 3", value: "" },
        { key: getUniqueId(), label: "Line 4", value: "" },
      ],
      commercial_general_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      commercial_general_liability_section_two: {
        eachOccurrence: { deductible: null, amount: null },
        dropDownPersonalAndAdvertInjury: {
          deductible: null,
          amount: null,
          value: "",
        },
        employerLiability: { deductible: null, amount: null },
        generalAggregate: { deductible: null, amount: null },
        hiredAutomobiles: { deductible: null, amount: null },
        medicalPayments: { deductible: null, amount: null },
        nonOwnedAutomobiles: { deductible: null, amount: null },
        productsAndCompletedOperationsAggregate: {
          deductible: null,
          amount: null,
        },
        tenantsLegalLiability: { deductible: null, amount: null },
      },
      commercial_general_liability_section_three: [
        {
          key: getUniqueId(),
          label: "",
          amount: null,
          deductible: null,
        },
      ],
      commercial_general_liability_section_five: [
        {
          key: getUniqueId(),
          label: "",
          amount: null,
          deductible: null,
        },
      ],
      commercial_general_liability_section_four: {
        description: { label: "", amount: null, deductible: null },
        building: { amount: null, deductible: null },
        earthquake: { amount: null, deductible: null },
        equipment: { amount: null, deductible: null },
        flood: { amount: null, deductible: null },
        sewerBackup: { amount: null, deductible: null },
        stock: { amount: null, deductible: null },
      },
      commercial_general_liability_section_six: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_seven: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_eight: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],
      commercial_general_liability_section_nine: [
        { key: getUniqueId(), label: "", amount: null, deductible: null },
      ],

      other_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      other_liability_section_two: [
        { key: getUniqueId(), coverage: "", amount: null, deductible: null },
      ],
      garage_liability_section_one: {
        effective_date: "",
        expiry_date: "",
        policy_number: "",
        insurance_company: "",
      },
      garage_liability_section_two: [
        {
          key: getUniqueId(),
          label: "Third Party Liability",
          amount: null,
          deductible: null,
        },
        {
          key: getUniqueId(),
          label: "Accident Benefits",
          amount: null,
          deductible: null,
        },
        {
          key: getUniqueId(),
          label: "Uninsured Automobile Coverage",
          amount: null,
          deductible: null,
        },
        { key: getUniqueId(), label: "DCPD", amount: null, deductible: null },
      ],
      garage_liability_section_three: {
        amount: null,
        deductible: null,
        lossOrDamage: "",
      },
      garage_liability_section_four: {
        label1: "",
        label1Amount: null,
        label1Deductible: null,
        label2: "",
        label2Amount: null,
        label2Deductible: null,
      },

      excess_liability_section_one: {
        insurance_company: "",
        policy_number: "",
        effective_date: "",
        expiry_date: "",
      },
      excess_liability_section_two: {
        umbrella_form_amount: "",
        umbrella_form_deductible: "",

        aggregate_form_amount: "",
        aggregate_form_deductible: "",
      },

      // other_liability_section_one: {
      //   insurance_company: "",
      //   policy_number: "",
      //   effective_date: "",
      //   expiry_date: "",
      // },
      // other_liability_section_two: [
      //   { key: getUniqueId(), label: "", deductible: null, amount: null },
      // ],

      cancelation_number_of_days: null,
      brokerage_full_name: null,
      brokerage_mailing_address: null,
      brokerage_customer_code: null,
      additional_name_insured_yes_or_no: null,
      additional_name_insured_name: null,
      additional_name_insured_mailing_address: null,
      certificate_authorization_branch_name: null,
      certificate_authorization_assigned_to: null,
      certificate_authorization_phone_number: null,
      certificate_authorization_fax_number: null,
      certificate_authorization_email: null,
      certificate_authorization_date: moment().format("YYYY-MM-DD"),
      status: "ACTIVE",
      certificate_holder_section_one: "",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  paymentAuthorization: {
    data: {
      customer_policy_id: null,
      customer_id: null,
      policy_type: null,
      applicant_name: null,
      contact_type: null,
      applicant_address: null,
      applicant_unit_or_suite: null,
      applicant_city: null,
      applicant_province_or_state: null,
      applicant_postal_code: null,
      applicant_contact_number: null,
      applicant_preferred_document_language: "E",
      applicant_email_address: "",
      applicant_website_address: "",
      brokerage_branch_name: null,
      brokerage_full_address: null,
      brokerage_postal_code: null,
      brokerage_broker_code: null,
      brokerage_contact_name: null,
      brokerage_phone_number: null,
      brokerage_fax_number: null,
      brokerage_contract_number: null,
      brokerage_sub_contract_number: null,
      brokerage_group_or_program_name: null,
      brokerage_group_id: null,
      brokerage_broker_client_id: null,
      brokerage_company_client_id: null,
      incremental_number: null,
      insurance_company_name: null,
      insurance_company_address: null,
      insurance_company_city: null,
      insurance_company_country: null,
      insurance_company_postal_code: null,
      insurance_company_province_or_state: null,
      insurance_company_unit_or_suite: null,
      policy_number: null,
      request: null,
      broker_code: null,
      total_estimated_policy_premium: null,
      installment_fee: null,
      tax_percentage: null,
      tax: null,
      percentage_finance: null,
      finance_charges: null,
      total_estimated_cost: null,
      mode_of_payment: null,
      plan_type: null,
      financial_institution_or_bank_name: null,
      address_bank: null,
      bank_city: null,
      bank_country: null,
      bank_postal_code: null,
      bank_province_or_state: null,
      bank_unit_or_suite: null,
      account_holder_name: null,
      address_account_holder: null,
      transit_number: null,
      institution_number: null,
      account_number: null,
      account_holder_unit_or_suite: null,
      account_holder_city: null,
      account_holder_country: null,
      account_holder_postal_code: null,
      account_holder_province_or_state: null,
      type_of_payment: null,
      down_payment_amount: null,
      insurance_company_additional_charges: null,
      broker_additional_charges: null,
      full_payment_amount: null,
      installment_amount: null,
      next_payment_date: null,
      electrical_year: null,
      consent_and_disclosure: [
        {
          sentence:
            "I/We hereby authorize the named financial institution above to debit my/our account for all payments payable to",
          checked: false,
        },
      ],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  privacyConsent: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      customer_id: 0,
      customer_name: null,
      email_address: null,
      text_field: null,
      modified_by_id: null,
      insert_ts: null,
      create_ts: null,
      status: "ACTIVE",
      created_by_id: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
};
