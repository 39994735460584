import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { api } from "../../../api/api";
import { IBusinessPolicyToolFloater } from "./businesPolicyToolFloater.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_FAILED";

export const fetchBusinessPolicyToolFloaterListProgress = () =>
  action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_PROGRESS);
export const fetchBusinessPolicyToolFloaterListSuccess = (
  data: IBusinessPolicyToolFloater[]
) => action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_SUCCESS, { data });
export const fetchBusinessPolicyToolFloaterListFailed = () =>
  action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_LIST_FAILED);

export const fetchBusinessPolicyToolFloaterListAsync =
  (
    customerPolicyId: number,
    status?: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyToolFloaterListProgress());

      let finalUrl = `/policy/get-policy-business-tool-floater?customer_policy_id=${customerPolicyId}`;

      if (status !== undefined && status !== "-1") {
        finalUrl += `&status=${status}`;
      }
      const res = await api.get(finalUrl);
      const data: IBusinessPolicyToolFloater[] = res.data.data;
      dispatch(fetchBusinessPolicyToolFloaterListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyToolFloaterListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyToolFloaterAsync =
  (
    data: IBusinessPolicyToolFloater,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-tool-floater`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Tool Floater saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_PROGRESS =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_PROGRESS";
export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_SUCCESS =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_SUCCESS";
export const FETCH_BUSINESS_POLICY_TOOL_FLOATER_FAILED =
  "FETCH_BUSINESS_POLICY_TOOL_FLOATER_FAILED";

export const fetchBusinessPolicyToolFloaterProgress = () =>
  action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_PROGRESS);
export const fetchBusinessPolicyToolFloaterSuccess = (
  data: IBusinessPolicyToolFloater
) => action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_SUCCESS, { data });
export const fetchBusinessPolicyToolFloaterFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_TOOL_FLOATER_FAILED, { errorMessage });

export const fetchBusinessPolicyToolFloaterAsync =
  (
    customerPolicyId: number,
    incrementNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyToolFloaterProgress());
      const res = await api.get(
        `/policy/get-policy-business-tool-floater?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incrementNumber}`
      );
      const data: IBusinessPolicyToolFloater[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyToolFloaterSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyToolFloaterFailed(
            "Oops! We couldn't find any records associated with your tool floater number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyToolFloaterFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_BUSINES_POLICY_TOOL_FLOATER_STATE =
  "CLEAR_BUSINES_POLICY_TOOL_FLOATER_STATE";
export const CLEAR_BUSINES_POLICY_TOOL_FLOATER =
  "CLEAR_BUSINES_POLICY_TOOL_FLOATER";

export const clearBusinessToolFloaterRiskState = () =>
  action(CLEAR_BUSINES_POLICY_TOOL_FLOATER_STATE);

export const clearBusinessToolFloater = () =>
  action(CLEAR_BUSINES_POLICY_TOOL_FLOATER);
