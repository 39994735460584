import produce from "immer";
import { CommAutoPolicyFormsActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { intitialCommAutoPolicyFormState } from "./commAutoPolicyForm.types";
import {
  CLEAR_COMM_AUTO_POLICY_FORMS_STATE,
  FETCH_COMM_AUTO_POLICY_FORMS_LIST_FAILED,
  FETCH_COMM_AUTO_POLICY_FORMS_LIST_PROGRESS,
  FETCH_COMM_AUTO_POLICY_FORMS_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED,
  FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS,
  FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS,
  FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS,
  FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS,
  FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS,
} from "./commAutoPolicyFormsActions";
import { LoadState } from "../../../constants/enums";

export const commAutoPolicyFormsReducer = (
  state: IStoreState["commAuto"]["policyForms"] = intitialCommAutoPolicyFormState,
  action: CommAutoPolicyFormsActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTO_POLICY_FORMS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.InProgress;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_COMM_AUTO_POLICY_FORMS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Loaded;
        draftState.policyFormsList.list = data;
        draftState.policyFormsList.totalRecords = totalRecords;
      });
      return newState;
    }

    case FETCH_COMM_AUTO_POLICY_FORMS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.policyFormsList.loading = LoadState.Failed;
        draftState.policyFormsList.list = [];
        draftState.policyFormsList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.liabilitySlips.loading = LoadState.InProgress;
        draftState.liabilitySlips.data =
          intitialCommAutoPolicyFormState["liabilitySlips"].data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Loaded;
        draftState.liabilitySlips.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Failed;
        draftState.liabilitySlips.data =
          intitialCommAutoPolicyFormState["liabilitySlips"].data;
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.InProgress;
        draftState.liabilityInsurance.data =
          intitialCommAutoPolicyFormState["liabilityInsurance"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Loaded;
        draftState.liabilityInsurance.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Failed;
        draftState.liabilityInsurance.data =
          intitialCommAutoPolicyFormState["liabilityInsurance"]["data"];
        draftState.liabilityInsurance.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.InProgress;
        draftState.opcf16.data =
          intitialCommAutoPolicyFormState["opcf16"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Loaded;
        draftState.opcf16.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Failed;
        draftState.opcf16.data =
          intitialCommAutoPolicyFormState["opcf16"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.InProgress;
        draftState.opcf17.data =
          intitialCommAutoPolicyFormState["opcf17"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Loaded;
        draftState.opcf17.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Failed;
        draftState.opcf17.data =
          intitialCommAutoPolicyFormState["opcf17"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.InProgress;
        draftState.opcf28A.data =
          intitialCommAutoPolicyFormState["opcf28A"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Loaded;
        draftState.opcf28A.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Failed;
        draftState.opcf28A.data =
          intitialCommAutoPolicyFormState["opcf28A"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.InProgress;
        draftState.paymentAuthorization.data =
          intitialCommAutoPolicyFormState["paymentAuthorization"]["data"];
        draftState.paymentAuthorization.error = null;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Loaded;
        draftState.paymentAuthorization.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_AUTHORIZATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.paymentAuthorization.loading = LoadState.Failed;
        draftState.paymentAuthorization.data =
          intitialCommAutoPolicyFormState["paymentAuthorization"]["data"];
        draftState.paymentAuthorization.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.InProgress;
        draftState.privacyConsent.data =
          intitialCommAutoPolicyFormState["privacyConsent"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Loaded;
        draftState.privacyConsent.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_CONSENT_FORM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.privacyConsent.loading = LoadState.Failed;
        draftState.privacyConsent.data =
          intitialCommAutoPolicyFormState["privacyConsent"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.retireeDiscount.loading = LoadState.InProgress;
        draftState.retireeDiscount.data =
          intitialCommAutoPolicyFormState["retireeDiscount"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireeDiscount.loading = LoadState.Loaded;
        draftState.retireeDiscount.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_RETIREE_DISCOUNT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.retireeDiscount.loading = LoadState.Failed;
        draftState.retireeDiscount.data =
          intitialCommAutoPolicyFormState["retireeDiscount"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.InProgress;
        draftState.rideSharing.data =
          intitialCommAutoPolicyFormState["rideSharing"]["data"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.Loaded;
        draftState.rideSharing.data = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_SHARING_FILLABLE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rideSharing.loading = LoadState.Failed;
        draftState.rideSharing.data =
          intitialCommAutoPolicyFormState["rideSharing"]["data"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_COMM_AUTO_POLICY_FORMS_STATE: {
      return intitialCommAutoPolicyFormState;
    }

    default: {
      return state;
    }
  }
};
