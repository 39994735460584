import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAutomobileCertificatesState } from "./automobileCertificates.types";
import { AutomobileCertificatesActions } from ".";
import {
  CLEAR_AUTOMOBILE_GARAGE_FORM,
  FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED,
  FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS,
  FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS,
} from "./automobileCertificatesActions";

export const automobileCertificatesReducer = (
  state: IStoreState["automobile"]["certificates"] = initialAutomobileCertificatesState,
  action: AutomobileCertificatesActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_GARAGE_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.InProgress;
        draftState.garageform.list = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_GARAGE_FORM_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.Loaded;
        draftState.garageform.list = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_GARAGE_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.loading = LoadState.Failed;
        draftState.garageform.list = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_GARAGE_FORM: {
      const newState = produce(state, (draftState) => {
        draftState.garageform.garageLoading = LoadState.NotLoaded;
        draftState.garageform.garage =
          initialAutomobileCertificatesState["garageform"]["garage"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
