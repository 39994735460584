/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  isAlphaNumeric,
  renderDropdownValue,
} from "../../../../../../../helpers";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import {
  addAutomobileVehicleAsync,
  fetchAutomobileVehicleAsync,
} from "../../../../../../../redux/automobileVehicle/automobileVehicleActions";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { fetchAutomobileDriverListAsync } from "../../../../../../../redux/automobileDriver/automobileDriverActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import {
  COMMON_STATUS,
  product_type_options,
  type_code_options,
  vehicle_use,
} from "../../../../../../../constants/constants";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { ExtendableSelect } from "../../../../../../../components/formsComponents/ExtendableSelect";

export const AutomobileVehicleInformation: React.FC<{
  customerId?: number;
  customerPolicyId?: number;
  vehicleNumber?: number;
  changeStep: (value: number) => void;
}> = (props) => {
  const { customerId, customerPolicyId, vehicleNumber } = props;

  const { VehicleInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.vehicle
  );

  const customerInfo = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.basicDetails.customerInfo.data
  );

  const { driverInformation } = useSelector(
    (storeState: IStoreState) => storeState.automobile.driver
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      ...VehicleInformation.data,
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.vin) {
        errors.vin = "VIN is required field.";
      } else if (values.vin.length < 17 || values.vin.length > 17) {
        errors.vin = "VIN number must be 17 digits.";
      } else if (!isAlphaNumeric(values.vin)) {
        errors.vin = "VIN number must be alpha numeric.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (customerId && customerPolicyId) {
        setLoading(true);
        dispatch(
          addAutomobileVehicleAsync(
            {
              ...values,
              customer_id: customerId,
              customer_policy_id: customerPolicyId.toString(),
            },
            (isSuccess) => {
              if (isSuccess) {
                if (buttonClicked === "submit") {
                  navigate(
                    `/automobile-policy/${customerId}/vehicle-tab/${customerPolicyId}?tab=0`
                  );
                } else {
                  window.scrollTo(0, 0);
                  props.changeStep(1);
                }
              }
              setLoading(false);
            }
          )
        );
      }
    },
  });

  // const getLeasedValue = (owned: string | null): string | null => {
  //   if (owned === "No") return "No";
  //   if (owned === "Yes") return "Yes";
  //   return null;
  // };

  // const getOwnedValue = (leased: string | null): string | null => {
  //   if (leased === "No") return "No";
  //   if (leased === "Yes") return "Yes";
  //   return null;
  // };

  // Set initial form values with correct leased and owned logic
  React.useEffect(() => {
    const { ...rest } = VehicleInformation.data;

    setValues({
      ...rest,
      customer_name: customerInfo.customer_name,
      policy_number: customerInfo.policy_number,
    });
  }, [VehicleInformation.data, customerInfo, setValues]);

  React.useEffect(() => {
    if (customerPolicyId && vehicleNumber) {
      dispatch(fetchAutomobileVehicleAsync(customerPolicyId, vehicleNumber));
    }
  }, [customerPolicyId, vehicleNumber]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchAutomobileDriverListAsync(customerPolicyId));
    }
  }, []);

  const driversDropdown = React.useMemo(() => {
    return driverInformation.driversList.map((item) => {
      return {
        label: item.driver_number + ` (${item.driver_name})`,
        value: item.driver_number || "--",
      };
    });
  }, [driverInformation.driversList]);

  return (
    <PageLoader
      loading={VehicleInformation.loading === LoadState.InProgress}
      error={
        VehicleInformation.error
          ? { message: VehicleInformation.error }
          : undefined
      }
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Number</CustomFormLabel>
            <CustomTextField
              name="incremental_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.incremental_number}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Customer Name</CustomFormLabel>
            <CustomTextField
              name="customer_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.customer_name}
              disabled
              //onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Unit Number</CustomFormLabel>
            <CustomTextField
              name="unit_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.unit_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Year</CustomFormLabel>
            <CustomDatePicker
              views={["year"]}
              fullWidth
              value={values.year}
              inputFormat="YYYY"
              onChange={(newValue, a, momentObject) => {
                const newDate = moment(momentObject).format("YYYY");
                setFieldValue("year", momentObject ? newDate : null);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Make</CustomFormLabel>
            <CustomTextField
              name="make"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.make}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Model</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              name="model"
              value={values.model}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>VIN</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="vin"
              value={values.vin}
              error={errors.vin ? true : false}
              helperText={errors.vin}
              inputProps={{ maxLength: 17 }}
              onChange={(e) =>
                setFieldValue("vin", e.target.value.toUpperCase())
              }
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Actual Cash Value</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.acutal_cash_value}
              onChange={(value) => {
                setFieldValue("acutal_cash_value", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>List Price New</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.list_price_new}
              onChange={(value) => {
                setFieldValue("list_price_new", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Owned</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.owned}
              name="owned"
              onChange={handleChange}
              placeholder="Select"
              displayEmpty
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Leased</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.leased}
              name="owned"
              onChange={handleChange}
              placeholder="Select"
              displayEmpty
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Purchase Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.purchase_date || ""}
              onChange={(newValue) => {
                setFieldValue("purchase_date", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Purchase Condition</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="puchase_condition"
              value={values.puchase_condition}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["New", "Used", "Demo"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Purchase price ($)</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.purchase_price}
              onChange={(value) => {
                setFieldValue("purchase_price", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Plate Number</CustomFormLabel>
            <CustomTextField
              name="plate_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.plate_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Code</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_code_one"
              value={values.vehicle_code_one}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Option 1", "Option 2"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Vehicle Code*</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="vehicle_code_two"
              value={values.vehicle_code_two}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Option 1", "Option 2"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Body Type</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.body_type}
              options={[
                "Tow Truck",
                "Tractor",
                "Van",
                "Trailer(Dry van)",
                "Trailer(Reefer)",
                "Chassis",
                "Container",
                "PPV",
                "Pickup Truck",
                "Service Van/ Truck",
                "Trailer-Office Use",
              ].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("body_type", newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Gross Vehicle Weight</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="gross_vehicle_weight"
              value={values.gross_vehicle_weight}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Location Code</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="location_code"
              value={values.location_code}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Fleet Identity</CustomFormLabel>
            <CustomTextField
              name="fleet_identity"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.fleet_identity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Anti-Lock Brakes</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              name="anti_lock_brakes"
              value={values.anti_lock_brakes}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Fuel Type</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="fuel_type"
              value={values.fuel_type}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Territory Code</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="territory_code"
              value={values.territory_code}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Use of Vehicle" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Vehicle Use</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.vehicle_use}
              options={vehicle_use.map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("vehicle_use", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Annual Distance</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              name="annual_distance"
              value={values.annual_distance}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Radius of Operation</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.radius_of_operation}
              options={[
                "35",
                "40",
                "50",
                "60",
                "80",
                "90",
                "120",
                "160",
                "200",
                "250",
                "400",
                "400+",
              ].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("radius_of_operation", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Commute One-way (Kms)</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="commute"
              value={values.commute}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Annual kms (Business)</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              name="annual_kms"
              value={values.annual_kms}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Business Use %</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="business_use_percentage"
              value={values.business_use_percentage}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Driver Assignment" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Driver Assignment Primary</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="driver_assignment_primary"
              value={values.driver_assignment_primary}
              onChange={handleChange}
              displayEmpty
              fullWidth
              placeholder="Select one"
              options={driversDropdown}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Driver Assignment Occassional</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="driver_assignment_occassional"
              value={values.driver_assignment_occassional}
              onChange={handleChange}
              displayEmpty
              fullWidth
              placeholder="Select one"
              options={driversDropdown}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Driver Assignment Secondary One</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="driver_assignment_secondary_one"
              value={values.driver_assignment_secondary_one}
              onChange={handleChange}
              displayEmpty
              fullWidth
              placeholder="Select one"
              options={driversDropdown}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Driver Assignment Secondary Two</CustomFormLabel>
            <ControlledCustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="driver_assignment_secondary_two"
              value={values.driver_assignment_secondary_two}
              onChange={handleChange}
              displayEmpty
              fullWidth
              placeholder="Select one"
              options={driversDropdown}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Vehicle - Rate Groups/Class" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Coll/AP</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="rate_groups_or_class_coll_or_ap"
              value={values.rate_groups_or_class_coll_or_ap}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Comp/SP</CustomFormLabel>
            <CustomTextField
              name="rate_groups_or_class_comp_or_sp"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.rate_groups_or_class_comp_or_sp}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <CustomFormLabel>AB</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              name="rate_groups_or_class_ab"
              value={values.rate_groups_or_class_ab}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>DCPD</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="rate_groups_or_class_dcpd"
              value={values.rate_groups_or_class_dcpd}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>TP</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="rate_groups_or_class_tp"
              value={values.rate_groups_or_class_tp}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <CustomFormLabel>Rating Class</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="rate_groups_or_class_rating_class"
              value={values.rate_groups_or_class_rating_class}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Driving Record" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>BI</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="driving_record_bi"
              value={values.driving_record_bi}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>PD</CustomFormLabel>
            <CustomTextField
              name="driving_record_pd"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.driving_record_pd}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>AB</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="string"
              fullWidth
              name="driving_record_ab"
              value={values.driving_record_ab}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>DCPD</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="driving_record_dcpd"
              value={values.driving_record_dcpd}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Coll/AP</CustomFormLabel>
            <CustomTextField
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              name="driving_record_coll_or_ap"
              value={values.driving_record_coll_or_ap}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Anti-Theft Devices" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Anti-Theft Devices</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.anti_theft_devices}
              options={["Y", "N"].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("anti_theft_devices", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}></Grid>
          <Grid item xs={12} lg={4}></Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Device Type Code One</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.device_type_code_one}
              options={type_code_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("device_type_code_one", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Device Type Code Two</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.device_type_code_two}
              options={type_code_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("device_type_code_two", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Device Type Code Three</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.device_type_code_three}
              options={type_code_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("device_type_code_three", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Product Type Code One</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.product_type_code_one}
              options={product_type_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("product_type_code_one", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Product Type Code Two</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.product_type_code_two}
              options={product_type_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("product_type_code_two", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Product Type Code Three</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.product_type_code_three}
              options={product_type_options.map((template) => {
                return { label: template.label, value: template.value };
              })}
              onChange={(newValue) => {
                setFieldValue("product_type_code_three", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Characteristics Code One</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.characteristics_code_one}
              options={["Option 1", "Option 2"].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("characteristics_code_one", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Characteristics Code Two</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.characteristics_code_two}
              options={["Option 1", "Option 2"].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("characteristics_code_two", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Characteristics Code Three</CustomFormLabel>
            <ExtendableSelect
              placeholder="Select one"
              value={values.characteristics_code_three}
              options={["Option 1", "Option 2"].map((policyType) => {
                return { label: policyType, value: policyType };
              })}
              onChange={(newValue) => {
                setFieldValue("characteristics_code_three", newValue);
              }}
            />
          </Grid>
        </Grid>

        <LabelCard heading="Vehicle Modifications" />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Modification</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.modification}
              name="modification"
              onChange={handleChange}
              placeholder="Select"
              displayEmpty
              options={[
                { label: "Y", value: "Yes" },
                { label: "N", value: "No" },
              ]}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Value of Modification</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.value_of_modification}
              onChange={(value) => {
                setFieldValue("value_of_modification", value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Engine</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="engine"
              value={values.engine}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Ground Clearance</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="ground_clearance"
              value={values.ground_clearance}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Interior Rollcage</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="interior_rollcage"
              value={values.interior_rollcage}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tires</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="tires"
              value={values.tires}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Coversion for Disabled Persons</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="coversion_for_disabled_persons"
              value={values.coversion_for_disabled_persons}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Sound Equipment</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="sound_equipment"
              value={values.sound_equipment}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Grid Rated</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="grid_rated"
              value={values.grid_rated}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>No Frills</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="no_frills"
              value={values.no_frills}
              onChange={handleChange}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["Y", "N"].map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.status}
              name="status"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={COMMON_STATUS}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("submit")}
          >
            Save & Exit
          </RoleBasedCustomButton>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={loading}
            variant="contained"
            onClick={() => setButtonClicked("save")}
          >
            Save & Next
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
