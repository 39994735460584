
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IAutomobileCSIOPolicyState } from "./automobileCSIOPolicyChangeReq.types";
import { transformCSIOToPolicyChangeReq } from "./transformCSIOToPolicyChangeReq";


export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_PROGRESS = "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_SUCCESS = "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_FAILED = "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_FAILED";

export const fetchCSIOAutomobilePolicyReqListProgress = () => action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_PROGRESS);
export const fetchCSIOAutomobilePolicyReqListSuccess = (list: IAutomobileCSIOPolicyState[], totalRecords: number) =>
  action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchCSIOAutomobilePolicyReqListFailed = () => action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_LIST_FAILED);

export const fetchCSIOAutomobilePolicyReqListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    searchValue: string,
    searchType: string[],
    date: IDate,
    map: string,
    customerpolicyId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCSIOAutomobilePolicyReqListProgress());
      let finalUrl = `/csio/get-csio-retrieve-messages?column=xml_message&value=PersAutoPolicyModRs&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/csio/get-csio-retrieve-messages?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      if (map && map !== "-1") {
        finalUrl += `&is_map=${map}&column=xml_message&value=PersAutoPolicyModRs`
      }
      // if (customerpolicyId) {
      //   finalUrl += `&customer_policy_id=${customerpolicyId}`;
      // }

      const res = await api.get(finalUrl);
      const data: IAutomobileCSIOPolicyState[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCSIOAutomobilePolicyReqListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCSIOAutomobilePolicyReqListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };


export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_FAILED";

export const fetchAutomobileCSIOApplicationPolicyReqProgress = () =>
  action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_PROGRESS);
export const fetchAutomobileCSIOApplicationPolicyReqSuccess = (
  data: IAutomobileCSIOPolicyState
) => action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileCSIOApplicationPolicyReqFailed = (error?: string) =>
  action(FETCH_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_FAILED, {error});

export const fetchAutomobileCSIOApplicationPolicyReqAsync =
  (
    messageGUid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
  //   try {
  //     dispatch(fetchAutomobileCSIOApplicationPolicyReqProgress());
  //     const res = await api.get(
  //       `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
  //     );
  //     const data = res.data.data;
  //     if (data.length > 0) {
  //       const { xml_json_message } = data[0];
  //       const parsedData = JSON.parse(xml_json_message);
  //       const result = transformCSIOToAutomobile(parsedData);
  //       dispatch(fetchAutomobileCSIOApplicationPolicyReqSuccess(result));
  //     } else {
  //       dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
  //     }
  //   } catch (err: any) {
  //     dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed());
  //     dispatch(
  //       showMessage({
  //         type: "error",
  //         message: err.response.data.message,
  //         displayAs: "snackbar",
  //       })
  //     );
  //   }
  // };
  try {
    dispatch(fetchAutomobileCSIOApplicationPolicyReqProgress());
    const res = await api.get(
      `/csio/get-csio-retrieve-messages?message_guid=${messageGUid}`
    );
    
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      console.log("AutomobileData:", data);
      
      if (data.length > 0) {
        const { xml_json_message } = data[0];
        console.log("xml_json_message:", xml_json_message);
        
        try {
          const parsedData = JSON.parse(xml_json_message);
          console.log("parsedData:", parsedData);
          const result = transformCSIOToPolicyChangeReq(parsedData);
          console.log("result:", result);
          // dispatch(fetchAutomobileCSIOApplicationPolicyReqSuccess(result));
        } catch (parseError) {
          console.error("JSON parse error:", parseError);
          dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed("Failed to parse JSON message"));
        }
      } else {
        console.log("No record found.");
        dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } else {
      console.log("No record found in response data.");
      dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
    }
  } catch (err: any) {
    console.error("Fetch error:", err);
    dispatch(fetchAutomobileCSIOApplicationPolicyReqFailed());
    dispatch(
      showMessage({
        type: "error",
        message: err.response?.data?.message || "An error occurred",
        displayAs: "snackbar",
      })
    );
  }
};

// export const addAutomobileCSIOApplicationPolicyReq =
//   (
//     data: IAutomobileCSIOPolicyState,
//     onCallback: (isSuccess: boolean) => void
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(saveLoaderProgress())
//       await api.post("/policy/edit-auto-automobile-application", {
//         ...data,
//       });
//       onCallback(true);
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Application saved successfully!",
//           displayAs: "snackbar",
//         })
//       );
//       dispatch(fetchAutomobileCSIOApplicationPolicyReqSuccess(data));
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         })
//       );
//     }
//     finally {
//       dispatch(saveLoaderCompleted())
//     }
//   };



export const CLEAR_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_STATE";

export const clearAutomobilePolicyReqState = () =>
  action(CLEAR_AUTOMOBILE_CSIO_POLICY_REQ_APPLICATION_STATE);
