import { LoadState } from "../../constants/enums";

export interface IAutomobileVehicleState {
  VehicleInformation: {
    data: IAutomobileVehicleInformation;
    loading: LoadState;
    vehiclesList: IAutomobileVehicleInformation[];
    vehiclesListLoading: LoadState;
    error: string | null;
  };
  additionalInformation: {
    data: IAutomobileAdditionalInformation;
    loading: LoadState;
    additionalInformationList: IAutomobileAdditionalInformation[];
    additionalInformationListLoading: LoadState;
    error: string | null;
  };
  interestedParty: {
    data: IAutomobileInterestedParty;
    loading: LoadState;
    interestedPartyList: IAutomobileInterestedParty[];
    interestedPartyListLoading: LoadState;
    error: string | null;
  };
  vehicleNumber: number | null;
}

export interface IAutomobileVehicleInformation {
  customer_policy_id: string | null;
  customer_id: number | null;
  customer_name: string | null;
  policy_number: string | null;
  incremental_number: number | null;
  vehicle_number: string | null;
  unit_number: string | null;
  year: string;
  make: string | null;
  model: string | null;
  vin: string | null;
  acutal_cash_value: string | null;
  list_price_new: string | null;
  owned: string | null;
  leased: string | null;
  purchase_date: string | null;
  puchase_condition: string | null;
  purchase_price: string | null;
  plate_number: string | null;
  vehicle_code_one: string | null;
  vehicle_code_two: string | null;
  body_type: string | null;
  gross_vehicle_weight: string | null;
  location_code: string | null;
  fleet_identity: string | null;
  anti_lock_brakes: string | null;
  fuel_type: string | null;
  territory_code: string | null;
  commute: string | null;
  annual_distance: string | null;
  vehicle_use: string | null;
  radius_of_operation: string | null;
  annual_kms: string | null;
  business_use_percentage: string | null;
  driver_assignment_primary: string | null;
  driver_assignment_occassional: string | null;
  driver_assignment_secondary_one: string | null;
  driver_assignment_secondary_two: string | null;
  rate_groups_or_class_coll_or_ap: string | null;
  rate_groups_or_class_comp_or_sp: string | null;
  rate_groups_or_class_ab: string | null;
  rate_groups_or_class_dcpd: string | null;
  rate_groups_or_class_tp: string | null;
  rate_groups_or_class_rating_class: string | null;
  driving_record_bi: string | null;
  driving_record_pd: string | null;
  driving_record_ab: string | null;
  driving_record_dcpd: string | null;
  driving_record_coll_or_ap: string | null;
  anti_theft_devices: string | null;
  device_type_code_one: string | null;
  device_type_code_two: string | null;
  device_type_code_three: string | null;
  product_type_code_one: string | null;
  product_type_code_two: string | null;
  product_type_code_three: string | null;
  characteristics_code_one: string | null;
  characteristics_code_two: string | null;
  characteristics_code_three: string | null;
  modification: string | null;
  value_of_modification: string | null;
  engine: string | null;
  ground_clearance: string | null;
  interior_rollcage: string | null;
  tires: string | null;
  grid_rated: string | null;
  no_frills: string | null;
  coversion_for_disabled_persons: string | null;
  sound_equipment: string | null;
  status: string;
}

export interface IAutomobileAdditionalInformation {
  status: unknown;
  incremental_number: number | null;
  customer_policy_id: number | null;
  facility_indicator: string | null;
  multi_car_discount: string | null;
  carry_goods_for_compensation: string | null;
  vehicle_equipped_with_winter_tires: string | null;
  have_a_copy_of_the_winter_tire_invoice: string | null;
  winter_tire_purchase_date: string;
  have_photos_of_this_vehicle: string | null;
  have_a_copy_of_vehicle_ownership: string | null;
  have_a_copy_of_the_bill_of_sale: string | null;
  rent_or_lease_to_others: string | null;
  existing_unrepaired_damage: string | null;
  carry_passengers_for_compensation: string | null;
  vehicle_used_outside_canada: string | null;
  explosive_or_radioactive_material: string | null;
  no_of_months: string | null;
  vehicle_used_in_car_pools: string | null;
  no_of_car_pool_uses: string | null;
  no_of_car_pool_passengers: string | null;
  time_period: string | null;
  vehicle_salvaged: string | null;
  pre_inspection_code: string | null;
  reason_pre_inspection_code_not_required: string | null;
}

export interface IAutomobileInterestedParty {
  customer_policy_id: number;
  vehicle_id: number | null;
  number: number | null;
  ownership: string | null;
  owner_operator_or_lessee: string | null;
  interested_party_code: string | null;
  interest: string | null;
  name: string | null;
  address: string | null;
  city: string | null;
  unit_or_suite: string | null;
  postal_code: string | null;
  country: string | null;
  province_or_state: string | null;
  status: "ACTIVE";
}

export const INITIAL_INTERESTED_STATE: IAutomobileInterestedParty = {
  customer_policy_id: 0,
  vehicle_id: null,
  number: null,
  ownership: null,
  owner_operator_or_lessee: null,
  interested_party_code: null,
  interest: null,
  name: null,
  address: null,
  city: null,
  unit_or_suite: null,
  postal_code: null,
  country: null,
  province_or_state: null,
  status: "ACTIVE",
};

export const INITIAL_VEHICLE_STATE: IAutomobileVehicleInformation = {
  customer_policy_id: null,
  customer_id: null,
  customer_name: null,
  policy_number: null,
  incremental_number: null,
  vehicle_number: null,
  unit_number: null,
  year: "",
  make: null,
  model: null,
  vin: null,
  acutal_cash_value: null,
  list_price_new: null,
  owned: null,
  leased: null,
  purchase_date: null,
  puchase_condition: null,
  purchase_price: null,
  plate_number: null,
  vehicle_code_one: null,
  vehicle_code_two: null,
  body_type: null,
  gross_vehicle_weight: null,
  location_code: null,
  fleet_identity: null,
  anti_lock_brakes: null,
  fuel_type: null,
  territory_code: null,
  commute: null,
  annual_distance: null,
  vehicle_use: null,
  radius_of_operation: null,
  annual_kms: null,
  business_use_percentage: null,
  driver_assignment_primary: null,
  driver_assignment_occassional: null,
  driver_assignment_secondary_one: null,
  driver_assignment_secondary_two: null,
  rate_groups_or_class_coll_or_ap: null,
  rate_groups_or_class_comp_or_sp: null,
  rate_groups_or_class_ab: null,
  rate_groups_or_class_dcpd: null,
  rate_groups_or_class_tp: null,
  rate_groups_or_class_rating_class: null,
  driving_record_bi: null,
  driving_record_pd: null,
  driving_record_ab: null,
  driving_record_dcpd: null,
  driving_record_coll_or_ap: null,
  anti_theft_devices: null,
  device_type_code_one: null,
  device_type_code_two: null,
  device_type_code_three: null,
  product_type_code_one: null,
  product_type_code_two: null,
  product_type_code_three: null,
  characteristics_code_one: null,
  characteristics_code_two: null,
  characteristics_code_three: null,
  modification: null,
  value_of_modification: null,
  engine: null,
  ground_clearance: null,
  interior_rollcage: null,
  tires: null,
  grid_rated: null,
  no_frills: null,
  coversion_for_disabled_persons: null,
  sound_equipment: null,
  status: "ACTIVE",
  //   effective_time: moment().startOf('day'),
}

export const initialAutomoblieVehicleInformation: IAutomobileVehicleState = {
  VehicleInformation: {
    data: INITIAL_VEHICLE_STATE,
    loading: LoadState.NotLoaded,
    vehiclesList: [],
    vehiclesListLoading: LoadState.NotLoaded,
    error: null,
  },
  additionalInformation: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      facility_indicator: null,
      multi_car_discount: null,
      carry_goods_for_compensation: null,
      vehicle_equipped_with_winter_tires: null,
      have_a_copy_of_the_winter_tire_invoice: null,
      winter_tire_purchase_date: "",
      have_photos_of_this_vehicle: null,
      have_a_copy_of_vehicle_ownership: null,
      have_a_copy_of_the_bill_of_sale: null,
      vehicle_salvaged: null,
      rent_or_lease_to_others: null,
      existing_unrepaired_damage: null,
      carry_passengers_for_compensation: null,
      vehicle_used_outside_canada: null,
      explosive_or_radioactive_material: null,
      no_of_months: null,
      vehicle_used_in_car_pools: null,
      no_of_car_pool_uses: null,
      no_of_car_pool_passengers: null,
      time_period: null,
      pre_inspection_code: null,
      reason_pre_inspection_code_not_required: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
    additionalInformationList: [],
    additionalInformationListLoading: LoadState.NotLoaded,
  },
  interestedParty: {
    data: INITIAL_INTERESTED_STATE,
    loading: LoadState.NotLoaded,
    error: null,
    interestedPartyList: [],
    interestedPartyListLoading: LoadState.NotLoaded,
  },
  vehicleNumber: null,
};
